<template>
    <div class="popupSelect">
        <mt-popup
        v-model="showPopover"
        popup-transition="popup-fade"
        class="mint-popup"
        position="bottom">
            <div
            class="popoverItem"
            v-for="(item, index) in selectlist"
            :key="index"
            @click="selectItem(index)">
            {{ item.age }}
            </div>
        </mt-popup>
        <div class="info">
            <div class="select" v-if="selectlist.length > 1" @click="()=>this.showPopover = !this.showPopover">
            {{ selectlist[selectIndex].age }}
            <img
                :src="
                showPopover == true
                    ? 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/up@2x.png'
                    : 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/down@2x.png'
                "
                alt=""
            />
            </div>
            <div class="select" v-else>
            {{ selectlist[selectIndex].age }}
            </div>
        </div>
    </div>
</template>
<script>
    import popupSelect from "./popupSelect.js";
    export default popupSelect;
</script>
<style scoped>
    @import './popupSelect.css';
</style>
