<template>
  <div class="orderList">
    <div class="waper_header">
      <headers-select
      :user='false'
      :selectIndex="i"
      @selectItem="selectBaby"
      ></headers-select>
    </div>
    <div v-if="requestLoad">
      <div v-if="mixMallState || mixParadiseState">
          <div class="top">
            <!-- <div class="topChoose">
              <div
                :class="chooseNum == '实物商铺' ? 'isChoose' : 'noIsChoose'"
                @click="getChooseNum('实物商铺')"
              >
                商城订单
              </div>
              <div
                :class="chooseNum == '课程商铺' ? 'isChoose' : 'noIsChoose'"
                @click="getChooseNum('课程商铺')"
              >
                学堂订单
              </div>
            </div> -->
            <div class="search">
              <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/search@2x.png" alt="" />
              <input
                type="text"
                placeholder="请输入关键字"
                v-model="searchAbout"
                @blur="search($event)"
              />
            </div>
            <div class="classify" v-if="chooseNum == '实物商铺'">
              <div
                :class="currentTab == 10 ? 'choose' : 'nochoose'"
                @click="chooseKind(10)"
              >
                <div>全部{{ numList.orderCount }}</div>
                <div :class="currentTab == 10 ? 'blueline' : 'line'"></div>
              </div>
              <div
                :class="currentTab == 1 ? 'choose' : 'nochoose'"
                @click="chooseKind(1)"
              >
                <div>待付款{{ numList.noPayCount }}</div>
                <div :class="currentTab == 1 ? 'blueline' : 'line'"></div>
              </div>
              <div
                :class="currentTab == 2 ? 'choose' : 'nochoose'"
                @click="chooseKind(2)"
              >
                <div>待发货{{ numList.noExpressCount }}</div>
                <div :class="currentTab == 2 ? 'blueline' : 'line'"></div>
              </div>
              <!-- <div
                :class="currentTab == 3 ? 'choose' : 'nochoose'"
                @click="chooseKind(3)"
              >
                <div>待收货</div>
                <div :class="currentTab == 3 ? 'blueline' : 'line'"></div>
              </div> -->
              <div
                :class="currentTab == 4 ? 'choose' : 'nochoose'"
                @click="chooseKind(4)"
              >
                <div>已完成{{ numList.payCount }}</div>
                <div :class="currentTab == 4 ? 'blueline' : 'line'"></div>
              </div>
              <div
                :class="currentTab == 0 ? 'choose' : 'nochoose'"
                @click="chooseKind(0)"
              >
                <div>已取消{{ numList.cancelCount }}</div>
                <div :class="currentTab == 0 ? 'blueline' : 'line'"></div>
              </div>
            </div>
          </div>
          <div class="list" v-if="list.length != 0">
            <div
              class="item"
              v-for="(item, index) in list"
              :key="index"
              @click="toOMS(item.orderId, item.shopNumber)"
            >
              <div class="itemState">
                <div class="itemStateL">订单编号：{{ item.orderId }}</div>
                <div
                  class="itemStateR"
                  v-if="item.status == 0 && chooseNum == '实物商铺'"
                >
                  已取消
                </div>
                <div
                  class="itemStateR"
                  v-if="item.status == 1 && chooseNum == '实物商铺'"
                >
                  待付款
                </div>
                <div
                  class="itemStateR"
                  v-if="item.status == 2 && chooseNum == '实物商铺'"
                  style="color: #cccccc;"
                >
                  待发货
                </div>
                <div
                  class="itemStateR"
                  v-if="item.status == 3 && chooseNum == '实物商铺'"
                >
                  待收货
                </div>
                <div
                  class="itemStateR"
                  v-if="item.status == 4 && chooseNum == '实物商铺'"
                  style="color: #666666;"
                >
                  已完成
                </div>
              </div>
              <div class="itemImg" v-if="item.product.length > 1">
                <img
                  name='dynamicImg'
                  :src="
                    items.levelCoverUrl != '' ? items.levelCoverUrl+imgSizeQuality : items.productImg+imgSizeQuality
                  "
                  :key="indexs"
                  alt=""
                  v-for="(items, indexs) in item.product"
                />
              </div>
              <div v-else :key="indexs" v-for="(items, indexs) in item.product">
                <div class="itemImgs" v-if="chooseNum != '课程商铺'">
                  <div class="itemImgsL">
                    <img
                      name='dynamicImg'
                      :src="
                        items.levelCoverUrl != ''
                          ? items.levelCoverUrl+imgSizeQuality
                          : items.productImg+imgSizeQuality
                      "
                      alt=""
                    />
                    <div class="itemInfo">
                      <div class="itemInfoTit">
                        {{ items.productName }}
                      </div>
                      <div v-if="items.levelName">规格：{{ items.levelName }}</div>
                    </div>
                  </div>
                  <div class="itemImgsR">
                    <div class="itemRmoney">
                      <span>¥</span>{{ items.productPrice }}
                    </div>
                    <div>×{{ items.productQuantity }}</div>
                  </div>
                </div>
                <div class="itemClass" v-else>
                  <img name='dynamicImg' class="itemClassL" :src="items.productImg+imgSizeQuality" alt="" />
                  <div class="itemClassR">
                    <div class="itemInfoTit">
                      {{ items.productName }}
                    </div>
                    <div class="itemClassLi">
                      <span>{{ item.useUserName }}</span>
                      <span>{{ item.userTag }}</span>
                    </div>
                    <div
                      class="itemClassTxt"
                      v-if="
                        item.product[0].day > 0 ||
                          item.product[0].validEndTime == null
                      "
                    >
                      <span v-if="item.product[0].validEndTime == null"
                        >永久有效</span
                      >
                      <span v-else>{{ items.day }}天有效期</span>
                    </div>
                  </div>
                  <!-- <div class="itemImgsL">
                          <img
                            :src="
                              items.levelCoverUrl != ''
                                ? items.levelCoverUrl
                                : items.productImg
                            "
                            alt=""
                          />
                          <div class="itemInfo">
                            <div class="itemInfoTit">
                              {{ items.productName }}
                            </div>
                            <div v-if="items.levelName">规格：{{ items.levelName }}</div>
                          </div>
                        </div> -->
                </div>
                <div class="itemState" v-if="chooseNum != '课程商铺'">
                  <div class="itemStateL">{{ item.createTime }}</div>
                  <div class="itemMoney">
                    <span>共{{ items.productQuantity }}件商品</span>
                    <span>合计：</span>¥{{ item.price }}
                  </div>
                </div>
                <div class="itemState" v-else>
                  <div class="itemStateL">{{ item.createTime }}</div>
                  <div class="itemMoney">¥{{ item.price }}</div>
                </div>
              </div>
              <div
                  v-if="item.orderCode && (item.status == 2 || item.status == 3) && chooseNum != '课程商铺'"
                  class="itemCode"
                  @click.stop="lookCode(item.orderCode)"
                >
                  查看取件码
                </div>
            </div>
            <div class="text">没有更多了</div>
          </div>
          <div v-else>
            <not-found
            notfound="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/valuation_not.png"
            :tip="'暂无'+(chooseNum == '实物商铺' ? '商城':'乐园')+'订单哦~'"
            ></not-found>
          </div>
          <div class="codeBg" @click="closeCode" v-if="code">
            <div class="codeDetails">
              <div class="codeTit">取件码</div>
              <div class="codeTxt">{{ codeNum }}</div>
              <div class="codeBut" @click.stop="copyCode">复制取件码</div>
            </div>
          </div>
        </div>
        <div v-else>
          <not-found></not-found>
        </div>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
import timeMixin from "../Mixins/time"
import headerSelect from "@/components/select/header/header.vue";
import notFound from "@/components/notfound/notfound.vue";
import mallMix from "@/Mixins/mallMix"
import paradiseMix from "@/Mixins/paradiseMix"
import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
  name: "orderList",
  mixins: [timeMixin,mallMix,paradiseMix,dynamicImgwh],
  data() {
    return {
      i:0,
      j:0,
      name: false,
      searchAbout: "",
      currentTab: 10,
      list: [],
      code: false,
      codeNum: "",
      numList: [],
      chooseNum: "实物商铺",
      requestLoad:false
    };
  },
  components: {
    "headers-select":headerSelect,
    "not-found":notFound
  },
  created() {
    this.babyList = JSON.parse(storage.get("babyList"));
    this.initData()
  },
  methods: {
    initData(){
      const that = this
      this.requestLoad = false
      Indicator.open("加载中...");
      // 检测并设置家长数据
      this.setFamilyInfo(()=>{
        that.i = storage.get("i");
        that.j = storage.get("j");
      }).then(()=>{
        if(window.location.href.indexOf('/paradise/') != -1){
          that.chooseNum = '课程商铺';
          that.currentTab = 4
          // 查询课程商铺信息
          that.getSchoolState().then(state=>{
            // 开通商铺
            if(state){
              if (window.location.href.split("#/")[1].indexOf("orderList?pay") != -1) {
                that.getPayQuery();
              } else {
                that.getOrderList();
              }
              that.get_dynamicImgSQ()
            }else{
              this.requestLoad = true
              Indicator.close();
            }
          })
        }else{
          // 查询实物商铺信息
          that.shopInfo().then(state=>{
            // 开通商铺
            if(state){
              if (window.location.href.split("#/")[1].indexOf("orderList?pay") != -1) {
                that.getPayQuery();
              } else {
                that.getOrderList();
              }
              that.get_dynamicImgSQ()
            }else{
              this.requestLoad = true
              Indicator.close();
            }
          })
        }
      })
    },
    selectBaby(e){
      Object.assign(this.$data, this.$options.data.call(this))
      this.initData()
    },
    getChooseNum(e) {
      // console.log("111", e);
      this.chooseNum = e;
      if (e == "课程商铺") {
        this.currentTab = 3;
      }
      this.getOrderList();
    },
    search() {
      // this.currentTab = "";
      this.getOrderList();
    },
    //支付结果查询
    getPayQuery() {
      console.log("7890-", storage.get("orderId"));
      let orderId = storage.get("orderId");
      this.$post(api.payQuery, {
        orderId: orderId
      }).then(res => {
        console.log("支付结果查询", res);
        this.getOrderList();
        // Toast(res.data.data.status);
        // this.list = res.data.data.row
        // console.log(this.list);
      }).catch(err=>{
        this.requestLoad = true
        Indicator.close();
      });
    },
    //获取订单数量
    getOrderList() {
      let userId = [];
      userId.push(storage.get("userUnionId"));
      let currentTab = "";
      if (this.currentTab != 10) {
        currentTab = this.currentTab;
      }
      this.$post(api.orderList, {
        shop: [
          {
            shopNumber: this.babyList[this.i].schoolList[this.j].organizationId,
            shopTag: this.chooseNum
          }
        ],
        // shopNumber: this.babyList[this.i].schoolList[this.j].organizationId,
        status: currentTab,
        productName: this.searchAbout,
        // userId: userId
        // orderId:'234567890',
        // orderNumber:'fghjklvnjkl'
      }).then(res => {
        this.list = res.data.data.row || [];
        if (this.chooseNum == "课程商铺") {
          for (let i = 0; i < this.list.length; i++) {
            if(this.list[i].product[0].validEndTime){
              this.list[i].product[0].day = this.twoTimeInterval(this.getNowDate(),this.list[i].product[0].validEndTime);
            }
          }
        }else{

        }
        this.orderCount();
        this.requestLoad = true
        Indicator.close();
        console.log(this.list);
      });
    },
    //用户订单统计
    orderCount() {
      this.$get(api.orderCount, {
        shopNumber: this.babyList[this.i].schoolList[this.j].organizationId,
        shopTag: this.chooseNum
      }).then(res => {
        this.numList = res.data.data;
        console.log("用户订单统计", res);
      });
    },
    chooseKind(e) {
      console.log(e);
      this.currentTab = e;
      this.getOrderList();
    },
    //去订单管理
    toOMS(orderId, shopNumber) {
      console.log(orderId, shopNumber);
      if(this.chooseNum =='课程商铺'){
        this.$router.push({
          path: "/courseOrderDetails",
          query: {
            orderId: orderId,
            shopNumber: shopNumber
          }
        });
      }else{
        this.$router.push({
          path: "/OMS",
          query: {
            orderId: orderId,
            shopNumber: shopNumber
          }
        });
      }

    },
    //查看取件码
    lookCode(e) {
      this.code = true;
      this.codeNum = e;
    },
    //关闭
    closeCode() {
      console.log("11111", this.code);
      this.code = false;
    },
    //复制取件码
    copyCode() {
      let createInput = document.createElement("input");
      createInput.value = this.codeNum;
      document.body.appendChild(createInput);
      createInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      createInput.style.display = "none";
      Toast("复制成功");
      // this.code = false;
    }
  }
};
</script>

<style scoped>
.waper_header{
  margin-top: 92px;
}
.topChoose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.topChoose div {
  width: 150px;
  height: 32px;
  border-radius: 4px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}
.isChoose {
  background-color: #fd8832;
  color: #ffffff;
}
.noIsChoose {
  background-color: #ffffff;
  color: #333333;
}
.top {
  padding: 10px 20px;
  background-color: #ffffff;
  margin-bottom: 10px;
}
.search {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 19px;
  border: 1px solid #d7d7d7;
  padding: 11px 15px;
}
.search img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
.classify {
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  /* margin: 0 auto; */
}
.classify div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}
.choose {
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}
.nochoose {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
.blueline {
  width: 16px;
  height: 3px;
  border-radius: 2px;
  margin-top: 6px;
  background: #f85212;
}
.line {
  width: 16px;
  height: 3px;
  /* background: pink; */
  border-radius: 2px;
}
.item {
  background-color: #ffffff;
  padding: 12px 20px;
  margin-bottom: 15px;
}
.itemState {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-bottom: 13px;
}
.itemStateL {
  color: #2f2f2f;
}
.itemStateR {
  color: #f85511;
}
.itemImg {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  /* display: flex; */
}
.itemImgs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemImg img {
  width: 80px;
  height: 80px;
  margin-right: 3px;
}
.itemImgsL {
  display: flex;
  width: 85%;
  word-wrap: break-word;
}
.itemImgsL img {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}
.itemImgsR {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
  text-align: right;
}
.itemRmoney {
  font-size: 17px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #000000;
}
.itemRmoney span {
  font-size: 11px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}
.itemInfo {
  width: 60%;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8d8d8d;
}
.itemInfoTit {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
}
.itemMoney {
  font-size: 18px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #f85511;
}
.itemMoney span {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
.itemCode {
  width: 103px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  margin: 0 auto;
  border-radius: 20px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f85511;
  border: 1px solid #f85511;
}
.codeBg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.59);
}
.codeDetails {
  background-color: #ffffff;
  width: 243px;
  padding: 23px 0;
  /* height: 206px; */
  text-align: center;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.codeTit {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #67676b;
  margin-bottom: 18px;
}
.codeTxt {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-bottom: 33px;
}
.codeBut {
  width: 156px;
  height: 40px;
  margin: 0 auto;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
}
.no {
  text-align: center;
  margin-top: 120px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #767676;
}
.no img {
  width: 265px;
  height: 135px;
  margin-bottom: 25px;
}
.text {
  text-align: center;
  color: #2f2f2f;
  font-size: 15px;
  padding: 10px 0;
}
.itemClass {
  display: flex;
}
.itemClassL {
  width: 120px;
  height: 88px;
  border-radius: 8px;
}
.itemClassR {
  margin-left: 10px;
}
.itemClassLi {
  font-size: 14px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #67676b;
  margin-bottom: 18px;
}
.itemClassTxt span {
  background: #fef6f3;
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #b3745f;
  padding: 6px 10px;
  margin-right: 10px;
}
</style>
