<template>
  <div class="wxaqcode">
      <div class="main">
          <div class="title flex">
              <div class="icon"><img src="../assets/order_success.png" alt=""></div>
              <div class="text">支付成功</div>
          </div>
          <div class="describe">访问“启蒙星球”小程序查看订单及更多商品</div>
          <div class="code">
              <img src="../assets/_app_shop-orderList-index.jpg" alt="">
          </div>
          <div class="tip">长按图片识别二维码</div>
      </div>
  </div>
</template>

<script>
import storage from "good-storage";
import { Toast } from "mint-ui";
import axios from 'axios'
export default {
  name: "wxaqcode",
  components: {},
  mixins: [],
  data() {
    return {
      code:''
    };
  },
  created() {
    // this.initData()
  },
  methods: {
    // 初始化数据信息
    initData() {
        const that = this
        let wx_access_token = storage.get("family_starwx_access_token");
        // 判断access_token是否过期或不存在
        if(Number(new Date().getTime()) - Number(wx_access_token) >= 7200 || !wx_access_token){
            // 获取启蒙星球小程序access_token
            this.wxAccess_token().then(res=>{
                debugger
                this.getWxcodeurl(res.token)
            })
        }else{
            this.getWxcodeurl(wx_access_token.token)
        }
    },
    wxAccess_token(){
        return new Promise(resolve => {
            axios({
            url:'/WX/cgi-bin/token',
            method:'GET',
            params:{
                    'grant_type':'client_credential',
                    'appid':'wxb57e6f26351f1ece',
                    'secret':'4c8be8ec590791df93df57dbd0e2ffd8'
                } // query参数
            }).then(res => {
                    console.log('123', res)
                    debugger
                    let family_starwx_access_token = {
                        token:res.data.access_token,
                        time:new Date().getTime()
                    }
                    storage.set('family_starwx_access_token',family_starwx_access_token)
                    resolve(family_starwx_access_token)   
            }).catch(err => {
                console.log(err)
            })
        })
    },
    getWxcodeurl(token){
        axios({
            url:'https://api.weixin.qq.com/cgi-bin/wxaapp/createwxaqrcode',
            method:'post',
            params:{'access_token': token}, // body参数
            data:{
                'path':'shop/orderList/index'
            } // query参数
        }).then(res => {
                console.log('123', res)

        }).catch(err => {})
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body{
  background-color: #fafafc;
}
.padding-lr-sm {
  padding-left: 20px;
  padding-right: 20px;
}
.title-tb-sm {
  margin: 16px 0;
}
.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    justify-content: space-around;
    overflow: hidden;
}
.title{
    display: flex;
    align-items: end;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FA6400;
    line-height: 27px;
}
.icon img{
    width: 63px;
    height: 64px;
    display: block;
}
.describe{
    width: 50%;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
}
.code img{
    width: 180px;
    display: block;
}
.tip{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
}
</style>
