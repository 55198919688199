<template>
  <div class="setting">
    <div class="list">
      <div class="li">
        <div class="liL">手机号</div>
        <div class="liR">{{uplineInfo.mobile}}</div>
      </div>
    </div>
    <div class="logOut" @click="logout">退出登录</div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import http from "@/untils/http";
  import storage from "good-storage";
  export default {
    name: 'setting',
    data() {
      return {
        babyList: [],
        i: 0,
        uplineInfo:{}
      }
    },
    created() {
      this.babyList = JSON.parse(storage.get("babyList"))
      this.i = storage.get("i")
      let userUnionId = JSON.parse(storage.get("userUnionId"));
      // 获取当前登录人的信息
      this.uplineInfo = this.babyList.map(item=>{
        if(item.babyFamily && item.babyFamily.length > 0){
          return item.babyFamily
        }
      }).reduce((a, b) => a.concat(b)).filter(item=>item.userUnionId == userUnionId)[0]
    },
    methods: {
      //退出登录
      logout() {
        this.$post(api.logOut).then(res => {
          console.log('退出成功')
          this.$store.commit('resetVuex')
          let len = localStorage.length;  // 获取长度
          let locKeys = []
          for(let i = 0; i < len; i++) {
              let getKey = localStorage.key(i);
              if(getKey != 'redirectpath_family'){
                locKeys.push(getKey)
              }
          }
          locKeys.map(item=>storage.remove(item))
          this.$router.push('/login');
          window.location.reload();
        })
      }
    }
  }
</script>

<style scoped>
  .list {
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .li {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #151515;
    padding: 20px;
  }
  .liL {
    width: 30%;
  }
  .liR {
    font-weight: 500;
    color: #151515;
  }
  .logOut {
    background-color: #ffffff;
    text-align: center;
    padding: 10px 0;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #151515;
  }
</style>
