export default {
    name:'commonNav',
    props:{
        list:{
            type:Array,
            default:()=>[]
        },
        activeIndex:{
            type:Number,
            default:0
        }
    },
    data(){
        return {}
    },
    created(){
    },
    methods:{
        // 选择某一项
        clickItem(index){
            this.$emit('clickItem',index)
        }
    }
}