<template>
  <div class="progressLog padding-lr-sm">
     <div class="curriculum-inner over-hider">
         <curriculum :info="queryInfo"></curriculum>
     </div>

     <div class="details">
        <common-nav
        class="title-tb-sm"
        :list="navs"
        :activeIndex="navIndex"
        @clickItem="navClick"
        ></common-nav>
        <div class="contain">
            <div class="progress-inner" v-show="navVal == 'progress'">
                <div class="item over-hider" v-for="(item,index) in curriculums" :key="index">
                    <curriculum :info="item" size="medium" :expireShow="false"></curriculum>
                </div>
                <div class="dataNot" v-if="curriculums.length == 0">
                  <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/curriculum/progress.png" alt="">
                </div>
            </div>
            <div class="logTimeLine-inner" v-show="navVal == 'log'">
                <log-timeLine :logs="logs"></log-timeLine>
                <div class="dataNot" v-if="logs.length == 0">
                  <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/curriculum/progress.png" alt="">
                </div>
            </div>
        </div>
     </div>
  </div>
</template>

<script>
import {
  Toast
} from "mint-ui";
import * as api from "@/untils/api";
import curriculum from '@/components/goods/curriculum/curriculum.vue'
import commonNav from '@/components/nav/commonNav/commonNav.vue'
import logTimeLine from '@/components/timeLine/log/log.vue'
import storage from "good-storage";
import timeMixin from '../Mixins/time'
export default {
  name: "progressLog",
  components:{
    'curriculum':curriculum,
    'common-nav':commonNav,
    'log-timeLine':logTimeLine
  },
  mixins: [timeMixin],
  data() {
    return {
        queryInfo:{},
        navs:[{
          title:'单课进度',
          val:'progress'
        },{
          title:'学习日志',
          val:'log'
        }],
        navIndex:0,
        navVal:'',
        logs:[],
        curriculums:[],
    };
  },
  created() {
    this.babyList = JSON.parse(storage.get("babyList"));
    let {day, classNum, productId,productName, productImg, tag, userId, levelId } = this.$route.query
    this.queryInfo = {
      productId,
      picture:productImg,
      title: productName,
      validityDate: day,
      chapter: classNum,
      tag,
      userId,
      levelId
    }
    if(tag.indexOf('单课') != -1){
      // 当前为单课
      this.navVal = this.navs[1].val
      this.navs = [this.navs[1]]
      this.getStudyCountDay()
    }else{
      // 当前为套餐课
      this.navVal = this.navs[0].val
      this.getSuitCourse()
    }
    console.log(this.$route.query)
  },
  methods: {
    navClick(e){
      this.navIndex = e;
      this.navVal = this.navs[e].val
    },
    // 获取套餐课下的所有的单课程
    getSuitCourse(args){
      const that = this
      that.$get(api.suitCourse, {
        courseSuitId:this.queryInfo.productId
      }).then(res => {
        if(res.data.code != 0) return Toast('服务器繁忙请稍后再试！')
        let courseIds = []
        this.curriculums = res.data.data.map(item=>{
          let newItem = {
            productId:item.courseId,
            picture:item.picture,
            title: item.title,
            validityDate: '',
            chapter: 0,
            tag:'单课程',
          }
          courseIds.push(item.courseId)
          return newItem
        })
        courseIds = courseIds.join(',')
        if(res.data.data.length!= 0){
          this.studyCountDay(courseIds)
        }

      }).catch(err => {
        Toast('服务器繁忙请稍后再试！')
        console.error(err);
      });
    },
    studyCountDay(courseIds) {
      const { userId } = this.queryInfo
      this.$get(api.studyCount, {
        userId: userId,
        courseId: courseIds
      }).then(res => {
        let chapter = 0
        this.curriculums.forEach(element => {
            res.data.data.map(item=>{
              if(element.productId == item.courseId){
                element.chapter = item.completeNum
                chapter = chapter + element.chapter
              }
              return element
            })
        });
        console.log(chapter,'学习的课节')
        this.queryInfo.chapter = chapter
        this.getStudyCountDay(courseIds)
      });
    },
    // 按时间段统计学习记录
    getStudyCountDay(args){
      const { userId,levelId } = this.queryInfo
      this.$get(api.studyCountDay, {
        userId,
        courseId:args || levelId
      }).then(res => {
        if(res.data.code != 0) return Toast('服务器繁忙请稍后再试！')
        if(args){
          this.logs = {}
          res.data.data.map(item=>{
            let { date } = item;
            if (!this.logs[date]) {
              this.logs[date] = {
                week:this.dateToweek(date),
                date:date,
                chapters: []
              }
            }
            this.logs[date].chapters.push({
              name:item.title,
              count:item.num
            });
          })
          this.logs = Object.values(this.logs);
        }else{
          this.logs = {}
          res.data.data.map(item=>{
            let { date } = item;
            if (!this.logs[date]) {
              this.logs[date] = {
                week:this.dateToweek(date),
                date:date,
                chapters: []
              }
            }
            this.logs[date].chapters.push({
              name:item.title,
              count:item.num
            });
          })
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.padding-lr-sm{
  padding-left:20px;
  padding-right: 20px;
}
.title-tb-sm{
  margin: 16px 0;
};
.over-hider{
  overflow: hidden;
}
.progressLog{
    background: #FBFBFB;
    min-height: 100vh;
    padding-top: 20px;
    box-sizing: border-box;
}
.progress-inner .item{
    padding: 10px 4px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 12px;
}
.curriculum-inner{
    margin-bottom: 20px;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
}
.dataNot{
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dataNot img{
  width: 210px;
  height: 120px;
  display: block;
}
</style>
