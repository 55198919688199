import timeMixins from "../../Mixins/time"
export default {
    name:'catalogue',
    mixins: [timeMixins],
    props:{
        catalogues:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return {
            showItem: null, //默认展开项
        }
    },
    created(){
    },
    
    methods:{
        // 选择某一项
        showChange(index){
            this.showItem = index;
            this.$emit('showChange',index)
        }
    }
}