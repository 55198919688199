<template>
  <div class="paySuccess">
    <div class="top">
      <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/invite/success@2x.png" alt="" />
      <div>支付成功</div>
    </div>

    <div class="wx">
      <div>关注<span>"启蒙未来服务"</span>公众号</div>
      <div>访问<span>"家长入口"</span>查看订单及更多商品</div>
      <div>
        <img class="er" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/invite/wxgzh.jpg" alt="" />
      </div>

      <img class="hand" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/invite/hand@2x.png" alt="" />
      <div class="hint">扫码关注不迷路呦</div>
    </div>
  </div>
</template>

<script>
import { Picker, DatetimePicker, Toast, Popup } from "vant";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
export default {
  name: "paySuccess",
  data() {
    return {
      // params: [],
      success: true
    };
  },
  created() {
    // this.params = this.$route.query.params;
    // console.log(this.params);
    this.getPayQuery();
    // this.cms_token();
  },
  methods: {
    //支付结果查询
    getPayQuery() {
      console.log("7890-", storage.get("orderId"));
      let orderId = storage.get("orderId");
      this.$post(api.payQuery, {
        orderId: orderId
      }).then(res => {
        console.log("支付结果查询", res);
        if (res.data.data.status == 0) {
          this.success = true;
        } else {
          this.success = false;
        }
        // this.getOrderLists();
        // Toast(res.data.data.status);
        // this.list = res.data.data.row
        // console.log(this.list);
      });
    }
  }
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background: #ffffff;
}
</style>

<style scoped>
.paySuccess {
  text-align: center;
}
.top {
  font-size: 18px;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin: 53px auto;
}
.top img {
  width: 70px;
  height: 75px;
  /* margin-bottom: 12px; */
}
.wx {
  margin-top: 130px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 28px;
}
.wx span {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}
.er {
  margin-top: 24px;
  width: 104px;
  height: 104px;
}
.hand {
  margin-top: 6px;
  width: 18px;
  height: 18px;
}
.hint {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
</style>
