export default {
    data() {
        return {
            imgSizeQuality: ''
        }
    },
    mounted() {
        window.addEventListener('resize', this.get_dynamicImgSQ);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.get_dynamicImgSQ);
    },
    methods: {
        get_dynamicImgSQ() {
            const _this = this;
            _this.$nextTick(() => {
                //监听到事件后执行的业务逻辑
                let nodeList = document.getElementsByName("dynamicImg")
                setTimeout(() => {
                    nodeList.forEach((element, index) => {
                        if (index == 0) {
                            _this.imgSizeQuality = '@h_' + element.clientHeight + ',w_' + element.clientWidth + ',q_80';
                        }
                    })
                }, 600)
            });
        }
    }
}
