<template>
    <div class="login" :style="{ height: h + 'px' }" v-cloak  v-if="!signIn">
      <div class="tit">
        <div>请使用您在启蒙乐园中预留的手机号码进行绑定～</div>
      </div>
      <div class="list">
        <div class="li">
          <div class="liL">
            <div>+86</div>
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/login/there.png" alt="" />
          </div>
          <div class="liR">
            <input
              type="tel"
              maxlength="11"
              placeholder="请输入手机号"
              @input="phoneNum"
              v-model="phone"
            />
          </div>
        </div>
       
        <div class="li limsg">
          <div class="liL" style="width: 50%;">
            <input type="text" placeholder="请输入验证码" v-model="phoneCode" />
          </div>
            <button
              class="button1"
              @click="clickCode"
              v-html="codemsg"
              :disabled="iscode"
            ></button>
        </div>
      </div>
      <div :class="phoneCode.length==0||phone.length==0||!isAgree?'button2':'button'"
       @click="submit" >绑定账号</div>
       <div class="bind" > </div>

      <div style="display: flex;flex-direction: row;justify-content: center;margin-top: 20px;" >
        <img   @click="isOrNoAgree" class="img_choose" :src="isAgree?'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/login/choose.png':'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/login/unchoose.png'" alt="" />
        <div :class="'text_gray'" >我已阅读并同意</div>
        <div :class="'text_blue'" @click="gotoProtocol">《启蒙开心乐园用户服务协议与隐私条款》</div>
      </div>
    </div>
    <div class="login" :style="{ height: h + 'px' }" v-cloak  v-else>
      <div class="binded">
        <img   class="img1" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/login/bind.png" alt="" />
          <div style="font-size:17px ;font-weight: bold;color: #333333;margin-bottom: 20px;">账号绑定成功</div>
          <div  style="font-size:15px ;color: #333333;margin-bottom: 20px;">已关联的学生列表</div>
          <div v-for="(item, index) in data" :key="item.id">
            <div   class="img2">
              <img   class="img3" :src="item.headImage!=''?item.headImage:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/login/head.png'" />
              <div style="display: flex;flex-direction: column;">
                <div style="font-size: 14px;color: #333333;">{{ item.name }}</div>
                <div style="display: flex;flex-direction: row;">
                  <div style="font-size: 12px;color: #333333;margin-right: 20px;">{{ item.schoolList&&item.schoolList.length>0?item.schoolList[0].name:''}}</div>
                  <div style="font-size: 12px;color: #333333;">{{item.schoolList&&item.schoolList.length>0&&item.schoolList[0].classList.length>0?item.schoolList[0].classList[0].name:'暂无班级'}}</div>
                </div>
              </div>
            </div> 
           
                        
          </div>
          

      </div>
      <div class="bind" >
        <div class="button3"
          @click="unbind" >解除绑定</div>
      </div>


    </div>

  </template>
  
  <script scoped>
  import { Toast,Dialog } from "vant";
  import * as api from "@/untils/api";
  import http from "@/untils/http";
  import storage from "good-storage";

  export default {
    name: "binding",
    data() {
      return {
        phone: "", // 手机号
        phoneCode: "", //手机验证码
        code: "", // 图形验证码
        codeDisabled: true,
        codemsg: "发送验证码", // 获取验证码的文案
        t: "", // 倒计时的T
        second: 60, // 倒计时的T
        iscode: false,
        isAgree:false,
        img: "",
        wxCode: "",
        h: "",
        signIn: false,
        data:[],
      };
    },
    created() {
      // if (localStorage.getItem("userRefreshToken_user") && localStorage.getItem("babyList")) {
      //   that.$router.push('/navigate');
      // } else {
      const height = document.documentElement.clientHeight;
      this.h = height;
      const url = location.search;
      let redirect_uri = "";
      console.log("process.env.NODE_ENV", process.env.NODE_ENV);
      if (process.env.NODE_ENV == "production") {
        redirect_uri =
        "https%3A%2F%2Fwebsite.vtronedu.cn%2Fqm_family%2Findex.html";

      } else if (process.env.NODE_ENV == "none") {

        //https://test.vtronedu.cn/qimeng-web/index.html
        redirect_uri =
        "https%3A%2F%2Fwebsite.vtronedu.cn%2Fqm_family%2Findex.html";

      } else if (process.env.NODE_ENV == "development") {
        redirect_uri =
          "https%3A%2F%2Fwebsite.vtronedu.cn%2Fqm_family%2Findex.html";

      }
    
      if (localStorage.getItem("userUnionId")) {
        this.signIn = true;
        // this.data=JSON.parse(localStorage.getItem("babyList"))
        // console.log('this.data',JSON.parse(localStorage.getItem("babyList")));
        this.userUnionId=localStorage.getItem("userUnionId")
        console.log('this.userUnionId',this.userUnionId);

        this.openId=localStorage.getItem("openId")
        console.log('this.openId',this.openId);
        this.getParentInfo();
      } else {
       
          console.log('/进入参数判断-----login');
          if (url.indexOf("?") != -1) {
            var str = url.substr(1);
            this.wxCode = str.split("&")[0].split("=")[1];
            console.log(this.wxCode,'/this.wxCode-----login');
          }
          if (this.wxCode == "") {
            console.log('/微信换取code重定向开始-----login');
            window.location.href =
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1c924e14678d55d1&redirect_uri=" +
              redirect_uri +
              "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
               //'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxae80bb1fd4a0d45b&redirect_uri='
            // + redirect_uri + '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
          } 
          this.cms_token();
        
      }
      // if (localStorage.getItem("userUnionId")) {
      //   this.signIn = true;
      //   // this.data=JSON.parse(localStorage.getItem("babyList"))
      //   // console.log('this.data',JSON.parse(localStorage.getItem("babyList")));
      //   this.userUnionId=localStorage.getItem("userUnionId")
      //   console.log('this.userUnionId',this.userUnionId);

      //   this.openId=localStorage.getItem("openId")
      //   console.log('this.openId',this.openId);
      //   this.getParentInfo();

      //   // this.cms_token();
      // }else{
      //   this.cms_token();

      // }
    },
    methods: {
     
      //获取cms_token
      cms_token() {
        this.$get(api.Authorization, {
          appId: "1434797802239561728"
        }).then(res => {
          storage.set("userToken_user", "Bearer " + res.data.data);
          this.getOpenid();
        });
      },
      //获取openid
      getOpenid() {
        // this.openId = "oav2Lwj1li6c-QdfChtJIzWC90Qg";
        // storage.set("openId", this.openId);
        if (process.env.NODE_ENV == "development") {
          this.openId = "oav2Lwj1li6c-QdfChtJIzWC90Qg";
          storage.set("openId", this.openId);
        } else {
          this.$post(api.getOpenid, {
            wechatAppId: "wx1c924e14678d55d1",//wxae80bb1fd4a0d45b
            code: this.wxCode
          }).then(res => {
            this.openId = res.data.data.openid;
            storage.set("openId", this.openId);
          });
        }
      },
   
  
      //验证手机号
      phoneNum() {
        const regex = /^(1)\d{10}$/;
        if (!regex.test(this.phone)) {
          this.codeDisabled = true;
          this.msg = "请正确输入手机号";
          return;
        }
        this.codeDisabled = false;
      },
      // 倒计时
      clickTime() {
        console.log(this);
        this.t = setInterval(() => {
          if (this.second == 0) {
            this.codemsg = "重新获取";
            this.iscode = false;
            this.second = 60;
            this.code = "";
            this.phoneCode = "";
            // this.getCaptchaImg();
            clearInterval(this.t);
            return;
          }else{
            this.iscode = true;
            this.codemsg = this.second + "s后重发";
            this.second = this.second - 1;
          }
          
        }, 1000);
      },
      // 点击获取验证码
      clickCode() {
        console.log("123");
        let that = this;
        console.log(that, that.code);
        // const regex = /^(1)\d{10}$/;
       
        if (
          !/^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/.test(
            that.phone
          )
        ) {
          that.$toast("请输入正确的手机号");
        //   that.getCaptchaImg();
          that.code = "";
          return;
        } else {
           that .$get(api.CaptchaPhone1, {
            phone: that.phone
                    })
                    .then(res => {
                      if (res.data.code == 0) {
                        that.clickTime();
                      } else {
                        that.$toast(res.data.msg);
                      }
                    }).catch(() => {
                      that.code = ''
                    });
        }
      },

        //获取分享用户信息
        getParentInfo() {
        console.log("获取分享用户信息1", 11);
        this.$get(api.parentInfo, {}).then(res => {
          console.log("获取分享用户信息", res);
          if (res.data.code == 0) {
            if (res.data.data.babyList.length != 0) {
              res.data.data.babyList = res.data.data.babyList.filter(element => element.schoolList.length > 0);
              res.data.data.babyList.forEach((baby,babyIndex)=>{
                if(baby.schoolList && baby.schoolList.length != 0){
                  baby.schoolList.forEach(school=>{
                    school.name = school.shortName || '暂无院所别名'
                  })
                }
              })
              res.data.data.babyList = res.data.data.babyList.filter(item=>item.schoolList.length != 0)
              this.data= res.data.data.babyList
              this.userUnionId= res.data.data.userUnionId
              console.log("this.data",this.data,);
              storage.set("babyList", JSON.stringify(res.data.data.babyList));
              storage.set("userUnionId", res.data.data.userUnionId);
              // this.signIn=true
              // console.log('this.data111',JSON.parse(localStorage.getItem("babyList")));
            } else {
              // window.localStorage.removeItem("userToken_user");
              console.log(storage.get("userToken_user"));
              this.$toast("您的账号当前暂无孩子，请联系您的幼儿园添加孩子信息");
            }
          }
        });
      },  
      
      bind(){
        this.$post(api.saveWXInfo, {
          openid: this.openId,
          type:1,
        }).then((res) => {
          console.log('saveWXInfo',res)
          if (res.data.code == 0) {
            this.signIn=true
            this.getParentInfo();

          } else {
            Toast(res.data.msg);
            console.log('saveWXInfo',res)

          }
        }).catch((e)=>{
          console.log('saveWXInfoEE',e)
        });
      },
      unbind(){
        Dialog.confirm({
          title: '提示',
          message: '取消绑定后您将无法实时接收孩子的签到通知',
        })
          .then(() => {
                this.$delete(api.deleteWXInfo, {
                  type: 1,
                  userUnionId:this.userUnionId,
                  appId: "1434797802239561728"
                }).then((res) => {
                  console.log(res)
                  if (res.data.code == 0) {
                    window.localStorage.removeItem("babyList");
                    window.localStorage.removeItem("userUnionId");
                    window.localStorage.removeItem("openId");
                    // window.localStorage.removeItem("userToken_user");

                    this.data=[];
                    this.signIn=false;
                    this.phone="";
                    this.phoneCode=""
                    this.codemsg = "获取验证码";
                    this.iscode = false;
                    this.second = 60;
                    clearInterval(this.t);

                    Toast("解除成功");
                  } else {
                    Toast(res.data.msg);
                  }
              });
          })
          .catch(() => {
            // on cancel
          });
        
      
      },
      //登录
      submit() {
        // console.log(this);
        if(!this.isAgree){
           
        }else{
          let that = this;
        if (that.phone == "") {
        //   that.$toast("请输入手机号");
        }else {
            if (that.phoneCode == "") {
            //   that.$toast("请填写短信验证码");
            } else {
              that
                .$post(api.codeLogin, {
                  username: that.phone,
                  // organizationId: organizationId,
                  captcha: that.phoneCode
                  // openId: that.openId
                })
                .then(res => {
                  if (res.data.code == 0) {
                    console.log("登录成功",res.data.data);
                    // Toast('登录成功')
                    storage.set(
                      "userToken_user",
                      "Bearer " + res.data.data.userToken
                    );
                    storage.set(
                      "userRefreshToken_user",
                      res.data.data.userRefreshToken
                    );
                    // that.signIn=true
                    that.bind()
                  } else {
                    that.$toast(res.data.msg);
                  }
                });
            }
          }
        }
       
        },
      isOrNoAgree(){
        console.log(' this.isAgree', this.isAgree)
        this.isAgree=!this.isAgree
      },
      gotoProtocol(){
        this.$router.push({ path: "/protocol", });
      }    
    },
   
  };
  </script>
  
  <style scoped>
  .login {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #FAFAFC;
  }
  .bind {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 25%;
    background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/login/bind_bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
  }
  .tit {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-left: 20px;
    height: 27px;
    color:#9B3B01;
    background-color: #FFF6E0;
  }
  .binded {
    display: flex;
    align-items: center;
    flex-direction: column;

  }
  .img1 {
    width: 56px;
    height: 56px;
    margin-top: 40px;
    margin-bottom: 20px
  }
  .img2{
    width: 290px;
    height: 60px;
    margin-top: 12px;
    background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/login/baby_item.png);
    background-size: 100% 100%;
    display: flex;flex-direction: row;align-items: center;
  }
  .img3{
    width: 44px;
    height: 44px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .img_choose {
    width: 15px;
    height: 15px;
    margin-right: 2px;
  }
  .tit img {
    width: 26px;
    height: 20px;
    margin-right: 10px;
  }
  .list {
    margin: 45px 30px;
    padding-bottom: 10px;
  }
  .li {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    height: 35px;
    margin-bottom: 15px;
    align-items: center;
    background: #f7f7f7;
    border-radius: 50px;
  }
  .liL {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2b2b2b;
    display: flex;
    align-items: center;
  }
  .left {
    width: 63%;
  }
  .right {
    width: 35%;
    /* width: 80px; */
    height: 40px;
    margin-right: 0;
  }
  .right img {
    width: 100%;
    height: 100%;
  }
  .liL img {
    width: 10px;
    height: 6px;
    margin-left: 9px;
    margin-right: 15px;
  }
  input {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  input::-webkit-input-placeholder {
    color: #cdcdd1;
    font-size: 15px;
    font-weight: 500;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  /* Mozilla Firefox 4 to 18 */
  input:-moz-placeholder {
    color: #cdcdd1;
    opacity: 1;
    font-weight: 500;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  /* Mozilla Firefox 19+ */
  input::-moz-placeholder {
    color: #cdcdd1;
    font-weight: 500;
    opacity: 1;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  /* Internet Explorer 10+ */
  input:-ms-input-placeholder {
    color: #cdcdd1;
    font-weight: 500;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  /* .liR button {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;    
    color: #3798fa;
  } */
  .limsg {
    justify-content: space-between;
  }
  .lil {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    /* padding: 7px 20px; */
    /* height: 35px; */
  }
  .lilL {
    width: 65%;
    height: 35px;
    padding: 7px 20px;
    line-height: 35px;
    background: #f7f7f7;
    border-radius: 50px;
  }
  .lilR img {
    margin-left: 9px;
    /* height: 60px; */
  }
  .button {
    height: 42px;
    text-align: center;
    line-height: 42px;
    width: 85%;
    margin: 0px auto;
    background: linear-gradient(
      90deg,
      rgba(0, 203, 255, 1) 0%,
      rgba(67, 139, 249, 1) 100%
    );
    border-radius: 25px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
  .button2 {
    height: 42px;
    text-align: center;
    line-height: 42px;
    width: 85%;
    margin: 0px auto;
    background: linear-gradient(
      90deg,
      rgba(0, 203, 255, 0.45) 0%,
      rgba(67, 139, 249,  0.45) 100%
    );
    border-radius: 25px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
  .button3 {
    height: 32px;
    text-align: center;
    line-height: 42px;
    width: 93px;
    margin: 0px auto;
    background: transparent;
    border-radius: 20px;
    border:  solid #2DA0FB;
    border-width: 1px;   
    font-weight: 400;
    font-size: 14px;
    color: #2DA0FB;
    line-height: 40rpx;
    letter-spacing: 1px;
    text-align: center;
    font-style: normal;
    font-family: PingFangSC, PingFang SC;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sign {
    margin: 22px 30px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #438bf9;
    line-height: 20px;
  }
  .button1{
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;    
    color: #3798fa!important;
  }

  .text_gray{
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #666!important;
  }
  .text_blue{
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #2DA0FB!important;
  }

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}
 
.close-button {
  margin-top: 10px;
}

  </style>
  