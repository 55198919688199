<template>
    <div class="nav">
        <div 
        class="nav-item" 
        :class="[activeIndex == index ? 'active' : '']"
        v-for="(item, index) in list" 
        :key="index"
        @click="clickItem(index)">
          <div class="nav-title">{{item.title}}</div>
          <div 
          class="nav-border"
          ></div>
        </div>
      </div>
</template>
<script>
    import commonNav from "./commonNav.js";
    export default commonNav;
</script>
<style scoped>
    @import './commonNav.css';
</style>