import Vue from 'vue'

import axios from 'axios'

// import { Toast, Indicator, MessageBox } from 'mint-ui'; //使用于vue项目中Toaset进行错误提示
import { Toast } from 'vant';
import { refreshToken } from './refreshToken';
import storage from "good-storage";
import router from '@/router/index';

import * as api from "@/untils/api";
import http from "@/untils/http";
import wx from 'weixin-js-sdk'

//取消请求

let CancelToken = axios.CancelToken

//设置默认请求头，如果不需要可以取消这一步

// axios.defaults.headers = {

//         'Content-Type': 'application/json',
//         "Authorization": 'Bearer ' + localStorage.getItem("userToken_user")


//     }
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

// 请求超时的时间限制

axios.defaults.timeout = 20000

// 开始设置请求 发起的拦截处理

// config 代表发起请求的参数的实体

axios.interceptors.request.use(config => {
    if (storage.get("userToken_user")) {
        config.headers.Authorization = storage.get("userToken_user"); //如果要求携带在请求头中
    }
    // 得到参数中的 requestName 字段，用于决定下次发起请求，取消对应的 相同字段的请求

    // 如果没有 requestName 就默认添加一个 不同的时间戳

    let requestName

    if (config.method === 'post') {

        if (config.data && config.data.requestName) {

            requestName = config.data.requestName

        } else {

            requestName = new Date().getTime()

        }

    } else {

        if (config.params && config.params.requestName) {

            requestName = config.params.requestName

        } else {

            requestName = new Date().getTime()

        }

    }

    // 判断，如果这里拿到的参数中的 requestName 在上一次请求中已经存在，就取消上一次的请求

    if (requestName) {

        if (axios[requestName] && axios[requestName].cancel) {

            axios[requestName].cancel()

        }

        config.cancelToken = new CancelToken(c => {

            axios[requestName] = {}

            axios[requestName].cancel = c

        })

    }

    return config

}, error => {

    return Promise.reject(error)

})



// 请求到结果的拦截处理

axios.interceptors.response.use(config => {
    if (config.data.code != 0) {
        if (config.data.code == '41002' || config.data.code == '2' || config.data.code == '1') {
            window.localStorage.removeItem('userToken_user')
            window.localStorage.removeItem('babyList')
            window.localStorage.removeItem('vuex')
            // router.push('/login');
            // 调用小程序接口，返回登录页面 
            wx.miniProgram.reLaunch({
              url: '/pages/start/index'
            });

        } else {
            // Toast(config.data.msg)
        }

    }
    // 返回请求正确的结果



    return config

}, error => {

    // 错误的请求结果处理，这里的代码根据后台的状态码来决定错误的输出信息

    if (error && error.response) {
        console.log(error.response)
            // err.response.status == 401 && err.response.data.exp == 'token expired'
        if (error.response.data == 'exp: "token expired"' || error.response.status == '401') {
            console.log('1111')

            refreshToken();
        } else {
            if(error.config.url.indexOf('client/heartBeat') != -1) return false
            Toast(error.response.data.error);
        }
        // switch (error.response.status) {
        //     case 400:
        //         Toast('错误请求');
        //         break
        //     case 401:
        //         refreshToken();
        //         // Toast('未授权，请重新登录');
        //         break
        //     case 403:
        //         Toast('拒绝访问');
        //         break

        //     case 404:
        //         Toast('请求错误,未找到该资源');
        //         break

        //     case 405:
        //         Toast('请求方法未允许');
        //         break

        //     case 408:
        //         Toast('请求超时');
        //         break

        //     case 500:
        //         Toast('服务器端出错');
        //         break

        //     case 501:
        //         Toast('网络未实现');

        //         break

        //     case 502:
        //         Toast('网络错误');

        //         break

        //     case 503:
        //         Toast('服务不可用');

        //         break

        //     case 504:
        //         Toast('网络超时');
        //         break

        //     case 505:
        //         Toast('http版本不支持该请求');

        //         break

        //     default:
        //         Toast(`'连接错误'${error.response.status}`);

        // }

    } else {
        // Toast('连接到服务器失败');

    }

    return Promise.reject(error.message)

})


// 将axios 的 post 方法，绑定到 vue 实例上面的 $post

Vue.prototype.$post = function(url, params, headers) {

    return new Promise((resolve, reject) => {

        axios.post(url, params, { headers: headers })

        .then(res => {

            resolve(res)

        }).catch(err => {

            reject(err)

        })

    })

}
Vue.prototype.$put = function(url, params, headers) {

    return new Promise((resolve, reject) => {

        axios.put(url, params, { headers: headers })

        .then(res => {

            resolve(res)

        }).catch(err => {

            reject(err)

        })

    })

}
Vue.prototype.$delete = function(url, params, headers) {

        return new Promise((resolve, reject) => {

            axios.delete(url, {

                params: params

            }, {

                headers: headers

            }).then(res => {

                resolve(res) // 返回请求成功的数据 data

            }).catch(err => {

                reject(err)

            })

        })

    }
    // Vue.prototype.$delete = function(url, params, headers) {


//         return new Promise((resolve, reject) => {

//             axios.delete(url, { data: params }, { headers: headers })

//             .then(res => {

//                 resolve(res)

//             }).catch(err => {

//                 reject(err)

//             })

//         })

//     }
// 将axios 的 get 方法，绑定到 vue 实例上面的 $get
Vue.prototype.$get = function(url, params, headers) {

    return new Promise((resolve, reject) => {

        axios.get(url, {

            params: params

        }, {

            headers: headers

        }).then(res => {

            resolve(res) // 返回请求成功的数据 data

        }).catch(err => {

            reject(err)

        })

    })

}

// 请求示例

// requestName 为多余的参数 作为请求的标识，下次发起相同的请求，就会自动取消上一次还没有结束的请求

// 比如正常的请求参数只有一个 name: '123'，但是这里我们需要额外再加上一个 requestName

/**
   //post请求实例
   this.$post(this.url2, {
name: "王",
docType: "pson",
requestName: 'name02'
}).then(res => {
    console.log(res)
  })
 //get请求实例

this.$get(this.url, {
    name: "李",
    requestName: 'name01'
}).then(res => {
    console.log(res)
})
 */



export default axios