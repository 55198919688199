var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"curriculum"},[_c('div',{staticClass:"kidList"},_vm._l((_vm.babyList),function(item,index){return _c('div',{key:index,class:_vm.currentTab == index ? 'isChoose' : 'noChoose',on:{"click":function($event){return _vm.chooseKid(index)}}},[_c('div',[_vm._v(_vm._s(item.name))]),_vm._v(" "),(_vm.currentTab == index)?_c('div',{staticClass:"line"}):_vm._e()])}),0),_vm._v(" "),(_vm.list.length != 0)?_c('div',{staticClass:"courseList"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:item.product[0].day > 0 || item.product[0].validEndTime == null
          ? 'li'
          : 'lii'},[_c('img',{staticClass:"liL",attrs:{"name":"dynamicImg","src":item.product[0].productImg+_vm.imgSizeQuality,"alt":""},on:{"click":function($event){return _vm.goCurriculumDet(item.product[0].productId, item.shopNumber)}}}),_vm._v(" "),_c('div',{staticClass:"liR"},[_c('div',{staticClass:"tit",on:{"click":function($event){return _vm.goCurriculumDet(item.product[0].productId, item.shopNumber)}}},[_vm._v(_vm._s(item.product[0].productName))]),_vm._v(" "),_c('div',{staticClass:"last"},[(
              item.product[0].day > 0 || item.product[0].validEndTime == null
            )?_c('div',[(item.product[0].validEndTime == null)?_c('div',{staticClass:"time"},[_vm._v("\n              有效期剩余:"),_c('span',[_vm._v("永久有效")])]):_c('div',{staticClass:"time"},[_vm._v("\n              有效期剩余:"),_c('span',[_vm._v(_vm._s(item.product[0].day))]),_vm._v("天\n            ")])]):_c('div',{staticClass:"time"},[_vm._v("\n            已过期\n          ")])]),_vm._v(" "),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.goToLog(
                item.product[0].validEndTime,
                item.product[0].day,
                item.product[0].classNum,
                item.product[0].productName,
                item.product[0].productImg,
                item.tag,
                item.product[0].productId,
                item.product[0].levelId
              )}}},[_vm._v("\n            学习记录\n          ")])])])}),0):_c('div',{staticClass:"noclass"},[_vm._v("当前暂无课程")])])}
var staticRenderFns = []

export { render, staticRenderFns }