<template>
  <div class="index">
    <div v-if="parkList.length">
      <div class="top">
        <mt-popup
          v-model="showPopover"
          popup-transition="popup-fade"
          class="mint-popup"
          position="bottom"
        >
          <div
            class="popoverItem"
            v-for="(item, index) in parkList"
            :key="index"
            @click="getPark(index)"
          >
            {{ item.name }}
          </div>
        </mt-popup>
        <div class="info">
          <div class="select" v-if="parkList.length > 1" @click="selectPark">
            {{ parkList[num].name }}
            <img
              :src="
                showPopover == true
                  ? 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/up@2x.png'
                  : 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/down@2x.png'
              "
              alt=""
            />
          </div>
          <div class="select" v-else-if="parkList.length == 1">
            {{ parkList[num].name }}
          </div>
          <div class="user" @click="toMyCenter()">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/user@2x.png" alt="" />
          </div>
        </div>
        <div class="list">
          <div class="li" @click="toChooseClass" v-if="lianxi">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/lianxi@2x.png" alt="" />
            <div>课后练习</div>
          </div>
          <div class="li" @click="toAppraisal">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/ceping@2x.png" alt="" />
            <div>测评报告</div>
          </div>
          <div class="li" @click="toHomepage" v-if="shangcheng">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/shangcheng@2x.png" alt="" />
            <div>在线商城</div>
          </div>
          <div class="li" @click="toSchool" v-if="schoolState">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/school@2x.png" alt="" />
            <div>启蒙乐园</div>
          </div>
        </div>
      </div>
      <div class="show">
        <div class="lessonList" v-if="lianxi">
          <div class="tit">
            <div class="titL">新练习</div>
            <div class="titR" v-if="lessonList.length > 0" @click="toChooseClass">
              <div>查看全部</div>
              <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/next@2x.png" alt="" />
            </div>
          </div>
          <div v-if="lessonList.length != 0">
            <div
              class="lessonLi"
              v-for="(item, index) in lessonList"
              :key="index"
              @click="toChoosePart(item.lessonId, item.courseId, item.classId)"
            >
              <img class="lessonLiL" :src="item.coverImage" alt="" />
              <div class="lessonLiR">
                <div>{{ item.lessonName }}</div>
                <div>
                  <span>{{ item.courseName }}</span>
                  <span>{{ item.levelName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="dont" v-else>
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/nolianxi@2x.png" alt="" />
            <div>暂无新的练习</div>
          </div>
        </div>
        <!-- <div style="padding:20px" v-if="false">  -->
        <div style="padding:20px" v-if="shangcheng"> <!-- 暂时隐藏 -->
          <div class="tit">
            <div class="titL">在线商城</div>
            <div class="titR" @click="toHomepage" v-if="shopList.length > 0">
              <div>查看全部</div>
              <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/next@2x.png" alt="" />
            </div>
          </div>
          <div class="shopList" v-if="shopList.length > 0">
            <div
              class="shopLi"
              v-for="(items, indexs) in shopList"
              :key="indexs"
              @click="toGoodsDetails(items.sku, items.productId)"
            >
              <img :src="items.productCoverUrl" alt="" />
              <div class="shopLitit">{{ items.productName }}</div>
              <div class="shopLinum">
                ¥{{ items.price }}
                <span>¥{{ items.priceOriginal }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="padding:20px" @click="toSchool">
          <div class="school">
            启蒙学院
          </div>
        </div> -->
      </div>
    </div>
    <div v-else>
      <not-found tip='当前无孩子进园'></not-found>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import * as api from "@/untils/api";
import storage from "good-storage";
import http from "@/untils/http";
import { refreshToken } from "@/untils/refreshToken";
import notFound from "@/components/notfound/notfound.vue";
export default {
  name: "index",
  components: {
    "not-found":notFound
  },
  data() {
    return {
      showPopover: false,
      name: "",
      parkList: [],
      // showClass: false,
      num: 0,
      i: 0,
      j: 0,
      goodsList: [],
      lessonNum: 0,
      lessonList: [],
      shopList: [],
      lianxi: true,
      shangcheng: true,
      schoolState:true
    };
  },
  created() {
    this.babyList = JSON.parse(storage.get("babyList"));
    if (storage.get("i") || storage.get("j")) {
      this.i = storage.get("i");
      this.j = storage.get("j");
    } else {
      storage.set("i", 0);
      storage.set("j", 0);
    }
    this.num = this.i;
    console.log(this.babyList, this.babyList.length);
    this.parkList = [];
    for (let i = 0; i < this.babyList.length; i++) {
      // console.log(i,this.babyList[i].name + '(' + this.babyList[i].schoolList[0].name + ')')
      if (this.babyList[i].schoolList) {
        // console.log(this.babyList[i].schoolList.length)
        for (let j = 0; j < this.babyList[i].schoolList.length; j++) {
          let schoolName = this.babyList[i].schoolList.length > 0 ? this.babyList[i].schoolList[0].name : '未进园'
          let arr = {
            i: i,
            j: j,
            name:
              this.babyList[i].name +
              "(" +
              schoolName +
              ")"
          };
          this.parkList.push(arr);
        }
      }
      // for (let j = 0; j < this.babyList[i].schoolList.length; j++) {
      // console.log(i,this.babyList[i].schoolList)
      // let arr = {
      //   i: i,
      //   j: j,
      //   name: this.babyList[i].name + '(' + this.babyList[i].schoolList[0].name + ')'
      // }
      // this.parkList.push(arr)
      // }
    }
    // console.log(this.babyList, this.parkList)
    // this.lessonCount();
    Promise.all([
      this.getSchoolCourse()
    ]).then(res=>{
      this.getSchoolState();
    })
  },
  methods: {
    //园所开通课程查询
    getSchoolCourse() {
      let qimengIdList = [];
      if(!this.babyList[this.i].schoolList.length){
          this.lianxi = false;
          this.shopInfo();
      }else{
        qimengIdList.push(
          this.babyList[this.i].schoolList[this.j].organizationId || ''
        );
        this.$post(api.getSchoolCourse, {
          qimengIdList: qimengIdList
        }).then(res => {
          console.log("园所开通课程查询", res);
          if (res.data.data.length > 0) {
            this.getIndexStatusList();
          } else {
            this.lianxi = false;
            this.shopInfo();
          }
        });
      }
    },
    // 开课课节列表
    getIndexStatusList() {
      let classIdList = "";
      console.log(this.babyList[this.i].schoolList[this.j].classList);
      if (this.babyList[this.i].schoolList[this.j].classList != null) {
        for (
          let i = 0;
          i < this.babyList[this.i].schoolList[this.j].classList.length;
          i++
        ) {
          if (classIdList == "") {
            classIdList = this.babyList[this.i].schoolList[this.j].classList[i]
              .organizationId;
          } else {
            classIdList =
              classIdList +
              "," +
              this.babyList[this.i].schoolList[this.j].classList[i]
                .organizationId;
          }
        }
      }
      console.log(classIdList);
      if (classIdList.length != 0) {
        this.$get(api.indexStatusList, {
          status: 1,
          classIdList: classIdList
        }).then(res => {
          console.log("开课课节列表", res);
          this.shopInfo();
          // this.lessonNum = res.data.total
          this.lessonList = res.data.data;
        });
      } else {
        this.shopInfo();
      }
    },
    //是否开商铺
    shopInfo() {
      if(!this.babyList[this.i].schoolList.length){
          this.shangcheng = false;
      }else{
        this.$post(api.shopInfo, {
          shopNumber: this.babyList[this.i].schoolList[this.j].organizationId
        }).then(res => {
          console.log("是否开商铺", res);
          if (res.data.total != 0) {
            this.productList();
          } else {
            this.shangcheng = false;
          }
        });
      }
    },
    //是否开通学堂
    getSchoolState(){
      console.log('进入是否开通学堂')
      if(!this.babyList[this.i].schoolList.length){
        return this.schoolState = false;
      }
      let organizationId = this.babyList[this.i].schoolList[this.j].organizationId
      this.$post(
        api.GetshopList,
        {
          shopNumber:[organizationId],
          shopTag:"课程商铺",
          status:1
        }
      ).then(res=>{
        if (res.data.total != 0) {
        } else {
          this.schoolState = false;
        }
      }).catch(err=>{
        console.error(err)
      })
    },
    //获取商品列表(暂时不用)
    productList() {
      this.$post(api.productList, {
        shopNumber: this.babyList[this.i].schoolList[this.j].organizationId
      }).then(res => {
        console.log("获取商品列表", res);
        if (res.data.total > 4) {
          this.shopList = res.data.data.slice(0, 4);
        } else {
          this.shopList = res.data.data;
        }
        // if (res.data.total > 0) {
        //   this.productList();
        // } else {
        //   this.shangcheng = false
        // }
      });
    },
    toChoosePart(lessonId, courseId, classId) {
      this.$router.push({
        path: "/choosePart",
        query: {
          lessonId: lessonId,
          courseId: courseId,
          babyId: this.babyList[this.i].babyId,
          schoolId: this.babyList[this.i].schoolList[this.j].organizationId,
          classId: classId
        }
      });
      // window.location.href = '../pages/choosePart.html?lessonId=' + lessonId + '&courseId=' + courseId + '&babyId=' + this.babyList[this.i].babyId + '&schoolId=' + this.babyList[this.i].schoolList[0].organizationId + '&classId=' + classId
    },
    selectPark() {
      this.showPopover = !this.showPopover;
    },
    getPark(e) {
      console.log(e);
      this.showPopover = false;
      this.num = e;
      console.log(this.parkList[e].j);
      this.i = this.parkList[e].i;
      this.j = this.parkList[e].j;
      storage.set("i", this.i);
      storage.set("j", this.j);
      this.getSchoolCourse();
      // this.lessonCount();
      // this.getProductCount();
      //  this.$router.push('/');
    },
    // //获取课程数量
    // lessonCount() {
    //   console.log(this.babyList[this.i].schoolList[this.j].classList.length)
    //   if (this.babyList[this.i].schoolList[this.j].classList.length != 0) {
    //     this.$get(
    //       api.lessonCount, {
    //         classId: this.babyList[this.i].schoolList[this.j].classList[0].organizationId,
    //       }
    //     ).then((res) => {
    //       console.log("获取课程数量", res.data.data.lesson)
    //       // this.showClass = true
    //       this.lessonNum = res.data.data.lesson
    //     })
    //   } else {
    //     this.showClass = false
    //   }
    // },
    // //获取商品统计
    // getProductCount() {
    //   console.log(this.i, this.j)
    //   console.log('11111', this.babyList[this.i].schoolList[this.j].organizationId, )
    //   this.$get(
    //     api.productCount, {
    //       shopNumber: this.babyList[this.i].schoolList[this.j].organizationId,
    //     }
    //   ).then((res) => {
    //     console.log(res)
    //     this.lessonCount();
    //     this.goodsList = res.data.data
    //     console.log(this.goodsList)
    //   })
    // },
    //跳转去个人中心
    toMyCenter() {
      this.$router.push("/myCenter");
    },
    //去商品主页
    toHomepage(catagoryId) {
      this.$router.push({
        path: "/homepage"
      });
    },
    //跳转到测评列表
    toAppraisal() {
      this.$router.push({
        path: "/appraisalList",
        query: {
          name: this.babyList[this.i].name,
          birthday: this.babyList[this.i].birthday,
          organizationId: this.babyList[this.i].schoolList[this.j]
            .organizationId,
          userId: this.babyList[this.i].babyId
          // type: type,
          // catagoryId: catagoryId
        }
      });
    },
    //去商品详情页
    toGoodsDetails(sku, productId) {
      this.$router.push({
        path: "/goodsDetails",
        query: {
          productId: productId,
          sku: sku
        }
      });
    },
    toSchool() {
      this.$store.dispatch("getCommonProfile");
      this.$router.push({
        path: "/school"
      });
    },
    toChooseClass() {
      // storage.get("api", process.env.API_URL);
      this.$router.push({
        path: "/chooseClass"
      });
      // window.location.href = '../../../static/pages/chooseClass.html'
      // window.location.href = 'http://prod.babygrow.cn/qimeng-web/family/pages/chooseClass.html'
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.index {
  width: 100%;
  background-color: #fafafc;
}
.top {
  /* background-color: #ffffff; */
  padding: 21px 20px;
}
.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select {
  padding: 5px 12px;
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
}
.select img {
  width: 16px;
  height: 16px;
  margin-left: 6px;
}
.user {
  width: 32px;
  height: 32px;
  background: #ffffff;
  text-align: center;
  border-radius: 100%;
}
.user img {
  margin-top: 5px;
  width: 22px;
  height: 22px;
}
.mint-popup {
  background-color: #ffffff;
  width: 100%;
  text-align: center;
}
.popoverItem {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgba(168, 168, 173, 0.2);
}
.list {
  display: flex;
  /* justify-content: space-between; */
  margin: 24px 15px;
  /* align-items: center; */
}
.li {
  text-align: center;
  width: 25%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #67676b;
}
.li img {
  margin-bottom: 8px;
  width: 44px;
  height: 44px;
}
.show {
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
}
.lessonList {
  padding: 20px;
}
.tit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titL {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 25px;
}
.titR {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #67676b;
  line-height: 16px;
  display: flex;
  align-items: center;
  /* flex-direction: column;
                            align-items: center; */
}
.titR img {
  /* vertical-align: middle; */
  width: 15px;
  height: 15px;
}
.lessonLi {
  display: flex;
  padding: 20px 0;
}
.lessonLiL {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 12px;
}
.lessonLiR {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #434345;
}
.lessonLiR span {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #dca37a;
  background: #fff9f0;
  border-radius: 12px;
  padding: 3px 10px;
  margin-right: 5px;
}
.shopList {
  /* padding: 20px; */
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.shopLi {
  margin-bottom: 20px;
  width: 46%;
}
.shopLi:nth-child(2n + 1) {
  margin-right: 8%;
}
/* .shopLii:nth-child(2n+1) {
          margin-right: 10%;
        }
        .shopLi {
            margin-top: 24px;
          display: flex;
          width: 45%;
          /* justify-content: space-between; */
/* margin-right: 1%; */
/* flex-wrap: wrap; */
.shopLi img {
  width: 100%;
  height: 160px;
  border-radius: 8px;
}
.shopLitit {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 27px;
}
.shopLinum {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f85511;
  line-height: 25px;
}
.shopLinum span {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8e8e93;
  line-height: 21px;
  text-decoration: line-through;
  margin-left: 10px;
}
.dont {
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8e8e93;
  line-height: 33px;
}
.dont img {
  width: 167px;
  height: 132px;
}
.school {
  width: 100%;
  height: 150px;
  background: #d8d8d8;
  border-radius: 7px;
  text-align: center;
  line-height: 150px;
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  letter-spacing: 1px;
}
</style>
