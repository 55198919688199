<template>
  <div class="school">
    <div class="waper_header">
      <headers-select
      v-if="headerSelect"
      :user='false'
      @selectItem="initData"
      ></headers-select>
    </div>
    <div v-show="mixParadiseState && requestLoad">
      <div class="header padding-lr-sm">
        <div class="info" v-if="selectlist.length != 0">
          <popup-select
            :selectlist="selectlist"
            :selectIndex="selectIndex"
            @selectItem="selectItem"
          ></popup-select>
          <div class="search">
            <input v-model="keyword" @change="toSearch" type="text" placeholder="请输入课程名" />
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/search@2x.png" alt="" @click="toSearch"/>
          </div>
        </div>
      </div>
      <!-- 体验课程 -->
      <div class="experience">
        <div class="title padding-lr-sm title-tb-sm">体验课程</div>
        <div class="experience-list" v-if="experiences.length > 0">
          <div
            class="experience-item"
            v-for="(item, index) in experiences"
            :key="index"
            @click="getGoodsDetails(item)"
          >
            <div class="item-img">
              <img name='dynamicImg' :src="item.picture+imgSizeQuality" alt="" />
            </div>
            <div class="item-contain">
              <div class="item-title">{{ item.title }}</div>
              <div class="item-validityMonth">{{ item.validityMonth | validityMonth }}</div>
              <div class="item-price">
                <div class="price-before">{{ item.price }}</div>
                <div class="price-after">{{ item.priceOriginal }}</div>
              </div>
            </div>
          </div>
          <div
            class="more padding-lr-sm"
            @click="
              () =>
                this.$router.push({
                  path: '/search',
                  query: { type: 'experience', ...this.organization }
                })
            "
            v-show="experiences.length > 3"
          >
            <div class="more-img">
              <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/next@2x.png" alt="" />
            </div>
            <div class="more-text">查看更多体验课</div>
          </div>
        </div>
        <div class="data-none" v-else>
          <div class="data-none-img">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/curriculum/progress.png" alt="">
          </div>
          <div>暂无数据</div>
        </div>
      </div>
      <!-- 课程分类 -->
      <div class="curriculumType padding-lr-sm">
        <common-nav
          class="title-tb-sm"
          :list="navs"
          :activeIndex="navIndex"
          @clickItem="navClick"
        ></common-nav>
        <div class="goods">
          <div v-if="goods.length != 0">
            <older-goods
              :info="item"
              v-for="(item, index) in goods"
              :key="index"
              @details="getGoodsDetails"
            ></older-goods>
          </div>
          <div class="data-none" v-else>
            <div class="data-none-img">
              <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/curriculum/progress.png" alt="">
            </div>
            <div>暂无数据</div>
          </div>
        </div>
        <div class="loading" v-if="!pagesNext">
          <div v-if="pagesNext && goods.length != 0">
            <mt-spinner type="fading-circle"></mt-spinner>
            <div class="tip">加载中</div>
          </div>
          <div class="tip" v-else>已经到底了！</div>
        </div>
      </div>
    </div>
    <div v-show="!mixParadiseState && requestLoad">
      <not-found></not-found>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
import olderGoods from "@/components/goods/olderGoods/olderGoods.vue";
import headerSelect from "@/components/select/header/header.vue";
import popupSelect from "@/components/select/popupSelect/popupSelect.vue";
import commonNav from "@/components/nav/commonNav/commonNav.vue";
import notFound from "@/components/notfound/notfound.vue";
import scrollMixin from '../Mixins/scroll'
import paradiseMix from "@/Mixins/paradiseMix"
import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
  name: "school",
  components: {
    "older-goods": olderGoods,
    "headers-select":headerSelect,
    "popup-select": popupSelect,
    "common-nav": commonNav,
    "not-found":notFound
  },
  mixins: [scrollMixin,paradiseMix,dynamicImgwh],
  filters:{
    validityMonth(val){
      let tipValidity = val == 0 ? '永久有效' : `有效期${val}个月`
      return tipValidity
    }
  },
  data() {
    return {
      keyword:'',
      loading: false,
      organization: {}, //当前机构信息
      experiences: [],
      navs: [
        {
          title: "单课",
          type:1
        },
        {
          title: "套餐课",
          type:2
        }
      ],
      navIndex: 0,
      goods: [],
      pageNumber: 1,
      pagesNext:true,
      babyAct:null,
      schoolState:true,
      headerSelect:false,
      requestLoad:false
    };
  },
  async created() {
    await this.$UpdateUserInfo().then(res=>{
      this.headerSelect = true
    })
    await this.initData()
  },
  computed:{
    //年龄端数据源
    selectlist(){
      return this.$store.state.ageList
    },
    //第几项年龄段
    selectIndex(){
      return this.$store.state.ageIndex
    }
  },
  methods: {
    // 初始化数据信息
    async initData() {
      const that = this
      this.requestLoad = false
      Indicator.open("加载中...");
      // 检测并设置家长数据
      await this.setFamilyInfo(()=>{
        that.babyAct = JSON.parse(storage.get("i"))
        // 初始化园所信息
        let bodyInfo = JSON.parse(storage.get("babyList"))[storage.get("i")];
        that.organization = {
          babyId: bodyInfo.babyId,
          name: bodyInfo.name,
          organizationId: bodyInfo.schoolList[storage.get("j")].organizationId,
          organizationName:bodyInfo.schoolList[storage.get("j")].name
        };
        that.goods = []
        that.pageNumber = 1
        that.pagesNext = true
      }).then(()=>{
        // 查询课程商铺信息
        that.getSchoolState()
        .then(async ({state,info})=>{
          // 开通课程商铺
          if(state){
            console.log(state,info,'返现信息')
            //获取购物车列表
            await that.$store.dispatch('getCommonProfile')
            await that.$store.commit('saveShopInfo',info)
            await that.$nextTick(()=>{
              that.getExperiences()
              that.get_dynamicImgSQ()
            })
          }else{
            this.requestLoad = true
            Indicator.close();
          }
        })
      })
    },
    toSearch(){
      let keyword = this.keyword
      this.$router.push({
        path: '/search',
        query: { ...this.organization,keyword }
      })
    },
    selectItem(e) {
      this.$store.commit('saveAgeIndex',e)
      this.goods = []
      this.pageNumber = 1
      this.pagesNext = true
      Promise.all([
        this.getCurriculum()
      ]).then(()=>{
        this.getExperiences()
      })
    },
    navClick(e) {
      this.navIndex = e;
      this.goods = [],
      this.pageNumber = 1,
      this.pagesNext = true
      this.getCurriculum()
    },
    // 获取体验课程
    getExperiences() {
      const that = this
      let { value } = that.selectlist[that.selectIndex]
      that.$get(api.shopCourse, {
        shopId: that.$store.state.shopInfo.shopId,
        ageId:value,
        isExperience: 1,
        pageNumber: 1,
        pageSize: 4,
        status:1
      }).then(res => {
        that.experiences = res.data.data
        console.log(res, "当前体验课");
        that.$nextTick(()=>{
            that.getCurriculum()
        })
      })
    },
    // 上拉加载更多
    onReachBottom() {
      this.loading = true;
      setTimeout(() => {
        console.log('上拉加载更多')
        // 调用接口
        this.getCurriculum()
        this.loading = false;
      }, 2500);
    },
    // 获取单课/体验课
    getCurriculum() {
        this.loading = true;
        if(!this.pagesNext) return false;
        console.log("上拉加载更多");
        const that = this
        let { value } = that.selectlist[that.selectIndex]
        // 调用接口
        that.$get(api.shopCourse, {
          shopId: that.$store.state.shopInfo.shopId,
          ageId:value,
          type:this.navs[this.navIndex].type,
          pageNumber: this.pageNumber,
          pageSize: 10,
          status:1
        }).then(res => {
          let data = res.data.data

          if(data.length == 10 && this.pagesNext){
            this.goods = [...this.goods,...data]
            this.pageNumber ++
            this.pagesNext = true
          }else if(data.length < 10 && this.pagesNext){
            this.goods = [...this.goods,...data]
            this.pagesNext = false
          }
          console.log(this.goods, "单课");
          this.loading = false;
          this.requestLoad = true
          Indicator.close();
        })
    },
    //获取课程详情
    getGoodsDetails(args) {
      const { courseOnlineId } = args;
      const organizationId = this.organization.organizationId
      this.$router.push({
        path: "/curriculumDetails",
        query: { courseOnlineId,organizationId }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body{
  background-color: #fafafc;
}
.padding-lr-sm {
  padding-left: 20px;
  padding-right: 20px;
}
.title-tb-sm {
  margin: 16px 0;
}
.over-hider {
  overflow: hidden;
}
.school {
  width: 100%;
  background: #FBFBFB;
}
.waper_header{
  margin-top: 92px;
}
.header {
  /* background-color: #ffffff; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search {
  background: #ffffff;
  border-radius: 19px;
  /* width: 100%; */
  border: 1px solid #d7d7d7;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 5px 15px;
}
.search img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.search input {
  width: 100%;
  background: none;
  outline: none;
  border: none;
}
.search input::-webkit-input-placeholder {
  color: #9E9E9E;
  font-size: 14px;
}
.banner img {
  width: 100%;
  height: 148px;
}
.banner {
  height: 148px;
}
.experience > .title {
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 700;
  color: #333333;
}
.experience > .experience-list {
  display: flex;
  overflow-x: auto;
  margin: 0 6px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.experience > .experience-list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.experience-list .experience-item {
  flex: 0 0 184px;
  margin: 0 12px;
  border-radius: 7px;
  overflow: hidden;
  background: #fff;
}
.experience-item .item-img {
  height: 137px;
}
.experience-item .item-img img {
  width: 100%;
  height: 100%;
}
.experience-item .item-contain {
  height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px 9px;
}
.experience-item .item-title {
  font-size: 17px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 23px;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.experience-item .item-validityMonth {
  width: fit-content;
  font-size: 13px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #b3745f;
  background: #fef6f3;
  border-radius: 24px;
  display: inline-block;
  padding: 2px 7px;
}
.experience-item .item-price {
  display: flex;
  align-items: flex-end;
  margin: 3px 0;
}
.experience-item .item-price > div::before {
  content: "¥";
  margin-right: 3px;
}
.experience-item .item-price .price-before {
  font-size: 19px;
  color: #ed6030;
  margin-right: 8px;
  font-weight: 600;
  line-height: 19px;
}
.experience-item .item-price .price-after {
  color: #8e8e93;
  font-size: 14px;
  text-decoration: line-through;
}
.experience-list .more {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nav-item{
  font-size: 20px !important;
}
.more .more-img {
  width: 20px;
  height: 20px;
  padding: 10px;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 4px #e4e4e4;
}
.more .more-img img {
  width: 100%;
  height: 100%;
}
.more .more-text {
  color: #8e8e93;
  font-size: 12px;
  text-align: center;
  width: 4em;
  margin-top: 10px;
  line-height: 18px;
}
.loading,.loading div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .tip {
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8E8E93;
}
.data-none-img{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
}
.data-none-img img{
  display: block;
  width: 167px;
  height: 132.5px;
  margin: 0 auto;
}
.data-none div{
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8E8E93;
  line-height: 33px;
}
</style>
