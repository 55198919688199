<template>
    <div class="catalogue-contain">
        <div class="special" :class="[ showItem != indexF ? 'hide' : '']" v-for="(special, indexF) in catalogues" :key="special.id">
            <div class="special-title" @click="showChange(indexF)">
                <div class="title-text">{{special.className}}</div>
                <div class="title-icon">
                    <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/curriculum/arrow.png" alt="">
                </div>
            </div>
            <div class="chapter">
                <div class="chapter-item" v-for="(item, index) in special.lesson" :key="item.id">
                    <div class="indexs">{{index < 9 ? '0' + (index+1) : index+1}}</div>
                    <!-- <div class="week">week{{index+1}}</div> -->
                    <div class="title">{{item.title}}</div>
                    <div class="time" v-if="item.media != 8">{{sec_to_time(item.duration)}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import catalogue from "./catalogue.js";
    export default catalogue;
</script>
<style scoped>
    @import './catalogue.css';
</style>
