<template>
    <div class="tabbar-waper">
        <van-tabbar v-model="active" @change="onChange">
            <van-tabbar-item v-for="(item,index) in tabs" :key="index" :badge="item.badge" :name="item.key">
                <template #icon="props">
                    <img :src="props.active ? item.active : item.inactive"/>
                </template>
                <div>{{item.name}}</div>
            </van-tabbar-item>
        </van-tabbar>
      </div>
</template>
<script>
    import tabbar from "./tabbar.js";
    export default tabbar;
</script>
<style scoped>
    @import './tabbar.css';
</style>