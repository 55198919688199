export default {
    name:'popupSelect',
    props:{
        selectlist:{
            type:Array,
            default:()=>[]
        },
        selectIndex:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            showPopover: false, //选择年龄段
        }
    },
    created(){
    },
    
    methods:{
        // 选择某一项
        selectItem(val){
            this.showPopover = false;
            this.$emit('selectItem',val)
        }
    }
}