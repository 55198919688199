<template>
  <div class="addressList">
    <!-- <div class="acquiescent">
                <img class="acquiescentL" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/gps@2x.png">
                <div class="acquiescentR">
                  <div class="acquiescentInfo">
                    <div class="acquiescentName">胡龙萍</div>
                    <div class="acquiescentPhone">18612000000</div>
                  </div>
                  <div class="acquiescentAdd">北京市朝阳区小营桥住总地产大厦5层502</div>
                </div>
              </div> -->
    <div class="list">
      <div class="li" v-for="(item,index) in list " :key="index">
        <div class="liL" @click="chooseAddress(index)">
          <div class="acquiescentInfo">
            <div class="acquiescentName">{{item.name}}</div>
            <div class="acquiescentPhone">{{item.phone}}</div>
            <div v-if="item.isDefault==1">(默认)</div>
          </div>
          <div class="acquiescentAdd">{{item.province}}{{item.city}}{{item.county}}{{item.address}}</div>
        </div>
        <img class="liR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/pen@2x.png" @click="toNewAddress(index)">
      </div>
    </div>
    <div class="botton" @click="toNewAddress('new')">
      <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/add@2x.png" alt=""> 新建收货地址
    </div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import storage from "good-storage";
  import http from "@/untils/http";
  export default {
    name: 'addressList',
    data() {
      return {
        list: [],
      }
    },
    created() {
      this.type = this.$route.query.type;
      this.getAddressList();
    },
    methods: {
      //获取地址列表
      getAddressList() {
        let that = this
        that.$get(api.addressList).then(res => {
          if (res.data.code == 0) {
            console.log('获取地址列表成功', res)
            that.list = res.data.data
            // that.$router.push('/address');
          }
        })
      },
      //修改地址
      toNewAddress(e) {
        console.log(e)
        if (e == 'new') {
          this.$router.push({
            path: '/newAddress',
            query: {
              type: 'new',
            }
          });
        } else {
          this.$router.push({
            path: '/newAddress',
            query: {
              type: 'change',
              name: this.list[e].name,
              phone: this.list[e].phone,
              province: this.list[e].province,
              city: this.list[e].city,
              county: this.list[e].county,
              address: this.list[e].address,
              addressId: this.list[e].addressId,
              isDefault: this.list[e].isDefault
            }
          });
        }
        //
      },
      chooseAddress(e) {
        if (this.type == 'choose') {
          storage.set("Address", JSON.stringify(this.list[e]));
          this.$router.go(-1);
        }
      }
    }
  }
</script>

<style scoped>
  .acquiescent {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    margin: 15px 0;
    padding: 15px 20px;
  }
  .acquiescentL {
    width: 20px;
    height: 25px;
    margin-right: 12px;
  }
  .acquiescentInfo {
    display: flex;
    /* align-items: center; */
  }
  .acquiescentName {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }
  .acquiescentPhone {
    font-size: 13x;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #B9B9B9;
    margin-left: 10px;
  }
  .acquiescentAdd {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2F2F2F;
    margin-top: 3px;
  }
  .list {
    background-color: #ffffff;
  }
  .li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
  }
  .liR {
    width: 18px;
    height: 16px;
  }
  .botton {
    width: 86%;
    margin: 0 7%;
    position: fixed;
    bottom: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
    border-radius: 20px;
  }
  .botton img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
</style>
