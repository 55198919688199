import Vue from "vue"
import storage from "good-storage";
import store from '../store'
import * as api from "@/untils/api";
// 格式化孩子所在园所信息
const babySchoolsHandle = res => {
    let newAry = []
    res.map((baby,babyIndex)=>{
        if(baby.schoolList && baby.schoolList.length != 0){
            baby.schoolList.map((school,schoolIndex)=>{
                    newAry.push({
                        i:babyIndex,
                        j:schoolIndex,
                        name:baby.name,
                        schoolName:school.name
                    })
            })
        }
    })
    newAry = newAry.map((ele,index)=>{
        ele.index = index
        return ele
    })
    return newAry
}
/**
 * 设置家长通用数据
 * @returns 
 */
export const setCommont = ( info )=>{
    return new Promise( (resolve, reject) =>{
        let babySchools = store.state['babySchools']
        if(!storage.get("i")) storage.set("i", 0)
        if(!storage.get("j")) storage.set("j", 0)
        if(babySchools.length == 0 || info){
            let babyList = info || JSON.parse(storage.get("babyList"))
            let selectlist = babySchoolsHandle(babyList)
            resolve(selectlist)
            store.commit('saveBabySchools',selectlist)
        }else{
            resolve(babySchools)
        }
    } )
}

/**
 * 设置获取用户信息
 * @returns 
 */
 export const updateUserInfo = ()=>{
    return new Promise( (resolve, reject) =>{
        Vue.prototype.$get(api.parentInfo).then(res => {
            if(res.data.data.babyList.length > 0 ){
                res.data.data.babyList.forEach((baby,babyIndex)=>{
                if(baby.schoolList && baby.schoolList.length != 0){
                    baby.schoolList.forEach(school=>{
                    school.name = school.shortName || '暂无院所别名'
                    })
                }
                })
                storage.set("userUnionId", res.data.data.userUnionId);
                res.data.data.babyList = res.data.data.babyList.filter(item=>item.schoolList.length != 0)
                storage.set("babyList",JSON.stringify(res.data.data.babyList));
                store.commit('saveBabySchools',[])
                setCommont( res.data.data.babyList ).then(list=>{
                    resolve(true)
                })
            }else{
                resolve(false)
            }
        });
    } )
}