import * as api from "@/untils/api";
import storage from "good-storage";
import { setCommont } from "@/untils/set_family_info";
export default {
    data() {
      return {
          mixExerciseState:false, //当前院所下是否开通课程商铺
      }
    },
    mounted(){},
    methods:{
        setFamilyInfo(cd){
            return new Promise(resolve => {
              // 判断是否包含院所id
              setCommont().then(()=>{
                  cd()
                  resolve()
              })
              
          })
      },
      //园所开通课程查询
      getSchoolCourse(organizationId) {
        return new Promise(resolve => {
            // let qimengIdList = [];
            // let babyList = JSON.parse(storage.get("babyList"))
            // let i = storage.get("i")
            // let j = storage.get("j")
            // qimengIdList.push(
            //     babyList[i].schoolList[j].organizationId
            // );
            this.$post(api.getSchoolCourse, {
                qimengIdList: [organizationId]
            }).then(res => {
                console.log("园所开通课程查询", res);
                if (res.data.data.length > 0) {
                    this.mixExerciseState = true;
                    resolve(true)
                } else {
                    this.mixExerciseState = false;
                    resolve(false)
                }
            });
        })
      },
    },
  }