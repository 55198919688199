// 转换为金额格式
export function convert_money(money, decimals) {
  /*
   * decimals：保留几位小数
   */
  decimals = decimals || 2;
  if (!money || money < 0) return '￥0.00'
  //保留两位小数的金额
  let money_tofixed = parseFloat(money).toFixed(decimals);
  //截取 暂存两位小数 toLocaleString方法只支持转换数字
  //小数点后两位
  let decimal = money_tofixed.split(".").pop();
  //转换之后的整数
  let money_toLocale = Number(money_tofixed).toLocaleString();
  //return ￥ + 2,222 + .27
  if(money_toLocale.indexOf('.') == -1 && decimals == 0){
    return '￥' + money_toLocale
  }
  return '￥' + money_tofixed.replace('.'+decimal, '') + '.' + decimal
}

//默认为---
export function empty_init(data) {
  return data ? data : '---'
}
//显示当前购买商品数量
export function totalProductQuantity(item) {
  let num = 0;
  item.map((params) => {
    num += params.productQuantity;
  })
  return num
}

//支付方式回显
export function orderPayType(type) {
  if(typeof type != 'string') type = new String(type)
  let text = "";
  switch(type) {
  	case '0': {text = "---";break;}
    case '0700': {text = "---";break;}
  	case '0302': {text = "扫码";break;}
  	case '0402': {text = "微信小程序支付";break;}
  	case '0502': {text = "支付宝 H5 支付 ";break;}
  	case '0602': {text = "微信公众号支付";break;}
    case '602': {text = "微信公众号支付";break;}
    case '0702': {text = "支付宝服务窗支付";break;}
    case '0802': {text = "微信 APP 支付";break;}
    case '0902': {text = "支付宝服务窗支付";break;;}
    case '1003': {text = "扫码";break;}
    case '1102': {text = "云闪付 H5 支付";break;}
    case '1202': {text = "云闪付 APP 支付 ";break;}
    case '1302': {text = "支付宝小程序支付 ";break;}
  	default: text = "其他"
  }
  return text
}
