<template>
  <div class="courseOrderDetails-inner">
    <div class="courseOrderDetails">
        <older-goods
        :priceOriginal='false'
        :info='courseDetails'>
        </older-goods>
    </div>
    <div class="babyInfo" @click="()=>this.showchildList = true">
        <div>宝宝信息</div>
        <div class="babyInfoL">
            <div>{{ babyNameList[babyActIndex].name }}</div>
            <img class="babyInfoR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" />
        </div>
    </div>
    <div class="footer-btns padding-lr-sm">
      <div class="payment">
        <div class="price">{{courseDetails.priceShop}}</div>
        <div class="paySub" @click="paySub">{{courseDetails.priceShop != "0.00" ? '立即购买': '立即领取'}}</div>
      </div>
    </div>
    <!-- 宝宝信息选择 -->
    <div class="childBg" v-if="showchildList">
      <div class="childBox">
        <div class="childTit">宝宝信息</div>
        <div class="childLi"
        v-for="(item, index) in babyNameList"
        :key="index"
        @click="changeChild(index, item.babyId, item.organizationId, item.name)">
          <div :class="index == babyActIndex ? 'orange' : ''">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {Toast} from "mint-ui";
import * as api from "@/untils/api";
import olderGoods from '@/components/goods/olderGoods/olderGoods.vue'
import storage from "good-storage";
export default {
  name: "subCourseOrderDetails",
  components:{
    'older-goods':olderGoods,
  },
  data() {
    return {
        showchildList:false,
        courseDetails:{},
        babyNameList:[],//宝宝信息
        babyActIndex:0,
        className: "",
        classId: 0,
        externalId: []
    };
  },
  created() {
        this.babyNameList = []
        JSON.parse(storage.get("babyList")).map(item=>{
            item.schoolList.map(el=>{
              if( el.organizationId == this.$store.state.shopInfo.shopNumber){
                this.babyNameList.push({
                  babyId:item.babyId,
                  name:item.name,
                  organizationId : el.organizationId,
                  organizationName:el.shortName,
                })
              }
            })
        })
        let babyId = JSON.parse(storage.get("babyList"))[storage.get("i")].babyId
        JSON.parse(storage.get("babyList")).map(item=>{
          if(item.babyId == babyId && item.organizationId == this.$store.state.shopInfo.shopNumber){
            this.babyActIndex = storage.get("i")
          }
        })
        this.getDetails()
  },
  methods: {
    getDetails(){
      const { courseOnlineId } = this.$route.query
      const { shopId } = this.$store.state.shopInfo
      this.$get(
        api.courseDetails,
        {courseOnlineId,shopId}
      ).then(res=>{
        if(res.status != 200 || res.data.code != 0){
          return Toast('服务器繁忙请稍后再试！')
        }
        res.data.data.price = res.data.data.priceShop
        this.courseDetails = res.data.data
        console.log(res.data.data,'/课程详情')
      }).catch(err=>{
        console.error(err)
      })
    },
    //切换购买用户信息
    changeChild(index, babyId, organizationId, name) {
      this.name = name;
      this.organizationId = organizationId;
      this.showchildList = false;
      this.babyActIndex = index;
      storage.set("i", index);
    },
    // 支付
    paySub:_.throttle(function(){
        // v3.2.2 代码逻辑调整修复0元课程不能购买
        // this.getByOnlyBabyIds();
        // v3.2.3验证一个家庭是否购买过相同商品
        const { priceShop } = this.courseDetails
        this.getState_shopCheck_repeat()
        .then(state=>{
          if(!state){
            this.toAddPayOrder()
          }else{
            Toast({
              duration:3000,
              message:priceShop == '0.00' ? '当前孩子已领取过，不能重复领取哦' : '当前孩子已购买过，不能重复购买哦'
            });
          }
        })
    },4000),
    getState_shopCheck_repeat(){
      const { courseOnlineId } = this.$route.query
      const { courseId } = this.courseDetails
      return new Promise((resolve, reject) =>{
        this.$post(api.shopCheck_repeat,{
          productList:[{
            productId:courseOnlineId,
            levelId:courseId || 0
          }]
        }).then(res => {
          if(res.data &&  res.data.code == 0){
            let state = res.data.data
            resolve(state)
          }
        })
      })
    },
    //获取孩子信息
    getByOnlyBabyIds() {
      let babyId = this.babyNameList[this.babyActIndex].babyId
      this.$post(api.getByOnlyBabyIds, {
        babyIds: [babyId]
      }).then(res => {
        this.classId = 0;
        this.external = null;
        this.externalId = []
        this.organization = null
        if (res.data.data.length == 0)
        {
          this.classId = -2;
          this.toAddPayOrder();
        } else {
          if (res.data.data[0].externalId == null) {
            this.classId = -1;
            this.toAddPayOrder();
          } else {
            this.external = res.data.data[0].externalId;
            this.externalId.push(res.data.data[0].externalId);
            this.organization = res.data.data[0].externalOrganizationId;
            this.queryKid();
          }
        }
        // this.getProductInfo();
      });
    },
    queryKid() {
      this.$post(api.ListPage, {
        organizationId: this.organization,
        babyId: this.externalId
      }).then(res => {
        if (res.data.code == 0) {
          this.badyId = res.data.data.badyId;
          if (
            res.data.data.length != 0 &&
            res.data.data[0].classList.length != 0
          ) {
            this.classId = res.data.data[0].classList[0].organizationId;
            this.className = res.data.data[0].classList[0].name;
          } else {
            this.classId = 0;
            this.className = "";
          }
          this.toAddPayOrder();
        } else {
          Toast(res.data.msg);
        }
        // this.getCartList(1);
      });
    },
    toAddPayOrder(){
      const {
        priceShop,
        type,
        picture,
        isExperience,
        courseOnlineId,
        validityMonth,
        title,
        courseId
      } = this.courseDetails
      this.$post(api.postCourseOrde, {
        tag:tagVal(type,isExperience),
        product: [{
          productId:courseOnlineId,
          productName:title,
          productImg:picture,
          num:1,
          price:priceShop,
          validityMonth,
          levelId:courseId,
          babyId: this.babyNameList[this.babyActIndex].babyId,
          babyName: this.babyNameList[this.babyActIndex].name,
          organizationId: this.babyNameList[this.babyActIndex].organizationId,
          organizationName: this.babyNameList[this.babyActIndex].organizationName,
        }],
        classId: 0,
        className: '',
        // v3.2.2 代码逻辑调整修复0元课程不能购买
        // classId: this.classId,
        // className: this.className,
        shopTag:"课程商铺",
        topupType: priceShop == '0.00' ? "0700":"0602",//临时将0302换成0602
        shopNumber: this.babyNameList[this.babyActIndex].organizationId,
        openId: localStorage.getItem("openId"),
        frontUrl: process.env.API_URL + `/qimeng-web/family/index.html#/curriculumDetails?pay=1&courseOnlineId=${courseOnlineId}`
      }).then(res => {
        console.log(res);
        if (res.data.code == 0) {
          storage.set("orderId", res.data.data.orderId);
          if(priceShop == '0.00'){
            setTimeout(()=>{
              this.$router.go(-1)
            },200)
            return Toast("领取成功");
          }else{
            Toast("提交成功");
            window.location.href = res.data.data.payCode;
          }
        } else {
          Toast(res.data.msg);
        }
        // this.getCartList(1);
      });
    }
  }
};
function tagVal(type,isExperience){
  let types = {
    1:'单课',
    2:'套餐课'
  }
  let isExperiences = {
    1:'试听课',
    2:''
  }
  return isExperiences[isExperience] ? types[type]+isExperiences[isExperience] : types[type]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.padding-lr-sm{
  padding-left:20px;
  padding-right: 20px;
}
.title-tb-sm{
  margin: 16px 0;
};
.over-hider{
  overflow: hidden;
}
.courseOrderDetails-inner{
  min-height: 100vh;
  background: #FAFAFC;
}
.courseOrderDetails{
    background: #fff;
    padding: 0 10px;
    margin-top: 20px;
}
.babyInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
  margin-top: 25px;
  padding: 10px 20px;
  background: #fff;
}
.babyInfoL {
  display: flex;
  align-items: center;
}
.babyInfoL div:first-child {
  margin-right: 15px;
}
.babyInfoR {
  width: 10px;
  height: 14px;
}
.childBg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.59);
}
.childBox {
  background-color: #ffffff;
  margin: 0 auto;
  width: 70%;
  /* margin-left: 15%; */
  border-radius: 10px;
  text-align: center;
  padding: 20px 27px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.childTit {
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  padding-bottom: 15px;
}
.childLi {
  font-size: 15px;
  padding: 10px 0;
  color: #000000;
  line-height: 42px;
  border-bottom: 1px solid #e9e9e9;
}
.orange {
  color: #f85511;
}
.childLi:last-child {
  border: 0;
}
.footer-btns{
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
}
.footer-btns .payment{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-btns .payment .price{
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ED6030;
}
.footer-btns .payment .price::before{
  content: "￥";
}
.footer-btns .payment .paySub{
    font-size: 17px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    background: #FB7E30;
    border-radius: 40px;
    padding: 10px 35px;
    letter-spacing: 3px;
}
</style>
