export const tabbars = {
    //课后练习
    exercise:[{
      key:'exerciseChooseClass',
      path:'/exercise/chooseClass',
      badge:'',
      name:'课后练习',
      active:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/practice_active.png',
      inactive:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/practice.png'
    },{
      key:'exerciseAppraisalList',
      path:'/exercise/appraisalList',
      badge:'',
      name:'测评报告',
      active:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/evaluation_active.png',
      inactive:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/evaluation.png'
    },{
      key:'exerciseMyCenter',
      path:'/exercise/myCenter',
      badge:'',
      name:'我的',
      active:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/me_active.png',
      inactive:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/me.png'
    }],
    //商城
    mall:[{
      key:'mallHomepage',
      path:'/mall/homepage',
      badge:'',
      name:'商店',
      active:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/shopping_active.png',
      inactive:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/shopping.png'
    },{
      key:'mallShoppingCar',
      path:'/mall/shoppingCar',
      badge:'',
      name:'购物车',
      active:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/car_active.png',
      inactive:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/car.png'
    },{
      key:'mallOrderList',
      path:'/mall/orderList',
      badge:'',
      name:'订单',
      active:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/order_active.png',
      inactive:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/order.png'
    },{
      key:'mallMyCenter',
      path:'/mall/myCenter',
      badge:'',
      name:'我的',
      active:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/me_active.png',
      inactive:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/me.png'
    }],
    //乐园
    paradise:[{
      key:'paradiseSchool',
      path:'/paradise/school',
      badge:'',
      name:'乐园',
      active:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/school_active.png',
      inactive:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/school.png'
    },{
      key:'paradiseOrderList',
      path:'/paradise/orderList',
      badge:'',
      name:'订单',
      active:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/order_active.png',
      inactive:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/order.png'
    },{
      key:'paradiseMyCenter',
      path:'/paradise/myCenter',
      badge:'',
      name:'我的',
      active:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/me_active.png',
      inactive:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/tabbars/me.png'
    }],
}
