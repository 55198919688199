<template>
  <div class="goodsDetails">
    <!-- <img style="width:100%" :src="bannerList[2]" alt="">
                  <img :src="bannerList[1]" alt=""> -->
    <div class="swiper-container" v-if="bannerList.length > 1">
      <div class="swiper-wrapper ">
        <!-- {{bannerList.length}} -->
        <img
          name='dynamicImg'
          v-for="(item, index) in bannerList"
          :key="index"
          class="swiper-slide detailsImg"
          :src="item+imgSizeQuality"
          alt=""
        />
      </div>
    </div>
    <img v-else class="detailsImg" name='dynamicImg' :src="bannerList[0]+imgSizeQuality" alt="" />
    <div class="detailsTit">
      <div class="detailsTitL">
        <div class="detailsName">{{ productInfo.productName }}</div>
        <div>{{ productInfo.catagoryName }}</div>
      </div>
      <div class="detailsNum">
        <div class="detailsNumL" v-if="level.length != 0">
          <span class="money">¥</span>{{ level[currentTab].price }}
          <span class="originalCost" v-if="level[currentTab].priceOriginal != 0"
            >¥{{ level[currentTab].priceOriginal }}</span
          >
        </div>
        <div class="detailsNumL" v-else>
          <span class="money">¥</span>{{ productInfo.price }}
          <span class="originalCost" v-if="productInfo.priceOriginal != 0"
            >¥{{ productInfo.priceOriginal }}</span
          >
        </div>
        <div class="detailsNumR" v-if="productInfo.num != -1">
          剩余库存:{{ productInfo.num }}
        </div>
      </div>
    </div>
    <div class="detailsType" v-if="level.length != 0">
      <div class="detailsTypeL">规格</div>
      <div class="detailsTypeR" @click="chooseType(0)">
        {{ level[currentTab].levelName }}
        <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" alt="" />
      </div>
    </div>
    <div class="detailsShow" v-if="contentImage">
      <div class="detailsShowTit">商品详情</div>
      <div style="padding:20px 0">
        {{ productInfo.content }}
      </div>
      <img
        :src="items"
        alt=""
        v-for="(items, indexs) in contentImage"
        :key="indexs"
      />
    </div>
    <div class="bottoms">
      <div class="upTit" v-if="productInfo.status == 0">商品已经下架啦～</div>
      <div>
        <div class="button" v-if="params.length == 0">
          <div class="buttonL" @click="toShoppingCar">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/shoppingcart@2x.png" alt="" />
            <div>购物车</div>
          </div>
          <div
            class="buttonR"
            v-if="productInfo.num != 0 && productInfo.status != 0"
          >
            <div class="join" @click="chooseType(1)">加入购物车</div>
            <div class="buy" @click="chooseType(2)">购买</div>
          </div>
          <div class="buttonR" style="opacity:0.6" v-else>
            <div class="join">加入购物车</div>
            <div class="buy">已售罄</div>
          </div>
        </div>
        <div class="button" v-else>
          <div
            class="shareBuy"
            v-if="productInfo.num != 0 && productInfo.status != 0"
            @click="chooseType(2)"
          >
            立即购买
          </div>
          <div class="shareBuy" v-else style="opacity:0.6">购买</div>
        </div>
        <div class="chooseShowbg" v-if="showChoose">
          <div class="chooseShow">
            <div class="chooseInfo">
              <div class="chooseInfoL">
                <img :src="level[currentTab].levelCoverUrl+'@h_70,w_70,q_80'" alt="" />
                <div class="chooseInfoNum">
                  <div class="chooseMoney">
                    <span>¥</span>{{ level[currentTab].price }}
                  </div>
                  <div class="originalMoney">
                    ¥{{ level[currentTab].priceOriginal }}
                  </div>
                </div>
              </div>
              <img
                class="chooseInfoR"
                src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/close@2x.png"
                alt=""
                @click="closeChoose"
              />
            </div>
            <div class="chooseTit">规格</div>
            <div class="chooseList">
              <div
                :class="
                  currentTab == levelIndex ? 'chooseItem' : 'nochooseItem'
                "
                v-for="(levelItem, levelIndex) in level"
                @click="changeTo(levelIndex)"
                :key='levelIndex'
              >
                {{ levelItem.levelName }}
              </div>
            </div>
            <div class="chooseNum">
              <div class="chooseTit">数量</div>
              <div class="chooseNumR">
                <div class="icon" @click="decrease">－</div>
                <input
                  class="number"
                  type="number"
                  value="num"
                  v-model="num"
                  @blur="changeNum($event, level[currentTab].num)"
                  onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                  onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                />
                <!-- <div class="number">{{num}}</div> -->
                <div class="icon" @click="addition(level[currentTab].num)">
                  ＋
                </div>
              </div>
            </div>
            <div class="bottom" @click="confirm">确定</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import {
  Toast,
  Swipe
  // SwipeItem
} from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
  name: "goodsDetails",
  mixins: [dynamicImgwh],
  data() {
    return {
      productInfo: [],
      level: [],
      showChoose: false,
      currentTab: 0,
      num: 1,
      contentImage: [],
      bannerList: {},
      // 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa0.att.hudong.com%2F30%2F29%2F01300000201438121627296084016.jpg&refer=http%3A%2F%2Fa0.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614851289&t=0df645759aca0b1581fb7ef0784c25f1',
      // 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa0.att.hudong.com%2F52%2F62%2F31300542679117141195629117826.jpg&refer=http%3A%2F%2Fa0.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614851289&t=f4d5d65e726498cca8d308f92867bbf2',
      // 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa2.att.hudong.com%2F27%2F81%2F01200000194677136358818023076.jpg&refer=http%3A%2F%2Fa2.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614851289&t=c039d97ba57f9196a79a420c15eefe10',
      // "http://service-images.cdn.bcebos.com/1277785181082…277/1272469160747511808/png/20210202105556991.png",
      // "http://service-images.cdn.bcebos.com/1277785181082…277/1272469160747511808/png/20210202105601392.png"
      // ],
      chooseList: [],
      params: []
    };
  },
  async created() {
    await this.$UpdateUserInfo().then(res=>{
      this.babyList = JSON.parse(storage.get("babyList"));
      this.i = storage.get("i");
      this.j = storage.get("j");
    })
    // console.log(this.$route.query.params, JSON.parse(this.$route.query.params));
    if (this.$route.query.params) {
      this.params = JSON.parse(this.$route.query.params);
      // console.log(decodeURIComponent(this.$route.query.params));
      console.log(this.params);
      // console.log(eval("(" + this.params + ")"));
      this.productId = this.params.productId;
      this.sku = this.params.sku;
    } else {
      this.productId = this.$route.query.productId;
      this.sku = this.$route.query.sku;
    }
    await this.getProductInfo();
    await this.get_dynamicImgSQ()
  },
  methods: {
    //获取商品信息
    getProductInfo() {
      // let that =this
      this.$get(api.productInfo, {
        productId: this.productId,
        // sku: this.sku
      }).then(res => {
        console.log(res);
        this.productInfo = res.data.data;
        this.level = res.data.data.level;
        this.contentImage = res.data.data.contentImageUrl;
        this.$nextTick(() => {
          //在这个函数里面，因为数据改变导致页面生成新的真实dom，全部渲染完成了
          new Swiper(".swiper-container", {
            loop: true
          });
        });
        this.bannerList = res.data.data.productImageUrl;
        console.log(this.bannerList);
        this.$forceUpdate();
      });
    },
    //去购物车
    toShoppingCar() {
      this.$router.push("/mall/shoppingCar");
    },
    //加入购物车
    getCartAdd() {
      let bodyInfo = JSON.parse(storage.get("babyList"))[storage.get("i")];
      console.log(this.level);
      if (this.level.length != 0) {
        this.levelId = this.level[this.currentTab].levelId;
      } else {
        this.levelId = "";
      }
      this.$post(api.cartAdd, {
        productId: this.productInfo.productId,
        num: this.num,
        levelId: this.levelId,
        shopNumber: this.babyList[this.i].schoolList[this.j].organizationId,
        useUserId:bodyInfo.babyId,
        useUserName:bodyInfo.name,
        useUserOrganizationId:this.productInfo.shopNumber,
        useUserOrganizationName:this.productInfo.organizationName,
      }).then(res => {
        if (res.data.code == 0) {
          Toast({
            message: "已加入购物车"
          });
        } else {
          Toast(res.data.msg);
        }
      });
    },
    //选择规则
    chooseType(e) {
      this.chooseNum = e;
      console.log(e);
      console.log(this.chooseNum);
      if (this.level.length == 0) {
        if (e == 1) {
          this.getCartAdd();
        } else if (e == 2) {
          window.localStorage.removeItem("Address");
          // remove('Address')
          let obj = {
            productId: this.productId,
            name: this.productInfo.productName,
            levelId: "",
            levelName: "",
            price: this.productInfo.price,
            num: this.num,
            levelCoverUrl: this.productInfo.productCoverUrl,
            shopNumber:this.productInfo.shopNumber,
            delivery:this.productInfo.delivery.split(',')[0]
          };
          console.log(obj);
          this.chooseList.push(obj);
          storage.set("chooseList", JSON.stringify(this.chooseList));
          if (this.params.length == 0) {
            this.$router.push("/orderDetails");
          } else {
            this.$router.push({
              path: "/orderDetails",
              query: {
                params: this.$route.query.params
              }
            });
          }
        }
      } else {
        this.showChoose = true;
      }
      // if (e == 1 && this.level.length == 0) {} else {
      //   this.showChoose = true;
      // }
    },
    closeChoose() {
      this.showChoose = false;
    },
    changeTo(e) {
      console.log(e);
      this.currentTab = e;
    },
    //增加
    addition(e) {
      if (this.num >= e) {
        Toast("超出当前库存");
      } else {
        this.num++;
      }
    },
    changeNum(event, num) {
      console.log(event, num);
      if (event.target.value <= num) {
        console.log(event.target.value);
        this.num = event.target.value;
        // this.cartUpdate(index);
      } else {
        Toast("当前商品最大库存为" + num);
        this.num = num;
      }
    },
    //规格选择确定
    confirm() {
      console.log(this.chooseNum);
      if (this.chooseNum == 0) {
        this.showChoose = false;
      } else if (this.chooseNum == 1) {
        this.getCartAdd();
        this.showChoose = false;
      } else if (this.chooseNum == 2) {
        window.localStorage.removeItem("Address");
        let obj = {
          productId: this.productId,
          name: this.productInfo.productName,
          levelId: this.productInfo.level[this.currentTab].levelId,
          levelName: this.productInfo.level[this.currentTab].levelName,
          price: this.productInfo.level[this.currentTab].price,
          num: this.num,
          levelCoverUrl: this.productInfo.level[this.currentTab].levelCoverUrl,
          shopNumber:this.productInfo.shopNumber,
          delivery:this.productInfo.delivery.split(',')[0]
        };
        console.log(obj);
        this.chooseList.push(obj);
        storage.set("chooseList", JSON.stringify(this.chooseList));
        console.log(this.chooseList);
        if (this.params.length == 0) {
          this.$router.push("/orderDetails");
        } else {
          this.$router.push({
            path: "/orderDetails",
            query: {
              params: this.$route.query.params
            }
          });
        }
      }
    },
    //减少数量
    decrease() {
      if (this.num == 1) {
        Toast("当前数量为1");
      } else {
        this.num--;
      }
    }
  }
};
</script>

<style scoped>
.detailsImg {
  width: 100%;
  height: 200px;
  /* min-height: 100px; */
  /* background-color: darkgray; */
}
.detailsTit {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
}
.detailsTitL {
  display: flex;
  justify-content: space-between;
}
.detailsName {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-bottom: 9px;
  width: 85%;
  word-wrap: break-word;
}
.detailsNum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.detailsNumL {
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff5001;
}
.money {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff5001;
  line-height: 42px;
}
.originalCost {
  font-size: 13px;
  font-weight: 400;
  color: #ababab;
  text-decoration: line-through;
}
.detailsType {
  display: flex;
  padding: 12px 20px;
  margin-bottom: 10px;
  font-size: 16px;
  background: #ffffff;
  justify-content: space-between;
  color: #2f2f2f;
}
.detailsTypeR {
  color: #000000;
}
.detailsTypeR img {
  width: 10px;
  height: 12px;
  margin-left: 4px;
}
.detailsShow {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 70px;
}
.detailsShowTit {
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-bottom: 12px;
}
.detailsShow img {
  width: 100%;
  margin-bottom: 10px;
}
.bottoms {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ababab;
}
.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 10px 24px;
}
.buttonL {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
  text-align: center;
}
.buttonL img {
  margin-bottom: 2px;
  width: 17px;
  height: 15px;
}
.buttonR {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 40px;
  color: #ffffff;
}
.join {
  width: 132px;
  height: 40px;
  background: #fdac3a;
  border-radius: 50px 0 0 50px;
}
.buy {
  width: 132px;
  height: 40px;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 0px 50px 50px 0px;
}
.shareBuy {
  /* margin-left: 70%; */
  color: #ffffff;
  width: 100%;
  text-align: center;
  line-height: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  height: 40px;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 50px;
}
.chooseShowbg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.59);
}
.chooseShow {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 13px 22px;
  border-radius: 10px 10px 0px 0px;
}
.chooseInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.chooseInfoL {
  display: flex;
  align-items: flex-end;
  padding-top: 7px;
}
.chooseInfoL img {
  width: 70px;
  height: 70px;
  /* background-color: darkgray; */
  margin-right: 15px;
}
.chooseMoney {
  font-size: 19px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff5001;
  letter-spacing: 1px;
  margin-bottom: 2px;
}
.chooseMoney span {
  font-size: 12px;
  font-weight: 400;
}
.originalMoney {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ababab;
  text-decoration: line-through;
}
.chooseInfoR {
  width: 20px;
  height: 20px;
}
.chooseTit {
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.chooseList {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  padding: 15px 0;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
}
.chooseItem {
  padding: 6px 12px;
  background: #fef8f9;
  border-radius: 5px;
  margin-right: 20px;
  border: 1px solid #f85511;
  color: #ff5001;
}
.nochooseItem {
  padding: 6px 12px;
  background: #fef8f9;
  border-radius: 5px;
  margin-right: 20px;
  color: #2f2f2f;
  background: #f1f1f1;
}
.chooseNum {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chooseNumR {
  display: flex;
  align-items: center;
}
.chooseNumR div {
  background-color: #f2f2f2;
  text-align: center;
  height: 22px;
  line-height: 22px;
}
.icon {
  width: 22px;
  margin: 0 2px;
  color: #d8d8d8;
  font-size: 18px;
  font-weight: 600;
}
.number {
  padding: 0 12px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.bottom {
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  text-align: center;
  color: #ffffff;
  margin-top: 120px;
}
.number {
  border: 1px solid #cdcdcd;
  text-align: center;
  padding: 0;
  width: 30px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.upTit {
  height: 38px;
  width: 100%;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 38px;
  text-align: center;
  background: #666666;
}
</style>
