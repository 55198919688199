import { Dialog } from 'vant';
import storage from "good-storage";
import { setCommont } from "@/untils/set_family_info";
export default {
    name:'headerSelect',
    props:{
        user:{
            type:Boolean,
            default:true
        }
    },
    data(){
        return {
            selectIndex:0,
            selectlist:[],
            showPopover: false,
        }
    },
    async created(){
        this.selectIndex = storage.get("i")
        await this.initData()
    },
    
    methods:{
        // 初始化数据
        initData(){
            const that = this
            let i = storage.get("i")
            let j = storage.get("j")
            setCommont().then(list=>{
                that.selectIndex = Number(i) + Number(j)
                that.selectlist = list
                if(!list.length){
                    return Dialog({message: '暂无进园的孩子！'})
                }
            })
        },
        // 选择某一项
        selectItem(event){
            console.log(this.selectlist)
            this.showPopover = false;
            storage.set("i", event.i)
            storage.set("j", event.j)
            this.selectIndex = event.index
            console.log(this.selectIndex)
            this.$emit('selectItem',event.index)
        },
        // 跳转到个人中心
        navMe(){
            let currentPath = this.$route.path.split('/').filter(n=>n).shift()
            this.$router.push({
                path: `/${currentPath}/myCenter`,
                query: {}
            })
        }
    }
}