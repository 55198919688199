export default {
    name:'notfound',
    props:{
        notfound:{
            type:String,
            default:'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/not_jurisdiction.png'
        },
        tip:{
            type:String,
            default:'当前机构暂未开通相关业务哦～'
        },
    },
    data(){
        return {}
    },
    mounted(){},
    methods:{}
}
