import Vue from 'vue'
import axios from 'axios'
import router from '@/router/index';
import storage from "good-storage";

// axios.init(router);
import {
    Toast
} from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import wx from 'weixin-js-sdk'

const vue = new Vue({
    // store,
    router
});


function refreshToken() {
    // let that = this
    axios.post(api.refreshToken, { 
        userRefreshToken: storage.get('userRefreshToken_user') 
    },
    { headers: { "Authorization": storage.get("userToken_user") } })
    .then(res => {
        console.log('123', res)
        storage.set("userToken_user", 'Bearer ' + res.data.data.userToken);
        storage.set(
            "userRefreshToken_user",
            res.data.data.userRefreshToken
        );
        location.reload();

    }).catch(err => {

        window.localStorage.removeItem('userToken_user')
        window.localStorage.removeItem('babyList')
        window.localStorage.removeItem('vuex')
        // router.push('/login');
        // 调用小程序接口，返回登录页面
        wx.miniProgram.reLaunch({
          url: '/pages/start/index'
        });


    })
}


export {
    refreshToken
}