<template>
  <div class="curriculum">
    <div class="kidList">
      <div
        v-for="(item, index) in babyList"
        :key="index"
        :class="currentTab == index ? 'isChoose' : 'noChoose'"
        @click="chooseKid(index)"
      >
        <div>{{ item.name }}</div>
        <div class="line" v-if="currentTab == index"></div>
      </div>
    </div>
    <div class="courseList" v-if="list.length != 0">
      <div
        :class="
          item.product[0].day > 0 || item.product[0].validEndTime == null
            ? 'li'
            : 'lii'
        "
        v-for="(item, index) in list"
        :key="index"
      >
        <img
          name='dynamicImg'
          class="liL"
          :src="item.product[0].productImg+imgSizeQuality"
          alt=""
          @click="goCurriculumDet(item.product[0].productId, item.shopNumber)"
        />
        <div class="liR">
          <div class="tit" @click="goCurriculumDet(item.product[0].productId, item.shopNumber)">{{ item.product[0].productName }}</div>
          <!-- <div class="plan">共学习{{ item.product[0].classNum }}个课节</div> -->
          <div class="last">
            <div
              v-if="
                item.product[0].day > 0 || item.product[0].validEndTime == null
              "
            >
              <div class="time" v-if="item.product[0].validEndTime == null">
                有效期剩余:<span>永久有效</span>
              </div>
              <div class="time" v-else>
                有效期剩余:<span>{{ item.product[0].day }}</span
                >天
              </div>
            </div>
            <div class="time" v-else>
              已过期
            </div>
          </div>
          <div
              class="button"
              @click="
                goToLog(
                  item.product[0].validEndTime,
                  item.product[0].day,
                  item.product[0].classNum,
                  item.product[0].productName,
                  item.product[0].productImg,
                  item.tag,
                  item.product[0].productId,
                  item.product[0].levelId
                )
              "
            >
              学习记录
            </div>
        </div>
      </div>
    </div>
    <div class="noclass" v-else>当前暂无课程</div>
  </div>
</template>

<script>
import {
  Toast,
  Swipe
  // SwipeItem
} from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
import timeMixin from "../Mixins/time"
import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
  name: "curriculum",
  mixins: [timeMixin,dynamicImgwh],
  data() {
    return {
      currentTab: 0,
      list: []
    };
  },
  created() {
    this.babyList = JSON.parse(storage.get("babyList"));
    this.i = storage.get("i");
    this.j = storage.get("j");
    console.log(this.babyList);
    this.getOrderList();
  },
  mounted(){
    this.get_dynamicImgSQ()
  },
  methods: {
    chooseKid(e) {
      this.currentTab = e;
      this.getOrderList();
    },
    getByOnlyBabyIds() {
      let babyId = this.babyList[this.currentTab].babyId;
      this.$post(api.getByOnlyBabyIds, {
        babyIds: [babyId]
      }).then(res => {
        this.external = null
        if (res.data.data.length == 0) 
        {
          this.getOrderList()
        } else {
          if (res.data.data[0].externalId == null) {
            this.getOrderList();
          } else {
            this.external = res.data.data[0].externalId;
            this.getOrderList();
          }
        }
        // this.getProductInfo();
      });
    },
    //获取订单数量
    getOrderList() {
      let userId = [];
      userId.push(storage.get("userUnionId"));
      this.$post(api.orderList, {
        shop: [
          {
            shopNumber: this.babyList[this.i].schoolList[this.j].organizationId,
            shopTag: "课程商铺"
          }
        ],
        useUserId: this.babyList[this.currentTab].babyId,
        userId: userId,
        status: 4
        // orderId:'234567890',
        // orderNumber:'fghjklvnjkl'
      }).then(res => {
        this.list = res.data.data.row;
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].product[0].classNum = 0;
          if(this.list[i].product[0].validEndTime){
            this.list[i].product[0].day = this.twoTimeInterval(this.getNowDate(),this.list[i].product[0].validEndTime);
          }
        }
        this.studyCountDay();
        console.log(this.list);
      });
    },
    studyCountDay() {
      let arr = "";
      for (let i = 0; i < this.list.length; i++) {
        if (i == 0) {
          arr = this.list[i].product[0].levelId;
        } else {
          arr = arr + "," + this.list[i].product[0].levelId;
        }
        console.log(arr);
      }
      this.$get(api.studyCount, {
        userId: this.babyList[this.currentTab].babyId,
        courseId: arr
      }).then(res => {
        console.log("拼接", res);

        for (let i = 0; i < this.list.length; i++) {
          for (let j = 0; j < res.data.data.length; j++) {
            if (this.list[i].product[0].levelId == res.data.data[j].courseId) {
              this.list[i].product[0].classNum = res.data.data[j].completeNum;
            }
          }
        }
        this.$forceUpdate();
        // this.list = res.data.data.row;
        // this.studyCountDay();
        // console.log(this.list);
      });
    },
    goToLog(
      validEndTime,
      day,
      classNum,
      productName,
      productImg,
      tag,
      productId,
      levelId
    ) {
      if (!day && validEndTime == null) {
        day = "永久有效";
      }
      if (day > 0 || validEndTime == null) {
        this.$router.push({
          path: "/progressLog",
          query: {
            day,
            classNum,
            productId,
            levelId,
            productName,
            productImg,
            tag,
            userId: this.babyList[this.currentTab].babyId
          }
        });
      }
    },
    // 跳转详情
    goCurriculumDet(courseOnlineId, shopNumber) {
      this.$router.push({
        path: "/curriculumDetails",
        query: { courseOnlineId, queryShop: shopNumber }
      });
    }
  }
};
</script>

<style scoped>
.curriculum{
  min-height: 100vh;
  background: #FBFBFB;
}
.kidList {
  display: flex;
  align-items: center;
  margin: 20px;
  overflow: auto;
}
.kidList > div{
  white-space: nowrap;
}
.isChoose {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-right: 20px;
}
.noChoose {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #959595;
  margin-right: 20px;
}
.line {
  width: 100%;
  margin-top: -8px;
  height: 11px;
  background: linear-gradient(360deg, #2ca0fb 0%, rgba(67, 139, 249, 0) 100%);
  border-radius: 3px;
}
.courseList {
  margin: 0 20px;
}
.li {
  display: flex;
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 4px;
  margin: 10px 0;
}
.lii {
  display: flex;
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 4px;
  opacity: 0.6;
}
.liL {
  width: 119px;
  height: 88px;
  margin-right: 13px;

  border-radius: 7px;
}

.liR {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tit {
  font-size: 15px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.plan {
  font-size: 13px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #2ca0fb;
}
.last {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.time {
  font-size: 11px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all
}
.time span {
  color: rgba(204, 61, 61, 1);
}
.button {
  width: 61px;
  height: 23px;
  line-height: 23px;
  text-align: center;
  background: #2ca0fb;
  border-radius: 11px;
  font-size: 11px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
}
.noclass {
  text-align: center;
  margin-top: 200px;
}
</style>
