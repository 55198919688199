import Vue from 'vue'
import Router from 'vue-router'
import login from '@/view/login'
import binding from '@/view/binding'

import homepage from '@/view/homepage'
import index from '@/view/index'

import myCenter from '@/view/myCenter'
import userInfo from '@/view/userInfo'
import goodsDetails from '@/view/goodsDetails'
import shoppingCar from '@/view/shoppingCar'
import orderDetails from '@/view/orderDetails'
import OMS from '@/view/OMS'
import newAddress from '@/view/newAddress'
import addressList from '@/view/addressList'
import setting from '@/view/setting'
import changePhone from '@/view/changePhone'
import changeName from '@/view/changeName'
import choosePart from '@/view/choosePart'
import say from '@/view/say'
import chooseClass from '@/view/chooseClass'
import voiceBank from '@/view/voiceBank'
import videoBank from '@/view/videoBank'
import orderList from '@/view/orderList'
import game from '@/view/game'
import showTalk from '@/view/showTalk'
import appraisalList from '@/view/appraisalList'
import appraisalInfo from '@/view/appraisalInfo'
import babyInfo from '@/view/invite/babyInfo'
import success from '@/view/invite/success'
import register from '@/view/signIn/register'
import authCode from '@/view/signIn/authCode'
import paySuccess from '@/view/signIn/paySuccess'
import curriculum from '@/view/curriculum'
import school from '@/view/school'
import search from '@/view/search/search'
import curriculumDetails from '@/view/details/curriculumDetails'
import subCourseOrderDetails from '@/view/details/subCourseOrderDetails'
import courseOrderDetails from '@/view/details/courseOrderDetails'
import progressLog from '@/view/progressLog'
import wxaqrcode from '@/view/wxaqrcode'
import protocol from '@/view/protocol'



// version 3.2 底部菜单/模块拆分
import tabbarRouter from './tabbar/index'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/register',
            name: 'register',
            component: register,
            meta: {
                title: '启蒙未来学院'
            }
        },
        {
            path: '/authCode',
            name: 'authCode',
            component: authCode,
            meta: {
                title: '启蒙未来学院'
            }
        },
        {
            path: '/paySuccess',
            name: 'paySuccess',
            component: paySuccess,
            meta: {
                title: '启蒙未来学院'
            }
        },
        {
            path: '/',
            name: 'index',
            component: binding,
            meta: {
                title: '启蒙未来'
            }

        },
        {
            path: '/login',
            name: 'login',
            component: login,
            meta: {
                title: '登录'
            }
        },
        {
            path: '/homepage',
            name: 'homepage',
            component: homepage,
            meta: {
                title: '在线商城'
            }
        },
        {
            path: '/myCenter',
            name: 'myCenter',
            component: myCenter,
            meta: {
                title: '个人中心'
            }
        },
        {
            path: '/userInfo',
            name: 'userInfo',
            component: userInfo,
            meta: {
                title: '个人信息'
            }
        },
        {
            path: '/goodsDetails',
            name: 'goodsDetails',
            component: goodsDetails,
            meta: {
                title: '商品详情'
            }
        },
        {
            path: '/shoppingCar',
            name: 'shoppingCar',
            component: shoppingCar,
            meta: {
                title: '购物车'
            }
        },
        {
            path: '/orderDetails',
            name: 'orderDetails',
            component: orderDetails,
            meta: {
                title: '订单确认'
            }
        },
        {
            path: '/OMS',
            name: 'OMS',
            component: OMS,
            meta: {
                title: '订单详情'
            }
        },
        {
            path: '/newAddress',
            name: 'newAddress',
            component: newAddress,
            // meta: {
            //     title: '新增地址'
            // }
        },
        {
            path: '/addressList',
            name: 'addressList',
            component: addressList,
            meta: {
                title: '收货地址'
            }
        },
        {
            path: '/setting',
            name: 'setting',
            component: setting,
            meta: {
                title: '设置'
            }
        },
        {
            path: '/changePhone',
            name: 'changePhone',
            component: changePhone,
            meta: {
                title: '修改手机号'
            }
        },
        {
            path: '/changeName',
            name: 'changeName',
            component: changeName,
            meta: {
                title: '修改用户名'
            }
        },
        {
            path: '/chooseClass',
            name: 'chooseClass',
            component: chooseClass,
            meta: {
                title: '课后练习'
            }
        },
        {
            path: '/choosePart',
            name: 'choosePart',
            component: choosePart,
            meta: {
                title: '选择环节'
            },
        },
        {
            path: '/voiceBank',
            name: 'voiceBank',
            component: voiceBank,
            meta: {
                title: '声音库'
            },
        },
        {

            path: '/videoBank',
            name: ' videoBank',
            component: videoBank,
            meta: {
                title: '小课堂'
            },
        },
        {
            path: '/say',
            name: 'say',
            component: say,
            meta: {
                title: '字正腔圆'
            },
        },
        {
            path: '/orderList',
            name: 'orderList',
            component: orderList,
            meta: {
                title: '订单列表'
            }
        },
        {
            path: '/game',
            name: 'game',
            component: game,
            meta: {
                title: '游戏闯关'
            }
        },
        {
            path: '/showTalk',
            name: 'showTalk',
            component: showTalk,
            meta: {
                title: '演说秀场'
            }
        },
        {
            path: '/appraisalList',
            name: 'appraisalList',
            component: appraisalList,
            meta: {
                title: '测评报告'
            }
        },
        {
            path: '/appraisalInfo',
            name: 'appraisalInfo',
            component: appraisalInfo,
            meta: {
                title: '测评报告'
            }
        },
        {
            path: '/babyInfo',
            name: 'babyInfo',
            component: babyInfo,
            meta: {
                title: '孩子信息录入'
            }
        },
        {
            path: '/success',
            name: 'success',
            component: success,
            meta: {
                title: '入园档案'
            }
        },
        {
            path: '/curriculum',
            name: 'curriculum',
            component: curriculum,
            meta: {
                title: '乐园课程'
            }
        },
        {
            path: '/school',
            name: 'school',
            component: school,
            meta: {
                title: '启蒙乐园'
            }
        },
        {
            path: '/search',
            name: 'search',
            component: search,
            meta: {
                title: '启蒙乐园'
            }
        },
        {
            path: '/curriculumDetails',
            name: 'curriculumDetails',
            component: curriculumDetails,
            meta: {
                title: '启蒙乐园'
            }
        },
        {
            path: '/subCourseOrderDetails',
            name: 'subCourseOrderDetails',
            component: subCourseOrderDetails,
            meta: {
                title: '提交订单'
            }
        },
        {
            path: '/courseOrderDetails',
            name: 'courseOrderDetails',
            component: courseOrderDetails,
            meta: {
                title: '订单详情'
            }
        },
        {
            path: '/progressLog',
            name: 'progressLog',
            component: progressLog,
            meta: {
                title: '启蒙乐园'
            }
        },
        {
            path:'/wxaqrcode',
            name:'wxaqrcode',
            component:wxaqrcode,
            meta: {
                title:'支付成功'
            }
        },
        {
            path: '/binding',
            name: 'binding',
            component: binding,
            meta: {
                title: '绑定'
            }
        },
        {
            path: '/protocol',
            name: 'protocol',
            component: protocol,
            meta: {
                title: '协议'
            }
        },
        ...tabbarRouter
    ]

})
export default router