<template>
    <div class="log-inner">
        <ul class="log-list">
            <li class="log-item" v-for="(item,indexf) in logs" :key="indexf">
                <div class="log-item_tail"></div>
                <div class="log-item_node">
                    <div class="week">
                        <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/curriculum/time.png" alt="">
                        <div class="">{{item.week}}</div>
                    </div>
                    <div class="date">{{item.date}}</div>
                </div>
                <div class="log-item_wrapper">
                    <div class="log-item_content">
                        <div class="log-item_timestamp">学习了:</div>
                        <div class="tbox" v-for="(itemChild,index) in item.chapters" :key="index">
                            <div class="tbox-title">
                                <span class="index">{{index + 1}}、</span>
                                <span class="text">{{'"' + itemChild.name + '"'}} {{itemChild.count}}次</span>
                            </div>
                        </div>
                    </div>

                </div>
            </li>
        </ul>
    </div>
</template>
<script>
    import log from "./log";
    export default log;
</script>
<style scoped>
    @import './log.css';
</style>
