<template>
  <div class="showTalk" v-cloak>
    <div class="score" v-if='scoreNum'>
      <div class="apples">
        <img v-for='(item,index) in apples' :key="index" :src="item.isshow?'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/apple@2x.png': 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/aapple@2x.png'" alt="">
        <!-- <img class="one" :src="[one?'../image/showTalk/apple@2x.png': '../image/showTalk/aapple@2x.png']" alt=""> -->
        <!-- <img class="two" :src="[two?'../image/showTalk/apple@2x.png': '../image/showTalk/aapple@2x.png']" alt=""> -->
        <!-- <img class="there" :src="[there?'../image/showTalk/apple@2x.png': '../image/showTalk/aapple@2x.png']" alt=""> -->
        <!-- <img class="two" :src="[four?'../image/showTalk/apple@2x.png': '../image/showTalk/aapple@2x.png']" alt=""> -->
        <!-- <img class="one" :src="[five?'../image/showTalk/apple@2x.png': '../image/showTalk/aapple@2x.png']" alt=""> -->
      </div>
      <div class="scoreDetails">
        <img class="scoreDetailImg" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/guang.png" alt="">
        <div class="scoreDetailsbg">
          <div class="scoreDetailsTit">恭喜你</div>
          <div class="scoreDetailsTxt">成功获得<span>{{scoreNum}}</span>颗苹果</div>
          <div class="scoreDetailsCon">
            {{contentTxt}}
          </div>
          <div class="scoreDetailsBut" @click='playAudio(1)'>

            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/play@2x.png" alt="">
            <div>回放</div>
            </div>
            <div class="goBACK">
              <a id="back" href="javascript:history.back();" rel="external nofollow" style="text-decoration:none;">完成</a>
            </div>

        </div>
      </div>
    </div>
    <div v-else>
      <img class="showTalkBg" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/bg@2x.png" alt="">
      <div class="showTalk" v-if='submit'>
        <!-- <div class='back'>
                                                    <a id="back" href="javascript:history.back();" rel="external nofollow"> <img src="../image/login/back.png" alt=""></a>
                                                    <div style='margin-left:-18px'>语说秀场</div>
                                                    <div style="width: 5px;height: 5px;"></div>
                                                </div> -->
        <div class="top">
          题目
        </div>
        <div class="tit">
          <div>{{list.title}}</div>
          <div @click='playAudio(0)'>
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/voice@2x.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="contentBut" v-if="audioUrl">
            <div class='contentGrade'>
              <div class='contentGradeL'>
                <div @click='playAgain'>
                  <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/play@2x.png" alt="">
                </div>
                <div>回放</div>
              </div>
              <div class='contentGradeL'>
                <div @click='delectAudio'>
                  <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/again@2x.png" alt="">
                </div>
                <div>重录</div>
              </div>
            </div>
            <div class="contentBitton" @click.stop='calculate'>提交</div>
          </div>
          <div class="contentBut" v-else>
            <div class="gif">
              <div v-show='showImg'>
                <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/gif.gif" alt="">
                <div>正在聆听...</div>
              </div>
            </div>
            <div class="contentAudio">
              <div @touchstart.stop.prevent="gtouchstart()" @touchend.stop.prevent="gtouchend()">
                <img v-show="!showImg" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/talk.png" alt="">
                <img v-show="showImg" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/talk_active.png" alt="">
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="submit" v-else>
        <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/show@2x.png" alt="">
        <div class="submitTxt">你已成功上传音频， <br> 请耐心等待老师评价哦 </div>
        <div class="submitBut" @click='toChoosePart'>确定</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import wx from 'weixin-js-sdk'
  import http from "@/untils/http";
  var triggerTime = 0
  var recordTimer = null
  export default {
    name: 'showTalk',
    data() {
      return {
        apples: [{
            isshow: false
          },
          {
            isshow: false
          },
          {
            isshow: false
          },
          {
            isshow: false
          },
          {
            isshow: false
          },
        ],
        showImg: false,
        submit: true,
        score: false,
        choose: 0,
        scoreNum: '',
        end: false,
        // one: true,
        // two: true,
        // there: true,
        // four: false,
        // five: false,
        list: [],
        audioUrl: '',
        subjects: [],
        contentTxt: ''
      }
    },
    created() {
      var isPageHide = false;
      window.addEventListener('pageshow', function() {
        if (isPageHide) {
          window.location.reload();
        }
      });
      window.addEventListener('pagehide', function() {
        isPageHide = true;
      });
      document.oncontextmenu = function(e) {
        e.preventDefault();
      };
      this.audio = new Audio();
      this.sectionId = this.$route.query.sectionId;
      this.babyId = this.$route.query.babyId;
      this.classId = this.$route.query.classId;
      this.getAudioList();
    },
    methods: {
      getwxabout() {
        this.$get(api.getAccessToken, {
          wechatAppId: 'wxae80bb1fd4a0d45b'
        }).then((res) => {
          this.token = res.data.data
          var u = navigator.userAgent;
          var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
          var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          let url = ''
          if (isAndroid) {
            url = window.location.href
          }
          if (isIOS) {
            url = window.location.href.split('#')[0] //hash后面的部分如果带上ios中config会不对
          }
          if (res.data.code == 0) {
            this.$post(api.getJsapiTicket, {
              wechatAppId: 'wxae80bb1fd4a0d45b',
              token: res.data.data,
              url: url
            }).then((res) => {
              if (res.data.code == 0) {
                wx.config({
                  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                  appId: 'wxae80bb1fd4a0d45b', // 必填，公众号的唯一标识
                  timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                  nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
                  signature: res.data.data.signature, // 必填，签名，见附录1
                  jsApiList: ["startRecord", "stopRecord", 'onVoiceRecordEnd', 'uploadVoice', 'downloadVoice', 'playVoice', 'stopVoice'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(() => {
                  wx.onVoiceRecordEnd({
                    // 录音时间超过一分钟没有停止的时候会执行 complete 回调
                    complete: function(res) {
                      console.log('录音时间超过一分钟没有停止的时候会执行 complete 回调',res)
                    }
                  })
                })
              }
            })
          }
        });
      },
      // 长按开始
      gtouchstart() {
        let that = this
        that.showImg = true
        triggerTime = Date.parse(new Date())
        // alert('1111')
        // this.$toast('开始录音');
        wx.ready(() => {
          recordTimer = setTimeout(function() {
              wx.startRecord({
              success: function(e) {
                console.log(e,'/开始录音成功')
                console.log(triggerTime,'/开始录音成功时间戳')
                console.log(that.showImg,'/是否显示正在倾听标识')
              },
              cancel: function(err) {
                console.log(err,'/开始录音失败')
              }
            })
          }, 0);
        })
      },
      // 长按结束
      gtouchend() {
        // alert('678')
        // this.$toast('结束录音');
        let that = this
        console.log(triggerTime,'/录音开始时间')
        let interval = Date.parse(new Date()) - triggerTime
        console.log(interval,'/录音时间间隔')
        if( (interval < 2000) || (triggerTime < 10000)){
            that.showImg = false
            that.audioUrl = ''
            that.aaa = ''
            triggerTime = 0
            try {
              clearTimeout(recordTimer)
            } catch (error) {
              recordTimer = null
            }
            return Toast({
              message: "录音时间太短", //弹窗内容
              position: "middle", //弹窗位置
              duration: 2000, //弹窗时间毫秒,如果值为-1，则不会消失
              iconClass: "iconfont icon-gantanhao", //设置 图标类
              className: "toastInfo" //自定义Toast 样式，需要自己提供一个类名
            });
        }else{
          wx.ready(() => {
            // 获取录音间隔时间
            wx.stopRecord({
              success: function(res) {
                that.showImg = false
                triggerTime = 0
                that.audioUrl = res.localId
                console.log(res, res.localId, that.audioUrl,'/结束录音成功信息')
                wx.uploadVoice({
                  localId: res.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
                  success: function(res) {
                    console.log(res,'/上传的音频成功信息')
                    that.aaa = res.serverId
                    var serverId = res.serverId; // 返回音频的服务器端ID
                  }
                });
              },
              fail: function(res) {
                that.showImg = false
                that.audioUrl = ''
                that.aaa = ''
                triggerTime = 0
                try {
                  clearTimeout(recordTimer)
                } catch (error) {
                  recordTimer = null
                }
                console.log(JSON.stringify(res),'/结束录音失败信息')
              }
            })
          })
          console.log(that.audioUrl,'/拿到结束录音成功信息中的localId')
        }
        // that.$toast('结束录音');
      },
      //重播
      playAgain() {
        let that = this
        console.log(that.audioUrl,'/重播时候获取结束录音成功信息中的localId')
        wx.playVoice({
          localId: that.audioUrl // 需要播放的音频的本地ID，由stopRecord接口获得
        });
      },
      delectAudio() {
        this.audioUrl = ''
      },
      //播放示例语音
      playAudio(e) {
        console.log(e, '/播放示例语音')
        if (e != '1') {
          this.audio.src = this.list.answers[0].content
        }
        this.audio.play()
      },
      //获取reportId
      getAudioList() {
        this.$get(api.sectionInfo, {
          sectionId: this.sectionId,
          babyId: this.babyId,
        }).then((res) => {
          if (res.data.code == 0) {
            console.log(res, res.data.data.lessonId)
            this.lessonId = res.data.data.lessonId
            this.sectionId = res.data.data.objectList[0].sectionId
            this.paperId = res.data.data.objectList[0].paperId
            this.reportId = res.data.data.reportId
            // that.getPaperInfo()
            // that.getwxabout()
            if (this.reportId.length != 0 && this.reportId != 0) {
              this.reportInfo()
              this.submit = false
              console.log('111', this.submit)
            } else {
              console.log('222')
              this.getPaperInfo()
            }
          } else {
            Toast(res.data.msg)
          }
        })
      },
      //获取音频
      reportGetList() {
        let that = this
        that.$indicator.open('音频加载中...');
        that.$get(api.reportGetList, {
          reportId: that.reportId
        }).then((res) => {
          console.log(res)
          that.audio.src = res.data.data[0].content
          that.$indicator.close();
        })
      },
      //完成返回环节
      toChoosePart() {
        // window.location.href = '../pages/choosePart.html?lessonId=' + this.lessonId
        this.$router.go(-1);
      },
      //获取分数
      reportInfo() {
        let that = this
        that.$get(api.reportInfo, {
          id: that.reportId,
        }).then((res) => {
          console.log('获取成功分数', res)
          this.reportGetList()
          if (res.data.data.content != null) {
            that.submit = true
            that.scoreNum = res.data.data.score
            that.contentTxt = res.data.data.content
            console.log(that.scoreNum)
            for (let i = 0; i < that.scoreNum; i++) {
              that.apples[i].isshow = true
            }
            console.log(that)
          } else {
            that.submit = false
          }
        })
      },
      //获取试卷
      getPaperInfo() {
        let that = this
        that.$get(api.paperInfo, {
          paperId: that.paperId,
        }).then((res) => {
          that.getwxabout()
          console.log('111', res)
          if (res.data.code == 0) {
            that.subjects = res.data.data.subjects
            that.list = res.data.data.subjects[0].questions[0]
          } else {
            Toast(res.data.msg);
          }
        })
      },
      //提交录音
      calculate() {
        let that = this
        that.$indicator.open('上传中...');
        that.$get(api.getAccessToken, {
          wechatAppId: 'wxae80bb1fd4a0d45b'
        }).then((rs) => {
          that.token = rs.data.data
          that.$post(api.transCoding, {
            filePath: 'https://file.api.weixin.qq.com/cgi-bin/media/get?access_token=' + that.token + '&media_id=' + that.aaa,
            outFileType: 'mp3',
          }).then((res) => {
            console.log('获取成功', res)
            console.log('音频1', that.subjects[0].questions[0].answers[0].content)
            console.log('音频2', res.data.data.outFilePath)
            that.subjects[0].questions[0].answers[0].content = res.data.data.outFilePath
            console.log('音频合计', that.subjects)
            that.$post(api.saveForUser, {
              paperId: that.paperId,
              subjects: that.subjects,
              // classId:
              // score: that.score,
              userId: that.babyId
            }).then((res) => {
              console.log('获取成功', res)
              console.log(res.data.data.reportId)
              that.$post(api.evaluate, {
                reportId: res.data.data.reportId,
                classId: that.classId,
                // score: that.score,
                babyId: that.babyId,
                sectionId: that.sectionId,
              }).then((res) => {
                console.log('保存reportId成功', res)
                that.submit = false
                that.$indicator.close();
              })
            })
          })
        });
      },
    }
  }
</script>
<style scoped>
  body {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  img {
    pointer-events: none;
    /* 禁止长按图片保存 */
  }
  .showTalkBg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/showTalk/bg@2x.png) no-repeat;
    background-size: 100% 100%;
    z-index: 99;
  }
  .showTalk {
    position: relative;
    z-index: 999;
  }
  .score {
    /* position: fixed;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0; */
    background-color: #292C3F;
  }
  .apples {
    display: flex;
    justify-content: space-around;
    padding: 30px 50px 0 50px;
  }
  .apples img:nth-child(1) {
    margin-top: 40px;
    width: 40px;
    height: 40px;
  }
  .apples img:nth-child(5) {
    margin-top: 40px;
    width: 40px;
    height: 40px;
  }
  .apples img:nth-child(2n+2) {
    margin-top: 20px;
    width: 50px;
    height: 50px;
  }
  .apples img:nth-child(3n+3) {
    width: 60px;
    height: 60px;
  }
  .top {
    margin: 20px 0;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #22CC65;
    padding: 0 20px;
  }
  .tit {
    margin: 0 20px;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(46, 47, 50, 1);
    padding: 25px 20px;
    justify-content: space-between;
    word-wrap: break-word;
    white-space: norma;
    display: flex;
    align-items: center;
  }
  .tit img {
    margin-left: 14px;
    width: 21px;
    height: 21px;
  }
  .content {
    /* background-color: aquamarine; */
    margin-top: 15%;
  }
  .gif {
    width: 100%;
    text-align: center;
    margin-bottom: 10%;
    height: 100px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #22CC65;
  }
  .gif img {
    width: 100px;
    height: 30px;
  }
  .contentAudio {
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    width: 168px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #22CC65;
  }
  .contentAudio img {
    width: 168px;
    height: 68px;
    /* background: url(../image/say/aduio@2x.png) no-repeat;
                                background-size: 100% 100%; */
    margin-bottom: 13px;
  }
  .contentGrade {
    justify-content: space-around;
    align-items: center;
    text-align: center;
    display: flex;
    margin: 20% 15%;
  }
  .contentGrade img {
    width: 56px;
    height: 56px;
    margin-bottom: 18px;
  }
  .contentGradeL {
    font-size: 12px;

    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(34, 204, 101, 1);
  }
  .contentBitton {
    cursor: pointer;
    width: 85%;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #22CC65;
    border-radius: 20px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(255, 255, 255, 1);
  }
  .scoreDetails {
    margin: 0px 40px;
    padding-bottom: 40px;
  }
  .scoreDetailImg {
    width: 100%;
  }
  .scoreDetailsbg {
    /* background: url(../image/showTalk/back.png) no-repeat;
                                    background-size: 100% 100%; */
    background-color: #fff;
    width: 100%;
    position: relative;
    text-align: center;
    border-radius: 20px;
    margin-top: -10px;
    padding-bottom: 15px;
  }
  .scoreDetailsTit {
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(46, 47, 50, 1);
    padding: 25px;
  }
  .scoreDetailsTxt {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(46, 47, 50, 1);
    margin-bottom: 25px;
  }
  .scoreDetailsTxt span {
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #FFA013;
    margin: 0 5px;
  }
  .scoreDetailsCon {
    text-align: left;
    padding: 15px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(134, 134, 134, 1);
    background: rgba(246, 247, 249, 1);
    margin-left: 12%;
    margin-right: 12%;
    border-radius: 7px;
    word-wrap: break-word;
    word-break: normal
  }
  .scoreDetailsBut {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(34, 204, 101, 1);
       margin-bottom: 10px;
  }
  .scoreDetailsBut img {
    width: 39px;
    height: 39px;
    margin-right: 14px;

  }
  a {
    color: #fff;
  }
  .goBACK {
    width: 30%;
    margin: 20px auto;
    padding: 5px 5px;
    border-radius: 15px;
    font-size: 14px;
    background-color: rgba(34, 204, 101, 1);
    color: #fff;
    /* padding-bottom: 15px; */
  }
  .submit {
    padding-top: 60px;
    text-align: center;
    position: relative;
    z-index: 999;
  }
  .submit img {
    width: 244px;
    height: 192px;
    margin-bottom: 50px;
  }
  .submitTxt {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(34, 204, 101, 1);
    margin-bottom: 100px;
  }
  .submitBut {
    margin: 0 auto;
    width: 253px;
    height: 40px;
    background: rgba(34, 204, 101, 1);
    border-radius: 20px;
    font-size: 18 px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(255, 255, 255, 1);
    line-height: 40px;
  }
  /*.top {
                                    margin: 20px 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 0 20px;
                                }
                                .topL {
                                    display: flex;
                                    align-items: center;
                                    font-size: 18px;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    color: #22CC65;
                                }
                                .topL div:last-child {
                                    margin-left: 10px;
                                    width: 30px;
                                    height: 24px;
                                    background: rgba(255, 255, 255, 1);
                                    border-radius: 10px;
                                }
                                .topL img {
                                    line-height: 24px;
                                    margin: 4px 8px;
                                    width: 16px;
                                    height: 16px;
                                }
                                .topR {
                                    font-size: 14px;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    color: rgba(134, 134, 134, 1);
                                }
                                .topR span {
                                    font-size: 18px;
                                    color: #22CC65;
                                }
                                .content {
                                    background: rgba(255, 255, 255, 1);
                                    border-radius: 10px;
                                    margin: 0 20px;
                                    height: 80%;
                                    position: relative;
                                }
                                .contentTit {
                                    font-size: 18px;
                                    word-wrap: break-word;
                                    white-space: norma;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    color: rgba(46, 47, 50, 1);
                                    padding: 25px 20px;
                                }
                                .contentAudio {
                                    margin: 0 auto;
                                    width: 65px;
                                    text-align: center;
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    color: #22CC65;
                                }
                                .contentAudioImg {
                                    width: 65px;
                                    height: 65px;
                                    background: url(../image/showTalk/audio@2x.png) no-repeat;
                                    background-size: 100% 100%;
                                    margin-bottom: 13px;
                                }
                                .contentA {
                                    position: absolute;
                                    bottom: 80px;
                                    left: 0;
                                    right: 0;
                                }
                                .contentGrade {
                                    justify-content: space-around;
                                    align-items: center;
                                    text-align: center;
                                    display: flex;
                                    margin-bottom: 40px;
                                }
                                .contentGrade img {
                                    width: 56px;
                                    height: 56px;
                                    margin-bottom: 18px;
                                }
                                .contentGradeL {
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    color: rgba(34, 204, 101, 1);
                                }
                                .contentBitton {
                                    width: 85%;
                                    margin: 0 auto;
                                    height: 40px;
                                    line-height: 40px;
                                    text-align: center;
                                    background: #22CC65;
                                    border-radius: 20px;
                                    font-size: 18px;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    color: rgba(255, 255, 255, 1);
                                }
                                .gif {
                                    width: 100%;
                                    text-align: center;
                                    margin-bottom: 100px;
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    color: #22CC65;
                                }
                                .gif img {
                                    width: 50%;
                                    height: 40px;
                                }
                                .popup {
                                    position: fixed;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    background: rgba(0, 0, 0, 0.59);
                                    padding: 50px;
                                }
                                .show {}
                                .show img {
                                    height: 155px;
                                    width: 100%;
                                }
                                .submit {
                                    padding-top: 60px;
                                    text-align: center;
                                }
                                .submit img {
                                    width: 244px;
                                    height: 192px;
                                    margin-bottom: 50px;
                                }
                                .submitTxt {
                                    font-size: 18px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    color: rgba(34, 204, 101, 1);
                                    margin-bottom: 100px;
                                }
                                .submitBut {
                                    margin: 0 auto;
                                    width: 253px;
                                    height: 40px;
                                    background: rgba(34, 204, 101, 1);
                                    border-radius: 20px;
                                    font-size: 18 px;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    color: rgba(255, 255, 255, 1);
                                    line-height: 40px;
                                } */
</style>
<style>
.toastInfo i{
    font-size: 50px !important;
  }
</style>
