<template>
  <div class="OMS">
    <div style="margin-bottom:60px;">
      <div class="list">
        <div class="itemState">
          <div class="itemStateL">订单编号：{{ info.orderId }}</div>
        </div>
        <div class="item" v-for="(item, index) in info.product" :key="index">
          <div class="itemL">
            <img @click="goCurriculumDet(item.productId, info.shopNumber)" name='dynamicImg' :src="item.productImg+imgSizeQuality" alt=""/>
            <div class="itemLR">
              <div class="itemTit">{{ item.productName }}</div>
              <div class="itemType">
                <span v-if="item.validEndTime == null">永久有效</span>
                <span v-else>{{ item.day }}天有效期</span>
              </div>
              <div class="itemMoney">
                    <span class="moneyNum">¥{{ info.price }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="payLiR">{{ info.createTime }}</div>
      </div>
      <div class="payInfo">
        <div class="title">下单信息</div>
        <div class="payLi">
          <div class="payLiL">手机号：</div>
          <div class="payLiR">{{ info.userTag }}</div>
        </div>
        <div class="payLi">
          <div class="payLiL">宝宝信息：</div>
          <div class="payLiR">{{ info.product[0].useUserName }}</div>
        </div>
        <div class="payLi" v-if="info.note">
          <div class="payLiL">备注信息：</div>
          <div class="payLiR">{{ info.note }}</div>
        </div>
        <div class="payLi">
          <div class="payLiL">下单时间：</div>
          <div class="payLiR">{{ info.createTime }}</div>
        </div>
      </div>
      <div class="address">
        <div class="title">支付信息</div>
        <div class="payLi">
          <div class="payLiL">支付方式：</div>
          <div class="payLiR">
            <span>{{ info.payType | orderPayType}}</span>
          </div>
        </div>
        <div class="payLi">
          <div class="payLiL">支付时间：</div>
          <div class="payLiR">
            {{ info.payTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
import timeMixin from "../../Mixins/time"
import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
  name: "courseOrderDetails",
  mixins: [timeMixin,dynamicImgwh],
  data() {
    return {
      info: [],
      timeDay: "",
      time: "",
      timeOut: "",
      isLogistics: false,
      refund: false,
      num: 0,
      productQuantity: "",
      createTime: "",
      refundTime: "",
      price: ""
    };
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.shopNumber = this.$route.query.shopNumber;
    console.log(this.shopNumber);
    this.babyList = JSON.parse(storage.get("babyList"));
    this.i = storage.get("i");
    this.getOrderInfo();
    // console.log(new Date().getTime(), new Date().getTime() + 30 * 60 * 1000)
    // this.setTime();
  },
  methods: {
    getOrderInfo() {
      this.$get(api.orderInfo, {
        orderId: this.orderId,
        shopNumber: this.shopNumber
      }).then(res => {
        this.info = res.data.data;
        console.log(res);
        this.info.product[0].day = this.twoTimeInterval(this.getNowDate(),this.info.product[0].validEndTime);
        console.log(this.info.product[0])
        this.get_dynamicImgSQ()
      });
    },
    showLogistics() {
      this.isLogistics = true;
    },
    closeLogistics() {
      this.isLogistics = false;
    },
    //退款详情
    refundInfo(e, price, productQuantity, createTime, refundTime) {
      this.num = e;
      this.price = price;
      this.productQuantity = productQuantity;
      this.createTime = createTime;
      this.refundTime = refundTime;
      this.refund = true;
    },
    //确定
    closeRefund() {
      this.refund = false;
    },
    // 跳转详情
    goCurriculumDet(courseOnlineId, shopNumber) {
      this.$router.push({
        path: "/curriculumDetails",
        query: { courseOnlineId, queryShop: shopNumber }
      });
    }
  }
};
</script>

<style scoped>
.OMS {
  margin-bottom: 80px;
}
.wait {
  width: 100%;
  background: url('https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/omsbg@2x.png') no-repeat;
  background-size: 100% 100%;
  text-align: center;
  margin: 0 auto;
  color: #ffffff;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  padding: 24px 0;
  text-align: center;
}
.wait img {
  width: 22px;
  height: 22px;
  margin-right: 9px;
}
.waitTop {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 11px;
}
.waitTxt {
  font-size: 13px;
}
.list {
  background-color: #ffffff;
  margin-top: 10px;
  padding: 12px 19px;
  margin-bottom: 10px;
}
.itemState {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  padding-bottom: 13px;
}
.itemStateL {
  color: #2f2f2f;
}
.itemStateR {
  color: #f85511;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.itemL {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.itemL img {
  width: 118px;
height: 88px;
border-radius: 8px;
  margin-right: 15px;
}
.itemLR {
  flex: 1;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.itemMoney {
  font-size: 17px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #000000;
}
.yuan {
  font-size: 11px;
}
.moneyNum {
  font-size: 18px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #f85511;
}
.itemTit {
    overflow: hidden;
text-overflow: ellipsis;
display:-webkit-box;
-webkit-box-orient:vertical;
-webkit-line-clamp:2;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
}
.itemType {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin: 6px 0;
  color: #8d8d8d;
}
.itemRefund {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3388ff;
  line-height: 19px;
}
.payInfo {
  background-color: #ffffff;
  padding: 15px 20px;
  margin-bottom: 10px;
}
.title {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #151515;
  margin-bottom: 12px;
}
.payLi {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 1px;
}
.payLiL {
  width: 30%;
  color: #676767;
}
.payLiR {
  color: #151515;
}
.address {
  padding: 15px 20px;
  /* margin-bottom: 80px; */
  background-color: #ffffff;
}
.addressInfo {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 21px;
  margin-bottom: 3px;
}
.addressLi {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  margin-top: 3px;
  /* align-items: flex-start; */
}
.button {
  background-color: #ffffff;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
}
.but {
  margin: 8px 20px;
  padding: 12px 0;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
  text-align: center;
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.logistics {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
}
.logistics div {
  text-align: center;
  color: #f85511;
  border-radius: 20px;
  border: 1px solid #f85511;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  width: 80%;
  margin: 5px auto;
  height: 40px;
  line-height: 40px;
}
.isLogisticsBg {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.59);
  z-index: 120;
}
.isLogisticsInfo {
  position: fixed;
  top: 74px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px 20px 0px 0px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  /* white-space: nowrap;
                                                        overflow-x: hidden;
                                                        overflow-y: auto; */
}
.isLogisticsInfoL {
  width: 100%;
  /* height: 80%;  */
  overflow: auto;
}
.imgclose {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
}
.isLogisticsitem {
  /* width: 70%; */
  padding-bottom: 30px;
}
.line {
  /* position: absolute;
                                                    width: 100%;
                                                     border-bottom: 1px solid #E9E9E9; */
}
.isLogisticsitemInfo {
  display: flex;
  /* align-items: center; */
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-bottom: 5px;
  color: #8d8d8d;
}
.isLogisticsitemInfo img {
  width: 72px;
  height: 72px;
  margin-right: 15px;
}
.isLogisticsitemtit {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-bottom: 7px;
}
.isLogisticsLi {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.isLogisticsLiL {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  /* line-height: 37px; */
}
.isLogisticsLiR {
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #151515;
  /* line-height: 37px; */
}
.logisticsBut {
  position: fixed;
  text-align: center;
  bottom: 5px;
  left: 20px;
  right: 20px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
}
.nohave {
  text-align: center;
  margin-top: 130px;
  font-size: 15px;
  color: #151515;
}
.refundBg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.59);
  display: flex;
  align-items: center;
  justify-content: center;
}
.refundDetails {
  width: 85%;
  text-align: center;
  border-radius: 10px;
  background: #ffffff;
  border-radius: 12px;
}
.refundTop {
  position: relative;
  width: 100%;
  height: 100px;
  z-index: 9;
}
.refundTop img {
  /* position: absolute; */
  /* margin-left: 100%; */
  width: 100%;
  height: 100%;
}
.refundShow {
  position: absolute;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 100%;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.refundShow img {
  width: 21px;
  height: 21px;
  margin-right: 9px;
}
.refundList {
  padding: 22px 28px;
}
.refundLi {
  display: flex;
  height: 36px;
  line-height: 36px;
}
.refundLiL {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  width: 40%;
}
.refundLiR {
  font-size: 15px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.refundBut {
  width: 85%;
  margin: 16px auto;
  height: 40px;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
  font-size: 17px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}
</style>
