<template>
  <div class="authCode">
    <div class="tit">请输入验证码</div>
    <div class="txt">已发送验证码至 {{ phone }}</div>
    <div class="li">
      <div class="liL">
        <input
          type=""
          name=""
          placeholder="请输入验证码"
          value=""
          v-model="code"
        />
      </div>
      <div class="liR">
        <button
          :class="iscode ? 'codeDisabled' : 'code'"
          @click="clickCode"
          v-html="codemsg"
          type="button"
          :disabled="iscode"
        ></button>
      </div>
    </div>
    <div :class="code.length == 4 ? 'button' : 'nobutton'" @click="submit">
      提交
    </div>
  </div>
</template>

<script>
import { Picker, DatetimePicker, Toast, Popup } from "vant";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
export default {
  name: "authCode",
  data() {
    return {
      params: [],
      codemsg: "发送验证码", // 获取验证码的文案
      t: "", // 倒计时的T
      second: 60, // 倒计时的T
      iscode: false,
      phone: "",
      code: ""
    };
  },
  created() {
    this.params = JSON.parse(this.$route.query.params);
    this.phone = this.$route.query.phone;
    this.name = this.$route.query.name;
    console.log(this.params, this.params.shopNumber);
    this.cms_token();
  },
  methods: {
    cms_token() {
      this.$get(api.Authorization, {
        appId: "1434797802239561728"
      }).then(res => {
        storage.set("userToken_user", "Bearer " + res.data.data);
        // localStorage.setItem("userToken_user", 'Bearer ' + res.data.data);
        // this.getListByKey();
      });
    },
    // 倒计时
    clickTime() {
      console.log(this);
      this.t = setInterval(() => {
        if (this.second == 0) {
          this.codemsg = "重新获取";

          this.iscode = false;
          this.second = 60;
          clearInterval(this.t);
          return;
        }
        this.iscode = true;
        this.codemsg = this.second + "s后重发";

        this.second = this.second - 1;
      }, 1000);
    },
    // 点击获取验证码
    clickCode() {
      console.log("123");
      this.$post(api.CaptchaPhone, {
        mobile: this.phone
      }).then(res => {
        console.log(res);
        if (res.data.code == 0) {
          this.clickTime();
        } else {
          Toast(res.data.msg);
        }
      });
    },
    //提交
    submit() {
      if (this.code.length == 4) {
        const bb = new Date()
        bb.setFullYear(bb.getFullYear() - 3)
        let birthday = bb.toLocaleDateString().replace(/\//g, "-")
        this.$post(api.parentReg, {
          parentMobile: this.phone,
          customerName: this.name+'的宝宝',
          customerSex:'男',
          code: this.code,
          origin: "商品分享",
          parentName: this.name,
          parentRelation:1,
          customerBirthday:birthday,
          organizationId: this.params.shopNumber
        }).then(res => {
          console.log(res);
          if (res.data.code == 0) {
            console.log("登录成功");
            // Toast('登录成功')
            storage.set("userToken_user", "Bearer " + res.data.data.userToken);
            storage.set(
              "userRefreshToken_user",
              res.data.data.userRefreshToken
            );
            this.getCurrentParentInfo();
          } else {
            Toast(res.data.msg);
          }
        });
      }
    },
    //获取用户信息
    getCurrentParentInfo() {
      this.$get(api.parentInfo, {
        // parentMobile: this.phone,
        // code: this.code,
        // origin: "商品分享"
      }).then(res => {
        console.log("获取用户信息", res);
        console.log("获取分享用户信息", res);
        if (res.data.code == 0) {
          if (res.data.data.babyList.length != 0) {
            res.data.data.babyList = res.data.data.babyList.filter(element => element.schoolList.length > 0);
            res.data.data.babyList.forEach((baby,babyIndex)=>{
              if(baby.schoolList && baby.schoolList.length != 0){
                baby.schoolList.forEach(school=>{
                  school.name = school.shortName || '暂无院所别名'
                })
              }
            })
            res.data.data.babyList = res.data.data.babyList.filter(item=>item.schoolList.length != 0)
            storage.set("babyList", JSON.stringify(res.data.data.babyList));
            storage.set("userUnionId", res.data.data.userUnionId);
            storage.set("i", "0");
            storage.set("j", "0");
            console.log("123", res.data.data.babyList);
            this.$router.push({
              path: "/goodsDetails",
              query: {
                params: this.$route.query.params
              }
            });
          }
        } else {
          Toast(res.data.msg);
        }
      });
    }
  }
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background: #ffffff;
}
</style>

<style scoped>
.authCode {
  /* padding: 40px; */
  margin: 40px;
}
.tit {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 28px;
  margin-bottom: 12px;
}
.txt {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #67676b;
  line-height: 20px;
  margin-bottom: 16px;
}
.li {
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
}
.liL {
  width: 60%;
  /* width: 210px; */
  background: #f7f7f7;
  border-radius: 50px;
  /* padding-left: 20px; */
}
.liL input {
  height: 44px;
  width: 84%;
  padding: 0 8%;
}
.liR {
  height: 42px;
  width: 33%;
  background: #ffffff;
  border-radius: 25px;
  border: 1px solid #438bf9;
  /* padding: 0 3px; */
  line-height: 42px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
  color: #5f9cfa;
}
.button {
  text-align: center;
  width: 100%;
  height: 44px;
  background: linear-gradient(90deg, #00cbff 0%, #438bf9 100%);
  border-radius: 22px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 44px;
  margin-top: 90px;
}
.nobutton {
  text-align: center;
  width: 100%;
  height: 44px;
  background: linear-gradient(90deg, #00cbff 0%, #438bf9 100%);
  border-radius: 22px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 44px;
  margin-top: 90px;
  opacity: 0.5;
}
</style>
