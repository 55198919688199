<template>
    <div class="olderGoods">
        <div class="goods-older" @click="changeGoods">
          <div class="goods-img">
            <img name='dynamicImg' :src="info.picture+'@h_137,w_184,q_80'" alt="">
          </div>
          <div class="item-contain">
            <div class="item-title">{{info.title}}</div>
            <div class="item-dateEffective">{{info.validityMonth | validityMonth}}</div>
            <div class="item-price">
              <div class="price-before">{{info.price}}</div>
              <div class="price-after" v-if="priceOriginal">{{info.priceOriginal}}</div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
    import olderGoods from "./olderGoods";
    export default olderGoods;
</script>
<style scoped>
    @import './olderGoods.css';
</style>