<template>
  <div class="login" :style="{ height: h + 'px' }" v-cloak>
    <div class="tit">
      <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/login/icon@2x.png" alt="" />
      <div>家长入口</div>
    </div>
    <div class="list">
      <div class="li">
        <div class="liL">
          <div>+86</div>
          <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/login/there.png" alt="" />
        </div>
        <div class="liR">
          <input
            type="tel"
            maxlength="11"
            placeholder="请输入手机号"
            @input="phoneNum"
            v-model="phone"
          />
        </div>
      </div>
      <div class="lil">
        <div class="lilL left">
          <input type="text" placeholder="请输入图形码" v-model="code" />
        </div>
        <div class="lilR right">
          <img :src="img" alt="" @click="changeCode" />
          <!-- style="width: 80px;height: 40px;margin-right: 0;" -->
        </div>
      </div>
      <div class="li limsg">
        <div class="liL" style="width: 50%;">
          <input type="text" placeholder="请输入验证码" v-model="phoneCode" />
        </div>
        <div class="liR ">
          <button
            :class="iscode ? 'codeDisabled' : 'code'"
            @click="clickCode"
            v-html="codemsg"
            type="button"
            :disabled="iscode"
          ></button>
        </div>
      </div>
    </div>
    <div class="button" @click="submit">登录</div>
    <div class="sign" @click="toSignIn" v-if="signIn">暂无账号，我要注册 ></div>
    <!-- <button @click="invite">测试</button> -->
  </div>
</template>

<script scoped>
import { Toast } from "vant";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
export default {
  name: "login",
  data() {
    return {
      phone: "", // 手机号
      phoneCode: "", //手机验证码
      code: "", // 图形验证码
      codeDisabled: true,
      codemsg: "发送验证码", // 获取验证码的文案
      t: "", // 倒计时的T
      second: 60, // 倒计时的T
      iscode: false,
      img: "",
      wxCode: "",
      h: "",
      signIn: false
    };
  },
  created() {
    // if (localStorage.getItem("userRefreshToken_user") && localStorage.getItem("babyList")) {
    //   that.$router.push('/navigate');
    // } else {
    const height = document.documentElement.clientHeight;
    this.h = height;
    const url = location.search;
    let redirect_uri = "";
    console.log("process.env.NODE_ENV", process.env.NODE_ENV);
    if (process.env.NODE_ENV == "production") {
      redirect_uri =
        "https%3A%2F%2Fprod.babygrow.cn%2Fqimeng-web%2Ffamily%2Findex.html";
    } else if (process.env.NODE_ENV == "none") {
      redirect_uri =
        "http%3A%2F%2Ftest-family.qimengweilai.com%2Findex.html";
    } else if (process.env.NODE_ENV == "development") {
      redirect_uri =
        "https%3A%2F%2Fdev.babygrow.cn%2Fqimeng-web%2Ffamily%2Findex.html";
    }
    if (this.$route.query.params) {
      this.params = JSON.parse(this.$route.query.params);
      this.signIn = true;
      console.log(this.params,'/this.params-----login');
      this.cms_token();
    } else {
      if (process.env.NODE_ENV == "development") {
        this.cms_token();
      } else {
        console.log('/进入参数判断-----login');
        if (url.indexOf("?") != -1) {
          var str = url.substr(1);
          this.wxCode = str.split("&")[0].split("=")[1];
          console.log(this.wxCode,'/this.wxCode-----login');
        }
        if (this.wxCode == "") {
          console.log('/微信换取code重定向开始-----login');
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxae80bb1fd4a0d45b&redirect_uri=" +
            redirect_uri +
            "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
        } else {
          this.cms_token();
        }
        // this.cms_token();
      }
    }
  },
  methods: {
    //去注册
    toSignIn() {
      this.$router.push({
        path: "/register",
        query: {
          params: this.$route.query.params
        }
      });
    },
    //获取cms_token
    cms_token() {
      this.$get(api.Authorization, {
        appId: "1434797802239561728"
      }).then(res => {
        storage.set("userToken_user", "Bearer " + res.data.data);
        this.getOpenid();
      });
    },
    //获取openid
    getOpenid() {
      // this.openId = "oav2Lwj1li6c-QdfChtJIzWC90Qg";
      // storage.set("openId", this.openId);
      // this.getCaptchaImg();
      if (process.env.NODE_ENV == "development") {
        this.openId = "oav2Lwj1li6c-QdfChtJIzWC90Qg";
        storage.set("openId", this.openId);
        this.getCaptchaImg();
      } else {
        this.$post(api.getOpenid, {
          wechatAppId: "wxae80bb1fd4a0d45b",
          code: this.wxCode
        }).then(res => {
          this.openId = res.data.data.openid;
          storage.set("openId", this.openId);
          this.getCaptchaImg();
        });
      }
    },
    //点击切换图形验证码
    changeCode() {
      this.getCaptchaImg();
    },
    //获取图形验证码
    getCaptchaImg() {
      this.$get(api.captchaImg, {
        mode: "character"
      }).then(res => {
        console.log(res);
        this.img = res.data.data.captcha;
        this.captchaId = res.data.data.captchaId;
        this.iscode = false
      }).catch(() => {
        this.iscode = false
      });
    },
    //验证手机号
    phoneNum() {
      const regex = /^(1)\d{10}$/;
      if (!regex.test(this.phone)) {
        this.codeDisabled = true;
        this.msg = "请正确输入手机号";
        return;
      }
      this.codeDisabled = false;
    },
    // 倒计时
    clickTime() {
      console.log(this);
      this.t = setInterval(() => {
        if (this.second == 0) {
          this.codemsg = "重新获取";
          this.iscode = false;
          this.second = 60;
          this.code = "";
          this.phoneCode = "";
          this.getCaptchaImg();
          clearInterval(this.t);
          return;
        }
        this.iscode = true;
        this.codemsg = this.second + "s后重发";
        this.second = this.second - 1;
      }, 1000);
    },
    // 点击获取验证码
    clickCode() {
      console.log("123");
      let that = this;
      console.log(that, that.code);
      // const regex = /^(1)\d{10}$/;
      if (that.iscode) return
      that.iscode = true
      if (
        !/^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/.test(
          that.phone
        )
      ) {
        that.$toast("请输入正确的手机号");
        that.getCaptchaImg();
        that.code = "";
        return;
      } else {
        if (that.code.length != "") {
          that
            .$get(api.checkCaptchaImg, {
              captchaId: that.captchaId,
              captcha: that.code
            })
            .then(res => {
              console.log(res);
              // that.img = res.data.data.captcha;
              // that.captchaId = res.data.data.captchaId;
              if (res.data.code == 0) {
                that
                  .$post(api.CaptchaPhone, {
                    mobile: that.phone
                  })
                  .then(res => {
                    if (res.data.code == 0) {
                      that.clickTime();
                    } else {
                      that.$toast(res.data.msg);
                      that.getCaptchaImg();
                    }
                  }).catch(() => {
                    that.code = ''
                    that.getCaptchaImg()
                  });
              } else {
                that.$toast("请输入图形验证码");
                that.code = ''
                that.getCaptchaImg()
              }
            }).catch(() => {
                that.code = ''
                that.getCaptchaImg()
            });
        }else{
          that.$toast("请填写图形验证码");
          that.iscode = false
        }
      }
    },
    //登录
    submit() {
      console.log(this);
      let that = this;
      if (that.phone == "") {
        that.$toast("请输入手机号");
      } else {
        if (that.code == "") {
          that.$toast("请填写图形验证码");
        } else {
          if (that.phoneCode == "") {
            that.$toast("请填写短信验证码");
          } else {
            //分享登录
            let organizationId = "";
            if (that.signIn) {
              organizationId = that.params.shopId;
            }
            // console.log(that.phone, that.phoneCode, that.params.shopId);
            that
              .$post(api.parentLogin, {
                username: that.phone,
                organizationId: organizationId,
                captcha: that.phoneCode
                // openId: that.openId
              })
              .then(res => {
                if (res.data.code == 0) {
                  console.log("登录成功");
                  // Toast('登录成功')
                  storage.set(
                    "userToken_user",
                    "Bearer " + res.data.data.userToken
                  );
                  storage.set(
                    "userRefreshToken_user",
                    res.data.data.userRefreshToken
                  );
                  that.getparentInfo();
                } else {
                  Toast(res.data.msg);
                }
              });
            // } else {
            //   that
            //     .$post(api.login, {
            //       mobile: that.phone,
            //       code: that.phoneCode,
            //       openId: that.openId
            //     })
            //     .then(res => {
            //       if (res.data.code == 0) {
            //         console.log("登录成功");
            //         // Toast('登录成功')
            //         storage.set(
            //           "userToken_user",
            //           "Bearer " + res.data.data.userToken
            //         );
            //         storage.set(
            //           "userRefreshToken_user",
            //           res.data.data.userRefreshToken
            //         );
            //         that.getCurrentParentInfo();
            //       }
            //     });
            // }
          }
        }
      }
    },
    //获取分享用户信息
    getparentInfo() {
      this.$get(api.parentInfo, {}).then(res => {
        console.log("获取分享用户信息", res);
        if (res.data.code == 0) {
          if (res.data.data.babyList.length != 0) {
            res.data.data.babyList = res.data.data.babyList.filter(element => element.schoolList.length > 0);
            res.data.data.babyList.forEach((baby,babyIndex)=>{
              if(baby.schoolList && baby.schoolList.length != 0){
                baby.schoolList.forEach(school=>{
                  school.name = school.shortName || '暂无院所别名'
                })
              }
            })
            res.data.data.babyList = res.data.data.babyList.filter(item=>item.schoolList.length != 0)
            storage.set("babyList", JSON.stringify(res.data.data.babyList));
            storage.set("userUnionId", res.data.data.userUnionId);
            storage.set("i", "0");
            storage.set("j", "0");
            if (this.signIn) {
              this.$router.push({
                path: "/goodsDetails",
                query: {
                  params: this.$route.query.params
                }
              });
            } else {
              let redirectpath_family  = storage.get("redirectpath_family")
              this.$router.push(decodeURIComponent(redirectpath_family));
            }
          } else {
            window.localStorage.removeItem("userToken_user");
            console.log(storage.get("userToken_user"));
            this.$toast("您的账号当前暂无孩子，请联系您的幼儿园添加孩子信息");
          }
        }
      });
    }
    // getCurrentParentInfo() {
    //   let that = this;
    //   that.$get(api.getCurrentParentInfo).then(res => {
    //     console.log(res);
    //     if (res.data.data.babyList != null) {
    //       if (
    //         res.data.data.babyList[0].schoolList != null &&
    //         res.data.data.babyList[0].schoolList.length != 0
    //       ) {
    //         that.$toast("登录成功");
    //         console.log("res.data.data.userUnionId", res.data.data.userUnionId);
    //         // if (res.data.data.babyList[0].schoolList[0].classList != null && res.data.data.babyList[0].schoolList[0].classList.length != 0) {
    //         storage.set("babyList", JSON.stringify(res.data.data.babyList));
    //         storage.set("userUnionId", res.data.data.userUnionId);
    //         console.log("123", res.data.data.babyList);
    //         that.$router.push("/");
    //         // } else {
    //         //   that.$toast('您的账号当前暂无班级，请联系您的幼儿园开通上课账号');
    //         // }
    //       } else {
    //         window.localStorage.removeItem("userToken_user");
    //         console.log(storage.get("userToken_user"));
    //         that.$toast("您的账号当前暂无园所，请联系您的幼儿园开通上课账号");
    //       }
    //     } else {
    //       // remove('userToken_user')
    //       // remove('userRefreshToken_user')
    //       window.localStorage.removeItem("userToken_user");
    //       console.log(storage.get("userToken_user"));
    //       that.$toast("您的账号当前暂无孩子，请联系您的幼儿园添加孩子信息");
    //     }
    //   });
    // }
  }
};
</script>

<style scoped>
.login {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/login/bg@2x.png) no-repeat;
  background-size: 100% 100%;
}
.tit {
  margin: 22px 30px;
  display: flex;
  align-items: center;
  font-size: 23px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}
.tit img {
  width: 26px;
  height: 20px;
  margin-right: 10px;
}
.list {
  margin: 45px 30px;
  padding-bottom: 10px;
}
.li {
  display: flex;
  align-items: center;
  padding: 7px 20px;
  height: 35px;
  margin-bottom: 15px;
  align-items: center;
  background: #f7f7f7;
  border-radius: 50px;
}
.liL {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  display: flex;
  align-items: center;
}
.left {
  width: 63%;
}
.right {
  width: 35%;
  /* width: 80px; */
  height: 40px;
  margin-right: 0;
}
.right img {
  width: 100%;
  height: 100%;
}
.liL img {
  width: 10px;
  height: 6px;
  margin-left: 9px;
  margin-right: 15px;
}
input {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
}
input::-webkit-input-placeholder {
  color: #cdcdd1;
  font-size: 15px;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
}
/* Mozilla Firefox 4 to 18 */
input:-moz-placeholder {
  color: #cdcdd1;
  opacity: 1;
  font-weight: 500;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
}
/* Mozilla Firefox 19+ */
input::-moz-placeholder {
  color: #cdcdd1;
  font-weight: 500;
  opacity: 1;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
}
/* Internet Explorer 10+ */
input:-ms-input-placeholder {
  color: #cdcdd1;
  font-weight: 500;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
}
.liR button {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3798fa;
}
.limsg {
  justify-content: space-between;
}
.lil {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  /* padding: 7px 20px; */
  /* height: 35px; */
}
.lilL {
  width: 65%;
  height: 35px;
  padding: 7px 20px;
  line-height: 35px;
  background: #f7f7f7;
  border-radius: 50px;
}
.lilR img {
  margin-left: 9px;
  /* height: 60px; */
}
.button {
  height: 42px;
  text-align: center;
  line-height: 42px;
  width: 85%;
  margin: 0px auto;
  background: linear-gradient(
    90deg,
    rgba(0, 203, 255, 1) 0%,
    rgba(67, 139, 249, 1) 100%
  );
  border-radius: 25px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}
.sign {
  margin: 22px 30px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #438bf9;
  line-height: 20px;
}
</style>
