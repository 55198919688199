<template>
  <div class="choosePart" v-cloak>
    <!-- <div class='back'>
                        <a id="back" href="javascript:history.back();" rel="external nofollow"> <img src="../image/login/back.png" alt=""></a>
                        <div style='margin-left:-18px'>选择环节</div>
                        <div style="width: 5px;height: 5px;"></div>
                    </div> -->
    <div class="top">
      <div class="topLi" @click='toPart(2)'>
        <!-- <img src="../image/choosePart/class@2x.png" alt=""> -->
        <div class="toptit">小课堂</div>
        <div class="toptxt">Small Class</div>
      </div>
      <div class="topLi topLiR" @click='toPart(1)'>
        <div class="toptit">声音库</div>
        <div class="toptxt">Voice Bank</div>
      </div>
    </div>
    <div class="list">
      <div class="listLi" @click='toPart(3)'>
        <div class="listLiL">
          <img style="width: 30px;height: 30px;" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/choosePart/dou@2x.png" alt="">
          <div>字正腔圆</div>
        </div>
        <img class="listLiR" v-if='thereChoose' src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/choosePart/okk@2x.png" alt="">
      </div>
      <div class="listLi" @click='toPart(5)'>
        <div class="listLiL">
          <img style="width: 30px;height: 22px;" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/choosePart/game@2x.png" alt="">
          <div>游戏闯关</div>
        </div>
        <img class="listLiR" v-if='fiveChoose' src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/choosePart/okk@2x.png" alt="">
      </div>
      <div class="listLi" @click='toPart(4)'>
        <div class="listLiL">
          <img style="width: 31px;height: 28px;" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/choosePart/show@2x.png" alt="">
          <div>演说秀场</div>
          <img class="listLiLimg" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/choosePart/talk@2x.png" alt="" v-if='isEvaluate==1'>
        </div>
        <img class="listLiR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/choosePart/okk@2x.png" alt="" v-if='fourChoose'>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import http from "@/untils/http";

  export default {
    name: 'choosePart',
    data() {
      return {
        one: false,
        two: false,
        there: false,
        four: false,
        five: false,
        thereChoose: false,
        fourChoose: false,
        fiveChoose: false,
        isEvaluate: 0
      }
    },
    created() {
      var isPageHide = false;
      window.addEventListener('pageshow', function() {
        console.log('1234567')
        if (isPageHide) {
          window.location.reload();
        }
      });
      window.addEventListener('pagehide', function() {
        console.log('789089')
        isPageHide = true;
      });
      this.lessonId = this.$route.query.lessonId;
      this.courseId = this.$route.query.courseId;
      this.babyId = this.$route.query.babyId;
      this.schoolId = this.$route.query.schoolId;
      this.classId = this.$route.query.classId;
      this.toChoosePart()
    },
    methods: {
      //获取学生学习记录
      reportList() {
        let that = this
        that.$post(api.reportList, {
          lessonId: that.lessonId,
          babyId: that.babyId,
          courseId: that.courseId,
        }).then(res => {
          console.log('获取学生学习记录', res)
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].sectionType == 3) {
              that.thereChoose = true
            }
            if (res.data.data[i].sectionType == 4) {
              that.fourChoose = true
              that.isEvaluate = res.data.data[i].isEvaluate
            }
            if (res.data.data[i].sectionType == 5) {
              that.fiveChoose = true
            }
          }
        })
      },
      toChoosePart(e) {
        let that = this
        that.$post(api.sectionList, {
          lessonId: that.lessonId,
        }).then(res => {
          console.log('获取环节列表', res)
          that.reportList();
          that.list = res.data.data
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].type == 1) {
              that.one = true
              that.oneSectionId = res.data.data[i].sectionId
            }
            if (res.data.data[i].type == 2) {
              that.two = true
              that.twoSectionId = res.data.data[i].sectionId
            }
            if (res.data.data[i].type == 3) {
              that.thereSectionId = res.data.data[i].sectionId
              that.there = true
            }
            if (res.data.data[i].type == 4) {
              that.fourSectionId = res.data.data[i].sectionId
              that.four = true
            }
            if (res.data.data[i].type == 5) {
              that.fiveSectionId = res.data.data[i].sectionId
              that.five = true
            }
          }
        })
      },
      toPart(e) {
        console.log(e)
        if (e == 1) {
          if (this.one) {
            this.$router.push({
              path: '/voiceBank',
              query: {
                sectionId: this.oneSectionId
              }
            });
            // window.location.href = '../pages/voiceBank.html?sectionId=' + this.oneSectionId
          } else {
            Toast('暂无内容');
          }
        } else if (e == 2) {
          if (this.two) {
             this.$router.push({
              path: '/videoBank',
              query: {
                sectionId: this.twoSectionId
              }
            });
            // this.$router.push({
            //   path: '/Flash',
            //   query: {
            //     sectionId: this.twoSectionId
            //   }
            // });
            // window.location.href = '../../../static/pages/Flash.html?sectionId=' + this.twoSectionId
          } else {
            Toast('暂无内容');
          }
        } else if (e == 3) {
          if (this.there) {
             this.$router.push({
              path: '/say',
              query: {
                sectionId: this.thereSectionId,
                babyId:this.babyId,
                classId:this.classId,
              }
            });
            // window.location.href = '../pages/say.html?sectionId=' + this.thereSectionId + '&babyId=' + this.babyId + '&classId=' + this.classId
          } else {
            Toast('暂无内容');
          }
        } else if (e == 4) {
          if (this.four) {
             this.$router.push({
              path: '/showTalk',
              query: {
                sectionId: this.fourSectionId,
                babyId:this.babyId,
                classId:this.classId,
              }
            });
            // window.location.href = '../pages/showTalk.html?sectionId=' + this.fourSectionId + '&babyId=' + this.babyId + '&classId=' + this.classId
          } else {
            Toast('暂无内容');
          }
        } else if (e == 5) {
          if (this.five) {
             this.$router.push({
              path: '/game',
              query: {
                sectionId: this.fiveSectionId,
                babyId:this.babyId,
                classId:this.classId,
              }
            });
            // window.location.href = '../pages/game.html?sectionId=' + this.fiveSectionId + '&babyId=' + this.babyId + '&classId=' + this.classId
          } else {
            Toast('暂无内容');
          }
        }
      },
    },
  }
</script>

<style scoped>
  body {
    background-color: #FBFBFB;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;
  }
  .topLi {
    width: 160px;
    height: 96px;
    background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/choosePart/class@2x.png) no-repeat;
    background-size: 100% 100%;
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.06);
  }
  .topLiR {
    background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/choosePart/vioce@2x.png) no-repeat;
    background-size: 100% 100%
  }
  .toptit {
    padding-left: 14px;
    padding-top: 13px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
  .toptxt {
    padding-top: 4px;
    padding-left: 14px;
    font-size: 12px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
  }
  .list {
    margin: 20px;
  }
  .listLi {
    display: flex;
    /* justify-content: space-between; */
    padding: 0 15px;
    height: 60px;
    /* align-items: center; */
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    margin: 22px 0;
  }
  .listLiL {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .listLiL div {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    padding-left: 12px;
  }
  .listLiLimg {
    /* position: absolute; */
    width: 15px;
    height: 14px;
    margin-left: 5px;
    /* margin-top: -10px; */
  }
  .listLiR {
    /* width: 18px; */
    width: 51px;
    height: 41px;
    /* height: 18px; */
  }
</style>
