<template>
  <div class="newAddress">
    <div class="list">
      <div class="li">
        <div class="liL">姓名</div>
        <input class="liR" placeholder="请填写收货人姓名" v-model="name" />
      </div>
      <div class="li">
        <div class="liL">手机号</div>
        <input class="liR" placeholder="请填写收货人手机号" v-model="phone" />
      </div>
      <div class="li">
        <div class="liL">选择地区</div>
        <!-- <input class="liR" placeholder="请选择省市区" v-model="city" @> -->
        <div class="liR" @click="getCity">{{ msg }}</div>
      </div>
      <div class="li">
        <div class="liL">详细地址</div>
        <input
          class="liR"
          placeholder="请输入详细地址"
          v-model="address"
          type="text"
        />
        <!-- <textarea
          class="liR"
          placeholder="请输入详细地址"
          name=""
          id=""
          cols="30"
          rows="3"
          v-model="address"
        ></textarea> -->
      </div>
    </div>
    <div class="chooseDefault">
      <div class="chooseDefaultL">设为默认地址</div>
      <!-- <input class="liR" placeholder="请选择省市区" v-model="city" @> -->
      <div class="chooseDefaulR" @click="chooseImg">
        <img
          :src="
            isDefault
              ? 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/open@2x.png'
              : 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/no@2x.png'
          "
          alt=""
        />
      </div>
    </div>
    <v-distpicker type="mobile" @selected="selected" v-show="addInp">
    </v-distpicker>
    <div class="mask" v-show="mask"></div>
    <div class="button" @click="addAddress" v-show="mask == false">保存</div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
import VDistpicker from "v-distpicker";
export default {
  name: "newAddress",
  data() {
    return {
      name: "",
      phone: "",
      msg: "请选择省市区",
      address: "",
      addInp: false,
      mask: false,
      change: false,
      isDefault: false
    };
  },
  components: {
    VDistpicker
  },
  created() {
    if (this.$route.query.type == "change") {
      this.name = this.$route.query.name;
      this.phone = this.$route.query.phone;
      this.msg =
        this.$route.query.province +
        " " +
        this.$route.query.city +
        " " +
        this.$route.query.county;
      this.address = this.$route.query.address;
      this.addressId = this.$route.query.addressId;
      this.change = true;
      this.city = this.$route.query.city;
      this.province = this.$route.query.province;
      this.county = this.$route.query.county;
      if (this.$route.query.isDefault == 1) {
        this.isDefault = true;
      } else {
        this.isDefault = false;
      }
      // this.isDefault = this.$route.query.county
      document.title = "编辑地址";
    } else {
      document.title = "新增地址";
    }
  },
  methods: {
    getCity() {
      this.mask = true;
      this.addInp = true;
    },
    // 省市区三级联动
    selected(data) {
      console.log(data);
      this.mask = false;
      this.addInp = false;
      this.msg =
        data.province.value + " " + data.city.value + " " + data.area.value;
      this.province = data.province.value;
      this.city = data.city.value;
      this.county = data.area.value;
    },
    //去购物车
    toShoppingCar() {
      this.$router.push("/shoppingCar");
    },
    //是否设置为默认地址
    chooseImg() {
      this.isDefault = !this.isDefault;
      console.log(this.isDefault);
    },
    //添加地址
    addAddress() {
      let that = this;
      let isDefault;
      if (that.isDefault) {
        that.num = 1;
      } else {
        that.num = 0;
      }
      console.log(that.isDefault, that.num);
      if (that.name) {
        if (
          !/^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/.test(
            that.phone
          ) == false
        ) {
          if (that.city) {
            if (that.address) {
              if (that.change) {
                that
                  .$post(api.addressUpdate, {
                    name: that.name,
                    phone: that.phone,
                    province: that.province,
                    city: that.city,
                    county: that.county,
                    address: that.address,
                    addressId: that.addressId,
                    isDefault: that.num
                  })
                  .then(res => {
                    if (res.data.code == 0) {
                      Toast({
                        message: "修改地址成功",
                        iconClass: "icon icon-success"
                      });
                      console.log("修改地址成功");
                      that.$router.go(-1);
                    }
                  });
              } else {
                that
                  .$post(api.addAddress, {
                    name: that.name,
                    phone: that.phone,
                    province: that.province,
                    city: that.city,
                    county: that.county,
                    address: that.address,
                    isDefault: that.num
                  })
                  .then(res => {
                    if (res.data.code == 0) {
                      console.log("添加地址成功");
                      that.$router.go(-1);
                    }
                  });
              }
            } else {
              that.$toast("请输入详细地址");
            }
          } else {
            that.$toast("请选择收货城市");
          }
        } else {
          that.$toast("请输入正确的手机号");
        }
      } else {
        that.$toast("请输入收货人姓名");
      }
    }
  }
};
</script>
<style>
.distpicker-address-wrapper .address-container {
  background-color: #fff;
  overflow: hidden;
  height: 250px;
}
</style>

<style scoped>
body {
  background: #fafafc;
}
.list {
  padding: 20px;
  background-color: #ffffff;
}
.li {
  display: flex;
  padding: 18px 0;
  font-size: 15px;
  color: #151515;
  border-bottom: 1px solid #eeeeee;
  align-items: center;
}
.li:last-child {
  border: 0;
}
.liL {
  width: 26%;
}
.button {
  width: 86%;
  margin: 0 7%;
  position: fixed;
  bottom: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
}
.divwrap {
  height: 400px;
  overflow-y: auto;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.distpicker-address-wrapper {
  position: fixed;
  color: #9caebf;
  left: 0;
  right: 0;
  bottom: 0;
  height: 300px;
}
.chooseDefault {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  padding: 18px 20px;
  font-size: 15px;
  color: #151515;
}
.chooseDefault img {
  width: 41px;
  height: 28px;
}
</style>
