<template>
  <div class="babyInfo">
    <div class="teacher">
      <div class="teacherTit">{{arr.organizationName}}</div>
      <div class="teacherTxt">邀请老师：{{arr.userUnionName}}</div>
    </div>
    <div class="kidInfo" v-if="one">
      <div class="tit">请填写孩子信息</div>
      <div class="list">
        <div class="li">
          <div class="liL">学生姓名：</div>
          <input type="text" placeholder="请输入姓名" v-model="babyName" style="text-align:right;font-size:15px">
        </div>
        <div class="li">
          <div class="liL">学生性别：</div>
          <div class="liR">
            <div class="radio" @click="changeSex('2')">
              <img :src="sex=='2'?'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/invite/choose@2x.png':'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/invite/nochoose@2x.png'" alt="">
              <div>女</div>
            </div>
            <div class="radio" @click="changeSex('1')">
              <img :src="sex=='1'?'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/invite/choose@2x.png':'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/invite/nochoose@2x.png'" alt="">
              <div>男</div>
            </div>
          </div>
        </div>
        <div class="li">
          <div class="liL">出生日期：</div>
          <div class="liR">
            <div class="birthday" @click="openDatetimePicker()">{{birthday}}</div>
            <img class='next' src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/next@2x.png" alt="">
          </div>
        </div>
        <van-datetime-picker v-if="datetime" show-toolbar toolbar-class='aaa' type="date" title="选择年月日" :min-date="minDate" :max-date="maxDate" @confirm="ondateConfirm" @cancel="onCancel" />
      </div>
      <div class="nextBut" @click="ConfirmKid">下一步</div>
    </div>
    <div class="parentInfo" v-else>
      <div class="tit">请填写家长信息</div>
      <div class="list">
        <div class="li">
          <div class="liL">家长姓名:</div>
          <input type="text" v-model="parentName" placeholder="请输入家长姓名" style="text-align:right;font-size:15px">
        </div>
        <div class="li">
          <div class="liL">家长关系:</div>
          <div class="liR">
            <div class="relation" @click="openPicker()">{{relation}}</div>
            <img class='next' src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/next@2x.png" alt="">
          </div>
        </div>
        <van-picker v-if="ship" title="选择关系" show-toolbar :columns="relationship" value-key='name' :default-index="1" @confirm="onConfirm" @cancel="onCancel" />
        <div class="li">
          <div class="liL">联系电话：</div>
          <input type="number" maxlength="11" v-model="phone" placeholder="请输入手机号码" style="text-align:right;font-size:15px">
        </div>
      </div>
      <div class="nextBut" @click="submit">提交</div>
      <div class="But" @click="toreturn">上一步</div>
    </div>
    <div class="hintbg" v-if="hint">
      <div class="hint">
        <img class="close" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/invite/close@2x.png" alt="" @click="closeHint">
        <div class="hintTit">
          <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/invite/jinggao@2x.png" alt="">
          <div> 此手机号已存在！</div>
        </div>
        <div class="hintTxt" style="margin-bottom:14px">已知绑定信息如下：</div>
        <div class="hintTxt" v-for="(item,index) in list.babyList" :key="index" style="margin-bottom:20px">
          <div>{{item.name}}{{item.relation}}, {{list.realName}},{{item.schoolList[0].name}}
          </div>
        </div>
        <!-- <div class="hintTxt" >大大爸爸，张三，启蒙园所名称；：</div> -->
        <div class="hintTxt" style="margin-bottom:25px">是否继续录入？</div>
        <div class="hintBut" @click="goOn">继续录入</div>
        <div class="hintButs" @click="closeHint">返回修改信息</div>
      </div>
    </div>
  </div>
</template>

<script>
  // import {
  //   ,
  //   DatetimePicker
  // } from "mint-ui";
  //  import Vue from 'vue';
  import {
    Picker,
    DatetimePicker,
    Toast
  } from 'vant';
  // Vue.use(Picker);
  import * as api from "@/untils/api";
  import http from "@/untils/http";
  import storage from "good-storage";
  export default {
    name: "babyInfo",
    components: {
      DatetimePicker,
    },
    data() {
      return {
        sex: 1,
        one: true,
        ship: false,
        datetime: false,
        birthday: '2010/01/01',
        minDate: new Date(2010, 0, 1),
        maxDate: new Date(),
        parentName: '',
        hint: false,
        babyName: '',
        phone: '',
        relation: "爸爸",
        type: 2,
        years: '2010-01-01',
        list: [],
        relationship: [],
        arr: [],
        // columns: ["妈妈", "爸爸", "爷爷", "奶奶", "", "嘉兴", "金华", "衢州"],
      };
    },
    created() {
      this.arr = JSON.parse(this.$route.query.params)
      console.log(this.arr)
      this.cms_token();
    },
    methods: {
      cms_token() {
        this.$get(api.Authorization, {
          appId: "1434797802239561728",
        }).then(res => {
          storage.set("userToken_user", 'Bearer ' + res.data.data)
          // localStorage.setItem("userToken_user", 'Bearer ' + res.data.data);
          this.getListByKey();
        })
      },
      //获取亲属关系
      getListByKey() {
        this.$get(api.listByKey, {
          dicKey: 'parent_child_relationship'
        }).then(res => {
          if (res.data.code == 0) {
            console.log('获取亲属关系', res)
            this.relationship = res.data.data
            this.relation = res.data.data[1].name
            // that.list = res.data.data
            // that.$router.push('/address');
          }
        })
      },
      openPicker() {
        this.ship = true
      },
      onConfirm(value, index) {
        console.log(value, index, this.relationship)
        this.relation = value.name
        this.type = value.dicValue
        this.ship = false
        // Toast(`当前值：${value}, 当前索引：${index}`);
      },
      onCancel() {
        this.ship = false
        this.datetime = false
      },
      ondateConfirm(value, index) {
        this.birthday = this.timeFormat(value);
        this.datetime = false
      },
      timeFormat(time) { // 时间格式化 2019-09-08
        let year = time.getFullYear();
        let month = time.getMonth() + 1;
        let day = time.getDate();
        if (month < 9) {
          month = '0' + month
        }
        if (day < 9) {
          day = '0' + day
        }
        this.years = year + '-' + month + '-' + day
        console.log(this.years)
        return year + '/' + month + '/' + day
      },
      changeSex(e) {
        this.sex = e;
      },
      openDatetimePicker() {
        this.datetime = true
        // this.$refs.picker.open();
        // this.isShow = !this.isShow;
      },
      //判断孩子是否重复
      ConfirmKid() {
        if (this.babyName) {
          this.$get(api.getRepeatBabyCount, {
            name: this.babyName,
            sex: this.sex,
            birthday: this.birthday,
            organizationId: this.arr.organizationId
          }).then(res => {
            if (res.data.code == 0) {
              console.log('判断孩子是否重复', res)
              if (res.data.data.length == 0) {
                this.one = false
              } else {
                this.one = true
                Toast('当前孩子已存在！')
              }
            }
          })
        } else {
          Toast('请输入孩子的姓名')
        }
      },
      //提交
      submit() {
        if (this.parentName) {
          const regex = /^(1)\d{10}$/;
          if (!regex.test(this.phone)) {
            Toast('请正确输入手机号')
            return;
          } else {
            this.getChildrenByParentPhone();
          }
        } else {
          Toast('请输入家长姓名')
        }
      },
      // 根据家长手机号获取儿童信息列表
      getChildrenByParentPhone() {
        this.$get(api.getChildrenByParentPhone, {
          phone: this.phone
        }).then(res => {
          if (res.data.code == 0) {
            console.log('根据家长手机号获取儿童信息列表', res)
            if (res.data.data) {
              if (res.data.data.realName) {
                this.parentName = res.data.data.realName
                if (res.data.data.babyList.length != 0) {
                  for (let i = 0; res.data.data.babyList.length > i; i++) {
                    for (let j = 0; this.relationship.length > j; j++) {
                      // console.log(this.relationship[j].dicValue, res.data.data.babyList[i].type)
                      if (this.relationship[j].dicValue == res.data.data.babyList[i].type) {
                        res.data.data.babyList[i].relation = this.relationship[j].name
                      }
                    }
                  }
                  this.list = res.data.data
                  this.hint = true
                  console.log(this.list)
                } else {
                  this.babySave();
                }
              }
            } else {
              this.babySave();
              // Toast('当前孩子已存在！')
            }
          }
        })
      },
      //提交数据
      babySave() {
        this.$post(api.babySave, {
          name: this.babyName,
          sex: this.sex,
          birthday: this.years,
          organizationId: this.arr.organizationId,
          inviteId: this.arr.userUnionId,
          babyFamily: [{
            realName: this.parentName,
            mobile: this.phone,
            type: this.type,
            relation: 1,
          }]
        }).then(res => {
          if (res.data.code == 0) {
            console.log('提交数据', res)
            if (res.data.code == 0) {
              this.$router.push({
                path: '/success',
              });
            } else {
              // this.one = true
              Toast(res.data.msg)
            }
          }
        })
      },
      toreturn() {
        this.one = true
        this.parentName = ''
        this.phone = ''
      },
      closeHint() {
        this.hint = false
      },
      goOn() {
        this.babySave();
      }
    },
  };
</script>
<style>
   button {
    color: #F85B23!important;
    font-size: 13px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
  }

  div.van-picker__toolbar {
    /* color: #F85B23; */
    font-size: 17px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #37383B;
    background: #F7F7F7;
  }
</style>


<style scoped>
  .teacher {
    background-color: #ffffff;
    padding: 15px 20px;
  }
  .teacherTit {
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 24px;
    margin-bottom: 6px;
  }
  .teacherTxt {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393939;
    line-height: 19px;
  }
  .tit {
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 24px;
    padding: 15px 20px;
  }
  .list {
    background-color: #ffffff;
    padding: 0 20px;
  }
  .li {
    display: flex;
    justify-content: space-between;
    height: 55px;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
  }
  .li:last-child {
    border: 0;
  }
  .liR {
    display: flex;
    align-items: center;
  }
  .radio {
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #414141;
    line-height: 21px;
    letter-spacing: 1px;
  }
  .radio img {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
  .birthday {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 21px;
  }
  .next {
    width: 11px;
    height: 11px;
    margin-left: 2px;
  }
  .van-picker {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .nextBut {
    width: 290px;
    height: 40px;
    margin: 60px auto;
    background: linear-gradient(90deg, #FD8934 0%, #F85B23 100%);
    border-radius: 20px;
    line-height: 40px;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
  .But {
    width: 290px;
    height: 40px;
    margin: -35px auto;
    border: 1px solid #FE5A00;
    border-radius: 20px;
    line-height: 40px;
    color: #FE5A00;
    font-size: 18px;
    text-align: center;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
  .hintbg {
    position: fixed;
    /* position: relative; */
    background: rgba(0, 0, 0, 0.48);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hint {
    width: 70%;
    position: relative;
    background: #ffffff;
    border-radius: 9px;
    padding: 24px;
  }
  .close {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 20px;
    top: 13px;
  }
  .hintTit {
    text-align: center;
    font-size: 17px;
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
    align-items: center;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }
  .hintTit img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
  .hintTxt {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 21px;
  }
  .hintBut {
    width: 80%;
    margin: 20px auto;
    height: 40px;
    background: linear-gradient(90deg, #FD8934 0%, #F85B23 100%);
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
  .hintButs {
    width: 80%;
    margin: 25px auto;
    height: 40px;
    border: 1px solid #FE5A00;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    color: #FE5A00;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
</style>
