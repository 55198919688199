import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
    name:'olderGoods',
    mixins: [dynamicImgwh],
    props:{
        info:{
            type:Object,
            default:()=>{}
        },
        priceOriginal:{
            type:Boolean,
            default:true
        }
    },
    filters:{
        validityMonth(val){
          let tipValidity = val == 0 ? '永久有效' : `有效期${val}个月`
          return tipValidity
        }
    },
    data(){
        return {}
    },
    created(){
        this.get_dynamicImgSQ()
    },
    
    methods:{
        changeGoods(){
            this.$emit('details',this.info)
        }
    }
}