<template>
  <div class="OMS">
    <div style="margin-bottom:60px;">
      <div class="wait">
        <div v-if="info.status == 1">
          <div class="waitTop">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/time@2x.png" alt="" />等待付款
          </div>
          <div class="waitTxt">
            需付款： ¥{{ info.price }} 剩余： {{ timeDay }}分钟{{ time }}秒
          </div>
        </div>
        <div v-if="info.status == 0">
          <div class="waitTop">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/close.png" alt="" />已取消
          </div>
        </div>
        <div v-if="info.status == 2">
          <div class="waitTop">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/goods.png" alt="" />等待发货
          </div>
        </div>
        <div v-if="info.status == 3">
          <div class="waitTop">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/goods.png" alt="" />等待收货
          </div>
        </div>
        <div v-if="info.status == 4">
          <div class="waitTop">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/finsh.png" alt="" />已完成
          </div>
        </div>
      </div>
      <div class="list">
        <div class="itemState">
          <div class="itemStateL">订单编号：{{ info.orderId }}</div>
          <div class="itemStateR" v-if="info.status == 0">已取消</div>
          <div class="itemStateR" v-if="info.status == 1">待付款</div>
          <div class="itemStateR" v-if="info.status == 2">待发货</div>
          <div class="itemStateR" v-if="info.status == 3">已收成</div>
          <div class="itemStateR" v-if="info.status == 4">已完成</div>
        </div>
        <div class="item" v-for="(item, index) in info.product" :key="index">
          <div class="itemL">
            <img
              name='dynamicImg'
              :src="
                item.levelCoverUrl != '' ? item.levelCoverUrl+imgSizeQuality : item.productImg+imgSizeQuality
              "
              alt=""
            />
            <div class="itemLR">
              <div class="itemTit">{{ item.productName }}</div>
              <div class="itemType" v-if="item.levelName != ''">
                规格：{{ item.levelName }}
              </div>
            </div>
          </div>
          <div class="itemR">
            <div class="itemRmoney"><span>¥</span>{{ item.productPrice }}</div>
            <div>×{{ item.productQuantity }}</div>
            <div class="itemType" v-if="item.delivery == 1">物流发货</div>
            <div class="itemType" v-if="item.delivery == 2">用户自取</div>
            <div class="itemType" v-if="item.delivery == 3">无需发货</div>
            <div v-if="item.refund.length != 0">
              <div
                class="itemRefund"
                v-for="(refundItem, refundIndex) in item.refund"
                :key="refundIndex"
                @click="
                  refundInfo(
                    refundItem.status,
                    refundItem.price,
                    refundItem.productQuantity,
                    refundItem.createTime,
                    refundItem.refundTime
                  )
                "
              >
                <div v-if="refundItem.status == 0">已退款</div>
                <div v-if="refundItem.status == 1">退款失败</div>
                <div v-if="refundItem.status == 2">退款待处理</div>
              </div>
            </div>
          </div>
        </div>
        <div class="itemState">
          <div class="itemStateL">{{ info.createTime }}</div>
          <div class="itemMoney">
            合计：<span class="yuan">¥</span>
            <span class="moneyNum">{{ info.price }}</span>
          </div>
        </div>
      </div>
      <div class="payInfo">
        <div class="title">下单信息</div>
        <div class="payLi">
          <div class="payLiL">手机号：</div>
          <div class="payLiR">{{ info.userTag }}</div>
        </div>
        <div class="payLi">
          <div class="payLiL">宝宝信息：</div>
          <div class="payLiR">{{ info.useUserName }}</div>
        </div>
        <div class="payLi" v-if="info.note">
          <div class="payLiL">备注信息：</div>
          <div class="payLiR">{{ info.note }}</div>
        </div>
        <div class="payLi">
          <div class="payLiL">下单时间：</div>
          <div class="payLiR">{{ info.createTime }}</div>
        </div>
      </div>
      <div class="address">
        <div class="title">发货信息</div>
        <div class="title">收件人</div>
        <div class="addressInfo">
          {{ info.consigneeName }} &nbsp;&nbsp;&nbsp; {{ info.consigneePhone }}
        </div>
        <div class="addressLi">地址：{{ info.consigneeAddress }}</div>
      </div>
      <!-- <div class="address" v-if="info.express.length != 0">
          <div class="title">快递信息</div>
          <div v-for="(item, index) in info.express" :key="index">
            <div class="title">{{ item.expressName }}</div>
            <div class="addressLi">单号：{{ item.expressNum }}</div>
            <div class="addressLi">发货时间：{{ item.expressTime }}</div>
          </div>
        </div> -->
      <div class="payInfo" v-if="info.status == 2">
        <div class="title">支付信息</div>
        <div class="payLi">
          <div class="payLiL">支付方式：</div>
          <div class="payLiR">{{ info.payType | orderPayType}}</div>
        </div>
        <div class="payLi">
          <div class="payLiL">支付时间：</div>
          <div class="payLiR">{{ info.payTime }}</div>
        </div>
      </div>
    </div>
    <div
      class="logistics"
      v-if="
        (info.status == 2 && info.express.length != 0) ||
          (info.status == 3 && info.express.length != 0)
      "
      @click="showLogistics"
    >
      <div>查看物流信息</div>
    </div>
    <div v-if="isLogistics" class="isLogisticsBg">
      <div class="isLogisticsInfo">
        <div class="isLogisticsInfoL" v-if="info.express.length != 0">
          <div style="border-bottom: 1px solid #e9e9e9;">
            <div
              class="isLogisticsitem"
              v-for="(item, index) in info.express"
              :key="index"
            >
              <div
                class="isLogisticsitemInfo"
                v-for="(items, indexs) in item.product"
                :key="indexs"
              >
                <img
                  :src="
                    items.levelCoverUrl != ''
                      ? items.levelCoverUrl
                      : items.productImg
                  "
                  alt=""
                />
                <div class="isLogisticsitemInfoR">
                  <div class="isLogisticsitemtit">{{ items.productName }}</div>
                  <div v-if="levelName">规格：{{ items.levelName }}</div>
                </div>
              </div>
              <div v-if="item.expressName != '' && item.expressNum">
                <div class="isLogisticsLi">
                  <div class="isLogisticsLiL">物流公司：</div>
                  <div class="isLogisticsLiR">{{ item.expressName }}</div>
                </div>
                <div class="isLogisticsLi">
                  <div class="isLogisticsLiL">快递单号：</div>
                  <div class="isLogisticsLiR">{{ item.expressNum }}</div>
                </div>
              </div>
              <div class="isLogisticsLi" v-else>
                <div class="isLogisticsLiL">暂未发货</div>
              </div>
            </div>
          </div>
          <div class="logisticsBut" @click="closeLogistics">确定</div>
        </div>
        <img
          class="imgclose"
          @click="closeLogistics"
          src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/close@2x.png"
          alt=""
        />
      </div>
    </div>
    <div class="button" @click="topay" v-if="info.status == 1">
      <div class="but">立即支付</div>
    </div>
    <div class="refundBg" v-if="refund">
      <div class="refundDetails">
        <div class="refundTop" v-if="num == 0">
          <div class="refundShow">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/success@2x.png" alt="" />
            <div>退款完成</div>
          </div>
          <div class="refundShow" v-if="num == 1">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/fail@2x.png" alt="" />
            <div>退款失败</div>
          </div>
          <div class="refundShow" v-if="num == 2">
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/ing@2x.png" alt="" />
            <div>退款处理中…</div>
          </div>
          <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/refund@2x.png" alt="" />
        </div>
        <div class="refundList">
          <div class="refundLi">
            <div class="refundLiL">退款数量</div>
            <div class="refundLiR">{{ productQuantity }}</div>
          </div>
          <div class="refundLi">
            <div class="refundLiL">申请退款金额</div>
            <div class="refundLiR">{{ price }}</div>
          </div>
          <div class="refundLi">
            <div class="refundLiL">退款申请时间</div>
            <div class="refundLiR">{{ createTime }}</div>
          </div>
          <div class="refundLi" v-if="num == 0">
            <div class="refundLiL">退款成功时间</div>
            <div class="refundLiR">{{ refundTime }}</div>
          </div>
        </div>
        <div class="refundBut" @click="closeRefund">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Toast } from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
  name: "OMS",
  mixins: [dynamicImgwh],
  data() {
    return {
      info: [],
      timeDay: "",
      time: "",
      timeOut: "",
      isLogistics: false,
      refund: false,
      num: 0,
      productQuantity: "",
      createTime: "",
      refundTime: "",
      price: ""
    };
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.shopNumber = this.$route.query.shopNumber;
    console.log(this.shopNumber);
    this.babyList = JSON.parse(storage.get("babyList"));
    this.i = storage.get("i");
    this.getOrderInfo();
    // console.log(new Date().getTime(), new Date().getTime() + 30 * 60 * 1000)
    // this.setTime();
  },
  methods: {
    getOrderInfo() {
      this.$get(api.orderInfo, {
        orderId: this.orderId,
        shopNumber: this.shopNumber
      }).then(res => {
        this.info = res.data.data;
        this.get_dynamicImgSQ()
        console.log(res);
        let clock = window.setInterval(() => {
          const nowTime = new Date().getTime();
          const timedate =
            new Date(this.info.createTime.replace(/-/g, "/")).getTime() +
            this.info.payExpire * 1000;

          const date = timedate * 1 - nowTime * 1;
          //  console.log(date)
          const time = Math.ceil(date / 1000) % 60;
          const timeDay = Math.ceil(date / (1000 * 60)) - 1;
          this.time = time > 0 ? time : 0;
          this.timeDay = timeDay > 0 ? timeDay : 0;
          // console.log(this.time, this.timeDay)
        }, 1000);
      });
    },
    showLogistics() {
      this.isLogistics = true;
    },
    closeLogistics() {
      this.isLogistics = false;
    },
    //退款详情
    refundInfo(e, price, productQuantity, createTime, refundTime) {
      this.num = e;
      this.price = price;
      this.productQuantity = productQuantity;
      this.createTime = createTime;
      this.refundTime = refundTime;
      this.refund = true;
    },
    //确定
    closeRefund() {
      this.refund = false;
    },
    topay:_.throttle(function(){
      console.log("sdddf");
      this.$post(api.orderRepay, {
        orderId: this.orderId,
        openId: localStorage.getItem("openId"),
        frontUrl:
          process.env.API_URL + "/qimeng-web/family/index.html#/mall/orderList"
        // shopNumber: this.shopNumber
      }).then(res => {
        if (res.data.code == 0) {
          console.log("确认支付", res);
          storage.set("orderId", res.data.data.orderId);
          window.location.href = res.data.data.payCode;
        } else {
          Toast(res.data.msg);
        }
      });
    },4000),
  }
};
</script>

<style scoped>
.OMS {
  margin-bottom: 80px;
}
.wait {
  width: 100%;
  background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/omsbg@2x.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  margin: 0 auto;
  color: #ffffff;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  padding: 24px 0;
  text-align: center;
}
.wait img {
  width: 22px;
  height: 22px;
  margin-right: 9px;
}
.waitTop {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 11px;
}
.waitTxt {
  font-size: 13px;
}
.list {
  background-color: #ffffff;
  margin-top: 10px;
  padding: 12px 19px;
  margin-bottom: 10px;
}
.itemState {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  padding-bottom: 13px;
}
.itemStateL {
  color: #2f2f2f;
}
.itemStateR {
  color: #f85511;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.itemL {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.itemL img {
  width: 72px;
  height: 72px;
  margin-right: 15px;
}
.itemR {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
  text-align: right;
}
.itemMoney {
  font-size: 17px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #000000;
}
.yuan {
  font-size: 11px;
}
.moneyNum {
  font-size: 18px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #f85511;
}
.itemTit {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
}
.itemType {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin: 6px 0;
  color: #8d8d8d;
}
.itemRefund {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3388ff;
  line-height: 19px;
}
.payInfo {
  background-color: #ffffff;
  padding: 15px 20px;
  margin-bottom: 10px;
}
.title {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #151515;
  margin-bottom: 12px;
}
.payLi {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 1px;
}
.payLiL {
  width: 30%;
  color: #676767;
}
.payLiR {
  color: #151515;
}
.address {
  padding: 15px 20px;
  /* margin-bottom: 80px; */
  background-color: #ffffff;
}
.addressInfo {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 21px;
  margin-bottom: 3px;
}
.addressLi {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  margin-top: 3px;
  /* align-items: flex-start; */
}
.button {
  background-color: #ffffff;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
}
.but {
  margin: 8px 20px;
  padding: 12px 0;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
  text-align: center;
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.logistics {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
}
.logistics div {
  text-align: center;
  color: #f85511;
  border-radius: 20px;
  border: 1px solid #f85511;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  width: 80%;
  margin: 5px auto;
  height: 40px;
  line-height: 40px;
}
.isLogisticsBg {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.59);
  z-index: 120;
}
.isLogisticsInfo {
  position: fixed;
  top: 74px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px 20px 0px 0px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  /* white-space: nowrap;
                                                        overflow-x: hidden;
                                                        overflow-y: auto; */
}
.isLogisticsInfoL {
  width: 100%;
  /* height: 80%;  */
  overflow: auto;
}
.imgclose {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
}
.isLogisticsitem {
  /* width: 70%; */
  padding-bottom: 30px;
}
.line {
  /* position: absolute;
                                                    width: 100%;
                                                     border-bottom: 1px solid #E9E9E9; */
}
.isLogisticsitemInfo {
  display: flex;
  /* align-items: center; */
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-bottom: 5px;
  color: #8d8d8d;
}
.isLogisticsitemInfo img {
  width: 72px;
  height: 72px;
  margin-right: 15px;
}
.isLogisticsitemtit {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-bottom: 7px;
}
.isLogisticsLi {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.isLogisticsLiL {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  /* line-height: 37px; */
}
.isLogisticsLiR {
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #151515;
  /* line-height: 37px; */
}
.logisticsBut {
  position: fixed;
  text-align: center;
  bottom: 5px;
  left: 20px;
  right: 20px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
}
.nohave {
  text-align: center;
  margin-top: 130px;
  font-size: 15px;
  color: #151515;
}
.refundBg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.59);
  display: flex;
  align-items: center;
  justify-content: center;
}
.refundDetails {
  width: 85%;
  text-align: center;
  border-radius: 10px;
  background: #ffffff;
  border-radius: 12px;
}
.refundTop {
  position: relative;
  width: 100%;
  height: 100px;
  z-index: 9;
}
.refundTop img {
  /* position: absolute; */
  /* margin-left: 100%; */
  width: 100%;
  height: 100%;
}
.refundShow {
  position: absolute;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 100%;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.refundShow img {
  width: 21px;
  height: 21px;
  margin-right: 9px;
}
.refundList {
  padding: 22px 28px;
}
.refundLi {
  display: flex;
  height: 36px;
  line-height: 36px;
}
.refundLiL {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  width: 40%;
}
.refundLiR {
  font-size: 15px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.refundBut {
  width: 85%;
  margin: 16px auto;
  height: 40px;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
  font-size: 17px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}
</style>
