import * as api from "@/untils/api";
import storage from "good-storage";
import { setCommont } from "@/untils/set_family_info";
export default {
    data() {
      return {
          mixMallState:false, //当前院所下是否开通商店
          mixMallName:false, //当前商店用户名称
          mixMallImage:false, //当前商店用户头像
      }
    },
    mounted(){},
    methods:{
        setFamilyInfo(cd){
            return new Promise(resolve => {
              // 判断是否包含院所id
              setCommont().then(()=>{
                  cd()
                  resolve()
              })
              
          })
      },
      //是否开商铺
      shopInfo() {
        return new Promise(resolve => {
            let babyList = JSON.parse(storage.get("babyList"))
            let i = storage.get("i")
            let j = storage.get("j")
            this.$post(api.shopInfo, {
                shopNumber: babyList[i].schoolList[j].organizationId,
                shopTag: "实物商铺",
                status: 1
            }).then(res => {
                console.log("是否开商铺查询", res);
                if (res.data.data.length > 0) {
                    this.mixMallState = true;
                    this.mixMallName = res.data.data[0].name;
                    this.mixMallImage = res.data.data[0].image;
                    resolve(true)
                } else {
                    this.mixMallState = false;
                    resolve(false)
                }
            });
        })
      },
    },
  }