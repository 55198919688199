<template>
  <div class="orderDetails" v-cloak>
    <div class="toAdd" v-if="address.length == 0">
      <div class="toAddL">此商品需要邮寄，您还未完善收件信息</div>
      <div class="toAddR" @click="toNewAddress">立即完善</div>
    </div>
    <div class="address" v-else @click="toAddress">
      <div class="addressL">
        <img class="addressLL" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/gps@2x.png" />
        <div class="addressLR">
          <div class="addressInfo">
            <div class="addressName">{{ address.name }}</div>
            <div class="addressPhone">{{ address.phone }}</div>
          </div>
          <div class="addressAdd">
            {{ address.province }}{{ address.city }}{{ address.county
            }}{{ address.address }}
          </div>
        </div>
      </div>
      <img class="addressR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" />
    </div>
    <div class="order">
      <div class="orderInfo" v-for="(kiditem, kidindex) in chooseList" :key="kidindex">
        <div class="orderInfoL">
          <img
            :src="
              kiditem.levelCoverUrl != ''
                ? kiditem.levelCoverUrl
                : kiditem.productCoverUrl
            "
          />
          <!-- levelCoverUrl -->
        </div>
        <div class="orderInfoR">
          <div class="orderTit">
            {{ kiditem.name }}
          </div>
          <div class="orderType">
            <div class="orderTypeL" v-if="kiditem.levelId != ''">
              <!-- @click='chooseType()' -->
              规格：{{ kiditem.levelName }}
              <!-- <img
                                                                        src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/down@2x.png"
                                                                        alt=""
                                                                      > -->
            </div>
            <!-- <div class="orderTypeR">商品分类</div> -->
          </div>
          <div class="orderNum">
            <div class="orderNumL"><span>¥</span>{{ kiditem.price }}</div>
            <div class="orderNumR">
              <div class="icon" @click="decrease(kidindex)">－</div>
              <!-- <div class="number">{{num}}</div> -->
              <input
                class="number"
                type="number"
                value="kiditem.num"
                v-model="kiditem.num"
                @blur="changeNum($event, kidindex)"
                onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
              />
              <div class="icon" @click="addition(kidindex)">＋</div>
            </div>
          </div>
        </div>
      </div>
      <div class="babyInfo" @click="changeBaby()">
        <div class="babyInfoL">
          <div>宝宝信息</div>
          <div>{{ babyNameList[i].name }}</div>
        </div>
        <img class="babyInfoR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" />
      </div>
      <div class="comment">
        <div class="commentL">备注信息</div>
        <input
          type="text"
          class="commentR"
          placeholder="选填，建议先和商家沟通后填写"
          v-model="remark"
        />
      </div>
    </div>
    <div class="orderMoney">
      <div>
        <span class="orderMoneyNum">共{{ allNum }}件,</span>
        <span class="orderMoneyTxt">合计:</span>
        <span class="orderMoneyY">¥</span>
        <span class="orderMoneys">{{ allMoney }}</span>
      </div>
      <div class="orderMoneyBtn" @click="toOMS">提交订单</div>
    </div>
    <div class="childBg" v-if="showchildList">
      <div class="childBox">
        <div class="childTit">宝宝信息</div>
        <div
          class="childLi"
          v-for="(item, index) in babyNameList"
          @click="
            changeChild(index, item.babyId, item.organizationId, item.name)
          "
        >
          <div :class="index == i ? 'orange' : ''">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="chooseShowbg" v-if='showChoose'>
                                                            <div class="chooseShow">
                                                              <div class="chooseInfo">
                                                                <div class="chooseInfoL">
                                                                  <img :src="level[currentTab].levelCoverUrl" alt="">
                                                                  <div class="chooseInfoNum">
                                                                    <div class="chooseMoney"> <span>¥</span>{{level[currentTab].price}}</div>
                                                                    <div class="originalMoney">¥{{level[currentTab].priceOriginal}}</div>
                                                                  </div>
                                                                </div>
                                                                <img class="chooseInfoR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/close@2x.png" alt="" @click="closeChoose">
                                                              </div>
                                                              <div class="chooseTit">规格</div>
                                                              <div class="chooseList">
                                                                <div :class="currentTab==levelIndex?'chooseItem':'nochooseItem'" v-for='(levelItem,levelIndex) in level' @click="changeTo(levelIndex)">
                                                                  {{levelItem.levelName}}
                                                                </div>
                                                              </div>
                                                              <div class="chooseNum">
                                                                <div class="chooseTit">数量</div>
                                                                <div class="chooseNumR">
                                                                  <div class="icon" @click="decrease">－</div>
                                                                  <div class="number">{{num}}</div>
                                                                  <div class="icon" @click="addition(level[currentTab].num)">＋</div>
                                                                </div>
                                                              </div>
                                                              <div class="bottom" @click="confirm">确定</div>
                                                            </div>
                                                          </div> -->
  </div>
</template>

<script>
import _ from "lodash";
import { Toast, MessageBox } from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
export default {
  name: "orderDetails",
  data() {
    return {
      level: [],
      num: "",
      address: [],
      currentTab: "",
      showChoose: false,
      remark: "",
      babyIdList: [],
      babyNameList: [],
      i: 0,
      showchildList: false,
      product: [],
      chooseList: [],
      allNum: 0,
      allMoney: 0,
      className: "",
      classId: 0,
      externalId: [],
      omsState:true
    };
  },
  async created() {
    await this.$UpdateUserInfo().then(res=>{
      if (this.$route.query.params) {
        this.params = JSON.parse(this.$route.query.params);
      }
      this.chooseList = JSON.parse(storage.get("chooseList"));
      this.babyList = JSON.parse(storage.get("babyList"));
      this.i = 0;
      this.j = 0;
      this.babyList.map(item=>{
        item.schoolList.map(el=>{
            if( el.organizationId == this.chooseList[0].shopNumber){
              this.babyNameList.push({
                babyId:item.babyId,
                name:item.name,
                organizationId : el.organizationId,
                organizationName:el.shortName,
                shortName:el.shortName
              })
            }
        })
      })
      this.babyNameList.map((el,index)=>{
        if(el.babyId == this.babyList[storage.get("i")].babyId){
          this.i = index
          this.babyId = el.babyId;
          this.organizationId = el.organizationId;
          this.name = el.name;
        }
      })
      for (let j = 0; j < this.chooseList.length; j++) {
        this.allNum = this.allNum * 1 + this.chooseList[j].num * 1;
        this.allMoney = this.cal.accAdd(
          this.allMoney,
          this.cal.accMul(this.chooseList[j].num, this.chooseList[j].price)
        );
      }
      if (storage.get("Address")) {
        this.address = JSON.parse(storage.get("Address"));
      } else {
        this.getAddressList();
      }
      console.log('///////////',this.babyNameList,this.i)
    })
    // this.getByOnlyBabyIds();
    // this.getProductInfo();
  },
  methods: {
    //获取商品信息
    // getProductInfo() {
    //   // let that =this
    //   this.$get(api.productInfo, {
    //     productId: this.productId,
    //     sku: this.sku,
    //   }).then((res) => {
    //     console.log('获取商品信息', res);
    //     // this.productInfo = res.data.data
    //     this.level = res.data.data.level
    //     this.$forceUpdate();
    //     console.log(this.level)
    //
    //   });
    // },
    //获取地址列表
    getAddressList() {
      let that = this;
      that
        .$get(api.addressList, {
          isDefault: 1
        })
        .then(res => {
          if (res.data.code == 0) {
            console.log("获取地址列表成功", res);
            if (res.data.data.length != 0) {
              that.address = res.data.data[0];
            }
            // that.list = res.data.data
            // that.$router.push('/address');
          }
        });
      that.$forceUpdate();
    },
    //选择规则
    chooseType(e) {
      if (this.level.length == 0) {
        this.showChoose = false;
      } else {
        this.showChoose = true;
      }
    },
    //规格选择确定
    confirm() {
      this.showChoose = false;
    },
    closeChoose() {
      this.showChoose = false;
    },
    changeTo(e) {
      console.log(e);
      this.currentTab = e;
    },
    //增加
    addition(index) {
      this.chooseList[index].num++;
      this.allNum = 0;
      this.allMoney = 0;
      this.count();
      // if (this.chooseList[index].num >= ) {
      //   Toast('超出当前库存');
      // } else {
      //   this.num++;
      // }
    },
    count() {
      for (let j = 0; j < this.chooseList.length; j++) {
        this.allNum = this.allNum + this.chooseList[j].num;
        this.allMoney = this.cal.accAdd(
          this.allMoney,
          this.cal.accMul(this.chooseList[j].num, this.chooseList[j].price)
        );
      }
    },
    //减少数量
    decrease(index) {
      if (this.chooseList[index].num == 1) {
        Toast("当前数量为1");
      } else {
        this.chooseList[index].num--;
        this.allNum = 0;
        this.allMoney = 0;
        this.count();
      }
    },
    changeNum(event, index) {
      console.log(event.target.value);
      this.chooseList[index].num = event.target.value;
      console.log(index);
      // this.cartUpdate(index)
      this.allNum = 0;
      this.allMoney = 0;
      this.count();
    },
    //修改购物车数量
    // cartUpdate(index) {
    //   this.allNum =0,
    //   this.allMoney =0
    //   this.$post(
    //     api.cartUpdate, {
    //       productId: this.chooseList[index].productId,
    //       shopNumber: this.babyList[this.i].schoolList[0].organizationId,
    //       num: this.chooseList[index].num,
    //       levelId: this.chooseList[index].levelId,
    //     }
    //   ).then((res) => {
    //     if (res.data.code == 0) {
    //       for (let j = 0; j < this.chooseList.length; j++) {
    //         this.allNum = this.allNum + this.chooseList[j].num
    //         this.allMoney = this.allMoney + this.chooseList[j].num * this.chooseList[j].price
    //       }
    //     } else {
    //       // this.num = 1
    //       Toast(res.data.msg)
    //     }
    //     // this.getCartList(1);
    //   });
    //   // this.$forceUpdate();
    // },
    //修改孩子
    changeBaby(e) {
      this.showchildList = true;
    },
    //获取孩子信息
    getByOnlyBabyIds() {
      console.log(this.babyId);
      this.babyIdList.push(this.babyId);
      this.$post(api.getByOnlyBabyIds, {
        babyIds: this.babyIdList
      }).then(res => {
        if (res.data.data.length == 0) {
          this.classId = -2;
          this.toAddPayOrder();
        } else {
          if (res.data.data[0].externalId == null) {
            this.classId = -1;
            this.toAddPayOrder();
          } else {
            this.external = res.data.data[0].externalId;
            this.externalId.push(res.data.data[0].externalId);
            this.organization = res.data.data[0].externalOrganizationId;
            this.queryKid();
          }
        }
        // this.getProductInfo();
      });
    },
    queryKid() {
      this.$post(api.ListPage, {
        organizationId: this.organization,
        babyId: this.externalId
      }).then(res => {
        if (res.data.code == 0) {
          this.badyId = res.data.data.badyId;
          if (
            res.data.data.length != 0 &&
            res.data.data[0].classList.length != 0
          ) {
            this.classId = res.data.data[0].classList[0].organizationId;
            this.className = res.data.data[0].classList[0].name;
          } else {
            this.classId = 0;
            this.className = "";
          }
          this.toAddPayOrder();
        } else {
          Toast(res.data.msg);
        }
        // this.getCartList(1);
      });
    },
    //切换孩子
    changeChild(index, babyId, organizationId, name) {
      this.babyId = babyId;
      this.name = name;
      this.organizationId = organizationId;
      this.showchildList = false;
      this.i = index;
      storage.set("i", this.i);
    },
    //跳转到订单管理
    toOMS:_.throttle(function(){
      if (this.address.length == 0) {
        MessageBox.alert("当前商品有邮寄服务，请完善地址信息").then(action => {
          console.log(action, "21134");
        });
      } else {
        if(!this.omsState){
          return false
        }
        this.omsState = false
        this.getByOnlyBabyIds();
      }
      // this.$router.push('/OMS');
    },4000),
    //跳转到增加地址
    toNewAddress() {
      this.$router.push("/NewAddress");
    },
    toAddress() {
      //  this.$router.push('/NewAddress');
      this.$router.push({
        path: "/addressList",
        query: {
          type: "choose"
        }
      });
    },
    //提交订单
    toAddPayOrder() {
      console.log(this.name, this.externa);
      let bodyInfo = this.babyNameList[this.i];
      this.product = [];
      console.log(this.organizationId);
      for (let j = 0; j < this.chooseList.length; j++) {
        let product = {
          productId: this.chooseList[j].productId,
          levelId: this.chooseList[j].levelId,
          num: this.chooseList[j].num,
          babyName: this.name,
          babyId: this.babyId,
          organizationId:this.babyNameList[this.i].organizationId,
          organizationName:this.babyNameList[this.i].shortName,
          delivery:this.chooseList[j].delivery
        };
        this.product.push(product);
      }
      let url = "";
      if (this.params) {
        url = "/qimeng-web/family/index.html#/paySuccess";
        // 测试环境没有路径
        if (process.env.NODE_ENV == "none") {
          url = "/index.html#/paySuccess";
        }
      } else {
        url = "/qimeng-web/family/index.html#/mall/orderList";
        if (process.env.NODE_ENV == "none") {
          url = "/index.html#/mall/orderList";
        }
      }

      // url = "/qimeng-web/family/index.html#/mall/wxaqrcode";
      this.$post(api.addPayOrder, {
        product: this.product,
        classId: this.classId,
        className: this.className,
        note: this.remark,
        topupType: "0602",//临时将0302换成0602
        shopNumber: this.chooseList[0].shopNumber,
        consigneeAddress:
          this.address.province +
          this.address.city +
          this.address.county +
          this.address.address,
        consigneeName: this.address.name,
        consigneePhone: this.address.phone,
        openId: localStorage.getItem("openId"),
        frontUrl: process.env.HOST_URL + url
      }).then(res => {
        console.log(res);
        if (res.data.code == 0) {
          Toast("提交成功");
          this.omsState = true
          storage.set("orderId", res.data.data.orderId);
          window.location.href = res.data.data.payCode;
          // this.badyId = res.data.data.badyId
          // if (res.data.data.classList != []) {
          //   this.classId = res.data.data.classList[0].organizationId
          // } else {
          //   this.classId = 0
          // }
          // this.toAddPayOrder();
        } else {
          Toast(res.data.msg);
        }
        // this.getCartList(1);
      });
    }
  }
};
</script>

<style scoped>
.toAdd {
  margin: 15px 0;
  background-color: #ffffff;
  padding: 11px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.toAddL {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b9b9b9;
}
.toAddR {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #dadada;
  padding: 4px 8px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.address {
  margin: 15px 0;
  background-color: #ffffff;
  padding: 11px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addressL {
  display: flex;
  align-items: center;
}
.addressLL {
  width: 20px;
  height: 25px;
  margin-right: 12px;
}
.addressInfo {
  display: flex;
}
.addressName {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}
.addressPhone {
  font-size: 13x;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b9b9b9;
  margin-left: 10px;
}
.addressAdd {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
  margin-top: 3px;
}
.addressR {
  width: 10px;
  height: 14px;
}
.order {
  background-color: #ffffff;
  padding: 20px;
}
.orderTit {
  margin-bottom: 10px;
  width: 100%;
}
.orderInfo {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  /* background-color: red; */
}
.orderInfoL {
  width: 30%;
  width: 80px;
  height: 80px;
  margin-right: 15px;
  /* background-color: grey; */
}
.orderInfoL img {
  width: 80px;
  height: 80px;
  margin-right: 15px;
  /* background-color: grey; */
}
.orderInfoR {
  width: 70%;
}
.orderType {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
}
.orderTypeL {
  font-size: 13px;
  color: #8d8d8d;
  background: #f1f1f1;
  border-radius: 15px;
  padding: 2px 7px;
}
.orderTypeL img {
  width: 7px;
  height: 7px;
  margin-left: 6px;
}
.orderNum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
}
.orderNumL {
  font-size: 19px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #f85511;
  letter-spacing: 1px;
}
.orderNumL span {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f85511;
}
.orderNumR {
  display: flex;
  height: 25px;
  align-items: center;
  font-size: 13px;
}
.orderNumR div {
  border: 1px solid #cdcdcd;
  height: 19px;
  text-align: center;
  line-height: 19px;
  margin: 0;
}
.icon {
  width: 19px;
  margin: 0 2px;
  color: #d8d8d8;
  font-size: 18px;
  font-weight: 600;
}
.number {
  border: 1px solid #cdcdcd;
  text-align: center;
  padding: 0;
  width: 30px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.babyInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
  margin-top: 25px;
}
.babyInfoL {
  display: flex;
  align-items: center;
}
.babyInfoL div:first-child {
  margin-right: 15px;
}
.babyInfoR {
  width: 10px;
  height: 14px;
}
.comment {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
  margin: 30px 0;
  justify-content: flex-start;
}
.commentL {
  margin-right: 15px;
}
.comment input {
  min-width: 200px;
}
.orderMoney {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
}
.orderMoneyNum {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b9b9b9;
}
.orderMoneyTxt {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.orderMoneyY {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f85511;
}
.orderMoneys {
  font-size: 19px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #f85511;
  letter-spacing: 1px;
}
.orderMoneyBtn {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  padding: 10px 15px;
  margin-left: 10px;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
}
.chooseShowbg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.59);
}
.chooseShow {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 13px 22px;
  border-radius: 10px 10px 0px 0px;
}
.chooseInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.chooseInfoL {
  display: flex;
  align-items: flex-end;
  padding-top: 7px;
}
.chooseInfoL img {
  width: 70px;
  height: 70px;
  /* background-color: darkgray; */
  margin-right: 15px;
}
.chooseMoney {
  font-size: 19px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff5001;
  letter-spacing: 1px;
  margin-bottom: 2px;
}
.chooseMoney span {
  font-size: 12px;
  font-weight: 400;
}
.originalMoney {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ababab;
  text-decoration: line-through;
}
.chooseInfoR {
  width: 20px;
  height: 20px;
}
.chooseTit {
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.chooseList {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  padding: 15px 0;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
}
.chooseItem {
  padding: 6px 12px;
  background: #fef8f9;
  border-radius: 5px;
  margin-right: 20px;
  border: 1px solid #f85511;
  color: #ff5001;
}
.nochooseItem {
  padding: 6px 12px;
  background: #fef8f9;
  border-radius: 5px;
  margin-right: 20px;
  color: #2f2f2f;
  background: #f1f1f1;
}
.chooseNum {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chooseNumR {
  display: flex;
}
.chooseNumR div {
  background-color: #f2f2f2;
  text-align: center;
  height: 22px;
  line-height: 22px;
}
.icon {
  width: 22px;
  margin: 0 2px;
  color: #d8d8d8;
  font-size: 18px;
  font-weight: 600;
}
.number {
  /* padding: 0 12px; */
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.bottom {
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  text-align: center;
  color: #ffffff;
  margin-top: 120px;
}
.childBg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.59);
}
.childBox {
  background-color: #ffffff;
  margin: 0 auto;
  width: 70%;
  /* margin-left: 15%; */
  border-radius: 10px;
  text-align: center;
  padding: 20px 27px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.childTit {
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  padding-bottom: 15px;
}
.childLi {
  font-size: 15px;
  padding: 10px 0;
  color: #000000;
  line-height: 42px;
  border-bottom: 1px solid #e9e9e9;
}
.orange {
  color: #f85511;
}
.childLi:last-child {
  border: 0;
}
</style>
