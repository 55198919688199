<template>
  <div class="say_inner">
    <img class="sayBg" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/say/bg@2x.png" alt="">
    <div class="say">
      <!-- <div class='back'>
                                              <a id="back" href="javascript:history.back();" rel="external nofollow"> <img src="../image/login/back.png" alt=""></a>
                                              <div style='margin-left:-18px'>字正腔圆</div>
                                              <div style="width: 5px;height: 5px;"></div>
                                          </div> -->
      <div class="top">
        <div class="topL">请跟读</div>
        <div class="topR"><span>{{num+1}}</span>/{{questionsNum}}</div>
      </div>
      <div class="tit">
        <div>{{voiceList.title}}</div>
        <div @click='playAudio'>
          <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/say/voice@2x.png" alt="">
        </div>
      </div>
      <div class="content">
        <div class="contentBut" v-if="score.length==0">
          <div class="gif">
            <div v-show='isShow'>
              <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/say/gif.gif" alt="">
              <div>正在聆听...</div>
            </div>
          </div>
          <div class="contentAudio">
            <div @touchstart.stop.prevent="gtouchstart()" @touchend.stop.prevent="gtouchend()">
              <img v-show="!isShow" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/say/say.png" alt="">
              <img v-show="isShow" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/say/say_active.png" alt="">
            </div>
          </div>
        </div>
        <div class='contentBut' v-else>
          <div class='contentGrade'>
            <div class='contentGradeL'>
              <div class="contentNum">{{score}}</div>
              <div v-if="score>=80">你的发音特别棒</div>
              <div v-else>还需要努力哦～</div>
            </div>
            <div class='contentGradeR'>分</div>
          </div>
          <div class="contentBitton" v-if="questionsNum == num+1" @click.stop="calculate">提交</div>
          <div class="contentBitton" v-else @click.stop="next">下一题</div>
          <div class="again" @click.stop="againSay">重新跟读</div>
        </div>
      </div>
      <div class="popup" v-if="end">
        <div class="show">
          <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/say/happy@2x.png" alt="">
          <div class="hint">
            <div class="hintTit">平均得分</div>
            <div class="hintTxt" v-if="score>=80">你的发音特别棒</div>
            <div class="hintTxt" v-else>再接再厉哦～</div>
            <div class="hintNum">
              <span>{{score}}</span>分
            </div>
            <div class="hintBut">
              <div class="hintButton" @click='toChoosePart'>完成</div>
              <div class="hintButtons" @click='delReportId'>重做</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import axios from 'axios'
  import wx from 'weixin-js-sdk'
  import http from "@/untils/http";
  import backMix from "@/Mixins/backMix"
  var triggerTime = 0
  var recordTimer = null
  var creatAudio;
  var playState = false
  export default {
    name: 'say',
    inject: [ 'reload' ],
    mixins:[backMix],
    data() {
      return {
        voiceList: [],
        // isPlay: true,
        choose: 0,
        audioSrc: '',
        isShow: false,
        rec: '',
        end: false,
        aaa: '',
        score: '',
        num: 0,
        totalPoints: '',
        questionsNum: '',
      }
    },
    created() {
      var isPageHide = false;
      window.addEventListener('pageshow', function() {
        if (isPageHide) {
          window.location.reload();
        }
      });
      window.addEventListener('pagehide', function() {
        isPageHide = true;
      });
      creatAudio = new Audio();
      document.oncontextmenu = function(e) {
        e.preventDefault();
      };
      this.sectionId = this.$route.query.sectionId;
      this.babyId = this.$route.query.babyId;
      this.classId = this.$route.query.classId;
      this.getAudioList();
    },
    methods: {
      goBack(){
        creatAudio.pause();
        creatAudio.load()
        this.num = 0
        window.history.back();
      },
      //播放示例语音
      playAudio() {
        creatAudio.src = this.voiceList.answers[0].content;
        if(playState){
          creatAudio.pause()
          playState=false;
        }else{
          creatAudio.play()
          playState=true
        }
      },
      getAudioList() {
        let that = this
        that.$get(api.sectionInfo, {
          sectionId: that.sectionId,
          babyId: that.babyId,
        }).then(res => {
          console.log(res, res.data.data.lessonId)
          that.lessonId = res.data.data.lessonId
          that.paperId = res.data.data.objectList[0].paperId
          that.reportId = res.data.data.reportId
          // that.getPaperInfo()
          if (that.reportId.length != 0 && that.reportId != 0) {
            console.log('111')
            that.reportInfo()
            that.end = true
          } else {
            console.log('222')
            that.getPaperInfo()
          }
        })
      },
      getPaperInfo() {
        let that = this
        that.$get(api.paperInfo, {
          paperId: that.paperId,
        }).then(res => {
          that.getwxabout();
          console.log('-------', res)
          that.subjects = res.data.data.subjects
          // console.log(that.subjects)
          // console.log(res.data.data.subjects[0].questions[that.num].answers, res.data.data.subjects[0].questions[that.num].answers.length)
          that.questionsNum = res.data.data.subjects[0].questions.length
          // that.gameabout = res.data.data.subjects[0].questions[that.num].type
          that.voiceList = res.data.data.subjects[0].questions[that.num]
          console.log('6789', that.questionsNum)
          // that.gameTit = res.data.data.subjects[0].questions[that.num].title
          // console.log('获取试题', that.gameList, that.gameabout)
        })
      },
      gtouchstart() {
        console.log('1111')
        let that = this
        that.isShow = true
        triggerTime = Date.parse(new Date())
        wx.ready(() => {
          console.log('67890')
          recordTimer = setTimeout(function() {
            wx.startRecord({
              success: function(e) {
                console.log(e, that.isShow)
              },
              cancel: function(err) {
                console.log(err)
              }
            })
          }, 0);
        })
      },
      gtouchend() {
        let that = this
        let  interval = Date.parse(new Date()) - triggerTime
        if( (interval < 2000) || (triggerTime < 10000)){
            that.isShow = false
            that.audioUrl = ''
            that.aaa = ''
            that.score = ''
            triggerTime = 0
            try {
              clearTimeout(recordTimer)
            } catch (error) {
              recordTimer = null
            }
            return Toast({
              message: "录音时间太短", //弹窗内容
              position: "middle", //弹窗位置
              duration: 2000, //弹窗时间毫秒,如果值为-1，则不会消失
              iconClass: "iconfont icon-gantanhao", //设置 图标类
              className: "toastInfo" //自定义Toast 样式，需要自己提供一个类名
            });
        }else{
          wx.ready(() => {
            wx.stopRecord({
              success: function(res) {
                that.isShow = false
                if( interval < 2000 ){
                  that.audioUrl = ''
                  that.aaa = ''
                  that.score = ''
                  triggerTime = 0
                  return Toast({
                    message: "录音时间太短", //弹窗内容
                    position: "middle", //弹窗位置
                    duration: 2000, //弹窗时间毫秒,如果值为-1，则不会消失
                    iconClass: "iconfont icon-gantanhao", //设置 图标类
                    className: "toastInfo" //自定义Toast 样式，需要自己提供一个类名
                  });
                }
                triggerTime = 0
                that.audioUrl = res.localId
                console.log(res, res.localId, that.audioUrl)
                wx.uploadVoice({
                  localId: res.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
                  isShowProgressTips: 1, // 默认为1，显示进度提示
                  success: function(res) {
                    console.log(res)
                    that.aaa = res.serverId
                    that.$get(api.getAccessToken, {
                      wechatAppId: 'wxae80bb1fd4a0d45b'
                    }).then(rs => {
                      that.token = rs.data.data
                      let amrurl = 'https://file.api.weixin.qq.com/cgi-bin/media/get?access_token=' + that.token + '&media_id=' + res.serverId
                      let text = that.voiceList.title
                      console.log(text)
                      let formData = new FormData()
                      formData.append('weixinAudio', amrurl)
                      formData.append('text', text)
                      axios.post(api.soundIse, formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime(),
                          // "Authorization": that.userToken
                        },
                      })
                        .then((res) => {
                          console.log('语音测评', res)
                          if (res.data.code == 0) {
                            that.score = Math.ceil(res.data.data.totalScore)
                            console.log(that.score)
                          } else {
                            that.$toast(res.data.msg);
                          }
                        })
                    });
                  }
                });
              },
              fail: function(res) {
                that.isShow = false
                that.audioUrl = ''
                that.aaa = ''
                that.score = ''
                triggerTime = 0
                try {
                  clearTimeout(recordTimer)
                } catch (error) {
                  recordTimer = null
                }
                console.log(JSON.stringify(res))
              }
            })
          })
        }
        // console.log(that.audioUrl)
      },
      againSay(){
        //  this.totalPoints = this.totalPoints * 1 + this.score * 1
        this.score = ''
        // this.num = this.num + 1
        console.log(this.one, this.num)
        this.getPaperInfo();
      },
      //下一题
      next() {
        this.baclk = 'red'
        this.totalPoints = this.totalPoints * 1 + this.score * 1
        this.score = ''
        this.num = this.num + 1
        console.log(this.one, this.num)
        this.getPaperInfo();
      },
      //提交
      calculate() {
        this.totalPoints = this.totalPoints * 1 + this.score * 1
        let that = this
        console.log(that.totalPoints, that.questionsNum)
        that.score = Math.ceil(that.totalPoints / that.questionsNum)
        // that.$toast(that.totalPoints);
        console.log(that.score)
        that.end = that
        that.$post(api.saveForUser, {
          paperId: that.paperId,
          subjects: that.subjects,
          score: that.score,
          userId: that.babyId
        }).then(res => {
          console.log('获取成功', res)
          console.log(res.data.data.reportId)
          that.reportId= res.data.data.reportId
          that.$post(api.evaluate, {
            reportId: that.reportId,
            classId: that.classId,
            score: that.score,
            babyId: that.babyId,
            sectionId: that.sectionId,
          }).then(res => {
            console.log('保存reportId成功', res)
          })
        })
      },
      //获取分数
      reportInfo() {
        let that = this
        that.$get(api.reportInfo, {
          id: that.reportId,
        }).then(res => {
          console.log('获取成功分数', res)
          that.score = res.data.data.score
        })
      },
      //完成返回环节
      toChoosePart() {
        // window.location.href = '../pages/choosePart.html?lessonId=' + this.lessonId
        this.$router.go(-1);
      },
      getwxabout() {
        console.log('  console.log(getwxabout)')
        this.$get(api.getAccessToken, {
          wechatAppId: 'wxae80bb1fd4a0d45b'
        }).then(res => {
          this.token = res.data.data
          var u = navigator.userAgent;
          var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
          var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          let url = ''
          if (isAndroid) {
            url = window.location.href
          }
          if (isIOS) {
            url = window.location.href.split('#')[0] //hash后面的部分如果带上ios中config会不对
          }
          this.$post(api.getJsapiTicket, {
            wechatAppId: 'wxae80bb1fd4a0d45b',
            token: res.data.data,
            url: url
          }).then(res => {
            console.log(res)
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: 'wxae80bb1fd4a0d45b', // 必填，公众号的唯一标识
              timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
              signature: res.data.data.signature, // 必填，签名，见附录1
              jsApiList: ["startRecord", "stopRecord", 'onVoiceRecordEnd', 'uploadVoice', 'downloadVoice', 'translateVoice'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });
            wx.ready(() => {
              console.log('67890')
              wx.onVoiceRecordEnd({
                // 录音时间超过一分钟没有停止的时候会执行 complete 回调
                complete: function(res) {
                  console.log('111')
                }
              })
            })
          })
        })
      },
      gotouchstart(e) {
        console.log('点击开始', e)
        this.isShow = true
      },
      gotouchend(e) {
        console.log(e)
      },
      gotouchmove(e) {
        console.log('点击取消', e)
        this.isShow = false
      },
      clickAudio(e) {
        creatAudio.src = this.voiceList[e].src
        for (var i = 0; i < this.voiceList.length; i++) {
          if (e == i) {
            if (this.voiceList[i].play) {
              this.voiceList[i].play = false
              creatAudio.pause();
              creatAudio.load();
            } else {
              this.voiceList[i].play = true
              creatAudio.play()
            }
          } else {
            this.voiceList[i].play = false
          }
        }
      },
      //删除答题报告重新做
      delReportId() {
        console.log(this.reportId)
        this.$delete(api.reportDel, {
          reportId: this.reportId
        }).then((res) => {
          console.log(res)
          if (res.data.code == 0) {
            this.end = false
            // location.reload();
            this.reload();
            //  this.getAudioList();
          } else {
            Toast(res.data.msg);
          }
          this.$forceUpdate();
        });
      }
    }
  }
</script>

<style scoped>
  body {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  img {
    pointer-events: none;
  }
  .sayBg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/say/bg@2x.png) no-repeat;
    background-size: 100% 100%;
    z-index: 99;
  }
  .say {
    position: relative;
    z-index: 100;
  }
  .top {
    margin: 20px 0;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 0 20px;
  }
  .topL {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(36, 168, 251, 1);
  }
  .topR {
    font-size: 14px;
    margin-left: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(134, 134, 134, 1);
  }
  .topR span {
    font-size: 18px;
    color: rgba(36, 168, 251, 1);
  }
  .tit {
    margin: 0 20px;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(46, 47, 50, 1);
    padding: 25px 20px;
    word-wrap: break-word;
    justify-content: space-between;
    white-space: norma;
    display: flex;
    align-items: center;
  }
  .tit img {
    margin-left: 14px;
    width: 21px;
    height: 21px;
  }
  .content {
    /* background-color: aquamarine; */
    margin-top: 15%;
  }
  .contentBut {
    /* background-color: blanchedalmond; */
  }
  .gif {
    width: 100%;
    height: 100px;
    text-align: center;
    margin-bottom: 10%;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(36, 168, 251, 1);
  }
  .gif img {
    width: 100px;
    height: 30px;
  }
  .contentAudio {
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    width: 168px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(36, 168, 251, 1);
  }
  .contentAudio img {
    width: 168px;
    height: 68px;
    /* background: url(../image/say/aduio@2x.png) no-repeat;
                                background-size: 100% 100%; */
    margin-bottom: 13px;
  }
  .contentGrade {
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    display: flex;
    margin-bottom: 40px;
  }
  .contentGradeL {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(46, 47, 50, 1);
  }
  .contentNum {
    font-size: 85px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(36, 168, 251, 1);
  }
  .contentGradeR {
    margin-bottom: 20px;
    color: rgba(36, 168, 251, 1);
  }
  .contentBitton {
    cursor: pointer;
    width: 85%;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgba(36, 168, 251, 1);
    border-radius: 20px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(255, 255, 255, 1);
  }
  .again{
    width: 85%;
    margin: 20px auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #FB7A17;
    border-radius: 20px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(255, 255, 255, 1);
  }
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.59);
    padding: 50px;
  }
  .show {}
  .show img {
    height: 155px;
    width: 100%;
  }
  .hint {
    margin-top: -16px;
    width: 100%;
    height: 270px;
    background: rgba(255, 255, 255, 1);
    border-radius: 19px 19px 50px 50px;
    text-align: center;
  }
  .hintTit {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: rgba(36, 168, 251, 1);
    padding: 10px 0;
  }
  .hintTxt {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(195, 208, 226, 1);
  }
  .hintNum {
    width: 206px;
    height: 105px;
    background: rgba(232, 248, 255, 1);
    border-radius: 15px;
    margin: 10px auto;
    line-height: 105px;
    text-align: center;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(36, 168, 251, 1);
  }
  .hintNum span {
    font-size: 50px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: rgba(36, 168, 251, 1);
  }
  .hintBut {
    margin-top: 40px;
  }
  .hintButton {
    width: 162px;
    height: 49px;
    background: rgba(36, 168, 251, 1);
    border-radius: 25px;
    font: size 18px;
    margin: 0 auto;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(255, 255, 255, 1);
    line-height: 49px;
  }
  .hintButtons {
    width: 162px;
    height: 49px;
    background: rgba(251, 122, 23, 1);
    border-radius: 25px;
    font: size 18px;
    margin: 0 auto;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(255, 255, 255, 1);
    line-height: 49px;
    margin-top: 15px;
  }
</style>
<style>
.toastInfo i{
    font-size: 50px !important;
  }
</style>
