<template>
    <div class="layout-waper">
        <router-view></router-view>
        <tabbar :tabkey="key"></tabbar>
      </div>
</template>
<script>
    import Layout from "./Layout.js";
    export default Layout;
</script>
<style scoped>
    @import './Layout.css';
</style>