<template>
  <div class="chooseClass">
    <div class="waper_header">
      <div class="header-waper">
        <div class="header-main">
          <div class="info">
            <div class="select">
              {{ `${babyName}(${organizationName})` }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="requestLoad">
      <div v-if="mixExerciseState">
        <div class="kidList">
          <img class="kidListBanner" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/chooseClass/kidbanner.png" alt="">
          <div class="kidListL">
            <div class="kidListName">{{babyName}},你好</div>
            <!-- <img class="kidListImg" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/chooseClass/cut@2x.png" alt="" v-if='babyList.length>1' @click='changeBaby'> -->
          </div>
          <!-- <div class="kidListR" @click='toSetup'>
                <img class="kidListRImg" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/chooseClass/set@2x.png" alt="">
                <div class="kidListRName">设置</div>
              </div> -->
        </div>
        <div class="details" v-if='list.length!=0'>
          <!-- <div class="newClass" v-if='newClassList.length!=0'>
            <div class="headline">最新课程</div>
            <div class="classList">
              <div class="classLi" v-for='(item,index) in newClassList' @click='toChoosePart(item.lessonId,item.courseId,item.classId)'>
                <div class="classLiL">
                  <div class="evaluate" v-if='item.evaluate'>已评价</div>
                  <img :src="item.coverImage" alt="">
                </div>
                <div class="classLiR">
                  <div class="classLiRTit">{{item.name}}</div>
                  <div class="classLiRTxt">
                    <span>{{item.courseName}}</span>
                    <span>{{item.levelName}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="courseList">
            <!-- <div class="headline">已学课程</div> -->
            <div class="courseTit">
              <div class="courseTitLi" v-for="(item,index) in list" :key="index">
                <div :class="currentTab==index?'choose':'nochoose' " @click='chooseCourse(index)'>
                  <div>{{item.levelName}}</div>
                </div>
              </div>
            </div>
            <div class="classList">
              <div v-if='CourseClassList.length !=0'>
                <div class="classLi" v-for='(items,indexs) in CourseClassList' @click='toChoosePart(items.lessonId,items.courseId,items.classId)' :key="indexs">
                  <div class="classLiL">
                    <div class="evaluate" v-if='items.evaluate'>已评价</div>
                    <img name='dynamicImg' :src="items.coverImage+imgSizeQuality" alt="">
                  </div>
                  <div class="classLiR">
                    <div class="classLiRTit">{{items.name}}</div>
                    <div class="classLiRTxt">
                      <span>{{items.courseName}}</span>
                      <!-- <span>{{items.levelName}}</span> -->
                    </div>
                    <!-- <div class="classLiRBut">待练习</div> -->
                  </div>
                </div>
              </div>
              <div v-else class="reminders">
                <not-found
                notfound="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/curriculum/log.png"
                tip="暂无课后练习"
                ></not-found>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="reminder">
          <not-found
          notfound="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/curriculum/progress.png"
          tip="暂无课程"
          ></not-found>
        </div>
      </div>
      <div v-else>
        <not-found></not-found>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Indicator,
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import http from "@/untils/http";
  import storage from "good-storage";
  import tabbar from "@/components/nav/tabbar/tabbar.vue"
  import headerSelect from "@/components/select/header/header.vue"
  import notFound from "@/components/notfound/notfound.vue";
  import exerciseMix from "@/Mixins/exerciseMix"
  import dynamicImgwh from "@/Mixins/dynamicImgwh"
  export default {
    name: 'chooseClass',
    mixins: [exerciseMix,dynamicImgwh],
    components:{
      tabbar,
      "headers-select":headerSelect,
      "not-found":notFound
    },
    data() {
      return {
        currentTab: 0,
        // chooseList: [],
        list: [],
        // userToken: '',
        // babyList: [],
        // i: 0,
        classIdList: '',
        newClassList: [],
        CourseClassList: [],
        // headerSelect:false,
        requestLoad:false
      }
    },
    async created(e) {
      console.log('++++++++', this.$route.query);
      const {appId, unionId, babyId, babyName, organizationId, organizationName, classId, className, courseId, courseName, levelId, levelName} = this.$route.query;
      this.babyId = babyId;
      this.babyName = babyName;
      this.organizationId = organizationId;
      this.organizationName = organizationName;
      this.classId = classId;
      this.className = className;
      this.courseId = courseId;
      this.courseName = courseName;
      this.levelId = levelId;
      this.levelName = levelName;
      await this.$get(api.Authorization, {appId}).then(res => {
        storage.set("userToken_user", "Bearer " + res.data.data);
      });
      await this.$post(api.weixin_openId_refresh, {
        openId: unionId,
        type: 2,
        appId
      }).then(res => {
        if (res.data.code == 0) {
          const {userToken, userRefreshToken} = res.data.data;
          storage.set(
            "userToken_user",
            "Bearer " + userToken
          );
          storage.set(
            "userRefreshToken_user",
            userRefreshToken
          );
        }
      });
      // await this.$UpdateUserInfo().then(res=>{
      //   this.headerSelect = true
      //   this.babyList = JSON.parse(storage.get("babyList"))
      // })
      // 初始化数据
      await this.initData()
    },
    methods: {
      initData(){
        this.requestLoad = false
        Indicator.open("加载中...");
        const that = this
        // 检测并设置家长数据
        // this.setFamilyInfo(()=>{
        //   that.i = storage.get("i")
        //   that.j = storage.get("j")
        // }).then(()=>{
          //园所开通课程查询
          that.getSchoolCourse(this.organizationId).then(state=>{
            // 课程已开通
            if(state){
              // 拼接班级并获取课后练习列表
              // this.getAbout();
              this.getCourseList();
            }else{
              this.requestLoad = true
              Indicator.close();
            }
          }).catch(err=>{
            this.requestLoad = true
            Indicator.close();
          })
        // })
      },
      // toSetup() {
      //   location.href = '../pages/setup.html'
      // },
      //切换孩子
      // changeBaby() {
      //   this.headerSelect = false
      //   if (this.i == this.babyList.length - 1) {
      //     this.i = 0;
      //   } else {
      //     this.i++;
      //   }
      //   storage.set('i',this.i)
      //   this.getAbout();

      // },

      //拼接班级
      // getAbout() {
      //   // this.schoolId = this.babyList[this.i].schoolList[this.j].organizationId
      //   // this.babyId = this.babyList[this.i].babyId
      //   this.$get(api.getDepartmentByBabyId,{
      //     babyId:this.babyId,
      //     schoolId:this.organizationId
      //   }).then(res=>{
      //     console.log(res,'/获取儿童所在班级列表')
      //     if(res.data.data && res.data.data.length > 0){
      //       this.classIdList = res.data.data.map(item=>item.organizationId)
      //     }else{
      //       this.classIdList = []
      //     }
      //     this.getCourseList();
      //     // this.headerSelect = true
      //   })
      //
      //   // this.classId = this.babyList[this.i].schoolList[0].classList[0].organizationId
      //   // if (this.babyList[this.i].schoolList[this.j].classList.length == 0) {
      //   //   this.classIdList = []
      //   // } else {
      //   //   for (let j = 0; j < this.babyList[this.i].schoolList[this.j].classList.length; j++) {
      //   //     // this.classIdList.push(this.babyList[this.i].schoolList[0].classList[j].organizationId);
      //   //     if (j == 0) {
      //   //       this.classIdList = this.babyList[this.i].schoolList[this.j].classList[j].organizationId
      //   //     } else {
      //   //       this.classIdList = this.classIdList + ',' + this.babyList[this.i].schoolList[this.j].classList[j].organizationId
      //   //     }
      //   //   }
      //   // }
      //
      // },
      //获取课程列表
      getCourseList() {
        let that = this
        if (!this.classId) {
          that.list = [];
          this.requestLoad = true
          Indicator.close();
        } else {
          that.list = [{
            courseId: this.courseId,
            courseName: this.courseName,
            levelId: this.levelId,
            levelName: this.levelName,
            classId: this.classId,
            className: this.className,
            organizationId: this.organizationId,

          }];
          that.chooseCourse(0);
        }

        // if (that.classIdList.length == 0) {
        //   that.list = [];
        //   this.requestLoad = true
        //   Indicator.close();
        //   // that.newClassList = [];
        // } else {
        //   console.log(api.courseList)
        //   that.$post(api.courseList, {
        //     classId: that.classIdList,
        //     organizationId: that.organizationId
        //   }).then(res => {
        //     if (res.data.code == 0) {
        //       that.list = res.data.data
        //       // 如果在当前位置没有课程列表"提示暂无课程"
        //       console.log('获取课程列表', that.list);
        //       let exerciseTab = storage.get('exerciseTab')
        //       if(exerciseTab){
        //         if(that.list.filter(item=>item.id == exerciseTab.id).length == 0 ) exerciseTab = {id:that.list[0].id,index:0};
        //       }else{
        //         exerciseTab = {id:that.list[0].id,index:0}
        //       }
        //       this.$nextTick(()=>{
        //         let offset = document.getElementsByClassName('courseTitLi')[0].clientWidth * exerciseTab.index
        //         document.getElementsByClassName('courseTit')[0].scrollLeft = offset
        //       })
        //       that.chooseCourse(exerciseTab.id,exerciseTab.index);
        //     } else {
        //       this.requestLoad = true
        //       Indicator.close();
        //       that.$toast(res.data.msg);
        //     }
        //   })
        // }
      },
      chooseCourse(e) {
        let that = this
        if (e) {
          this.currentTab = e
        } else {
          this.currentTab = 0
        }
        // storage.set('exerciseTab',{ id:id, index:this.currentTab })
        this.$post(api.lessonList, {
          courseId: this.list[this.currentTab].courseId,
          organizationId: this.list[this.currentTab].organizationId,
          babyId: this.babyId,
          status: '1',
          classId: this.list[this.currentTab].classId,
          levelId: this.list[this.currentTab].levelId,
        }).then(res => {
          // var newarr = res.data.data.slice();
          //           newarr.pop();
          //           this.CourseClassList = newarr
                    this.CourseClassList = res.data.data
                    this.requestLoad = true
                    Indicator.close();
                    this.get_dynamicImgSQ()
                    console.log( this.CourseClassList )
        })
      },
      toChoosePart(lessonId, courseId, classId) {
        this.$router.push({
          path: '/choosePart',
          query: {
            lessonId: lessonId,
            courseId: courseId,
            babyId: this.babyId,
            schoolId: this.organizationId,
            classId: classId
          }
        });
        // window.location.href = '../pages/choosePart.html?lessonId=' + lessonId + '&courseId=' + courseId + '&babyId=' + this.babyList[this.i].babyId + '&schoolId=' + this.babyList[this.i].schoolList[0].organizationId + '&classId=' + classId
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import '../components/select/header/header.css';
  body {
    background: #FBFBFB
  }
  .waper_header{
    margin-top: 92px;
  }
  .chooseClass {
    padding: 20px;
  }
  .kidList {
    position: relative;
    /* z-index: 999; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 2px;
    /* z-index: 999; */
    /* height: 45px; */
  }
  .kidListL {
    display: flex;
    align-items: center;
    z-index: 999;
  }
  .kidListR {
    display: flex;
    align-items: center;
    z-index: 999;
  }
  .kidListBanner {
    /* background-color: red; */
    z-index: 10;
    width: 100%;
    position: absolute;
    z-index: 9;
    height: 50px;
  }
  .kidListName {
    padding-left: 15px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #161616;
  }
  .kidListImg {
    width: 16px;
    height: 14px;
    z-index: 999;
    margin: 8px 15px;
  }
  .kidListRImg {
    width: 16px;
    height: 16px;
  }
  .kidListRName {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2DA0FB;
    padding-left: 8px;
    padding-right: 15px;
  }
  .details {
    margin-top: 30px;
  }
  .headline {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #333333;
    line-height: 25px;
  }
  .courseTit {
    display: flex;
    margin: 10px 10px;
    align-items: flex-end;
     width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  .courseTit::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .courseTitLi {
    margin-right: 13px;
  }
  .choose {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    background: #2DA0FB;
    border-radius: 30px;
    padding: 5px 8px
  }
  .nochoose {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    background: #FFFFFF;
    border-radius: 30px;
    padding: 5px 8px
  }
  /* .haveline {
                      width: 30px;
                      height: 3px;
                      background: rgba(36, 168, 251, 1);
                      border-radius: 3px;
                      margin: 3px auto;
                  }
                  .noline {
                      width: 30px;
                      height: 3px;
                      background: rgba(251, 251, 251, 1);
                      border-radius: 3px;
                      margin: 3px auto;
                  } */
  .classList {
    margin: 15px 0;
  }
  .classLi {
    display: flex;
    /* align-items: center; */
    background: #FFFFFF;
    /* width: 100%; */
    /* justify-content: space-between; */
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
  }
  .classLiL {
    position: relative;
    width: 30%;
  }
  .classLiL img {
    /* background-color: #959595; */
    /* width: 105px; */
    width: 100%;
    min-height: 80px;
    /* height: 80px; */
  }
  .evaluate {
    position: absolute;
    width: 40px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background: #FE753C;
    font-size: 8px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #FFFFFF;
    right: -10px;
    top: -15px;
    border-radius: 10px 10px 10px 0;
  }
  .classLiR {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* align-items: center; */
    width: 70%;
    margin-left: 14px;
  }
  .classLiRTit {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #000000;
    line-height: 22px;
    margin-bottom: 7px;
    /* width: inherit; */
    display: block;
    white-space: normal;
  }
  .classLiRTxt {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #48B8FF;
    margin-bottom: 7px;
  }
  .classLiRTxt span {
    background: #ECF7FE;
    border-radius: 12px;
    padding: 5px;
    margin-right: 10px;
  }
  .classLiRBut {
    width: 50px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin: 3px 0px 0px 10px;
    background: #2DA0FB;
    border-radius: 15px;
    font-size: 11px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #FFFFFF;
    align-self: flex-end
  }
  .reminder {
    /* margin-top: 50%;
    font-size: 18px;
    text-align: center; */
  }
  .reminders {
    /* padding: 44px; */
    /* font-size: 15px; */
    /* font-family: PingFangSC-Regular, PingFang SC; */
    /* text-align: center; */
    background: #FFFFFF;
    color: #B4B4B4;
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
  }
</style>
