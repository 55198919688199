<template>
  <div class="appraisalList">
    <div class="waper_header">
      <div class="header-waper">
        <div class="header-main">
          <div class="info">
            <div class="select">
              {{ `${babyName}(${organizationName})` }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top">
      <div>宝宝姓名：{{ babyName }}</div>
      <div>出生日期：{{ birthday }}</div>
    </div>

    <div v-if="requestLoad">
      <div class="list" v-if="list.length != 0">
        <div
          class="li"
          v-for="(item, index) in list"
          :key="index"
          @click="toAppraisalInfo(item.reportId, item.paperId, item.createTime)"
        >
          <div class="liL">
            <div class="tit">{{ item.paperTitle }}</div>
            <div class="time">测评时间:{{ item.createTime }}</div>
          </div>
          <div class="liR">
            <!-- 临时注销显示分数切换为查看 -->
            <!-- <div class="grade">{{ item.score }}</div> -->
            <div class="see_text">查看</div>
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/next@2x.png" alt="" />
          </div>
        </div>
      </div>
      <div v-else class="donthave">
        <not-found
        notfound="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/valuation_not.png"
        tip="暂无测评记录"
        ></not-found>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast,Indicator } from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
import headerSelect from "@/components/select/header/header.vue";
import notFound from "@/components/notfound/notfound.vue";
import exerciseMix from "@/Mixins/exerciseMix"
export default {
  name: "appraisalList",
  mixins: [exerciseMix],
  components: {
    "headers-select":headerSelect,
    "not-found":notFound
  },
  data() {
    return {
      birthday: "",
      name: "",
      currPage: 1,
      pageSize: 10,
      list: [],
      babyAct:null,
      j:null,
      requestLoad:false
    };
  },
  async created() {
    console.log('++++++++', this.$route.query);
    const {appId, unionId, babyId, babyName, organizationId, organizationName, birthday} = this.$route.query;
    this.babyId = babyId;
    this.babyName = babyName;
    this.organizationId = organizationId;
    this.organizationName = organizationName;
    this.birthday = birthday;
    await this.$get(api.Authorization, {appId}).then(res => {
      storage.set("userToken_user", "Bearer " + res.data.data);
    });
    await this.$post(api.weixin_openId_refresh, {
      openId: unionId,
      type: 2, 
      appId
    }).then(res => {
      if (res.data.code == 0) {
        const {userToken, userRefreshToken} = res.data.data;
        storage.set(
          "userToken_user",
          "Bearer " + userToken
        );
        storage.set(
          "userRefreshToken_user",
          userRefreshToken
        );
      }
    });
    await this.initData()
  },
  methods: {
    initData(){
      this.requestLoad = false
      Indicator.open("加载中...");
      // 检测并设置家长数据
      // this.setFamilyInfo(()=>{
      //   this.babyAct = JSON.parse(storage.get("i"))
      //   this.j = storage.get("j")
      //   let babyList = JSON.parse(storage.get("babyList"))
      //   this.name = babyList[this.babyAct].name
      //   this.birthday = babyList[this.babyAct].birthday
      //   this.organizationId = babyList[this.babyAct].schoolList[this.j].organizationId
      //   this.userId = babyList[this.babyAct].babyId
      // }).then(()=>{
        this.getReportList()
      // })
    },
    getReportList() {
      this.$get(api.ReportList, {
        userId: this.babyId,
        organizationId: this.organizationId,
        pageSize: this.pageSize,
        currPage: this.currPage
      }).then(res => {
        this.list = res.data.data;
        this.requestLoad = true
        Indicator.close();
        console.log("获取测评列表", this.list,this.requestLoad);
        // this.showClass = true
        // this.lessonNum = res.data.data.lesson
      });
    },
    //获取试卷详情
    toAppraisalInfo(reportId, paperId, createTime) {
      console.log(createTime.split(" ")[0]);
      this.$router.push({
        path: "/appraisalInfo",
        query: {
          reportId: reportId,
          paperId: paperId,
          createTime: createTime.split(" ")[0],
          birthday: this.birthday,
          name: this.babyName
        }
      });
    }
  }
};
</script>

<style scoped>
@import '../components/select/header/header.css';
.waper_header{
  margin-top: 92px;
}
.see_text{
  font-size: 14px;
  font-family: FFDINPro-BoldItalic, FFDINPro;
  font-weight: normal;
  color: #ff6b00;
}
.top {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  line-height: 48px;
  height: 48px;
  background: #ffffff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #434345;
}
.list {
  margin: 0 20px;
}
.li {
  background-color: #ffffff;
  padding: 23px 19px;
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
}
.liL {
  width: 80%;
}
.tit {
  margin-bottom: 8px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #19191a;
}
.time {
  font-size: 12px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #67676b;
}
.liR {
  display: flex;
  align-items: center;
}
.grade {
  font-size: 24px;
  font-family: FFDINPro-BoldItalic, FFDINPro;
  font-weight: normal;
  color: #ff6b00;
}
.liR img {
  margin-left: 4px;
  width: 15px;
  height: 15px;
}
.donthave {
  text-align: center;
  margin-top: 100px;
  font-size: 16px;
  color: #0c152f;
}
</style>
