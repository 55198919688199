<template>
  <div class="success">
    <div class="contnet">
      <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/invite/success@2x.png" alt="">
      <div>提交成功</div>
    </div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import http from "@/untils/http";
  import storage from "good-storage";
  export default {
    name: 'success',
    data() {
      return {
        list: [],
      }
    },
    created() {},
    methods: {}
  }
</script>

<style scoped>
  .success {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
  }
  .contnet {
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 25px;
      margin-top: 140px;
    }
    img {
      width: 62px;
      height: 63px;
      margin-bottom: 15px;
    }
</style>
