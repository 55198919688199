<template>
  <div id="app">
    <!-- <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/logo.png"> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive && isRouterAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive && isRouterAlive"></router-view>
  </div>
</template>

<script>
  import Vue from 'vue';
  import storage from "good-storage";
  import axios from 'axios'
  export default {
    name: 'App',
    provide () {
      return {
        reload: this.reload
      }
    },
    data () {
      return {
        isRouterAlive: true
      }
    },
    methods: {
      reload () {
        this.isRouterAlive = false
        this.$nextTick(function () {
          this.isRouterAlive = true
        })
      }
    }
  }

</script>


<style>

  @import './assets/css/iconfont.css';
  body {
    margin: 0;
    padding: 0;
    background: #FAFAFC;

  }
  input {
    background: none;
    outline: none;
    border: none;
  }
  button {
    background-color: transparent;
    border: none;
    outline: none;
  }
  textarea {
    background: none;
    outline: none;
    border: none;
  }
</style>
