<template>
    <!-- <div class="search-inner"
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="loading"
    infinite-scroll-distance="0"> -->
    <div class="search-inner">
        <!-- 顶部搜索功能区 -->
        <div class="header padding-lr-sm">
            <div class="info">
                <popup-select
                :selectlist="selectlist"
                :selectIndex="selectIndex"
                @selectItem="selectItem"
                ></popup-select>
                <div class="search">
                    <input
                        type="text"
                        placeholder="请输入课程名"
                        v-model="keyword"
                        @change="toSearch"
                    />
                    <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/search@2x.png" alt="" />
                </div>
            </div>
        </div>
        <!-- 课程分类 -->
        <div class="curriculumType padding-lr-sm">
            <common-nav
            class="title-tb-sm"
            :list="navs"
            :activeIndex="navIndex"
            @clickItem="navClick"
            ></common-nav>
            <div class="goods">
                <div v-if="goods.length != 0">
                  <older-goods :info='item' v-for="(item, index) in goods" :key="index" @details="getGoodsDetails"></older-goods>
                </div>
                <div class="data-none" v-else>
                  <div class="data-none-img">
                    <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/curriculum/progress.png" alt="">
                  </div>
                  <div>暂无数据</div>
                </div>
            </div>
            <div class="loading">
              <div v-if="pagesNext && goods.length != 0">
                <mt-spinner type="fading-circle"></mt-spinner>
                <div class="tip">加载中</div>
              </div>
              <div class="tip" v-if="!pagesNext && goods.length != 0">已经到底了！</div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from "mint-ui";
import _ from 'lodash'
import * as api from "@/untils/api";
import storage from "good-storage";
import olderGoods from '@/components/goods/olderGoods/olderGoods.vue'
import popupSelect from '@/components/select/popupSelect/popupSelect.vue'
import commonNav from '@/components/nav/commonNav/commonNav.vue'
import scrollMixin from '../../Mixins/scroll'
export default {
    name:'search',
    components:{
        'older-goods':olderGoods,
        'popup-select':popupSelect,
        'common-nav':commonNav
    },
    mixins: [scrollMixin],
    data(){
        return {
            loading:false,
            keyword:'',
            navs:[{
              title:'体验课',
              parmas:{
                isExperience:1
              }
            },{
              title:'单课',
              parmas:{
                type:1
              }
            },{
              title:'套餐课',
              parmas:{
                type:2
              }
            }],
            navIndex:0,
            goods:[],
            pageNumber:1,
            pagesNext:true
        }
    },
    created(){
      const { keyword } = this.$route.query
      this.keyword = keyword
      this.newshopCourse()
    },
    computed:{
      //年龄端数据源
      selectlist(){
        return this.$store.state.ageList
      },
      //第几项年龄段
      selectIndex(){
        return this.$store.state.ageIndex
      }
    },
    methods:{
        toSearch(){
          this.goods = []
          this.pagesNext = true
          this.pageNumber = 1;
          this.newshopCourse()
        },
        selectItem(e) {
          this.$store.commit('saveAgeIndex',e)
          this.toSearch()
        },
        navClick(e){
          if(e == this.navIndex) return false;
          this.goods = []
          this.pagesNext = true
          this.navIndex = e;
          this.pageNumber = 1;
          this.newshopCourse()
        },
        // 上拉加载更多
        onReachBottom() {
          this.loading = true;
          setTimeout(() => {
            console.log('上拉加载更多')
            // 调用接口
            this.newshopCourse()
            this.loading = false;
          }, 2500);
        },
        // 获取课程
        newshopCourse(){
          const that = this
          if(!this.pagesNext) return false;
          let params = Object.assign(that.navs[that.navIndex].parmas,{
            status:1,
            shopId:that.$store.state.shopInfo.shopId,
            ageId:that.selectlist[that.selectIndex].value,
            courseName:that.keyword == '' ? null : that.keyword,
            pageNumber:that.pageNumber
          })
          params = _.pickBy(params, _.identity);
          this.$get(
            api.shopCourse,
            params
          ).then(res=>{
            if(res.status != 200 || res.data.code != 0){
              return Toast('服务器繁忙请稍后再试！')
            }
            let resGoods = res.data.data
            if(resGoods.length == 10 && that.pagesNext){
              that.goods = [...that.goods,...resGoods]
              that.pageNumber ++
              that.pagesNext = true
            }else if(resGoods.length < 10 && that.pagesNext){
              that.goods = [...that.goods,...resGoods]
              that.pagesNext = false
            }
          }).catch(err=>{
            console.error(err)
          })
        },
        //获取课程详情
        getGoodsDetails(args){
          const { courseOnlineId, organizationId } = args
          this.$router.push({path:'/curriculumDetails',query:{courseOnlineId,organizationId}})
        }
    }
}
</script>
<style scoped>
.padding-lr-sm{
  padding-left:20px;
  padding-right: 20px;
}
.header {
  /* background-color: #ffffff; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-inner{
  min-height: 100vh;
  background: #fbfbfb;
}
.search {
  background: #ffffff;
  border-radius: 19px;
  flex: 1;
  border: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
  padding: 5px 15px;
}
.search img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.search input {
  width: 100%;
  background: none;
  outline: none;
  border: none;
}
.loading,.loading div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .tip{
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8E8E93;
}
.data-none-img{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
}
.data-none-img img{
  display: block;
  width: 167px;
  height: 132.5px;
  margin: 0 auto;
}
.data-none div{
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8E8E93;
  line-height: 33px;
}
</style>
