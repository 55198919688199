<template>
    <div class="header-waper">
        <mt-popup
        v-model="showPopover"
        popup-transition="popup-fade"
        class="mint-popup"
        position="bottom">
            <div
            class="popoverItem"
            v-for="(item, index) in selectlist"
            :key="index"
            @click="selectItem(item)">
            {{`${item.name}(${item.schoolName})` }}
            </div>
        </mt-popup>
        <div class="header-main" v-if="selectlist.length > 0">
            <div class="info">
                <div class="select" v-if="selectlist.length > 1" @click="()=>this.showPopover = !this.showPopover">
                {{ `${selectlist[selectIndex].name}(${selectlist[selectIndex].schoolName})` }}
                <img
                    :src="
                    showPopover == true
                        ? 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/selectup.png'
                        : 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/selectdown.png'
                    "
                    alt=""
                />
                </div>
                <div class="select" v-else>{{ `${selectlist[selectIndex].name}(${selectlist[selectIndex].schoolName})` }}</div>
            </div>
            <div class="header-user" @click.stop="navMe" v-show="user">
                <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/header-me.png" alt="" />
            </div>
        </div>
    </div>
</template>
<script>
    import header from "./header.js";
    export default header;
</script>
<style scoped>
    @import './header.css';
</style>
