import tabbar from '@/components/nav/tabbar/tabbar.vue';
export default {
    name:'Layout',
    components: {
        tabbar
    },
    data(){
        return {
            key:'',
        }
    },
    watch: {
        '$route':'getTabKey'
    },
    created(){
        console.log(this.$router,'/this.$router')
        this.getTabKey()
    },
    methods:{
        getTabKey(){
            this.key = this.$route.path.split('/').filter(n=>n).shift()
        }
    }
}