<template>
    <div class="notfound-waper">
        <div>
            <img :src="notfound" alt="">
            <p>{{tip}}</p>
        </div>
    </div>
</template>
<script>
    import notfound from "./notfound.js";
    export default notfound;
</script>
<style scoped>
    @import './notfound.css';
</style>