<template>
  <div class="myCenter">
    <div class="user">
      <div class="userL">
        <img
          class="userImg"
          :src="
            (uplineInfo.headImage == '' || !uplineInfo.headImage)
              ? 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/userImg@2x.png'
              : uplineInfo.headImage
          "
          alt=""
        />
        <div class="Info">
          <div class="InfoT">
            <span class="InfoName">{{
              uplineInfo.realName
            }}</span>
            <!-- <span>{{ uplineInfo.mobile }}</span> -->
          </div>
          <div>
             <span>{{ uplineInfo.mobile }}</span>
            <!-- <span>{{ babyList[i].schoolList[j].name }}</span> -->
            <!-- <span class="cut"><img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/cut@2x.png" alt=""> 切换</span> -->
          </div>
        </div>
      </div>
      <img
        class="userR"
        src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/to@2x.png"
        alt=""
        @click="toUserInfo()"
      />
    </div>
    <div class="list">
      <div class="li" @click="toCourseList()" v-if="navType == 'paradise' && paradiseState">
        <div class="liL">乐园课程</div>
        <img class="liR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" alt="" />
      </div>

      <!-- <div class="li" @click="toOMS()">
        <div class="liL">我的订单</div>
        <img class="liR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" alt="" />
      </div> -->
      <!-- <div class="li" @click="toShoppingCar()" v-if="navType == 'mall'">
        <div class="liL">我的购物车</div>
        <img class="liR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" alt="" />
      </div> -->
      <div class="li" @click="toAddress()" v-if="navType == 'mall' && mixMallState">
        <div class="liL">收货地址</div>
        <img class="liR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" alt="" />
      </div>
      <div class="li" @click="toSetting()">
        <div class="liL">设置</div>
        <img class="liR" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import storage from "good-storage";
import * as api from "@/untils/api";
import http from "@/untils/http";
import paradiseMix from "@/Mixins/paradiseMix"
import mallMix from "@/Mixins/mallMix"
export default {
  name: "myCenter",
   mixins: [mallMix,paradiseMix],
  data() {
    return {
      babyList: [],
      i: 0,
      j: 0,
      navType:'',
      paradiseState:false,
      uplineInfo:{}
    };
  },
  created() {
    // window.location.href = '../../../static/pages/chooseClass.html'
    // if (JSON.parse(storage.get("babyList"))) {
    this.babyList = JSON.parse(storage.get("babyList"));
    this.i = storage.get("i");
    this.j = storage.get("j");
    this.navType = this.$route.path.split('/').filter(n=>n).shift()
    this.userUnionId = JSON.parse(storage.get("userUnionId"));
    // 获取当前登录人的信息
    this.uplineInfo = this.babyList.map(item=>{
      if(item.babyFamily && item.babyFamily.length > 0){
        return item.babyFamily
      }
    }).reduce((a, b) => a.concat(b)).filter(item=>item.userUnionId == this.userUnionId)[0]
    if(this.navType == 'paradise'){
      this.getSchoolState()
        .then(({state,info})=>{
          // 开通课程商铺
          this.paradiseState = state
        })
    }
    if(this.navType == 'mall'){
      // 查询商铺信息
      this.shopInfo()
        .then(state=>{})
    }
    // } else {
    //   localStorage.clear();
    //   this.$router.push('/');
    // }
  },
  methods: {
    //去学堂课程
    toCourseList() {
      this.$router.push("/curriculum");
    },
    //去个人中心
    toUserInfo() {
      this.$router.push("/userInfo");
    },
    //去收货地址
    toAddress() {
      this.$router.push("/addressList");
    },
    //去订单列表
    toOMS() {
      this.$router.push("/orderList");
    },
    //去购物车
    toShoppingCar() {
      this.$router.push("/mall/shoppingCar");
    },
    //去设置
    toSetting() {
      this.$router.push("/setting");
    }
  }
};
</script>

<style scoped>
.myCenter {
  background-color: #ffffff;
}
.user {
  /* width: 100%; */
  background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/userbg@2x.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding: 40px 20px 55px 20px;
  justify-content: space-between;
}
.userL {
  display: flex;
  align-items: center;
}
.userImg {
  width: 59px;
  height: 59px;
  border-radius: 100%;
  background-color: darkgray;
  margin-right: 8px;
}
.Info {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.InfoT {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  margin-bottom: 6px;
}
.InfoName {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  letter-spacing: 1px;
}
.cut {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  background: #0a9bf2;
  border-radius: 12px;
  padding: 3px 7px;
  margin-left: 4px;
}
.cut img {
  width: 13px;
  height: 12px;
}
.userR {
  width: 12px;
  height: 16px;
}
.list {
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding: 0 15px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #151515;
  margin-top: -20px;
}
.li {
  /* width: 100%; */
  height: 60px;
  line-height: 60px;
  display: flex;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.liR {
  width: 12px;
  height: 16px;
}
</style>
