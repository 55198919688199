<template>
    <div class="curriculum" :class="[size]">
        <div class="curriculum-img">
            <img name='dynamicImg' :src="info.picture+imgSizeQuality" alt="">
        </div>
        <div class="curriculum-contain">
            <div class="title">{{info.title}}</div>
            <div class="chapterOrexpire">
                <div class="chapter">共学习{{info.chapter}}个课节</div>
                <div class="expire" v-if="expireShow">
                    有效期剩余： <span v-if="validityDateType">{{info.validityDate}}天</span>
                    <span v-else>{{info.validityDate}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import curriculum from "./curriculum";
    export default curriculum;
</script>
<style scoped>
    @import './curriculum.css';
</style>