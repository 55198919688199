import { Tabbar, TabbarItem } from 'vant';
import { tabbars } from './tabbarConfig';
export default {
    name:'tabbar',
    props:{
        tabkey:{
            type:String,
            default:''
        }
    },
    components: {
        Tabbar,
        TabbarItem
    },
    data(){
        return {
            tabs:[],
            active:''
        }
    },
    watch: {
        '$route':'getPath'
    },
    created(){
        this.getPath()
    },
    methods:{
        getPath(to,from){
            this.tabs = tabbars[this.tabkey]
            let active = this.tabs.filter(n=>n.path.indexOf(this.$route.path) != -1).pop().key
            this.$nextTick(() => {
                this.$set(this,"active",active);
                console.log(this.active,'/active')
            })
        },
        // 选择某一项
        onChange(toPath){
            console.log(toPath)
            this.tabs.forEach(element => {
                if(element.key == toPath){
                    this.$router.push({
                        path: element.path,
                        query:{}
                    });
                }
            });
        }
    }
}