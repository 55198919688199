<template>
  <div class="homepage">
    <div class="waper_header">
      <headers-select
      v-if="headerSelect"
      :user='false'
      @selectItem="initData"
      ></headers-select>
    </div>
    <div v-if="requestLoad">
      <div v-if="mixMallState">
        <div v-if="productList.length != 0">
          <div class="shop">
            <div class="shopAubot">
              <div class="user">
                <!-- <img
                  :src="mixMallImage"
                  alt=""
                > -->
                <div>
                  <div class="name">{{ mixMallName }}的小店</div>
                  <div class="welcome">欢迎光临</div>
                </div>
              </div>
              <div class="search">
                <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/search@2x.png" alt="" />
                <input
                  type="text"
                  placeholder="请输入关键词"
                  v-model="keyword"
                  @change="toSearch($event)"
                />
              </div>
              <div class="classify">
                <div
                  v-for="(item, index) in goodsList"
                  :key="index"
                  :class="currentTab == index ? 'choose' : 'nochoose'"
                  @click="chooseKind(index)"
                >
                  <div>{{ item.catagoryName }}</div>
                  <div :class="currentTab == index ? 'blueline' : 'line'"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="goods">
            <div v-if="productList.length != 0">
              <div class="goodsList">
                <div
                  class="item"
                  @click="toGoodsDetails(items.sku, items.productId)"
                  :key="indexs"
                  :index="indexs"
                  v-for="(items, indexs) in productList"
                >
                  <img name='dynamicImg' class="itemImg" :src="items.productCoverUrl+imgSizeQuality" alt="" />
                  <div class="itemAbout">
                    <div class="itemName">{{ items.productName }}</div>
                    <div class="itemPrice">
                      <span class="price">¥</span>{{ items.price }}
                      <span class="originalPrice">¥{{ items.priceOriginal }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text">没有更多了</div>
            </div>
            <div v-else class="donthave">
              当前暂无商品
            </div>
          </div>
        </div>
        <div v-else>
          <not-found
          notfound="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/goodsNot.png"
          tip="当前机构暂无商品哦～"
          ></not-found>
        </div>
      </div>
      <div v-else>
        <not-found></not-found>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
import headerSelect from "@/components/select/header/header.vue";
import notFound from "@/components/notfound/notfound.vue";
import mallMix from "@/Mixins/mallMix"
import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
  name: "homepage",
  mixins: [mallMix,dynamicImgwh],
  components: {
    "headers-select":headerSelect,
    "not-found":notFound
  },
  data() {
    return {
      currentTab: 0,
      goodsList: [
        {
          catagoryId: "",
          catagoryName: "全部分类"
        }
      ],
      keyword: "",
      productList: [],
      i:null,
      j:null,
      headerSelect:false,
      requestLoad:false
    };
  },
  async created(e) {
    await this.$UpdateUserInfo().then(res=>{
        this.headerSelect = true
        this.babyList = JSON.parse(storage.get("babyList"))
    })
    await this.initData()
  },
  methods: {
    initData(){
      const that = this
      this.requestLoad = false
      Indicator.open("加载中...");
      this.catagoryId = this.$route.query.catagoryId;
      // 检测并设置家长数据
      this.setFamilyInfo(()=>{
        that.i = storage.get("i");
        that.j = storage.get("j");
      }).then(()=>{
        // 查询商铺信息
        that.shopInfo()
        .then(state=>{
          // 开通商铺
          if(state){
            //获取商品统计
            this.getProductCount();
            this.get_dynamicImgSQ()
          }else{
            this.requestLoad = true
            Indicator.close();
          }
        })
      })
    },
    toSearch(e) {
      this.getProductList();
    },
    //获取商品统计
    getProductCount() {
      this.productList = [];
      this.$get(api.productCount, {
        shopNumber: this.babyList[this.i].schoolList[this.j].organizationId
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          this.goodsList.push(res.data.data[i]);
          if (this.catagoryId == res.data.data[i].catagoryId) {
            this.currentTab = i + 1;
          }
        }
        console.log(this.goodsList);
        this.getProductList();
      }).catch(err=>{
        this.requestLoad = true
        Indicator.close();
      });
    },
    //获取商品列表
    getProductList() {
      this.$post(api.productList, {
        shopNumber: this.babyList[this.i].schoolList[this.j].organizationId,
        catagoryId: this.goodsList[this.currentTab].catagoryId,
        name: this.keyword
      }).then(res => {
        console.log(res);
        this.productList = res.data.data;
        this.requestLoad = true
        Indicator.close();
      });
    },
    chooseKind(e) {
      this.requestLoad = false
      Indicator.open("加载中...");
      this.currentTab = e;
      this.getProductList();
    },
    //去商品详情页
    toGoodsDetails(sku, productId) {
      this.$router.push({
        path: "/goodsDetails",
        query: {
          productId: productId,
          sku: sku
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.shop {
  background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/topbg@2x.png) no-repeat;
  background-size: 100% 100%;
  padding: 20px;
}
.waper_header{
  margin-top: 92px;
}
.shopAubot {
  padding-top: 20px;
}
.user {
  width: 100%;
  display: flex;
  align-items: center;
  color: #0c152f;
  padding-bottom: 20px;
}
.user img {
  width: 59px;
  height: 59px;
  /* background-color: blueviolet; */
  margin-right: 10px;
  border-radius: 100%;
}
.name {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.welcome {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  letter-spacing: 1px;
}
.search {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 19px;
  /* width: 100%; */
  border: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 15px;
}
.search img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.search input {
  background: none;
  outline: none;
  border: none;
}
.classify {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-top: 16px;
  overflow: auto;
  /* margin: 0 auto; */
}
.nochoose,.choose{
  padding-right: 10px;
  white-space: nowrap;
}
.classify div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}
.choose {
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0c152f;
}
.nochoose {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #2f2f2f;
}
.blueline {
  width: 25px;
  height: 3px;
  background: #24a8fb;
  border-radius: 2px;
  margin-top: 6px;
}
.line {
  width: 25px;
  height: 3px;
  /* background: pink; */
  border-radius: 2px;
}
.goods {
  padding: 15px 20px;
}
.goodsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.item {
  width: 48%;
  background: #ffffff;
  margin-bottom: 10px;
  border-radius: 6px;
}
.itemImg {
  border-radius: 6px 6px 0 0;
  /* background-color: #24A8FB; */
  width: 100%;
  height: 105px;
}
.itemAbout {
  padding: 10px;
}
.itemName {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0c152f;
  margin-bottom: 4px;
}
.itemPrice {
  font-size: 15px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #f85511;
}
.price {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.originalPrice {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ababab;
  text-decoration: line-through;
}
.donthave {
  text-align: center;
  margin-top: 100px;
  font-size: 16px;
  color: #0c152f;
}
.text {
  text-align: center;
  color: #2f2f2f;
  font-size: 15px;
  padding: 10px 0;
}
</style>
