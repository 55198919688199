import _ from 'lodash'
const scroll = {
    name:'',
    mounted(){
      let self = this
      window.addEventListener("scroll", this.scrollEvent.bind(this, self), true);
    },
    destroyed () {
      let self = this
      window.removeEventListener('scroll', this.scrollEvent.bind(this, self), true);
    },
    methods:{
        scrollEvent:_.debounce(self=>{
            const that = self
            if (document.documentElement.scrollTop
                + document.documentElement.clientHeight 
              >= document.body.scrollHeight) {
                //   上拉加载更多
                  if(that.onReachBottom) that.onReachBottom()
            }
        },1000)
    }
}

export default scroll