import * as api from "@/untils/api";
import storage from "good-storage";
import { setCommont } from "@/untils/set_family_info";
export default {
    data() {
      return {
          mixParadiseState:false, //当前院所下是否开通课程商铺
      }
    },
    mounted(){},
    methods:{
        setFamilyInfo(cd){
            return new Promise(resolve => {
              // 判断是否包含院所id
              setCommont().then(()=>{
                  cd()
                  resolve()
              })
              
          })
      },
      //是否开通学堂
      getSchoolState() {
        return new Promise(resolve => {
            let babyList = JSON.parse(storage.get("babyList"))
            let i = storage.get("i")
            let j = storage.get("j")
            let organizationId = babyList[i].schoolList[j].organizationId
            this.$post(api.GetshopList, {
                shopNumber:[organizationId],
                shopTag:"课程商铺",
                status:1
            }).then(res => {
                console.log("是否开课程商铺查询", res.data);
                if (res.data.total != 0) {
                    this.mixParadiseState = true;
                    resolve({
                        state:true,
                        info:res.data.data[0]
                    })
                } else {
                    this.mixParadiseState = false;
                    resolve({
                        state:false,
                        info:null
                    })
                }
            });
        })
      },
    },
  }