<template>
  <div class="voiceBank" v-cloak>
    <!-- <div class='back'>
                    <a id="back" href="javascript:history.back();" rel="external nofollow"> <img src="../image/login/back.png" alt=""></a>
                    <div style='margin-left:-18px'>声音库</div>
                    <div style="width: 5px;height: 5px;"></div>
                </div> -->
    <div class="banner">
      <div class="bannerTix">
        <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/voiceBank/music.png" alt="">
        <div>声音库</div>
      </div>
    </div>
    <!-- <audio id="audio-element"><source src="audioSrc" type="audio/mpeg"></audio> -->
    <div class="list">
      <div class="li" v-for="(item,index) in voiceList" @click='clickAudio(index)' :key='index'>
        <!-- <audio id="audio-element" src="{item.src}}"></audio> -->
        <img alt="" :src="index==num?'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/voiceBank/start@2x.png':'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/voiceBank/play@2x.png'">
        <div :class="[item.play?'liChoose': 'litext']">{{item.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import http from "@/untils/http";
  import backMix from "@/Mixins/backMix"
  export default {
    name: 'voiceBank',
    mixins:[backMix],
    data() {
      return {
        voiceList: [{
            src: 'http://downsc.chinaz.net/Files/DownLoad/sound1/201906/11582.mp3',
            name: "小青蛙的叫声",
            play: false,
          },
          {
            src: 'http://downsc.chinaz.net/files/download/sound1/201206/1638.mp3',
            name: "弟子规001总述",
            play: false
          },
          {
            src: 'http://downsc.chinaz.net/Files/DownLoad/sound1/201906/11582.mp3',
            name: "阿凡提种金子",
            play: false
          },
        ],
        audioSrc: '',
        num: 99
      }
    },
    created() {
      this.sectionId = this.$route.query.sectionId;
      this.getAudioList();
      this.audio = new Audio();
    },
    deactivated(){

    },
    methods: {
      goBack(){
        this.audio.pause();
        this.audio.load()
        this.num = null
        window.history.back();
      },
      getAudioList() {
        let that = this
        this.$get(api.sectionInfo, {
          sectionId: that.sectionId,
        }).then(res => {
          that.voiceList = res.data.data.objectList
          console.log('获取环节列表', that.voiceList)
        })
      },
      clickAudio(e) {
        let that = this
        that.audio.src = that.voiceList[e].mediaUrl
        console.log(that.audio.src)
        that.audio.play()
        if (that.num == e) {
          that.audio.pause();
        } else {
          that.audio.play()
          that.num = e
        }
        that.audio.addEventListener("pause", function() { // 暂停时会触发，当播放完一首歌曲时也会触发
          that.num = 99
        });
      },
    },
  }
</script>

<style scoped>
  body {
    background-color: #FFFFFF;
  }
  .banner {
    height: 87px;
    width: 100%;
    background: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/voiceBank/banner@2x.png) no-repeat;
    background-size: 100% 100%;
  }
  .bannerTix {
    margin-left: 20px;
    height: 87px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
  .bannerTix img {
    width: 25px;
    height: 26px;
    margin-right: 14px;
  }
  .list {
    background-color: #FFFFFF;
    /* background-color: aquamarine; */
    z-index: 9999;
    border-radius: 10px;
    margin-top: -6px;
  }
  .li {
    padding: 15px 18px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .li img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  .litext {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  .liChoose {
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(36, 168, 251, 1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
</style>
