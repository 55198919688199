import _ from 'lodash'
const timeFuns = {
    methods:{
        /**
         * 时间秒数格式化
         * @param time 时间戳（单位：秒）
         * @returns {*} 格式化后的时分秒"00:40:00"
         */
        sec_to_time(time){
            var t;
            if(time > -1){
                var hour = Math.floor(time/3600);
                var min = Math.floor(time/60) % 60;
                var sec = time % 60;
                if(hour < 10) {
                    t = '0'+ hour + ":";
                } else {
                    t = hour + ":";
                }

                if(min < 10){t += "0";}
                t += min + ":";
                if(sec < 10){t += "0";}
                t += sec.toFixed(0);
            }
            return t;
        },
        /**
         * 日期格式化星期几
         * @param date 时间戳 例：2022-05-25
         * @returns {*} string 星期几
         */
        dateToweek(date){
            let weekDay = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];  
            let myDate = new Date(Date.parse(date));  
            return weekDay[myDate.getDay()]
        },
        /**
         * 获取当前日期
         * @returns {*} now 2022-05-25 14:52:53
         */
        getNowDate() {
            var myDate = new Date;
            var year = myDate.getFullYear(); //获取当前年
            var mon = myDate.getMonth() + 1; //获取当前月
            var date = myDate.getDate(); //获取当前日
            var hours = myDate.getHours(); //获取当前小时
            var minutes = myDate.getMinutes(); //获取当前分钟
            var seconds = myDate.getSeconds(); //获取当前秒
            var now = year + "-" + mon + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
            return now;
        },
        /**
         * JS 计算两个时间间隔多久（时分秒）
         * @param startTime "2019-10-23 15:27:23"
         * @param endTime "2019-10-23 15:27:55"
         * @return 1天2时3分5秒
         */
        twoTimeInterval(startTime, endTime) {
 
            // 开始时间
            let d1 = startTime.replace(/\-/g, "/");
            let date1 = new Date(d1);
           
            // 结束时间
            let d2 = endTime.replace(/\-/g, "/");
            let date2 = new Date(d2);
           
            // 时间相差秒数
            let dateDiff = date2.getTime() - date1.getTime();

            // 计算出相差天数
            let days = _.ceil(dateDiff / (24 * 3600 * 1000));
            // let days = Math.floor(dateDiff / (24 * 3600 * 1000));

            // // 计算出小时数
            // let residue1 = dateDiff % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
            // let hours = Math.floor(residue1 / (3600 * 1000));
           
            // // 计算相差分钟数
            // let residue2 = residue1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
            // let minutes = Math.floor(residue2 / (60 * 1000));
           
            // // 计算相差秒数
            // let residue3 = residue2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
            // let seconds = Math.round(residue3 / 1000);
           
            // let returnVal =
            //   ((days == 0) ? "" : days+"天") +
            //   ((hours == 0) ? "" : days+"时") +
            //   ((minutes == 0) ? "" : minutes+"分") +
            //   ((seconds == 0) ? "" : seconds+"秒");
           
            return days;
           
          }
    }
}

export default timeFuns