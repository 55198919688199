export default {
    name:'log',
    props:{
        logs:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return {}
    },
    created(){},
    
    methods:{
        
    }
}