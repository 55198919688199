import layout from '@/components/Layout/Layout.vue'
import chooseClass from '@/view/chooseClass'
import appraisalList from '@/view/appraisalList'
import homepage from '@/view/homepage'
import shoppingCar from '@/view/shoppingCar'
import orderList from '@/view/orderList'
import school from '@/view/school'
import myCenter from '@/view/myCenter'
import clueEntry from "@/view/invite/clueEntry"
const tabberRouter = [
    {
        path: '/exercise',
        name: 'exercise',
        component: layout,
        meta: {title: '课后练习'},
        children: [ // 通过 children 数组属性，来实现路由的嵌套
            { meta: {title: '课后练习'},path: 'chooseClass', component: chooseClass }, // 注意，子路由的开头位置，不要加 / 路径符
            { meta: {title: '测评报告'},path: 'appraisalList', component: appraisalList },
            { meta: {title: '个人中心'},path: 'myCenter', component: myCenter }
        ]
    },
    {
        path: '/mall',
        name: 'mall',
        component: layout,
        meta: {title: '商店'},
        children: [
            { meta: {title: '商店'},path: 'homepage', component: homepage },
            { meta: {title: '购物车'},path: 'shoppingCar', component: shoppingCar },
            { meta: {title: '订单'},path: 'orderList', component: orderList },
            { meta: {title: '个人中心'},path: 'myCenter', component: myCenter }
        ]
    },
    {
        path: '/paradise',
        name: 'paradise',
        component: layout,
        meta: {title: '启蒙乐园'},
        children: [
            { meta: {title: '启蒙乐园'},path: 'school', component: school },
            { meta: {title: '乐园订单'},path: 'orderList', component: orderList },
            { meta: {title: '个人中心'},path: 'myCenter', component: myCenter }
        ]
    },
    {
        path: '/clueEntry',
        name: 'clueEntry',
        component: clueEntry,
        meta: {title: '孩子信息录入'}
    },
]
export default tabberRouter
