<template>
  <div class="curriculumDetails-inner">
    <!-- banner -->
    <div class="banner">
        <mt-swipe :auto="4000" class="swper">
            <mt-swipe-item v-for="(item, index) in details.picture" :key="index">
                <img name='dynamicImg' :src="item+imgSizeQuality">
            </mt-swipe-item>
        </mt-swipe>
    </div>
    <!-- 课程简介 -->
    <div class="synopsis padding-lr-sm">
        <div class="synopsis-title">{{details.title}}</div>
        <div class="synopsis-dateEffective">{{details.validityMonth | validityMonth}}</div>
        <div class="synopsis-priceOrtip">
          <div class="synopsis-price">
            <div class="price-before">{{details.priceShop}}</div>
            <div class="price-after">{{details.priceOriginal}}</div>
          </div>
          <div class="synopsis-tip" v-if="validEndTime">
            有效期剩余：<span v-if="validEndTime != ''"><span>{{validEndTime}}</span>天</span>
                      <span v-else><span>{{validEndTime}}</span>天</span>
          </div>
        </div>
    </div>
    <!-- 课程详情 -->
    <div class="curriculumDetails-contain">
      <common-nav 
      class="curriculumDetailsType title-tb-sm padding-lr-sm"
      :list="navs"
      :activeIndex="navIndex"
      @clickItem="navClick"
      ></common-nav>
      <!-- 课程介绍 -->
      <div class="introduce" v-show='navIndex == 0'>
          <div class="introduce-imgs">
            <div class="details-contain" v-html="details.content"></div>
          </div>
      </div>
      <!-- 课程目录 -->
      <div class="catalogue padding-lr-sm" v-show="navIndex != 0">
          <catalogue 
          :catalogues="details.lesson"
          @showChange="showChange"
          ></catalogue>
      </div>
    </div>
    <div class="footer-btns padding-lr-sm">
      <div class="payment" v-if="!validEndTime && validEndTime !=''">
        <div class="price">{{details.priceShop}}</div>
        <div class="paySub" @click="paySub">{{details.priceShop != '0.00' ? '立即购买':'立即领取'}}</div>
      </div>
      <div class="examine" v-else @click="()=>this.tipVisible = true">已购买，查看学习方式</div>
    </div>
    <!-- 底部提示  -->
    <div class="popup-tip">
      <mt-popup
        v-model="tipVisible"
        class="mint-popup"
        position="bottom">
          <div class="tip-contain">
            <div class="title">学习方式：</div>
            <ol>
              <li>1、在PAD端，下载“启蒙乐园”客户端；</li>
              <li>2、登录后，进入首页，找到我的课程， 点击“立即学习”。</li>
            </ol>
          </div>
      </mt-popup>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import * as api from "@/untils/api";
import storage from "good-storage";
import timeMixins from '../../Mixins/time'
import commonNav from '@/components/nav/commonNav/commonNav.vue'
import catalogue from '@/components/catalogue/catalogue.vue'
import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
  name: "curriculumDetails",
  components:{
    'common-nav':commonNav,
    'catalogue':catalogue
  },
  filters:{
    validityMonth(val){
      let tipValidity = val == 0 ? '永久有效' : `有效期${val}个月`
      return tipValidity
    }
  },
  mixins: [timeMixins,dynamicImgwh],
  data() {
    return {
        details:{},
        navs:[{
          title:'课程介绍'
        },{
          title:'课程目录'
        }],
        navIndex:0,
        tipVisible:false,
        validEndTime:null,
        babyNameList:[],
        babyActIndex:0
    };
  },
  async created() {
     await this.$UpdateUserInfo().then(res=>{
       if(storage.get("babyList")){
          this.initData()
        }else{
          this.getparentInfo().then(res=>{
            if(res) this.initData()
          })
        }
     })
  },
  methods: {
    initData(){
      this.babyNameList = JSON.parse(storage.get("babyList")).map(item=>{
          return {
              babyId:item.babyId,
              name:item.name,
              organizationId:item.schoolList.length > 0 ? item.schoolList[storage.get("j")].organizationId : null,
          }
      })
      this.babyActIndex = storage.get("i")
      let { pay } = this.$route.query
      // 支付结果查询
      if(pay == 1){
        this.getPayQuery()
      }else{
        this.getDetails()
      }
      this.get_dynamicImgSQ()
    },
    getparentInfo() {
      return new Promise(resolve => {
        this.$get(api.parentInfo, {}).then(res => {
          console.log("获取分享用户信息", res);
          if (res.data.code == 0) {
            if (res.data.data.babyList.length != 0) {
              res.data.data.babyList = res.data.data.babyList.filter(element => element.schoolList.length > 0);
              res.data.data.babyList.forEach((baby,babyIndex)=>{
                if(baby.schoolList && baby.schoolList.length != 0){
                  baby.schoolList.forEach(school=>{
                    school.name = school.shortName || '暂无院所别名'
                  })
                }
              })
              res.data.data.babyList = res.data.data.babyList.filter(item=>item.schoolList.length != 0)
              res.data.data.babyList.filter(item=>item.schoolList.length != 0)
              storage.set("userUnionId", res.data.data.userUnionId);
              storage.set("i", "0");
              storage.set("j", "0");
              console.log("123", res.data.data.babyList);
              resolve()
            } else {
              window.localStorage.removeItem("userToken_user");
              console.log(storage.get("userToken_user"));
              this.$toast("您的账号当前暂无孩子，请联系您的幼儿园添加孩子信息");
              resolve()
            }
          }
        });
      })
    },
    // 获取商品详情
    getDetails(){
      const { courseOnlineId } = this.$route.query
      const { shopId } = this.$store.state.shopInfo
      this.$get(
        api.courseDetails,
        {courseOnlineId,shopId}
      ).then(res=>{
        if(res.status != 200 || res.data.code != 0){
          return Toast('服务器繁忙请稍后再试！')
        }
        res.data.data.picture = [res.data.data.picture]
        this.details = res.data.data
        if(res.data.data.type == 2 ) this.navs = [this.navs[0]]
        try {
          this.getOrderList()
        } catch (error) {
          console.log('无订单')
        }
        console.log(res.data.data,'/课程详情')
      }).catch(err=>{
        console.error(err)
      })
    },
    // 获取所购买的有效期
    getOrderList() {
      let userId = [];
      userId.push(storage.get("userUnionId"));
      const {courseOnlineId} = this.details
      const { shopNumber } = this.$store.state.shopInfo
      let { queryShop } = this.$route.query
      this.$post(api.orderList, {
        productId:courseOnlineId,
        shop: [{
          shopNumber: queryShop || shopNumber,
          shopTag: '课程商铺'
        }],
        status: 4,
        // 暂时注销因为名字同商品更改名字后订单查不到
        // productName: this.details.title,
        userId: userId
      }).then(res => {
        let list = res.data.data.row
        let { validEndTime } = list[0].product[0]
        let useUserName = list[0].product[0].useUserName
        let babyName = this.babyNameList[this.babyActIndex].name
        if((validEndTime || validEndTime == null) && (babyName == useUserName)){
          this.validEndTime = validEndTime == null ? '' : this.twoTimeInterval(this.getNowDate(),validEndTime);
        }
      }).catch(err=>{
        console.error(err,'/获取订单列表')
      });
    },
    //支付结果查询
    getPayQuery() {
      console.log("7890-", storage.get("orderId"));
      let orderId = storage.get("orderId");
      this.$post(api.payQuery, {
        orderId: orderId
      }).then(res => {
        console.log("支付结果查询", res);
        this.getDetails();
        // Toast(res.data.data.status);
        // this.list = res.data.data.row
        // console.log(this.list);
      });
    },
    selectItem(e) {
      this.selectIndex = e;
    },
    navClick(e){
      this.navIndex = e;
    },
    showChange(e){
      console.log(e)
    },
    paySub(){
      const { courseOnlineId } = this.$route.query
      this.$router.push({
        path: "/subCourseOrderDetails",
        query:{courseOnlineId}
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.curriculumDetails-inner{
  background: #ffffff;
  overflow-x: hidden;
}
.padding-lr-sm{
  padding-left:20px;
  padding-right: 20px;
}
.title-tb-sm{
  margin: 16px 0;
};
.over-hider{
  overflow: hidden;
}
.school {
  width: 100%;
  background-color: #fafafc;
}
.header {
  /* background-color: #ffffff; */
  padding-top: 21px;
  padding-bottom: 21px;
}
.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search {
  background: #ffffff;
  border-radius: 19px;
  /* width: 100%; */
  border: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 15px;
}
.search img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.search input {
  background: none;
  outline: none;
  border: none;
}
.banner img{
    width: 100%;
    height: 313px;
}
.banner {
    height: 313px;
}
.synopsis,.curriculumDetailsType{
    padding-bottom: 10px;
    border-bottom: 1px #EEEEEE solid;
}
.synopsis .synopsis-title{
  margin: 10px 0;
  font-size: 17px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  width: 100%; 
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.synopsis .synopsis-dateEffective{
  font-size: 12px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #B3745F;
  background: #FEF6F3;
  border-radius: 24px;
  display: inline-block;
  padding: 5px 13px;
}
.synopsis .synopsis-priceOrtip{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.synopsis .synopsis-tip{
  font-size: 14px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
}
.synopsis .synopsis-tip span{
  color: #CC3D3D;
}
.synopsis .synopsis-price{
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
}
.synopsis .synopsis-price > div::before{
  content: "¥";
  margin-right: 3px;
}
.synopsis .synopsis-price .price-before{
  font-size: 20px;
  color: #ED6030;
  margin-right: 8px;
  font-weight: 600;
}
.synopsis .synopsis-price .price-after{
  color: #8E8E93;
  font-size: 12px;
  text-decoration: line-through;
}
.curriculumDetails-contain{
  margin-bottom: 62px;
}
.introduce-imgs img{
    width: 100%;
    display: block;
}
.footer-btns{
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}
.footer-btns .payment{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-btns .payment .price{
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ED6030;
}
.footer-btns .payment .price::before{
  content: "￥";
}
.footer-btns .payment .paySub{
    font-size: 17px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    background: #FB7E30;
    border-radius: 40px;
    padding: 10px 35px;
    letter-spacing: 3px;
}
.footer-btns .examine{
  width: 70%;
  font-size: 17px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #FFFFFF;
  background: #2CA0FB;
  border-radius: 40px;
  padding: 10px 35px;
  letter-spacing: 3px;
  margin: 0 auto;
}
.popup-tip .mint-popup{
  width: 100%;
  border-radius: 28px 28px 0px 0px;
  padding: 30px;
  box-sizing: border-box;
}
.popup-tip .tip-contain{
  padding-bottom: 40px;
}
.popup-tip .tip-contain .title{
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  line-height: 41px;
}
.popup-tip .tip-contain ol{
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 31px;
}
</style>
