<template>
  <div class="shoppingCar">
    <div class="waper_header">
      <headers-select
      :user='false'
      :selectIndex="i"
      @selectItem="initData"
      ></headers-select>
    </div>
    <div v-if="requestLoad">
      <div v-if="mixMallState">
        <div class="orderList" v-if="list.length != 0" >
          <!-- @click="toGoodsDetails(item.productId)" -->
          <div class="order" v-for="(item, index) in list" :key="index">
            <div
              class="orderImg"
              @click="getChoose(index, item.productId, item.num, item.price)"
            >
              <img
                :src="
                  item.choose
                    ? 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/select@2x.png'
                    : 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/noselect@2x.png'
                "
                alt=""
              />
            </div>
            <div class="orderInfo">
              <div class="orderInfoL">
                <img
                  name='dynamicImg'
                  :src="
                    item.levelCoverUrl != ''
                      ? item.levelCoverUrl+imgSizeQuality
                      : item.productCoverUrl+imgSizeQuality
                  "
                />
              </div>
              <div class="orderInfoR">
                <div class="orderTit">
                  {{ item.name }}
                </div>
                <div class="orderType" v-if="item.levelName != ''">
                  <!-- @click="getLevel(item.productId,item.levelId,item.num)" -->
                  规格：{{ item.levelName }}
                  <!-- <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/down@2x.png" alt=""> -->
                </div>
                <div class="orderTypeR" v-if="item.catagoryName != ''">
                  {{ item.catagoryName }}
                </div>
                <div class="orderNum">
                  <div class="orderNumL"><span>¥</span>{{ item.price }}</div>
                  <div class="orderNumR">
                    <div
                      class="icon"
                      @click="
                        decrease(item.productId, item.levelId, item.num, index)
                      "
                    >
                      －
                    </div>
                    <!-- <div class="number">{{item.num}}</div> -->
                    <input
                      class="number"
                      type="number"
                      value="item.num"
                      v-model="item.num"
                      @blur="changeNum($event, item.productId, item.levelId, index)"
                      onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                      onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                    />
                    <div
                      class="icon"
                      @click="
                        addition(item.productId, item.levelId, item.num, index)
                      "
                    >
                      ＋
                    </div>
                  </div>
                </div>
                <div
                  class="carTit"
                  v-if="item.productStatus == 0 || item.levelDelete == 1"
                >
                  该规格商品已下架！
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <not-found
          notfound="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/goodsNot.png"
          tip="购物车空空如也，快去逛逛吧～"
          ></not-found>
        </div>
        <div class="bottom" v-if="list.length != 0">
          <div class="bottomL">
            <img
              :src="
                allChoose
                  ? 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/select@2x.png'
                  : 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/shopping/noselect@2x.png'
              "
              alt=""
              @click="getAllChoose"
            />
            全选
            <div @click="toDel">删除</div>
          </div>
          <div class="bottomR">
            <div class="bottomRL">
              <span class="text">合计:</span>
              <span class="money">¥</span>
              <span class="numbers">{{ sum }}</span>
            </div>
            <div class="bottomRR" @click="toOrderDetails()">下单</div>
          </div>
        </div>
      </div>
      <div v-else>
        <not-found></not-found>
      </div>
    </div>
    <div class="chooseShowbg" v-if="showChoose">
      <div class="chooseShow">
        <div class="chooseInfo">
          <div class="chooseInfoL">
            <img :src="level[currentTab].levelCoverUrl" alt="" />
            <div class="chooseInfoNum">
              <div class="chooseMoney">
                <span>¥</span>{{ level[currentTab].price }}
              </div>
              <div class="originalMoney">
                ¥{{ level[currentTab].priceOriginal }}
              </div>
            </div>
          </div>
          <img
            class="chooseInfoR"
            src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/close@2x.png"
            alt=""
            @click="closeChoose"
          />
        </div>
        <div class="chooseTit">规格</div>
        <div class="chooseList">
          <div
            :class="currentTab == levelIndex ? 'chooseItem' : 'nochooseItem'"
            v-for="(levelItem, levelIndex) in level"
            :key="levelIndex"
            @click="changeTo(levelIndex)"
          >
            {{ levelItem.levelName }}
          </div>
        </div>
        <div class="chooseNum">
          <div class="chooseTit">数量</div>
          <div class="chooseNumR">
            <div class="icon" @click="decrease()">－</div>
            <div class="number">{{ num }}</div>
            <div class="icon" @click="addition()">＋</div>
          </div>
        </div>
        <div class="bottomm" @click="confirm">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Swipe, SwipeItem, Indicator } from "mint-ui";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
import headerSelect from "@/components/select/header/header.vue";
import notFound from "@/components/notfound/notfound.vue";
import mallMix from "@/Mixins/mallMix"
import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
  name: "shoppingCar",
  mixins: [mallMix,dynamicImgwh],
  components: {
    "headers-select":headerSelect,
    "not-found":notFound
  },
  data() {
    return {
      list: [],
      chooseList: [],
      level: [],
      sum: 0,
      allChoose: false,
      currentTab: "",
      showChoose: false,
      productlist: [],
      remark: "",
      num: 1,
      requestLoad:false
    };
  },
  created() {
    this.babyList = JSON.parse(storage.get("babyList"));
    this.initData();
  },
  methods: {
    initData(e){
      const that = this
      this.requestLoad = false
      Indicator.open("加载中...");
      // 检测并设置家长数据
      this.setFamilyInfo(()=>{
        that.i = storage.get("i");
        that.j = storage.get("j");
      }).then(()=>{
        // 查询商铺信息
        that.shopInfo()
        .then(state=>{
          // 开通商铺
          if(state){
            //获取购物车列表
            that.getCartList();
            that.get_dynamicImgSQ()
          }else{
            this.requestLoad = true
            Indicator.close();
          }
        })
      })
    },
    //购物车中选择
    getChoose(index, productId, num, price) {
      this.list[index].choose = !this.list[index].choose;
      if (this.list[index].choose) {
        this.chooseList.push(this.list[index]);
        this.sum = this.cal.accAdd(this.cal.accMul(num, price), this.sum);
      } else {
        for (let i = 0; i < this.chooseList.length; i++) {
          if (this.chooseList[i].productId == productId) {
            this.chooseList.splice(i, 1);
          }
        }
        this.sum = this.cal.accSub(this.sum, this.cal.accMul(num, price));
      }
      // 触发全选
      if(this.list.length == this.chooseList.length){
        this.allChoose = false
        this.getAllChoose()
      }else{
        this.allChoose = false
      }
      // this.$forceUpdate();
    },
    //获取购物车列表
    getCartList(e) {
      console.log(e);
      // let that = this
      this.$get(api.cartList, {
        shopNumber: this.babyList[this.i].schoolList[this.j].organizationId
      }).then(res => {
        this.list = res.data.data;
        if (e) {
          this.count();
          console.log("获取购物车列表", this.list, this.chooseList);
          for (let i = 0; i < this.list.length; i++) {
            for (let j = 0; j < this.chooseList.length; j++) {
              if (this.chooseList[j].productId == this.list[i].productId) {
                this.list[i].choose = true;
              } else {
                this.list[i].choose = false;
              }
            }
          }
        } else {
          console.log(e, 67890);
          this.allChoose = true
          this.getAllChoose()
        }
        this.requestLoad = true
          Indicator.close();
        console.log("获取购物车列表", this.list);
      }).catch(err=>{
        this.requestLoad = true
        Indicator.close();
      });
      this.$forceUpdate();
    },
    //获取商品分类
    getLevel(productId, levelId, num) {
      this.$get(api.productLevelList, {
        productId: productId
      }).then(res => {
        this.level = res.data.data;
        for (let i = 0; i < this.level.length; i++) {
          if (levelId == this.level[i].levelId) {
            this.currentTab = i;
            this.num = num;
          }
        }
        this.showChoose = true;
        console.log("获取商品分类", this.level);
      });
      this.$forceUpdate();
    },
    //全部选中
    getAllChoose() {
      this.allChoose = !this.allChoose;
      this.sum = 0;
      if (this.allChoose) {
        this.chooseList = [];
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].choose = true;
          this.sum = this.cal.accAdd(
            this.cal.accMul(this.list[i].num * 1, this.list[i].price * 1),
            this.sum
          );
          this.chooseList.push(this.list[i]);
        }
      } else {
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].choose = false;
        }
        this.sum = 0;
        this.chooseList = [];
      }
    },
    //增加
    addition(productId, levelId, num, index) {
      console.log(productId, levelId, num, index);
      if (this.showChoose) {
        // this.productId = this.level[this.currentTab].productId
        // this.levelId = this.level[this.currentTab].levelId
        if (this.num >= this.level[this.currentTab].num) {
          Toast("超出当前库存");
        } else {
          this.num++;
          this.cartUpdate(index);
        }
      } else {
        this.productId = productId;
        this.levelId = levelId;
        this.num = num;
        if (this.num == 999) {
          Toast("超出当前库存");
        } else {
          this.num++;
          this.cartUpdate(index);
        }
      }
    },
    //减少数量
    decrease(productId, levelId, num, index) {
      if (this.showChoose) {
        // this.productId = this.level[this.currentTab].productId
        // this.levelId = this.level[this.currentTab].levelId
        if (this.num == 1) {
          Toast("当前数量为1");
        } else {
          this.num--;
          this.cartUpdate(index);
        }
      } else {
        this.productId = productId;
        this.levelId = levelId;
        this.num = num;
        if (this.num == 1) {
          Toast("当前数量为1");
        } else {
          this.num--;
          this.cartUpdate(index);
        }
      }
    },
    changeNum(event, productId, levelId, index) {
      console.log("hjkl", event);
      if (event.target.value) {
        this.num = event.target.value;
      } else {
        this.num = 1;
      }

      this.productId = productId;
      this.levelId = levelId;
      this.cartUpdate(index);
    },
    //修改购物车数量
    cartUpdate(e) {
      this.$post(api.cartUpdate, {
        productId: this.productId,
        shopNumber: this.babyList[this.i].schoolList[this.j].organizationId,
        num: this.num,
        levelId: this.levelId
      }).then(res => {
        if (res.data.code == 0) {
          this.list[e].num = this.num;
          this.sum = 0;
          for (let i = 0; i < this.chooseList.length; i++) {
            this.sum = this.cal.accAdd(
              this.sum,
              this.cal.accMul(this.chooseList[i].price, this.chooseList[i].num)
            );
          }
          // this.getCartList(1);
        } else {
          Toast(res.data.msg);
        }
      });
      // this.$forceUpdate();
    },
    //删除购物车物品
    toDel() {
      console.log(this.chooseList);
      if (this.chooseList.length == 0) {
        Toast("您还没有选择商品哦");
      } else {
        for (let i = 0; i < this.chooseList.length; i++) {
          let arr = {
            productId: this.chooseList[i].productId,
            levelId: this.chooseList[i].levelId
          };
          this.productlist.push(arr);
        }
        console.log("890", this.productlist);
        this.$post(api.cartDel, {
          product: this.productlist,
          shopNumber: this.babyList[this.i].schoolList[this.j].organizationId
        }).then(res => {
          console.log(res);
          if (res.data.code == 0) {
            this.productlist = [];
            Toast("删除成功");
            this.getCartList();
          } else {
            Toast(res.data.msg);
          }
        });
      }
    },
    toGoodsDetails(e) {
      this.$router.push({
        path: "/goodsDetails",
        query: {
          productId: e
          // sku: sku
        }
      });
    },
    //规格选择确定
    confirm() {
      this.productId = this.level[this.currentTab].productId;
      this.levelId = this.level[this.currentTab].levelId;
      this.cartUpdate();
      this.showChoose = false;
    },
    closeChoose() {
      this.showChoose = false;
    },
    changeTo(e) {
      console.log(e);
      this.currentTab = e;
    },
    toOrderDetails() {
      if (this.chooseList.length == 0) {
        Toast("您还没有选择商品哦");
      } else {
        console.log(this.chooseList);
        let a = 0;
        for (let i = 0; i < this.chooseList.length; i++) {
          if (
            this.chooseList[i].productStatus == 0 ||
            this.chooseList[i].levelDelete == 1
          ) {
            Toast("该商品已下架");
          } else {
            a++;
          }
          this.chooseList[i].shopNumber = this.babyList[this.i].schoolList[this.j].organizationId
          // if()
        }

        if (a == this.chooseList.length) {
          storage.set("chooseList", JSON.stringify(this.chooseList));
          this.$router.push({
            path: "/orderDetails"
            // query: {
            //   productId: this.productId,
            //   sku: this.sku,
            //   num:this.num,
            //   currentTab:this.currentTab
            // }
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.waper_header{
  margin-top: 92px;
}
.orderList {
  width: 100%;
  margin-bottom: 80px;
}
.order {
  display: flex;
  padding: 20px;
  align-items: center;
  margin-top: 10px;
  background-color: #ffffff;
}
.orderImg {
  width: 10%;
}
.orderImg img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.orderInfo {
  width: 90%;
  display: flex;
}
.orderInfoL {
  width: 30%;
}
.orderInfoL img {
  width: 80px;
  height: 80px;
  margin-right: 15px;
  /* background-color: grey; */
}
.orderInfoR {
  width: 70%;
}
.orderTit {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-bottom: 6px;
}
.orderType {
  display: inline;
  font-size: 13px;
  color: #8d8d8d;
  background: #f1f1f1;
  border-radius: 15px;
  padding: 2px 7px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
}
.orderType img {
  width: 7px;
  height: 7px;
  margin-left: 6px;
}
.orderTypeR {
  margin: 6px;
  font-size: 12px;
  color: #f85511;
}
.orderNum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
}
.orderNumL {
  font-size: 19px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #f85511;
  letter-spacing: 1px;
}
.orderNumL span {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f85511;
}
.orderNumR {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.orderNumR div {
  border: 1px solid #cdcdcd;
  height: 19px;
  text-align: center;
  line-height: 19px;
  margin: 0;
}
.icon {
  width: 19px;
  margin: 0 2px;
  color: #d8d8d8;
  font-size: 18px;
  font-weight: 600;
}
.number {
  padding: 0 12px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.bottom {
  position: fixed;
  bottom: 68px;
  background-color: #ffffff;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}
.bottomL {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.bottomL img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.bottomL div {
  color: #f85511;
  margin-left: 10px;
}
.bottomR {
  display: flex;
  align-items: center;
}
.bottomRL {
  margin-right: 10px;
}
.text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.money {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.numbers {
  font-size: 19px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #2f2f2f;
  letter-spacing: 1px;
}
.bottomRR {
  font-size: 15px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  padding: 10px 30px;
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
}
.nofound {
  margin: 150px auto;
  text-align: center;
  font-size: 14px;
  height: 140px;
  width: 265px;
  color: #999999;
}
.nofound img {
  width: 100%;
  height: 100%;
  margin-bottom: 25px;
}
.chooseShowbg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.59);
}
.chooseShow {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 13px 22px;
  border-radius: 10px 10px 0px 0px;
}
.chooseInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.chooseInfoL {
  display: flex;
  align-items: flex-end;
  padding-top: 7px;
}
.chooseInfoL img {
  width: 70px;
  height: 70px;
  background-color: darkgray;
  margin-right: 15px;
}
.chooseMoney {
  font-size: 19px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff5001;
  letter-spacing: 1px;
  margin-bottom: 2px;
}
.chooseMoney span {
  font-size: 12px;
  font-weight: 400;
}
.originalMoney {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ababab;
  text-decoration: line-through;
}
.chooseInfoR {
  width: 20px;
  height: 20px;
}
.chooseTit {
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.chooseList {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  padding: 15px 0;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
}
.chooseItem {
  padding: 6px 12px;
  background: #fef8f9;
  border-radius: 5px;
  margin-right: 20px;
  border: 1px solid #f85511;
  color: #ff5001;
}
.nochooseItem {
  padding: 6px 12px;
  background: #fef8f9;
  border-radius: 5px;
  margin-right: 20px;
  color: #2f2f2f;
  background: #f1f1f1;
}
.chooseNum {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chooseNumR {
  display: flex;
}
.chooseNumR div {
  background-color: #f2f2f2;
  text-align: center;
  height: 22px;
  line-height: 22px;
}
.icon {
  width: 22px;
  margin: 0 2px;
  color: #d8d8d8;
  font-size: 18px;
  font-weight: 600;
}
.number {
  padding: 0 12px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.bottomm {
  background: linear-gradient(90deg, #fd8934 0%, #f85b23 100%);
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  text-align: center;
  color: #ffffff;
  margin-top: 120px;
}
.number {
  border: 1px solid #cdcdcd;
  text-align: center;
  padding: 0;
  width: 30px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2f2f2f;
}
.carTit {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f85511;
  line-height: 17px;
  margin-top: 7px;
}
</style>
