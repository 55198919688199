<template>
    <div  style="width:100;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;padding-left: 10px;padding-right: 10px;"  >
        <div style="font-weight: bold;font-size: 16px;margin-top: 20px;">启蒙开心乐园用户服务协议与隐私条款</div>
        <div class="text_content">欢迎您使用北京领悟教育科技有限公司（以下简称“本公司”）提供的启蒙开心乐园软件（以下简称“本软件”）及服务！</div>
        <div class="text_content">为使用本软件及服务，您须仔细阅读并同意遵守《启蒙开心乐园用户服务协议与隐私条款》（以下简称“本协议”）。您的使用、申请及获取账号、登录等行为即视为您已阅读并同意上述协议的约束。本公司有权不时地对本协议条款做任何修改和补充，并在本软件及服务中公布。对本协议条款的修改和补充将在公布时立即生效。您继续登录和使用本软件及服务即视为接受修订后的本协议。否则，您有权通过停止登录且拒绝使用本软件及服务的方式来终止本协议。</div>
        <div class="text_content">您承诺遵守适用的法律、法规、规章及其他政府规定。用户发生违法或犯罪行为，或者本公司有相关证据合理怀疑用户可能存在违法、犯罪或违反本协议的行为的，本公司有权中止或终止对该用户的服务，有权在本公司及互联网络上公布该用户的违法、违约行为，有权向有关机关报案并配合有关机关的调查活动，包括但不限于披露该用户的所有用户信息、交易记录及其他信息，本公司实施上述任何及全部行为所造成的后果、风险及责任由用户自行承担。用户签署本协议即视为理解并接受上述条款，永久性不可撤销地授权本公司公开相关信息及采取相应措施。</div>
        <div class="text_content">本协议内容包括本协议正文以及本公司已经发布的或将来可能发布的各类规则、声明、说明。所有规则、声明、说明为本协议不可分割的一部分，与本协议正文具有同等法律效力。</div>
        <div class="text_content">如果您未满18周岁，请在法定监护人的陪同下阅读本协议及其他上述协议，并在征得监护人同意的前提下使用学习平台。未成年人行使和履行本协议项下的权利和义务即视为已获得了监护人的认可。</div>
        <div class="text_content">您应仔细阅读并充分理解本协议后在登录页面选择同意协议。</div>
        <div class="text_content">您应当清楚，当您点击选择同意协议按钮后，即表示您同意本协议，并与本公司签订本协议生效，您在使用本软件服务过程中不得违反本协议的约定。</div>
        <div class="text_title_max">1.协议的范围</div>
        <div class="text_title_small">1.1.协议适用主体范围</div>
        <div class="text_content">本协议是您与本软件之间关于授权、登录及使用本软件相关服务所订立的协议。</div>
        <div class="text_title_small">1.2.协议关系及冲突条款</div>
        <div class="text_content">本协议内容同时包括本软件可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。</div>
        <div class="text_title_max">2.关于本软件服务</div>
        <div class="text_title_small">2.1.本软件服务的内容</div>
        <div class="text_content">本软件服务内容是指本软件向用户家庭提供儿歌、故事、动画资源的在线学习平台，包括学习产品查看、购买、订单查看、学习记录查看；</div>
        <div class="text_title_small">2.2.本软件服务的形式</div>
        <div class="text_content">您使用本服务需要在微信中使用“启蒙开心乐园”小程序、下载“启蒙乐园”APP，对于这些软件，本软件会根据您所属机构在启蒙未来系统中给您开通的手机号账号，作为您登录“本软件”及APP的账号。系统会在您点击“立即登录”时授权获取您的手机号作为账号登录和使用系统。</div>

        <div class="text_title_max">3.软件的使用及更新</div>
        <div class="text_content">3.1 本公司可能为不同的终端设备开发了不同的软件版本，您应当根据实际情况选择合适的版本进行使用。</div>
        <div class="text_content">3.2为了改善用户体验、完善服务内容，本公司将不断努力开发新的功能或版本，并为您不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。</div>
        <div class="text_content">3.3为了保证本软件及服务的安全性和功能的一致性，本公司有权不经向您特别通知而对本软件进行更新，或者对本软件的部分功能效果进行改变或限制。如果您继续使用本软件及服务，则视为您同意接受更新后的本软件及服务。如果您拒绝接受更新后的本软件及服务，您应立即停止登录及使用本软件及服务；该等情形下，您仍需就您在停止使用前已发生的行为承担全部责任。</div>
        <div class="text_content">3.4您理解并同意，本公司有权因本软件及服务维护、升级或其他原因而暂停服务、调整服务功能或终止服务，本公司将事先在软件上进行公示或通知。您同意本公司无须就此向您承担任何责任。</div>
        <div class="text_title_max">4.用户个人信息保护</div>
        <div class="text_content">4.1 保护用户个人信息是本软件的一项基本原则，本软件将会采取合理的措施保护用户的个人信息。除法律法规规定的情形外，未经用户许可本软件不会向第三方公开、透露用户个人信息。本软件对相关信息采用专业加密存储与传输方式，保障用户个人信息的安全。</div>
        <div class="text_content">4.2 您在授权登录或使用本服务的过程中，需要授权获取您的手机号码。若国家法律法规或政策有特殊规定的，您需要提供真实的身份信息。若您提供的信息不完整，则无法使用本服务或在使用过程中受到限制。</div>
        <div class="text_content">4.3 本软件将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</div>
        <div class="text_content">4.4 未经您的同意，本软件不会向本软件以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。</div>
        <div class="text_content">4.5 本软件非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，请在法定监护人的陪同下阅读本协议及其他上述协议，并在征得监护人同意的前提下使用学习平台。未成年人行使和履行本协议项下的权利和义务即视为已获得了监护人的认可。</div>
        <div class="text_title_max">5.用户使用规范</div>
        <div class="text_title_small">5.1.帐号使用规范</div>
        <div class="text_content">5.1.1 您在使用本服务前需要将您的信息提供给儿童所学习的机构，由机构协助您开通本软件账号。</div>
        <div class="text_content">5.1.2 您用开通好的账号登录本软件为儿童购买的学习产品，在启蒙乐园APP，查看在本软件中购买的学习产品，并学习。</div>
        <div class="text_content">5.1.3 您用开通好的账号登录本软件购买儿童学习周边产品。</div>
        <div class="text_content">5.1.4 您有责任妥善保管帐户信息的安全，您需要对自己的帐户下的行为承担法律责任。用户同意在任何情况下不向他人透露帐户信息。当在您怀疑他人在使用您的帐号时，您应立即通知本公司。</div>
        <div class="text_title_small">5.2.用户注意事项</div>
        <div class="text_content">5.2.1 您理解并同意：为了向您提供有效的服务，本软件会利用您终端设备的处理器和带宽等资源。本软件使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。</div>
        <div class="text_content">5.2.2 您理解并同意本软件将会尽其商业上的合理努力保障您在本软件及服务中的数据存储安全，但是，本软件并不能就此提供完全保证，包括但不限于以下情形：</div>
        <div class="text_content">5.2.2.1 本软件不对您在本软件及服务中相关数据的删除或储存失败负责；</div>
        <div class="text_content">5.2.2.2 如果您停止使用本软件及服务或服务被终止或取消，本软件可以从服务器上永久地删除您的数据。服务停止、终止或取消后，本软件没有义务向您返还任何数据。</div>
        <div class="text_content">5.2.3 您在使用本软件及服务时，须自行承担如下来自本软件不可掌控的风险内容，包括但不限于：</div>
        <div class="text_content">5.2.3.1 由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险；</div>
        <div class="text_content">5.2.3.2 您必须选择与所安装终端设备相匹配的软件版本，否则，由于软件与终端设备型号不相匹配所导致的任何问题或损害，均由用户自行承担；</div>
        <div class="text_content">5.2.3.3 用户在使用本软件访问第三方网站时，因第三方网站及相关内容所可能导致的风险，由用户自行承担；</div>
        <div class="text_content">5.2.3.4 由于无线网络信号不稳定、无线网络带宽小等原因，所引起的本软件登录失败、资料同步不完整、页面打开速度慢等风险。</div>
        <div class="text_title_small">5.3.第三方产品和服务</div>
        <div class="text_content">(1)您在使用本软件第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。本软件和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。</div>
        <div class="text_content">(2)因用户使用本软件或要求本软件提供特定服务时，本软件可能会调用第三方系统或者通过第三方支持用户的使用或访问，使用或访问的结果由该第三方提供（包括但不限于第三方通过本软件公众帐号提供的服务等），本软件不保证通过第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，由此若引发的任何争议及损害，与本软件无关，本软件不承担任何责任。</div>
        <div class="text_title_max">6.用户行为规范</div>
        <div class="text_title_small">6.1.信息内容规范</div>
        <div class="text_content">6.1.1 本条所述信息内容是指用户使用本软件及服务过程中所制作、复制、发布、传播的任何内容，包括但不限于本软件帐号头像、名字等信息，或文字、语音、图片等发送、回复、朋友圈图文和相关链接页面，以及其他使用本软件帐号或本软件及服务所产生的内容。</div>
        <div class="text_content">6.1.2 您理解并同意，本软件一直致力于为用户提供文明健康、规范有序的网络环境，您不得利用本软件帐号或本软件及服务制作、复制、发布、传播如下干扰本软件正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于：</div>
        <div class="text_content">6.1.2.1 发布、传送、传播、储存违反国家法律法规禁止的内容：
            （1）违反宪法确定的基本原则的；
            （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
            （3）损害国家荣誉和利益的；
            （4）煽动民族仇恨、民族歧视，破坏民族团结的；
            （5）破坏国家宗教政策，宣扬邪教和封建迷信的；
            （6）散布谣言，扰乱社会秩序，破坏社会稳定的；
            （7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
            （8）侮辱或者诽谤他人，侵害他人合法权益的；
            （9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；
            （10）以非法民间组织名义活动的；
            （11）遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；
            （12）含有法律、行政法规禁止的其他内容的。
        </div>
        <div class="text_content">6.1.2.2 发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</div>
        <div class="text_content">6.1.2.3 涉及他人隐私、个人信息或资料的；</div>
        <div class="text_content">6.1.2.4 发表、传送、传播骚扰、广告信息、过度营销信息及垃圾信息或含有任何性或性暗示的；</div>
        <div class="text_content">6.1.2.5 其他违反法律法规、政策及公序良俗、社会公德或干扰本软件正常运营和侵犯其他用户或第三方合法权益内容的信息。</div>
        <div class="text_title_small">6.2.软件使用合法性规范</div>
        <div class="text_content">6.2.1 除非法律允许或本软件书面许可，您使用本软件过程中不得从事下列行为，包括但不限于：
            (1)	删除或修改本软件上关于著作权的信息；
            (2)	对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；
            (3)	对本公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
            (4)	对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入本软件和相关系统；
            (5)	 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
            (6)	通过非本公司开发、授权的第三方软件、插件、外挂、系统，登录或使用本软件及服务，或制作、发布、传播上述工具；
            (7)	自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；
            (8)	故意制作、传播计算机病毒等破坏性程序的；
            (9)	其他违反法律法规规定、侵犯其他用户合法权益、干扰本软件及服务正常运营的行为。
        </div>
        <div class="text_content">6.2.2 用户使用本软件及服务过程中不得发生下列行为：
            (1)	提交、发布虚假信息，或冒充、利用他人名义的；
            (2)	强制、诱导其他用户点击链接页面或分享信息的；
            (3)	虚构事实、隐瞒真相以误导、欺骗他人的；
            (4)	侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
            (5)	将软件账号或其任何功能以任何方式提供给第三方，或用于任何商业性用途；
            (6)	利用软件账号或本软件及服务从事任何违法犯罪活动的；
            (7)	制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
            (8)	利用技术手段批量建立虚假账号；
            (9)	其他违反法律法规规定、侵犯其他用户合法权益、干扰本软件及服务正常运营的行为。
        </div>
        <div class="text_content">6.2.3 您理解并同意，基于用户体验、本软件或相关服务平台运营安全、平台规则要求及健康发展等综合因素，本软件有权选择提供服务的对象，有权决定功能设置，有权决定功能开放、数据接口和相关数据披露的对象和范围。针对以下情形，有权视具体情况中止或终止提供本服务，包括但不限于：
            (1)	违反法律法规或本协议规定的；
            (2)	影响服务体验的；
            (3)	存在安全隐患的；
            (4)	与本软件或其服务平台已有主要功能或功能组件相似、相同，或可实现上述功能或功能组件的主要效用的；
            (5)	界面、风格、功能、描述或使用者体验与本软件或其服务平台类似，可能造成本软件用户认为其所使用的功能或服务来源于本软件或经本软件授权的；
            (6)	违背本软件或其服务平台运营原则，或不符合本软件其他管理要求的。
         </div>
         <div class="text_title_small">6.3.服务运营规范</div>
        <div class="text_content">用户使用本软件及服务过程中不得发生下列行为：
            (1)	提交、发布虚假信息，或冒充、利用他人名义的；
            (2)	强制、诱导其他用户点击链接页面或分享信息的；
            (3)	虚构事实、隐瞒真相以误导、欺骗他人的；
            (4)	侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
            (5)	将软件账号或其任何功能以任何方式提供给第三方，或用于任何商业性用途；
            (6)	利用软件账号或本软件及服务从事任何违法犯罪活动的；
            (7)	制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
            (8)	利用技术手段批量建立虚假账号；
            (9)	其他违反法律法规规定、侵犯其他用户合法权益、干扰本软件及服务正常运营的行为。
        </div>
        <div class="text_title_small">6.4.对自己行为负责</div>
        <div class="text_content">您充分了解并同意，您必须为自己帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。本软件无法且不会对因前述风险而导致的任何损失或损害承担责任。</div>
        <div class="text_title_small">6.5.违约处理</div>
        <div class="text_content">6.5.1 如果本软件发现或收到他人举报或投诉用户违反本协议约定的，本软件有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚，并公告处理结果。</div>
        <div class="text_content">6.5.2 您理解并同意，本软件有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</div>
        <div class="text_content">6.5.4 您理解并同意，如果您注册的账号在任何连续【180】日内未实际使用，则本公司有权删除该账号并停止为该用户提供相关服务。</div>
        <div class="text_title_max">7.保密和知识产权保护</div>
        <div class="text_content">7.1 本软件是本软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、H5文件、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，本软件享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。</div>
        <div class="text_content">7.2 未经本软件或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。</div>
        <div class="text_content">7.3 您对本协议的内容以及在本协议签订、履行过程中知悉、获取的本公司的资料和信息，除本公司做出相反的书面说明外，均应视为本公司的商业秘密。未经本公司书面同意不得向任何第三方泄漏。您因违反保密义务而给本公司造成损失的，应负相应的赔偿责任。</div>
        <div class="text_content">7.4尽管有上述规定，但本条款不适用以下信息：
            	依据法律法规的规定应当提供，行政、司法等职权部门要求提供的信息；
            	披露方同意接受方向第三方提供的信息；
            	不违反本协议的情况下，接受方从第三方合法正当接受的，不受保密限制的信息；
            	接受方未违反本协议的情况下即已公开或可以公开获得的信息；
            	法律法规所要求披露的信息。
        </div>
        <div class="text_content">7.5 无论本协议是否变更、解除或终止，本协议保密条款不受其限制而继续有效，双方都应继续承担保密条款约定的保密义务。</div>
        <div class="text_content">7.6 本公司为课程所制作的全部资料，在课程学习过程中产生的全部资料，本公司的软件上所显示、使用或提供的软件、程序及内容，未经本公司同意，您在任何时候均不得以商业目的（包括但不限于将资料上传到您或其他方自媒体平台等）对上述资料进行利用；不得以任何方式翻译、复制、传播、编辑上述资料；不得删除、掩盖或更改本公司的版权声明、商标或其他权利声明/标签。</div>
        <div class="text_title_max">8.违约责任</div>
        <div class="text_content">用户发生如下情形之一的，视为违约：
            （1）使用平台服务时违反有关法律法规规定的；
            （2）违反本协议或平台发布的规则的。
            如果本公司发现或收到他人举报您有违约行为的，本公司有权根据自身的判断，不经通知直接采取以下一项或多项处理措施：
            （1）对相关内容进行删除、屏蔽；
            （2）书面警告，以短信、邮件、站内信等一种或多种形式通知您要求您限期纠正该等行为；
            （3）限制、暂停、终止您使用部分或全部服务；
            （4）不退还任何的服务费用，且不进行任何的赔偿或补偿；
            （5）其他根据平台规则可采取的措施。
            如您的行为使本公司遭受损失的（包括但不限于被相关行政机关进行行政处罚或被第三人主张赔偿的），您应赔偿本公司的全部损失（包括但不限于直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等经济损失）。
        </div>
        <div class="text_title_max">9.不允诺担保、责任有限及免责</div>
        <div class="text_title_small">9.1.不允诺担保：</div>
        <div class="text_content">本公司学习平台的用户提问、回答、发言仅代表用户个人观点，与本公司无关，且本公司不对用户发表的回答或评论的正确性、准确性、真实性进行保证。本公司为按现状提供，包含瑕疵及缺陷，本公司不提供任何其他担保。本公司不允诺其他无论是明示的还是默示的保证和担保，包括但不限于对特定目的的适销性和适应性，不侵权，以及任何出自交易过程或商业惯例的保证和条件。从本公司或他处获取的任何建议或信息，不论是口头的还是书面的，都不会对本公司产生本协议明确约定之外的其他保证和担保。</div>
        <div class="text_title_small">9.2.责任有限：</div>
        <div class="text_content">适用法律所允许的最大范围内，不论出于何种原因，本公司对您的全部责任为您为使用本公司的产品和服务而向本公司支付的费用总额。如果本公司免费向您提供了本公司服务，则本公司无义务对您承担任何赔偿责任。任何情况下，本公司不对出自本协议或与本协议有关的或与本公司的运行有关的任何特殊的、偶然的、惩罚性或间接的损害（包括但不限于数据损失、利润损失或营业损失）或采购替代产品承担责任，不论该等责任是基于合同，担保，侵权（包括过失），严格责任或其他，也不论本公司是否被事先告知了损失或损害发生的可能性。即使本协议约定的有限补救措施未能实现其根本目的，前述的责任限制依然适用。</div>
        <div class="text_title_small">9.3.免责：</div>
        <div class="text_content">如因下所列任何原因而造成的用户资料泄露、丢失、被盗用或被篡改等，本公司均有权获得免责：
            （1）任何因政府管制而造成的暂时性关闭等影响网络正常浏览的不可抗力原因；
            （2）任何计算机系统问题、黑客攻击、计算机病毒侵入或发作的原因；
            （3）任何因网络安全问题导致的账户或银行卡信息泄露，该风险可能会给用户造成经济损失，用户承诺自行承担向侵权方追究责任及追究责任不能的后果；
            （4）任何因用户个人原因导致的用户资料泄露、丢失、被盗用或被篡改等；
            （5）其他不能归责于本公司的原因。
        </div>
        <div class="text_title_max">10.合同终止与解除</div>

        <div class="text_content">1、存在以下情况之一的，本公司将有权终止协议：
            （1）您主动中止或终止，包括但不限于课程到期未进行续费等；
            （2）因为您的违约行为，本公司主动中止或终止提供服务的；
            （3）因国家或相关政府监管部门要求或发生不可抗力事件时，本公司中止或终止服务的；
            （4）其他根据法律法规应中止或终止服务的。
        </div>
        <div class="text_content">2、中止或终止服务后，本公司有权利但无需确保您收到特别提示或通知。当您发现无法登录或享受服务时，可以咨询客服人员。</div>
        <div class="text_content">3、中止或终止后的处理
            （1）除法律规定的责任外，本公司对本服务的中止或终止对您和第三人不承担任何责任；
            （2）已收取的费用按照退款制度予以退还；
            （3）在本公司正在进行的交易，本公司将根据情况合理判断，是否继续进行或终止相关交易，并视情况决定是否进行退费或扣除相关费用作为违约金。
        </div>
        <div class="text_content">4、除本协议另有约定情况外，本协议自您选择接受或使用本服务后生效，直至您终止本服务/注销账号时终止。</div>
        <div class="text_title_max">11.争议解决</div>
        <div class="text_content">在本协议执行期间如果双方发生争议，双方应友好协商解决。如果协商不成，任何一方可向本公司所在地有管辖权的人民法院提请诉讼。
            诉讼期间，除争议内容以外，双方均应继续履行本协议约定的其他内容。
        </div>
        <div class="text_title_max">12.其他</div>
        <div class="text_content">1、本协议自您授权登录提交信息之日起生效，本协议最终解释权归本公司所有，并保留一切解释和修改的权力。本协议其他条款未明示授权的其他一切权利仍由本公司保留，您在行使这些权利时须另外取得本公司的书面许可。本公司如果未行使前述任何权利，并不构成对该权利的放弃。</div>
        <div class="text_content">2、若本协议的任何条款被享有合法管辖权的法院裁定为无效或不可执行，则该等条款应在最低必要范围内予以限制或排除，并以最能体现本用户协议意图的新条款予以替换，以使本用户协议继续完全有效。</div>
        <div class="text_content">3、本协议构成双方之间使用本软件相关的整体协议，并在此取代及取消双方之间先前存在的与该等使用相关的任何书面或口头协议或理解（如有）。</div>
        <div class="text_content">4、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</div>
        <div class="text_content">5、您的账号是您接受平台服务的唯一身份凭证，由您负责保管。如因您的原因造成损失，由您自行承担。</div>
        <div class="text_content">6、您在使用学习平台时不可将个人隐私、商业秘密、国家机密等不得公开的信息发表、上传至本公司的学习平台，也不可将该等信息通过本公司的学习平台的服务传播给其他人。如因您的原因原因造成损失，由您自行承担。</div>
        <div class="text_content">7、您授权登录的账号是您接受平台服务的唯一身份凭证，由您自行负责保管，禁止赠与、借用、租用、转让或者售卖。如因此给本公司造成损害，由您自行承担。</div>
        <div class="text_title_max">13.联系本公司</div>
        <div class="text_content">如果您对本协议有任何疑问、意见或建议，通过以下方式与本公司联系：010-87484999（每周一至周五9:00-18:00）</div>
        <div class="text_content2">北京领悟教育科技有限公司</div>


    </div>
  </template>
  
  <script scoped>
  import { Toast } from "vant";
  import * as api from "@/untils/api";
  import http from "@/untils/http";
  import storage from "good-storage";
  export default {
    name: "binding",
    data() {
      return {
       
      };
    },
    created() {
      // if (localStorage.getItem("userRefreshToken_user") && localStorage.getItem("babyList")) {
      //   that.$router.push('/navigate');
      // } else {
      const height = document.documentElement.clientHeight;
      this.h = height;
      const url = location.search;
      let redirect_uri = "";
      console.log("process.env.NODE_ENV", process.env.NODE_ENV);
      // if (process.env.NODE_ENV == "production") {
      //   redirect_uri =
      //   "https%3A%2F%2Fwebsite.vtronedu.cn%2Fqm_family%2Findex.html";

      // } else if (process.env.NODE_ENV == "none") {

      //   //https://test.vtronedu.cn/qimeng-web/index.html
      //   redirect_uri =
      //   "https%3A%2F%2Fwebsite.vtronedu.cn%2Fqm_family%2Findex.html";

      // } else if (process.env.NODE_ENV == "development") {
      //   redirect_uri =
      //     "https%3A%2F%2Fwebsite.vtronedu.cn%2Fqm_family%2Findex.html";

      // }
    
      // if (localStorage.getItem("userUnionId")) {
      //   this.signIn = true;
      //   // this.data=JSON.parse(localStorage.getItem("babyList"))
      //   // console.log('this.data',JSON.parse(localStorage.getItem("babyList")));
      //   this.userUnionId=localStorage.getItem("userUnionId")
      //   console.log('this.userUnionId',this.userUnionId);

      //   this.openId=localStorage.getItem("openId")
      //   console.log('this.openId',this.openId);
      //   this.getParentInfo();
      // } else {
       
      //     console.log('/进入参数判断-----login');
      //     if (url.indexOf("?") != -1) {
      //       var str = url.substr(1);
      //       this.wxCode = str.split("&")[0].split("=")[1];
      //       console.log(this.wxCode,'/this.wxCode-----login');
      //     }
      //     if (this.wxCode == "") {
      //       console.log('/微信换取code重定向开始-----login');
      //       window.location.href =
      //         "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1c924e14678d55d1&redirect_uri=" +
      //         redirect_uri +
      //         "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
      //          //'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxae80bb1fd4a0d45b&redirect_uri='
      //       // + redirect_uri + '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
      //     } 
      //     this.cms_token();
        
      // }
      
   
  
     
     
     
        
      },
   
  };
  </script>
  
  <style scoped>
  

  .liL {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2b2b2b;
    display: flex;
    align-items: center;
  }
  .left {
    width: 63%;
  }
  .right {
    width: 35%;
    /* width: 80px; */
    height: 40px;
    margin-right: 0;
  }
  .right img {
    width: 100%;
    height: 100%;
  }
  .text_content{
    font-size: 12px;margin-top: 15px;width: 100%;
  }
  .text_title_max{
    font-size: 16px;margin-top: 15px;width: 100%;
    font-weight: bold;
  }
  .text_title_small{
    font-size: 14px;margin-top: 15px;width: 100%;
    font-weight: bold;
  }
  .text_content2{
    font-size: 12px;margin-top: 15px;width: 100%;
    margin-bottom: 20px;text-align: right;
}
  </style>