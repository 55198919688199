
<template>
  <div class="changeName">
    <div class="change">
      <div class="changeName">
        <input type="text" placeholder="请填写修改姓名" v-model="name">
        <div class="bottom" @click="changeUserInfo">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Toast,
    Swipe,
    SwipeItem
  } from "mint-ui";
  import * as api from "@/untils/api";
  import http from "@/untils/http";
  import storage from "good-storage";
  export default {
    name: "changeName",
    data() {
      return {
        name: ''
      };
    },
    created() {
      this.babyList = JSON.parse(storage.get("babyList"))
      this.i = storage.get("i")
      this.j = storage.get("j")
      console.log(this.babyList)
      this.userToken = storage.get('userToken_user')
      var base64Url = this.userToken.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      console.log(JSON.parse(window.atob(base64)));
      this.userUnionId = JSON.parse(window.atob(base64)).userData.userUnionId
      this.userName = JSON.parse(window.atob(base64)).userData.userName
      console.log(this.userUnionId)
    },
    methods: {
      //更改用户信息
      changeUserInfo() {
        console.log('214312')
        this.$put(api.uesrUpdate, {
          userUnionId: this.userUnionId,
          username: this.userName,
          organizationId: this.babyList[this.i].schoolList[this.j].organizationId,
          realName: this.name,
          // headImage: this.headImage,
        }).then(res => {
          console.log(res)
          this.getCurrentParentInfo();
        })
      },
      //获取用户信息
      getCurrentParentInfo() {
        this.$get(
          api.getCurrentParentInfo,
        ).then((res) => {
          if(res.data.data.babyList.length > 0 ){
            res.data.data.babyList.forEach((baby,babyIndex)=>{
              if(baby.schoolList && baby.schoolList.length != 0){
                baby.schoolList.forEach(school=>{
                  school.name = school.shortName || '暂无院所别名'
                })
              }
            })
            res.data.data.babyList = res.data.data.babyList.filter(item=>item.schoolList.length != 0)
            this.babyList = res.data.data.babyList
            storage.set("babyList", JSON.stringify(res.data.data.babyList));
            console.log('123', res.data.data.babyList)
            this.$router.go(-1);
          }
        });
      },
    },
  };
</script>

<style scoped>
  body {
    background-color: #ffffff;
  }
  .changeName {
    position: fixed;
    /* top: 74px; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    padding: 10px 20px;
  }
  input {
    padding: 15px 0;
    width: 100%;
    border-bottom: 1px solid #cecece;
  }
  input::-webkit-input-placeholder {
    font-size: 14px;
    color: #a0a0a0;
    letter-spacing: 2px;
  }
  .bottom {
    background: linear-gradient(90deg, #00cbff 0%, #438bf9 100%);
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    width: 85%;
    text-align: center;
    margin: 5% auto;
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    opacity: 0.59;
    color: #ffffff;
  }
</style>
