// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'
import App from './App'
import router from './router'
import Mint from 'mint-ui';
import Vant from 'vant';
import '@/permission';
import 'mint-ui/lib/style.css';
import 'vant/lib/index.css';
import 'swiper/swiper-bundle.css';
import store from './store/index';
Vue.use(Mint);

Vue.use(Vant);
import cal from '@/untils/calculation'
import { updateUserInfo } from '@/untils/set_family_info'
Vue.prototype.cal = cal
//更新用户信息
Vue.prototype.$UpdateUserInfo = updateUserInfo
Vue.config.productionTip = false
/* eslint-disable no-new */
// router.beforeEach((to, from, next) => {
//     /* 路由发生变化修改页面title */

// })

//全局注册过滤器
import * as filters from './filters'
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
import { VueJsonp }  from  'vue-jsonp'
Vue.use(VueJsonp)
new Vue({
        el: '#app',
        router,
        store,
        components: {
            App
        },
        template: '<App/>'

    })
    // document.onselectstart = function() { return false; }
    // document.onselectstart = function() { return false; }
