<template>
  <div class="videoBank" v-cloak>
    <video id="video" :src="videoSrc" controls="controls"></video>
    <div class="list" v-for="(item,index) in videoList" :key="index">
      <div class="li" @click='clickAudio(index)'>
        <img name='dynamicImg' :src="item.lessonCoverImage+imgSizeQuality" alt="">
        <div :class="[index==num?'choose': 'nochoose']"> {{item.title}}</div>
      </div>
      <!-- <div  :class="[index==num?'choose': 'nochoose']"> {{videoList[num].title}}</div>
          <div  :class="[index==num?'choose': 'nochoose']"> {{videoList[num].title}}</div> -->
    </div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import http from "@/untils/http";
  import dynamicImgwh from "@/Mixins/dynamicImgwh"
  export default {
    name: 'videoBank',
    mixins: [dynamicImgwh],
    data() {
      return {
        videoList: [],
        videoSrc: '',
        num: 0,
        myVideo:''
      }
    },
    created() {

      this.sectionId = this.$route.query.sectionId;
      this.getAudioList();
    },
    methods: {
      getAudioList() {
        let that = this
        this.$get(api.sectionInfo, {
          sectionId: that.sectionId,
        }).then(res => {
          that.videoList = res.data.data.objectList
          that.videoSrc = that.videoList[0].mediaUrl
          console.log('获取环节列表', that.videoList)
          that.get_dynamicImgSQ()
        })
      },
      clickAudio(e) {
   let myVideo=document.getElementById("video");
        console.log(e)
        this.num = e
        this.videoSrc = this.videoList[e].mediaUrl
        this.$nextTick(() => {
          myVideo.src=this.videoList[e].mediaUrl
           myVideo.play();
          // this.$refs.videoPlay.load();
        });
        console.log(this.videoSrc)
        this.$forceUpdate();
      },
    },
  }
</script>

<style scoped>
  body {
    background-color: #FFFFFF;
  }
  video {
    width: 100%;
  }
  .choose {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #24A8FB;
  }
  .list {
    padding: 20px;
  }
  .list div {
    height: 70px;
    line-height: 70px;
  }
  .nochoose {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .li img {
    width: 132px;
    height: 75px;
    margin-right: 12px;
  }
</style>
