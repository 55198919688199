import dynamicImgwh from "@/Mixins/dynamicImgwh"
export default {
    name:'curriculum',
    mixins: [dynamicImgwh],
    props:{
        info:{
            type:Object,
            default:()=>{}
        },
        size:{
            type:String,
            default:'small'
        },
        expireShow:{
            type:Boolean,
            default:true
        }
    },
    data(){
        return {
            validityDateType:true
        }
    },
    created(){
        if(this.info.validityDate.indexOf('永久') != -1){
            this.validityDateType= false
        }
        this.get_dynamicImgSQ()
    },
    
    methods:{
        
    }
}