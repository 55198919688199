<template>
  <div class="appraisalInfo">
    <img class="bg" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/main/infoBg@2x.png" alt="">
    <div class='content'>
      <div class="top">
        <div>级别推荐</div>
        <div class="tit">{{list.comment}}</div>
      </div>
      <div class="info">
        <div class="li">
          <div class="liTit">宝宝姓名</div>
          <div class="liTxt">{{name}}</div>
        </div>
        <div class="li">
          <div class="liTit">测评时间</div>
          <div class="liTxt">{{createTime}}</div>
        </div>
        <div class="li">
          <div class="liTit">出生日期</div>
          <div class="liTxt">2020/10/10</div>
        </div>
      </div>
      <div class="evaluate">
        <div id="leiDaTu" class="echart" >
        </div>
        <div class="evaluateTxt">
         {{text}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import echarts from 'echarts'
  import http from "@/untils/http";
  export default {
    name: 'appraisalInfo',
    data() {
      return {
        name:'',
        createTime:'',
        birthday:'',
        list:[],
        nameList:[],
        value:[],
        text:''
      }
    },
    created() {
      this.reportId = this.$route.query.reportId;
      this.createTime = this.$route.query.createTime;
      this.name = this.$route.query.name;
      this.birthday = this.$route.query.birthday;
      this.getReportInfo()
    },
    mounted() {
      // this.$nextTick(function() {
      //   this.drawPie('leiDaTu')
      // })
    },
    methods: {  
      drawPie() {
        let nameList =this.nameList
        let value =  this.value
        // console.log(this.nameList,this.value)
        let charts = echarts.init(document.getElementById('leiDaTu'));
        var option = {
          title: {
          text: null
        }, // 隐藏图表标题
        legend: {
          show: false
        }, // 隐藏图例
        tooltip: {
          trigger: 'axis'
        },
        grid: { // 控制图的大小，调整下面这些值就可以，
          x: 40,
          x2: 100,
          y2: 150 // y2可以控制 X轴跟Zoom控件之间的间隔，避免以为倾斜后造成 label重叠到zoom上
        },
        polar: [{
          nameGap: 3, // 图中工艺等字距离图的距离
          splitNumber: 3, // 网格线的个数
          center: ['50%', '50%'], // 图的位置
          indicator: nameList,
          axisLine: {
            show: false,        // 默认显示，属性show控制显示与否
            lineStyle: {       // 属性lineStyle控制线条样式
                color: '#000',
                width: 0,
                type: 'solid'
            }
          },
          name: {
            show: true,
            formatter: null,
            textStyle: {
              color: '#000',
              fontSize: '15rem',
              fontWeight: 500
            }
          },
          axisLabel: {
            show: false,
          },
          splitArea: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#DDDDDD' // 图表背景网格线的颜色
            }
          }
        }],
        areaStyle: {
          color: "rgba(251, 140, 0, 0.42)"
        },
        series: [{
          name: '完全实况球员数据',
          type: 'radar',
          symbol: "none", // 去掉图表中各个图区域的边框线拐点
          itemStyle: {
            normal: {
              lineStyle: {
                color: "rgba(255,255,255,0.5)" // 图表中各个图区域的边框线颜色
              },
              areaStyle: {
                type: 'default'
              }
            }
          },
          data: [{
            value: value,
            itemStyle: {
              normal: {
                areaStyle: {
                  type: 'default',
                  opacity: .9, // 图表中各个图区域的透明度
                  color: "#FB8C00" // 图表中各个图区域的颜色
                }
              }
            },
          }]
        }]
        }
        charts.setOption(option);
      },
      getReportInfo() {
        this.$get(
          api.ReportGetInfo, {
            reportId: this.reportId,
          }
        ).then((res) => {
          console.log("获取测评列表", res.data)
          this.list = res.data.data
           console.log("获取测评列表", this.list)
           for(let i =0;i<this.list.subject.length;i++){
             let arr={
              text: this.list.subject[i].title,
              max: this.list.subject[i].maxScore
            }
            this.nameList.push(arr)
            // this.value =this.value+","+this.list.subject[i].score
            this.value.push(this.list.subject[i].score)
            console.log(this.list.subject[i].comment!=null)
            if(this.list.subject[i].comment!=null){
               this.text = this.text+this.list.subject[i].comment
            }

           }
           console.log(this.nameList,this.value)
           this.drawPie()
          // this.showClass = true
          // this.lessonNum = res.data.data.lesson
        })
      },
      //获取试卷详情
      toAppraisalInfo(reportId) {
        this.$router.push({
          path: '/appraisalInfo',
          query: {
            reportId: reportId
          }
        });
      }
    }
  }
</script>

<style scoped>
  .appraisalInfo {
    position: relative;
  }
  .bg {
    width: 100%;
    position: absolute;
    z-index: 9;
  }
  .content {
    z-index: 99;
    position: relative;
    padding: 0 15px;
  }
  .top {
    text-align: center;
    padding: 22px 0;
    font-size: 11px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;

  }
  .tit {
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    padding-top: 12px;
  }
  .info {
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 60px;
      margin-bottom: 12px;
  }
  .li {
    text-align: center;
  }
  .liTit {
    font-size: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #A8A8AD;
    margin-bottom: 5px;
  }
  .liTxt {
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #67676B;
  }
  .evaluate {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 15px;
  }
  .evaluateTxt {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
   .echart {
          margin: 20px auto;
          width: 300px;
          height: 250px;

        }
</style>
