import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import http from "@/untils/http";
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        ageList: [],//儿童年龄段
        ageIndex:1,//儿童默认年龄段
        shopInfo:{},//当前用户下的商铺信息
        babySchools:[],//顶部园所选择项
    },
    mutations:{
        // 保存儿童年龄段
        saveAgeList(state,ageList){
            state.ageList = ageList;
        },
        // 用户所选儿童年龄段
        saveAgeIndex(state,ageIndex){
            state.ageIndex = ageIndex
        },
        // 保存商铺信息
        saveShopInfo(state,shopInfo){
            state.shopInfo = shopInfo
        },
        //保存用户学校选择菜单
        saveBabySchools(state,babySchools){
            state.babySchools = babySchools
        },
        //清空vux中数据
        resetVuex (state){
            state.ageList = []
            state.ageIndex = 1
            state.shopInfo = {}
            state.babySchools = []
        },
    },
    actions:{
        // 获取公用配置文件
        getCommonProfile(context){
            return Vue.prototype.$get('https://service-images.cdn.bcebos.com/appFiles/sysConfig.json')
            .then(res=>{
                if(res.status != 200 || !res.data){
                    let ageList = [{
                       "age":"通用","value":0
                    },{"age":"2-3周岁","value":3
                    },{"age":"3-4周岁","value":4
                    },{"age":"4-5周岁","value":5
                    },{"age":"5-6周岁","value":6
                    }]
                    context.commit('saveAgeList',ageList)
                }else{
                    const { ageList } = res.data
                    context.commit('saveAgeList',ageList)
                }
            })
            .catch(function (error) {
                let ageList = [{
                    "age":"通用","value":0
                 },{"age":"2-3周岁","value":3
                 },{"age":"3-4周岁","value":4
                 },{"age":"4-5周岁","value":5
                 },{"age":"5-6周岁","value":6
                 }]
                 context.commit('saveAgeList',ageList)
                console.log(error)
            })
        }
    },
    plugins: [createPersistedState()]
})
