<template>
  <div class="unerInfo">
    <div class="list">
      <div class="li">
        <div class="liL">头像</div>
        <div class="liR">
          <input
            class="upImage"
            type="file"
            @change="upData($event)"
            ref="InputFile"
            name="files"
          />
          <img
            class="userImg"
            :src="
              (uplineInfo.headImage == '' || !uplineInfo.headImage)
                ? 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/userImg@2x.png'
                : uplineInfo.headImage
            "
            alt=""
          />
          <img class="next" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" alt="" />
        </div>
      </div>
      <div class="li" @click="changeName">
        <div class="liL">姓名</div>
        <div class="liR">
          <div>{{ uplineInfo.realName }}</div>
          <img class="next" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" alt="" />
        </div>
      </div>
      <div class="li" @click="changePhone">
        <div class="liL">手机号</div>
        <div class="liR">
          <div>{{ uplineInfo.mobile }}</div>
          <img class="next" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/go@2x.png" alt="" />
        </div>
      </div>
      <!-- <div class="li">
        <div class="liL">宝宝幼儿园</div>
        <div class="liR">
          <div>{{ babyList[i].schoolList[0].name }}</div>
        </div>
      </div> -->
    </div>
    <div class="babyList">
      <div class="babyTit">我的宝宝</div>
      <div class="babyLi" v-for="(item, index) in babyList" :key="index">
        <img
          :src="
            item.headImage == ''
              ? 'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/user/babyImg@2x.png'
              : item.headImage
          "
          alt=""
        />
        <div>
          <div class="babyName">{{ item.name }}</div>
          <div class="babyPark">
            <!-- <span v-if="item.schoolList[0].classList.length != 0">{{
              item.schoolList[0].classList[0].name
            }}</span> -->
            {{ item.schoolList.length >0 ? item.schoolList[0].name : '暂无园所' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { Toast } from "mint-ui";
import storage from "good-storage";
import * as api from "@/untils/api";
import http from "@/untils/http";
export default {
  name: "unerInfo",
  data() {
    return {
      name: false,
      babyList: [],
      i: 0,
      isUpload: false,
      imgUrl: "",
      headImage: "",
      uplineInfo:{}
    };
  },
  created() {
    this.babyList = JSON.parse(storage.get("babyList"));
    console.log(this.babyList);

    this.i = storage.get("i");
    this.j = storage.get("j");
    console.log(storage.get("i"), this.babyList[this.i]);
    this.userToken = storage.get("userToken_user");
    var base64Url = this.userToken.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    console.log(JSON.parse(window.atob(base64)));
    this.userUnionId = JSON.parse(window.atob(base64)).userData.userUnionId;
    // 获取当前登录人的信息
    this.uplineInfo = this.babyList.map(item=>{
      if(item.babyFamily && item.babyFamily.length > 0){
        return item.babyFamily
      }
    }).reduce((a, b) => a.concat(b)).filter(item=>item.userUnionId == this.userUnionId)[0]
    console.log(this.userUnionId);
  },
  methods: {
    upData(event) {
      var reader = new FileReader();
      let fileData = this.$refs.InputFile.files[0];
      reader.readAsDataURL(fileData);
      let _this = this;
      reader.onload = function(e) {
        //这里的数据可以使本地图片显示到页面
        _this.addimg = e.srcElement.result;
      };
      // 使用formapi打包
      let formData = new FormData();
      formData.append("file", fileData);
      axios
        .post(api.upload, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          console.log(res);
          (this.headImage = res.data.data.url), this.changeUserInfo();
        });
    },
    //更改用户信息
    changeUserInfo() {
      console.log("214312");
      this.$put(api.uesrUpdate, {
        userUnionId: this.userUnionId,
        username: this.uplineInfo.mobile,
        organizationId: this.babyList[this.i].schoolList[this.j].organizationId,
        headImage: this.headImage
      }).then(res => {
        console.log(res);
        this.getCurrentParentInfo();
      });
    },
    //获取用户信息
    getCurrentParentInfo() {
      this.$get(api.getCurrentParentInfo).then(res => {
        if(res.data.data.babyList.length > 0 ){
          res.data.data.babyList.forEach((baby,babyIndex)=>{
            if(baby.schoolList && baby.schoolList.length != 0){
              baby.schoolList.forEach(school=>{
                school.name = school.shortName || '暂无院所别名'
              })
            }
          })
          res.data.data.babyList = res.data.data.babyList.filter(item=>item.schoolList.length != 0)
          this.babyList = res.data.data.babyList;
          storage.set("babyList", JSON.stringify(res.data.data.babyList));
          console.log("123", res.data.data.babyList);
        }
      });
    },
    //修改姓名
    changeName() {
      // this.name = true
      this.$router.push("/changeName");
    },
    //修改手机号
    changePhone() {
      this.$router.push("/changePhone");
    },
    //关闭修改姓名
    closeName() {
      this.name = false;
    }
  }
};
</script>

<style scoped>
body {
  color: #151515;
}
.userImg {
  width: 59px;
  height: 59px;
  border-radius: 100%;
  background-color: darkgray;
  /* margin-right: 8px; */
}
.list {
  background-color: #ffffff;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 18px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  padding: 0 15px;
}
.li {
  display: flex;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
}
.li:last-child {
  border: 0;
}
.liR {
  display: flex;
  align-items: center;
  position: relative;
}
.upImage {
  position: absolute;
  right: 0;
  width: 80px;
  height: 59xp;
  opacity: 0;
}
.next {
  width: 12px;
  height: 16px;
  margin-left: 10px;
}
.babyList {
  background: #ffffff;
  padding: 0 15px;
}
.babyTit {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 11px 10px;
  border-bottom: 1px solid #eeeeee;
}
.babyLi {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding: 15px 10px;
}
.babyLi:last-child {
  border: 0;
}
.babyLi img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: darkgray;
  margin-right: 9px;
}
.babyName {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #151515;
}
.babyPark {
  margin-top: 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #a0a0a0;
}
.babyPark span {
  margin-right: 25px;
}
</style>
