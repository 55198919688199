export default {
    data() {
        return {
        }
    },
    mounted() {
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.goBack, false);
          }
    },
    methods: {
        // mountPopstate() {
        //     if (window.history && window.history.pushState) {
        //         history.pushState(null, null, document.URL);
        //         console.info('挂载后', history, '挂载当前页面时候的历史长度')
        //         window.addEventListener('popstate', this.back, false);
        //     }
        // }
    },
    destroyed() {
        window.removeEventListener('popstate', this.goBack, false);
    },
}
