<template>
  <div class="game" v-cloak>
    <div class="bg"></div>
    <div class="aaa">
      <!-- <div class='back'>
                                                <a id="back" href="javascript:history.back();" rel="external nofollow"> <img src="../image/login/back.png" alt=""></a>
                                                <div style='margin-left:-18px'>游戏闯关</div>
                                                <div style="width: 5px;height: 5px;"></div>
                                            </div> -->
      <div class="top">
        <div class="topL">
          <div>题目</div>
          <div @click='playAudio'>
            <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/game/voice@2x.png" alt="">
          </div>
        </div>
        <div class="topR">
          <span>{{num+1}}</span>/{{questionsNum}}
        </div>
      </div>
      <div class="content">
        <div class="contentTit">{{gameTit}}</div>
        <div class="contentList">
          <div v-for='(item,index) in gameList' :key="index" @click='chooseRight(index)' :class="[one==index||item.one?'chooseLi': 'contentLi']">
            <div class="contentLiL">
              <div v-if='gameabout==1||gameabout==2'>{{item.prefixCode}}.{{item.title}}</div>
              <div v-if='gameabout==7||gameabout==8'  style="display: flex;justify-content: center;align-items: center;">
                <div>{{item.prefixCode}}</div>    <img :src="item.title"  alt="">
              </div>

            </div>
            <div v-if='showKey'>
              <div class="contentLiR" v-if='gameabout==1||gameabout==7'>
                <!-- <img v-if="one==index" src="../image/game/right@2x.png" alt=""> -->
              </div>
              <div class="contentLiR" v-if='gameabout==2||gameabout==8'>
                <!-- <img v-if='item.one' src="../image/game/right@2x.png" alt=""> -->
              </div>
            </div>
            <div v-else>
              <div v-if='isCorrect'>
                <div class="contentLiR" v-if='gameabout==1||gameabout==7'>
                  <img v-if="item.isCorrect=='true' " src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/game/right@2x.png" alt="">
                </div>
                <div class="contentLiR" v-if='gameabout==2||gameabout==8'>
                  <img v-if="item.isCorrect=='true' " src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/game/right@2x.png" alt="">
                </div>
              </div>
              <div v-else>
                <div class="contentLiR" v-if='gameabout==1||gameabout==7'>
                  <img v-if='one==index' :src="item.isCorrect=='true'?'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/game/right@2x.png':'https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/game/no.png'" alt="">
                </div>
                <div class="contentLiR" v-if='gameabout==1||gameabout==7'><img v-if="item.isCorrect=='true' " src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/game/right@2x.png" alt=""></div>
                <div class="contentLiR" v-if='gameabout==2||gameabout==8'><img v-if="item.one&&item.isCorrect=='false'" src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/game/no.png" alt=""></div>
                <div class="contentLiR" v-if='gameabout==2||gameabout==8'><img v-if="item.isCorrect=='true' " src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/game/right@2x.png" alt=""></div>
              </div>
            </div>
            <!-- <div class="contentLiR" v-if='gameabout==2'>11111</div> -->
            <!-- <div class="contentLiR" v-if='gameList[num].type==2'><img :src="../image/game/right@2x.png" alt=""></div> -->
          </div>
        </div>
        <div v-if='showKey'>
          <div class="contentBitton" @click='showResult'>提交</div>
        </div>
        <div v-else>
          <div class="analysis">
            <div class="analysisTit">解析: <span v-if='isCorrect'>回答正确</span> <span v-else>回答错误</span></div>
            <div v-if='gameTxt'>{{gameTxt}}</div>
            <div v-else>暂无解析</div>
          </div>
          <div class="contentBitton" v-if='questionsNum ==num+1' @click='next'>提交试卷</div>
          <div class="contentBitton" v-else @click='next'> 下一题</div>
        </div>
      </div>
      <div class="popup" v-if="end">
        <div class="show">
          <img src="https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/say/happy@2x.png" alt="">
          <div class="hint">
            <div class="hintTit">平均得分</div>
            <div class="hintTxt" v-if='score==100'>你真棒</div>
            <div class="hintTxt" v-else>再接再厉哦～</div>
            <div class="hintNum">
              <span>{{score}}</span>分
            </div>
            <div class="hintBut">
              <div class="hintButton" @click='toChoosePart'>完成</div>
              <div class="hintButtons" @click='delReportId'>重做</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import http from "@/untils/http";
  import storage from "good-storage";
  export default {
    name: 'game',
    data() {
      return {
        gameList: [],
        arrList:['A','B','C','D','E','F','G','H','I','J','K'],
        gameTit: '',
        gameabout: '',
        one: 99,
        num: 0,
        // isPlay: true,
        choose: 0,
        end: false,
        right: 0, //对的题数
        questionsNum: '',
        score: 0,
        showKey: true,
        isCorrect: false,
        gameTxt: ''
      }
    },
    created() {
      var isPageHide = false;
      console.log('--=-=-=-')
      window.addEventListener('pageshow', function() {
        console.log('1234567')
        if (isPageHide) {
          window.location.reload();
        }
      });
      window.addEventListener('pagehide', function() {
        console.log('789089')
        isPageHide = true;
      });
      this.audio = new Audio();
      this.sectionId = this.$route.query.sectionId;
      this.babyId = this.$route.query.babyId;
      this.classId = this.$route.query.classId;
      this.getAudioList();
    },
    methods: {
      //播放示例语音
      playAudio() {
        //  this.soundUrl = res.data.data.audioUrl
        this.audio.src = this.subjects[0].questions[this.num].titleSound;
        this.audio.play()
      },
      getAudioList() {
        this.$get(api.sectionInfo, {
          sectionId: this.sectionId,
          babyId: this.babyId,
        }).then((res) => {
          if (res.data.code == 0) {
            console.log(res, res.data.data.lessonId)
            this.lessonId = res.data.data.lessonId
            this.reportId = res.data.data.reportId
            this.paperId = res.data.data.objectList[0].paperId
            console.log(this.reportId)
            if (this.reportId.length != 0 && this.reportId != 0) {
              console.log('111')
              this.reportInfo()
              this.end = true
            } else {
              console.log('222')
              this.getPaperInfo()
            }
          } else {
            Toast(res.data.msg);
          }
          this.$forceUpdate();
        });
      },
      //获取考题
      getPaperInfo() {
        this.$get(api.paperInfo, {
          paperId: this.paperId,
        }).then((res) => {
          if (res.data.code == 0) {
            this.subjects = res.data.data.subjects
            console.log(this.subjects)
            console.log(res.data.data.subjects[0].questions[this.num].answers, res.data.data.subjects[0].questions[this.num].answers.length)
            for (let i = 0; i < res.data.data.subjects[0].questions[this.num].answers.length; i++) {
              res.data.data.subjects[0].questions[this.num].answers[i].one = false
            }
            this.questionsNum = res.data.data.subjects[0].questions.length
            this.gameabout = res.data.data.subjects[0].questions[this.num].type
            this.gameList = res.data.data.subjects[0].questions[this.num].answers

            // for(let i=0;i<res.data.data.subjects[0].questions[this.num].answers.length;i++){
            //   if( res.data.data.subjects[0].questions[this.num].type==7|| res.data.data.subjects[0].questions[this.num].type==8'){
            //      res.data.data.subjects[0].questions[this.num].answers[i].title =this.arrList[i]+'.'+res.data.data.subjects[0].questions[this.num].answers[i].title

            //   }

            // }
            this.gameTit = res.data.data.subjects[0].questions[this.num].title
            this.gameTxt = res.data.data.subjects[0].questions[this.num].analysis
            // this.soundTts();
            console.log('获取试题', this.gameList, this.gameabout)
          } else {
            Toast(res.data.msg);
          }
        })
      },
      //转换音频
      // soundTts() {
      //   this.$post(api.soundTts, {
      //     content: this.gameTit,
      //     speed: 20,
      //     vcn: 'xiaoyan'
      //   }).then((res) => {
      //     if (res.data.code == 0) {
      //       console.log(res.data.data.audioUrl)
      //       this.soundUrl = res.data.data.audioUrl
      //     } else {
      //       Toast(res.data.msg);
      //     }
      //   })
      // },
      //获取分数
      reportInfo() {
        this.$get(api.reportInfo, {
          id: this.reportId,
        }).then((res) => {
          if (res.data.code == 0) {
            console.log('获取成功分数', res)
            this.score = res.data.data.score
          } else {
            Toast(res.data.msg);
          }
        })
      },
      chooseRight(e) {
        this.doTest = true
        if (this.showKey) {
          if (this.gameabout == 1 || this.gameabout == 7) {
            // for(i = 0; i < this.gameList.length; i++)
            this.one = e
          }
          if (this.gameabout == 2 || this.gameabout == 8) {
            this.gameList[e].one = !this.gameList[e].one
          }
        }
      },
      next() {
        if (this.num == this.questionsNum - 1) {
          console.log(this.right)
          this.calculate();
        } else {
          this.one = '99'
          this.num = this.num + 1
          this.isCorrect = false
          this.showKey = true
          this.getPaperInfo();
        }
      },
      showResult() {
        if (this.gameabout == 1 || this.gameabout == 7) {
          if (this.one == '99') {
            this.$toast('请选择这道题的答案');
            this.showKey = true
          } else {
            this.showKey = false
            console.log(this.one, this.gameList[this.one].isCorrect)
            if (this.gameList[this.one].isCorrect == 'true') {
              this.isCorrect = true
              this.right++;
            } else {
              this.isCorrect = false
            }
          }
        }
        if (this.gameabout == 2 || this.gameabout == 8) {
          console.log('多选题')
          var a = 0;
          var b = 0
          for (let i = 0; i < this.gameList.length; i++) {
            if (this.gameList[i].one.toString() == 'false') {
              a++;
            }
            console.log(a, this.gameList.length)
            if (a == this.gameList.length) {
              console.log('12345678990---==')
              this.$toast('请选择这道题的答案');
              this.showKey = true
            } else {
              this.showKey = false
              if (this.gameList[i].isCorrect == this.gameList[i].one.toString()) {
                b++;
              }
              if (b == this.gameList.length) {
                this.isCorrect = true
                this.right++;
              } else {
                this.isCorrect = false
              }
            }
          }
        }
      },
      calculate() {
        console.log('123444')
        let that = this
        console.log(that.right, that.questionsNum)
        that.score = Math.ceil(that.right / that.questionsNum * 100)
        console.log(that.score)
        that.end = that
        that.$post(api.saveForUser, {
          paperId: that.paperId,
          subjects: that.subjects,
          score: that.score,
          userId: that.babyId,
        }).then((res) => {
          if (res.data.code == 0) {
            console.log('获取成功', res)
            console.log(res.data.data.reportId)
            that.reportId =res.data.data.reportId
            that.$post(api.evaluate, {
              reportId: that.reportId,
              classId: that.classId,
              score: that.score,
              babyId: that.babyId,
              sectionId: that.sectionId,
            }).then((res) => {
              console.log('保存reportId成功', res)
            })
          } else {
            Toast(res.data.msg);
          }
        })
      },
      toChoosePart() {
        this.$router.go(-1);
      },
      //删除答题报告重新做
      delReportId() {
        console.log(this.reportId)
        this.$delete(api.reportDel, {
          reportId: this.reportId
        }).then((res) => {
          console.log(res)
          if (res.data.code == 0) {
            this.end = false
            // location.reload();
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
            var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
              this.$router.go(0)
            }else{
              window.location.reload()
            }
            //  this.getAudioList();
          } else {
            Toast(res.data.msg);
          }
          this.$forceUpdate();
        });
      }
    }
  }
</script>

<style scoped>
  .game {
    position: relative;
  }
  .bg {
    position: fixed;

    /* z-index: 1; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* width: 100%;
                                    height: 100%; */
    background-image: url(https://service-images.cdn.bcebos.com/soft/qimeng/family/assets/game/bg@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
  }
  .aaa {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
  }
  .top {
    /* margin-bottom: 20px; */
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .topL {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #F46869;
  }
  .topL div:last-child {
    margin-left: 10px;
    width: 30px;
    height: 24px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
  }
  .topL img {
    /* margin-left: 16px; */
    line-height: 24px;
    margin: 4px 8px;
    /* margin-top: 3px; */
    width: 16px;
    height: 16px;
  }
  .topR {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(134, 134, 134, 1);
  }
  .topR span {
    font-size: 18px;
    color: #F2696B;
  }
  .content {
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    /* width: 100%; */
    min-height: 220px;
    padding-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
    position: relative;
  }
  .contentTit {
    font-size: 18px;
    word-wrap: break-word;
    white-space: norma;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(46, 47, 50, 1);
    padding: 25px 20px;
  }
  .contentList {
    padding: 10px;
  }
  .contentLi {
    border-bottom: 1px solid #E9E9E9;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(102, 102, 102, 1);
    border-bottom: 1px solid #E9E9E9;
    display: flex;
    align-items: center;
    padding: 0 10px;
    min-height: 60px;
    justify-content: space-between;
  }
  .chooseLi {
    min-height: 60px;
    padding: 0px 10px;
    background: rgba(255, 245, 242, 1);
    color: rgba(242, 107, 106, 1);
    border-bottom: 1px solid #E9E9E9;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contentLiL {
    width: 90%;
  }
  .contentLiL div {
    text-align: left;
    word-wrap: break-word;
    word-break: normal
  }
  .contentLiL img {
    margin: 10px auto;
    /* text-align: center; */
    width: 150px;
    height: 100px;
  }
  .contentLiR {
    width: 20px;
    /* display: flex;
                                    justify-content: flex-end; */
    /* line-height: 60px; */
  }
  .contentLiR img {
    width: 18px;
    height: 18px;
  }
  .contentBitton {
    width: 206px;
    margin: 50px auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #F26B6A;
    border-radius: 20px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(255, 255, 255, 1);
  }
  .gif {
    width: 100%;
    text-align: center;
    margin-bottom: 100px;
    /* position: absolute; */
    /* margin-top: 30%; */
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(36, 168, 251, 1);
  }
  .gif img {
    width: 100%;
    height: 70px;
  }
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.59);
    padding: 50px;
  }
  .show {}
  .show img {
    height: 155px;
    width: 100%;
  }
  .hint {
    margin-top: -16px;
    width: 100%;
    height: 270px;
    background: rgba(255, 255, 255, 1);
    border-radius: 19px 19px 50px 50px;
    text-align: center;
  }
  .hintTit {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #F26B6A;
    padding: 10px 0;
  }
  .hintTxt {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(195, 208, 226, 1);
  }
  .hintNum {
    width: 206px;
    height: 105px;
    background: #FFEEF1;
    border-radius: 15px;
    margin: 10px auto;
    line-height: 105px;
    text-align: center;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #F26B6A;
  }
  .hintNum span {
    font-size: 50px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #F26B6A;
  }
  .hintBut {
    margin-top: 40px;
  }
  .hintButton {
    width: 162px;
    height: 49px;
    background: #F26B6A;
    border-radius: 25px;
    font: size 18px;
    margin: 0 auto;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(255, 255, 255, 1);
    line-height: 49px;
  }
  .hintButtons {
    width: 162px;
    height: 49px;
    background: rgba(251, 122, 23, 1);
    border-radius: 25px;
    font: size 18px;
    margin: 0 auto;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(255, 255, 255, 1);
    line-height: 49px;
    margin-top: 15px;
  }
  .analysis {
    background: #FBFBFB;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
    margin: 20px;
    padding: 10px;
    word-wrap: break-word;
    white-space: norma;
  }
  .analysisTit {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #2E2F32;
    margin-bottom: 3px;
  }
  .analysisTit span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #F26B6A;
  }
</style>
