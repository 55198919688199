/**
 * @name 请求api配置文件
 * @author 田乃予
 * @version 1.0.0
 * @default 2021.1.23
 */
// let host = false
// let url = host ? 'https://dev.babygrow.cn' : 'https://test.babygrow.cn'
// import GLOBAL from '@/utils/global_variable'
// let url = GLOBAL.url //知识服务
let url = process.env.API_URL
let updateUrl = process.env.UPLOAD_URL
    // let url = 'https://test.babygrow.cn'

/**
 * @name 获取临时凭证
 */
export const Authorization = url + '/certificate?'

/**
 * @name 获取图形验证码
 */
export const captchaImg = url + '/captcha/index'

/**
 * @name 获取openid
 */
export const getOpenid = url + '/wechat/wechat-openid'

/**
 * @name 微信openId换取登录凭证
 */
export const weixin_openId_refresh = url + '/cas/jwt/login/weixinOpenId/refresh'

/**
 * @name 验证图形验证码
 */
export const checkCaptchaImg = url + '/captcha/verify'

/**
 * @name 验证短信验证码
 */
export const CaptchaPhone = url + '/captcha/sendSms'
/**
 * @name 验证短信验证码1
 */
export const CaptchaPhone1 = url + '/cas/sms/get/captcha'

/**
 * @name 登录
 */
export const login = url + '/cas/jwt/login/weixinOpenId/token'

/**
 * @name 家长登录获取个人信息
 */
export const getCurrentParentInfo = url + '/userCenter/user/getCurrentParentInfo'

/**
 * @name 添加收货地址
 */
export const addAddress = url + '/shop/address/add'

/**
 * @name 获取收货地址列表
 */
export const addressList = url + '/shop/address/list'

/**
 * @name 修改收货地址
 */
export const addressUpdate = url + '/shop/address/update'

/**
 * @name 获取购物车商品
 */
export const cartList = url + '/shop/cart/list'

/**
 * @name 商品分类统计
 */
export const productCount = url + '/shop/catagory/count'

/**
 * @name 刷新token
 */
export const refreshToken = url + '/certificate/refresh'

/**
 * @name 修改用户信息
 */
export const uesrUpdate = url + '/userCenter/user/update'

/**
 * @name 获取getAccessToken
 */
export const getAccessToken = url + '/wechat/getAccessToken'

/**
 * @name 获取微信JSSDK签名
 */
export const getJsapiTicket = url + '/wechat/getjssdksign'

/**
 * @name 用户退出登录
 */
export const logOut = url + '/certificate/logOut'

/**
 * @name 更换用户手机号码
 */
export const changePhone = url + '/userCenter/user/union/update'

/**
 * @name 获取当前开课数量
 */
export const lessonCount = url + '/qimeng/lessionEvaluate/count'

/**
 * @name 获取商铺列表
 */
export const shopInfo = url + '/shop/list'

/**
 * @name 获取商品列表
 */
export const productList = url + '/shop/product/list'

/**
 * @name 获取商品信息
 */
export const productInfo = url + '/shop/product/info'

/**
 * @name 加入购物车
 */
export const cartAdd = url + '/shop/cart/add'

/**
 * @name 文件上传
 */
export const upload = updateUrl + '/upload'


/**
 * @name 批量获取儿童信息
 */
export const getByOnlyBabyIds = url + '/childrenService/baby/list/getByOnlyBabyIds'

/**
 * @name 商品规格列表
 */
export const productLevelList = url + '/shop/productLevel/list'

/**
 * @name 修改购物车数量
 */
export const cartUpdate = url + '/shop/cart/update'

/**
 * @name 订单列表
 */
export const orderList = url + '/shop/order/list'


/**
 * @name 获取课程列表
 */
export const courseList = url + '/qimeng/class/list'

/**
 * @name 获取课节列表
 */
export const lessonList = url + '/qimeng/lesson/list'

/**
 * @name 获取学生学习记录
 */
export const reportList = url + '/qimeng/reportList'

/**
 * @name 获取环节列表
 */
export const sectionList = url + '/qimeng/section/list'

/**
 * @name 获取环节详情
 */
export const sectionInfo = url + '/qimeng/section/info'

/**
 * @name 获取试题
 */
export const paperInfo = url + '/evaluateService/paper/info'

/**
 * @name 语音测评
 */
export const soundIse = url + '/soundService/xunfei/sound/ise'

/**
 * @name 保存答题
 */
export const saveForUser = url + '/evaluateService/answer/saveForUser'

/**
 * @name 添加学生评价
 */
export const evaluate = url + '/qimeng/evaluate'

/**
 * @name 获取报告详情
 */
export const reportInfo = url + '/evaluateService/report/info'

/**
 * @name 查询儿童信息列表
 */
export const ListPage = url + '/childrenService/baby/list/page?sysId=1277785189073645568'

/**
 * @name 提交订单
 */
export const addPayOrder = url + '/shop/order/addPayOrder'

/**
 * @name 获取订单信息
 */
export const orderInfo = url + '/shop/order/info'

/**
 * @name 文字转语音
 */
export const soundTts = url + '/soundService/xunfei/sound/tts'

/**
 * @name 获取答题报告列表
 */
export const reportGetList = url + '/evaluateService/reportanswerrecord/list'

/**
 * @name 音频转码
 */
export const transCoding = url + '/soundService/audio/transCoding'

/**
 * @name 获取商铺列表
 */
export const GetshopList = url + '/shop/list'

/**
 * @name 获取报告列表
 */
export const ReportList = url + '/evaluateService/report/getList'

/**
 * @name 获取报告详情
 */
export const ReportGetInfo = url + '/evaluateService/report/getInfo'

/**
 * @name 按栏目统计
 */
export const reportSubjectCount = url + '/evaluateService/reportSubject/count'

/**
 * @name 删除测评报告
 */
export const reportDel = url + '/qimeng/reportDel'

/**
 * @name 开课课节列表
 */
export const indexStatusList = url + '/qimeng/lesson/statusList'

/**
 * @name 园所开通课程查询
 */
export const getSchoolCourse = url + '/qimengService/schoolcourse/getSchoolCourse'

/**
 * @name 获取亲属关系
 */
export const listByKey = url + '/userCenter/dictionary/listByKey'

/**
 * @name 检查孩子是否重复
 */
export const getRepeatBabyCount = url + '/childrenService/baby/getRepeatBabyCount'

/**
 * @name 检查线索是否重复
 */
 export const crm_customer_list = url + '/crm/customer/list'

/**
 * @name 根据家长手机号获取儿童信息列表
 */
export const getChildrenByParentPhone = url + '/childrenService/family/getChildrenByParentPhone'

/**
 * @name 新增儿童
 */
export const babySave = url + '/childrenService/baby/save'


/**
 * @name 订单支付结果查询接口
 */
export const payQuery = url + '/shop/order/payQuery'

/**
 * @name 重新支付接口
 */
export const orderRepay = url + '/shop/order/repay'

/**
 * @name 删除购物车商品
 */
export const cartDel = url + '/shop/cart/del'

/**
 * @name 用户订单统计
 */
export const orderCount = url + '/shop/user/orderCount'

/**
 * @name 检查账号是否存在
 */
export const parentCheck = url + '/userCenter/user/login/parent/check'

/**
 * @name 客户线索注册
 */
export const crm_customer_clue = url + '/crm/customer/clue'

/**
 * @name 线索用户注册
 */
export const parentReg = url + '/userCenter/user/login/parent/reg'

/**
 * @name 线索用户登录
 */
export const parentLogin = url + '/userCenter/user/login/parent/login'

/**
 * @name 线索用户信息获取
 */
export const parentInfo = url + '/userCenter/user/login/parent/info'

//机构学堂

/**
 * @name 课程列表信息
 */
export const shopCourse = url + '/online/shop/course'

/**
 * @name 课程详情
 */
export const courseDetails = url + '/online/course'

/**
 * @name 课程订单
 */
export const postCourseOrde = url + '/shop/order/addCourseOrder'

/**
 * @name 按时间段统计学习记录
 */
export const studyCountDay = url + '/knowledge/study/countDay'

/**
 * @name 获取套餐课下的所有的单课程
 */
export const suitCourse = url + '/online/suit/course'

/**
 * @name 课程学习进度
 */
export const studyCount = url + '/knowledge/study/count'

/**
 * @name 验证一个家庭是否购买过相同商品
 */
export const shopCheck_repeat= url + '/shop/order/checkUserOrder'

/**
 * @name 登录上报用户信息
 */
 export const client_heartBeat= url + '/client/heartBeat'

 /**
 * @name 获取儿童所在班级列表
 */
  export const getDepartmentByBabyId= url + '/organizationService/organization/baby/getDepartmentByBabyId'

/**
 * @name 用户登录接口（账号密码/短信验证码）
 */
export const codeLogin= url + '/cas/jwt/login/user/token'

/**
 * @name 保存用户微信信息

 */
export const saveWXInfo= url + '/userCenter/user/wechat/save'  


/**
 * @name 删除用户微信绑定信息

 */
export const deleteWXInfo= url + '/userCenter/user/wechat/delete'