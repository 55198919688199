<template>
  <div class="changePhone">
    <div class="list">
      <div class="li phone">
        <div class="phoneL">修改手机号码</div>
        <div class="phoneR">
          <input type="tel" maxlength="11" placeholder="请输入手机号码" @input="phoneNum" v-model='phone'>
        </div>
      </div>
      <div class="li line">
        <div class="lineL"> <input type="text" placeholder="请输入右侧验证码" v-model='code'></div>
        <div class="lineR">
          <img :src="img" alt="" style="width: 80px;height: 30px;margin-right: 0;" @click='changeCode'>
        </div>
      </div>
      <div class="li line">
        <div class="lineL">
          <input type="text" placeholder="请输入短信验证码" v-model='phoneCode'>
        </div>
        <div class="lineR">
          <button :class=" iscode ? 'codeDisabled' : 'code' " @click="clickCode" v-html='codemsg' type="button" :disabled="iscode"></button>
        </div>
      </div>
    </div>
    <div class="button" @click='submit'>提交</div>
  </div>
</template>

<script>
  import {
    Toast
  } from "mint-ui";
  import * as api from "@/untils/api";
  import http from "@/untils/http";
      import storage from "good-storage";
  export default {
    name: 'changePhone',
    data() {
      return {
        img: '',
        captchaId: '',
        phone: '',
        code: '',
        phoneCode: '',
        codeDisabled: true,
        codemsg: '发送验证码', // 获取验证码的文案
        t: '', // 倒计时的T
        second: 60, // 倒计时的T
        iscode: false,
        img: '',
        wxCode: '',
        h: '',
      }
    },
    created() {
      this.babyList = JSON.parse(storage.get("babyList"))
      this.i = storage.get("i")
      this.userToken = storage.get('userToken_user')
      var base64Url = this.userToken.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      console.log(JSON.parse(window.atob(base64)));
      this.userUnionId = JSON.parse(window.atob(base64)).userData.userUnionId
      console.log(this.userUnionId)
      this.getCaptchaImg()
    },
    methods: {
      //点击切换图形验证码
      changeCode() {
        this.getCaptchaImg();
      },
      //获取图形验证码
      getCaptchaImg() {
        this.$get(api.captchaImg, {
          mode: 'character',
        }).then(res => {
          console.log(res)
          this.img = res.data.data.captcha
          this.captchaId = res.data.data.captchaId
        })
      },
      //验证手机号
      phoneNum() {
        const regex = /^(1)\d{10}$/;
        if (!regex.test(this.phone)) {
          this.codeDisabled = true;
          // Toast("请正确输入手机号");
          return
        }
        this.codeDisabled = false;
      },
      // 倒计时
      clickTime() {
        console.log(this)
        this.t = setInterval(() => {
          if (this.second == 0) {
            this.codemsg = '重新获取'
            this.iscode = false
            this.second = 60
            clearInterval(this.t)
            return
          }
          this.iscode = true
          this.codemsg = this.second + "s后重发"
          this.second = this.second - 1
        }, 1000)
      },
      // 点击获取验证码
      clickCode() {
        let that = this
        console.log(that, that.code)
        // const regex = /^(1)\d{10}$/;
        if (!/^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/.test(that.phone)) {
          Toast("请输入正确的手机号");
          that.getCaptchaImg();
          that.code = ''
          return
        } else {
          if (that.code.length != '') {
            that.$get(api.checkCaptchaImg, {
              captchaId: that.captchaId,
              captcha: that.code
            }).then(res => {
              console.log(res)
              that.$post(api.CaptchaPhone, {
                mobile: that.phone,
              }).then(res => {
                console.log(res)
                if (res.data.code == 0) {
                  that.clickTime()
                } else {
                  Toast(res.data.msg);
                  that.getCaptchaImg();
                }
              })
            })
          } else {
            Toast("请输入图形验证码");
          }
        }
      },
      //确认 //更改用户信息
      submit() {
        console.log(this)
        let that = this
        if (that.phone == '') {
          Toast("请输入手机号");
        } else {
          if (that.code == '') {
            Toast("请填写图形验证码");
          } else {
            if (that.phoneCode == '') {
              Toast("请填写短信验证码");
            } else {
              console.log('修改成功')
              that.$put(api.changePhone, {
                username: that.phone,
                code: that.phoneCode,
                userUnionId: that.userUnionId
                // headImage: this.headImage,
              }).then(res => {
                if (res.data.code == 0) {
                  console.log(res)
                  that.getCurrentParentInfo();
                } else {
                  Toast(res.data.msg);
                  that.getCaptchaImg();
                  that.phone='',
                  that.phoneCode ='',
                  that.code= ''
                }
              })
            }
          }
        }
      },
      //获取用户信息
      getCurrentParentInfo() {
        this.$get(
          api.getCurrentParentInfo,
        ).then((res) => {
          if(res.data.data.babyList.length > 0 ){
            res.data.data.babyList.forEach((baby,babyIndex)=>{
              if(baby.schoolList && baby.schoolList.length != 0){
                baby.schoolList.forEach(school=>{
                  school.name = school.shortName || '暂无院所别名'
                })
              }
            })
            res.data.data.babyList = res.data.data.babyList.filter(item=>item.schoolList.length != 0)
            this.babyList = res.data.data.babyList
            storage.set("babyList", JSON.stringify(res.data.data.babyList));
            console.log('123', res.data.data.babyList)
            this.$router.go(-1);
          }
        });
      },
    }
  }
</script>

<style scoped>
  .list {
    padding: 30px;
    margin-bottom: 50%
  }
  .li {
    height: 55px;
    border-bottom: 1px solid #CECECE;
    display: flex;
    align-items: flex-end;
    padding-bottom: 7px;
  }
  .phone {}
  .phoneL {
    width: 30%;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    padding-right: 10%;
  }
  .phoneR {
    width: 60%;
  }
  .line {
    /* display: flex; */
    justify-content: space-between;
  }
  .lineR button {
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(55, 152, 250, 1);
  }
  .button {
    background: linear-gradient(90deg, #00cbff 0%, #438bf9 100%);
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    width: 85%;
    text-align: center;
    margin: 5% auto;
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    opacity: 0.59;
    color: #ffffff;
  }
</style>
