<template>
  <div class="register">
    <div class="tit">{{ params.selectName || params.name }} 欢迎您 ~</div>
    <div class="hint">为了更好的为您提供服务，请填写如下信息：</div>
    <div class="li">
      <div class="liL">您的姓名</div>
      <div class="liR">
        <input type="text" v-model="name" placeholder="请输入姓名" />
      </div>
    </div>
    <div class="li">
      <div class="liL">联系电话</div>
      <div class="liR">
        <input
          type="tel"
          maxlength="11"
          v-model="phone"
          placeholder="请输入手机号"
        />
      </div>
    </div>
    <div class="button" @click="next">下一步</div>
    <van-popup v-model="show">
      <div class="popup">
        <div class="popupTit">手机号已存在，可直接登录呦</div>
        <div class="popupBut" @click="toLogin()">返回登录</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Picker, DatetimePicker, Toast, Popup } from "vant";
import * as api from "@/untils/api";
import http from "@/untils/http";
import storage from "good-storage";
export default {
  name: "register",
  data() {
    return {
      params: [],
      name: "",
      phone: "",
      show: false
    };
  },
  created() {
    this.params = JSON.parse(this.$route.query.params);
    console.log(this.params);
    this.cms_token();
  },
  methods: {
    cms_token() {
      this.$get(api.Authorization, {
        appId: "1434797802239561728"
      }).then(res => {
        storage.set("userToken_user", "Bearer " + res.data.data);
        // localStorage.setItem("userToken_user", 'Bearer ' + res.data.data);
        // this.getListByKey();
      });
    },
    next() {
      if (this.name == "") {
        Toast("请输入姓名");
      } else {
        if (
          !/^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/.test(
            this.phone
          )
        ) {
          Toast("请输入正确的手机号");
        } else {
          this.$get(api.parentCheck, {
            mobile: this.phone,
            organizationId: this.params.shopNumber
          }).then(res => {
            if (JSON.stringify(res.data.data) == "{}") {
              this.$router.push({
                path: "/authCode",
                query: {
                  params: this.$route.query.params,
                  phone: this.phone,
                  name: this.name
                }
              });
            } else {
              this.show = true;
            }
          });
        }
      }
    },
    toLogin() {
      this.show = false;
      this.$router.push({
        path: "/login",
        query: {
          params: this.$route.query.params
        }
      });
    }
  }
};
</script>

<style scoped>
.register {
  margin: 40px;
}
.tit {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 28px;
}
.hint {
  margin-top: 18px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #67676b;
  line-height: 20px;
  margin-bottom: 8px;
}
.li {
  display: flex;
  align-items: center;
  margin: 16px 0;
}
.liL {
  margin-right: 8px;
}
.liR {
  width: 210px;
  height: 44px;
  line-height: 44px;
  background: #f7f7f7;
  border-radius: 50px;
}
.li input {
  padding-left: 20px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
input::-webkit-input-placeholder {
  color: #cdcdd1;
}
input::-moz-input-placeholder {
  color: #cdcdd1;
}
input::-ms-input-placeholder {
  color: #cdcdd1;
}
.button {
  margin-top: 30px;
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: linear-gradient(90deg, #00cbff 0%, #438bf9 100%);
  border-radius: 27px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
</style>
<style>
body {
  margin: 0;
  padding: 0;
  background: #ffffff;
}
.van-popup {
  width: 272px;
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
  filter: blur(0px);
}
.popup {
  text-align: center;
  padding: 30px 0;
}
.popupTit {
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #19191a;
  line-height: 27px;
}
.popupBut {
  width: 127px;
  height: 44px;
  line-height: 44px;
  background: linear-gradient(90deg, #00cbff 0%, #438bf9 100%);
  border-radius: 27px;
  margin: 30px auto 0;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
</style>
